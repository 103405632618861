import { Component, OnInit } from '@angular/core';
import { BreadCrumb } from '@nationwide/internet-servicing-angular-components';
import { environment } from 'environments/environment';
import { PersonalConstants } from '../common/personalConstants';
import { LoggerService } from '../shared/services/logger.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'error-Prop',
    templateUrl: './errorProp.html'
})

export class ErrorPropComponent implements OnInit {
    breadCrumbs: BreadCrumb[];
    homeIconLink = environment.NATIONWIDE.accountSummaryPage;
    currentPage = 'Contact us';
    constructor (private LOGGER: LoggerService) { }

    ngOnInit(): any {
        this.breadCrumbs = [
            {
                name: 'Claims center',
                link: environment.NATIONWIDE.accountSummaryPage
            },
            {
                name: 'File a claim',
                link: `${environment.NATIONWIDE.appBasePath}/#${PersonalConstants.URLS.fileAClaim}`
            },
            {
                name: 'Contact us',
                link: `${environment.NATIONWIDE.appBasePath}/#${PersonalConstants.URLS.errorProp}`
            }
        ];

        this.LOGGER.error(`User navigated to Property Error page via route ${window.location.hash}`);
    }

    callNationwide(): void {
        window.location.href = environment.NATIONWIDE.HOME;
    }
}
