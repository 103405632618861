import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
interface BuildInfo {
    branchName: string,
    aws_bucket: string,
    bucket_Env: string,
    date: string;
    build_type: string;
}
@Component({
  selector: 'is-buildinfo',
  templateUrl: './buildinfo.component.html',
  styleUrls: ['./buildinfo.component.scss']
})
export class BuildinfoComponent implements OnInit {
    branchName: string;
    aws_bucket: string;
    bucket_Env: string;
    build_date: string;
    build_type: string;
    isLoading = true;
    isError = false;

    constructor (
        private http: HttpClient,
  ) { }

    ngOnInit(): void {
       this.http.get('tridion/build.json', { responseType: 'json' }).subscribe((
           results: BuildInfo) => {
                this.isLoading = false;
                this.isError = false;
                this.build_date = results?.date;
                this.aws_bucket = results?.aws_bucket;
                this.branchName = results?.branchName;
                this.bucket_Env = results?.bucket_Env;
                this.build_type = results?.build_type;
        });
    }
}
