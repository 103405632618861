import { Injectable } from '@angular/core';
import { ClaimsOidcAuthService } from 'app/efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { environment } from 'environments/environment';
import { PersonalConstants } from '../../common/personalConstants';
import { LocationService } from './location.service';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';

@Injectable()
export class ClaimsHelperService {
    static readonly SERVICE_ERROR = '***SERVICE ERROR***';
    static readonly ERROR = '***ERROR***';

    constructor (
        private locationService: LocationService,
        private sessionService: SessionService,
        private authService: ClaimsOidcAuthService,
        private loggerService: LoggerService) { }


    filterExposureByWhitelist(exposures: any): any {
        return exposures.filter((exposure) =>
            PersonalConstants.trackerCoverageWhiteList.find((element) => element === exposure.coverageType));
    }

    getPreferredExposure(exposures: any[]): any {
        let prefExposure: any;

        switch (exposures.length) {
            case 0:
                this.loggerService.error('Claim does not have an exposure');
                break;
            case 1:
                prefExposure = exposures[0];
                break;
            // multiple exposures
            default:
                const openExposures = exposures.filter((exposure) => exposure.exposureState === 'open');
                switch (openExposures.length) {
                    case 0:
                        exposures.forEach((currentExposure) => {
                            currentExposure.closeTime = Date.parse(currentExposure.closeTime);
                        });
                        prefExposure = exposures.reduce((max, currentExposure) =>
                            (max.closeTime > currentExposure.closeTime ? max : currentExposure)
                        );
                        prefExposure.closeTime = prefExposure.closeTime.toString();
                        prefExposure.hasMultipleDeductible =
                            exposures.filter((exposure) => exposure.exposureState === 'closed')
                                .length > 1;
                        break;
                    case 1:
                        prefExposure = openExposures[0];
                        break;
                    // multiple open exposures
                    default:
                        prefExposure = openExposures.reduce((max, currentExposure) =>

                            (max.deductible > currentExposure.deductible ? max : currentExposure)
                        );
                        break;
                }
                break;
        }
        return prefExposure;
    }

    hasVanishingDeductible(coverage: any): any {
        let hasVanishing = false;
        if (coverage.coverageTerms.length > 0) {
            for (const coverageTerm of coverage.coverageTerms) {
                if (coverageTerm.description === 'Vanishing Deductible') {
                    hasVanishing = true;
                }
            }
        }
        return hasVanishing;
    }

    getInsuredContact(claimContacts: any): any {
        let contact;
        for (const claimContact of claimContacts) {
            if (claimContact.contactDTO.isInsured) {
                contact = claimContact;
            }
        }

        return contact;
    }

    checkExposureIncidentType = function (exposure: any, type: string): boolean {
        if (exposure.lobs.personalAuto !== undefined || exposure.lobs.homeowner !== undefined) {/* check for weird data */
            switch (type) {
                case 'vehicle':
                    return (exposure.lobs.personalAuto.vehicleIncident !== undefined);
                case 'property':
                    return (exposure.lobs.homeowner.fixedPropertyIncident !== undefined);
                case 'injury':
                    return (exposure.lobs.personalAuto.injuryIncident !== undefined);
            }
        }
    };

    processAddressValidation(address: string, addressValidationAttempts: number): any {
        const sessionlogparams = this.sessionService.getSessionLogParams();
        const encodedLocation: string = encodeURIComponent(address);
        return new Promise((resolve: any, reject: any) => {
            this.locationService.validateAddress(encodedLocation)
                .then((validationResult) => resolve(this._handleValidateAddressResult(validationResult,
                    addressValidationAttempts))).catch((error) => {
                        this.loggerService.error('Claims Helper - An error occurred in validating address:',
                            { error: error }, sessionlogparams);
                        // eslint-disable-next-line prefer-promise-reject-errors
                        reject({
                            errorDetails: error,
                            errorType: ClaimsHelperService.SERVICE_ERROR
                        });
                    });
        });
    }

    // this is for getting the policy address primary location for property policies only
    // For Auto policies we need to get PNI address from contact DTO instead of primary policy address
    getHomeAddress(continueAnyways?: boolean): Promise<any> {
        const sessionlogparams = this.sessionService.getSessionLogParams();
        return new Promise(
            (resolve: any, reject: any) => {
                const addresses: any = this.sessionService.getFnolObject() || {};
                if (Object.keys(addresses).length > 0) {
                    const currentLobList = Object.keys(addresses.policy.lobs);
                    const primaryAddress: any = [];
                    for (const address of addresses.policy.lobs[currentLobList[0]].locations) {
                        if (address.locationNumber === '1') {
                            primaryAddress.push(address);
                        }
                    }
                    return this.locationService.validateAddress(encodeURIComponent(primaryAddress[0].locationAddress.displayName))
                        .then((response) => {
                            if (!!response.location) {
                                const claimsLocation = this._mapLossMatchToClaimsLocation(response.location);
                                claimsLocation.publicID = primaryAddress[0].locationAddress.publicID;
                                this.sessionService.setPolicyAddress(claimsLocation);
                                this.loggerService.debug('ClaimsHelper - validateAddress success for home address :',
                                    { response: response }, sessionlogparams);
                                return resolve(claimsLocation);
                            } else {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return reject('invalid home address');
                            }
                        })
                        .catch((error) => {
                            this.loggerService.error('ClaimsHelper - validateAddress failure for home address :', { error: error },
                                sessionlogparams);
                            if (continueAnyways) {
                                return Promise.resolve(primaryAddress);
                            } else {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject({
                                    errorDetails: error,
                                    errorType: ClaimsHelperService.ERROR
                                });
                            }
                        });
                }
            });
    }

    _handleValidateAddressResult(validationResult: any,
        addressValidationAttempts: number): any {
        const sessionlogparams = this.sessionService.getSessionLogParams();
        if (validationResult.isValid || addressValidationAttempts >= 3) {
            if (!validationResult.isValid) {
                // user validated 3 times with an invalid address, bypass and use home address
                // sending home address for even if it's invalid acc. to requirement
                return this.getHomeAddress(true).then((homeAddress) => {
                    this.loggerService.debug('ClaimsHelper - User entered address Validation failed 3 times hence mapping Home address:',
                        null, sessionlogparams);
                    return homeAddress;
                }).catch((error) => {
                    this.loggerService.error('ClaimsHelper - An error occurred in validating address:',
                        { error: error }, sessionlogparams);
                    if (addressValidationAttempts < 3) {
                        // this._utilService.showAlert('',
                        // CommonConstants.ErrorMessages.CONNECTION_DROPPED_MSG, CommonConstants.Buttons.OK);
                    }
                    return ({
                        errorDetails: error,
                        errorType: ClaimsHelperService.SERVICE_ERROR
                    });
                });
            } else {
                return this._mapLossMatchToClaimsLocation(validationResult.location);
            }
        } else {
            return Promise.resolve(({
                errorDetails: '',
                errorType: ClaimsHelperService.ERROR
            }));
        }
    }

    _mapLossMatchToClaimsLocation(locationMatch: any): any {
        const claimsLocation: any = {
            addressLine1: locationMatch.attributes.StAddr,
            city: locationMatch.attributes.City,
            state: locationMatch.attributes.Region,
            postalCode: locationMatch.attributes.Postal,
            country: locationMatch.attributes.CountryCode,
            county: locationMatch.attributes.SubRegion ? locationMatch.attributes.SubRegion.replace(/county/i, '').trim() : 'unknown',
            displayName: locationMatch.address,
            latitude: Number(locationMatch.location.y).toPrecision(7),
            longitude: Number(locationMatch.location.x).toPrecision(7),
            hexID: locationMatch.attributes.HexId

        };
        return claimsLocation;
    }

    lossTypeToCamelCase(lossType: string): any {
        if (lossType.toUpperCase() === 'AUTO') {
            return 'Auto';
        } else if (lossType.toUpperCase() === 'PROPERTY') {
            return 'Property';
        } else {
            return 'Unknown';
        }
    }

    isMobile(): any {
        let isMobile: boolean;

        isMobile = false;
        if (window
            .navigator
            .userAgent
            .match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|kindle|Windows Phone|playbook|silk|IEMobile|Opera Mini|mobile.+firefox/i)) {
            isMobile = true;
        }

        return isMobile;
    }

    getLossCauseTranslations(lossCause: any): any {
        const lossCauseMap = {
            // for auto
            'earthmovement': { 'translated': 'Earth Movement', 'style': 'earth-movement' },
            'animal': { 'translated': 'Contact With Animal', 'style': 'animal' },
            'injured_around_veh': { 'translated': 'Injury', 'style': 'injury' },
            'vehcollision': { 'translated': 'Accident With Other Vehicle(s)', 'style': 'multicarcollision' },
            'lockout': { 'translated': 'Roadside Assistance', 'style': 'roadside' },
            'fixedobjcoll': { 'translated': 'Contact With Fixed Object', 'style': 'contact-fixed-object' },
            'vandalism': { 'translated': 'Vandalism', 'style': 'vandalism' },
            'fire': { 'translated': 'Fire', 'style': 'fire' },
            'glassbreakage': { 'translated': 'Glass Damage Only', 'style': 'glass' },
            'partialTheft': { 'translated': 'Theft', 'style': 'theft' },
            'theftentire': { 'translated': 'Theft', 'style': 'theft' },
            'pedcollision': { 'translated': 'Pedestrian Accident', 'style': 'pedestrian-accident' },
            'bikecollision': { 'translated': 'Accident With Other Vehicle(s)', 'style': 'multicarcollision' },
            'shoppingcart': { 'translated': 'Contact With Object', 'style': 'contact-object' },
            'loadingdamage': { 'translated': 'Business Auto Claim', 'style': 'business-auto-claim-loss-cause' },
            'FallingObject': { 'translated': 'Object Hit Vehicle', 'style': 'object-hit-vehicle' },
            'ranOffRoad': { 'translated': 'Accident', 'style': 'accident' },
            'weatherFlood': { 'translated': 'Weather', 'style': 'weather' },
            'weatherHail': { 'translated': 'Weather', 'style': 'weather' },
            'weatherAllOther': { 'translated': 'Weather', 'style': 'weather' },
            'collisionAllOther': { 'translated': 'Accident', 'style': 'accident' },
            'compAllOther': { 'translated': 'Non-accident Damage', 'style': 'non-accidental-damage' },
            // for property
            'earthquake': { 'translated': 'Earthquake, Earth Movement or Landslide', 'style': 'earthquake' },
            'nw_equipmentbreakdown': { 'translated': 'Equipment Breakdown or Power Surge (not from weather)', 'style': 'equipment' },
            'nw_freezing': { 'translated': 'Freezing', 'style': 'freezing' },
            'hail': { 'translated': 'Hail Damage', 'style': 'hail-property' },
            'nw_identitytheft': { 'translated': 'Identity Theft', 'style': 'id-theft' },
            'nw_liabilitymedpay': { 'translated': 'Liability and Medical Payments', 'style': 'lib-med-payments' },
            'nw_lightning': { 'translated': 'Lightning', 'style': 'lightning' },
            'nw_lostitem': { 'translated': 'Lost Item or Mysterious Disappearance', 'style': 'lost-item' },
            'nw_smoke': { 'translated': 'Smoke', 'style': 'smoke' },
            'nw_theft': { 'translated': 'Theft', 'style': 'theft-home' },
            'nw_theftfromauto': { 'translated': 'Theft of Items From Vehicle', 'style': 'theft-vehicle' },
            'nw_vehiclewaterandair': { 'translated': 'Damage from Vehicle, Watercraft or Aircraft', 'style': 'damage-vwa' },
            'nw_water': { 'translated': 'Water Damage (from weather)', 'style': 'water-weather' },
            'nw_weightofice': { 'translated': 'Weight of Ice, Snow or Sleet', 'style': 'snow-weight' },
            'wind': { 'translated': 'Wind Damage', 'style': 'wind-damage' },
            'nw_allother': { 'translated': 'All Other Physical Damage', 'style': 'other-phys-damage' },
            'nw_waternonweather': { 'translated': 'Water Damage (not from weather)', 'style': 'water-notweather' },
            'nw_foundation': { 'translated': 'Foundation', 'style': 'foundation' },
            'nw_accidentaldeath': { 'translated': 'Accidental Death', 'style': 'prop-death' },
            'nw_breakage': { 'translated': 'Breakage', 'style': 'prop-breakage' },
            'nw_collapseexcept': { 'translated': 'Collapse - except from Weight of Ice, Sleet, Snow', 'style': 'prop-collapse' },
            'explosion': { 'translated': 'Explosion', 'style': 'prop-explosion' },
            'nw_hdliabilitymedpay': { 'translated': 'Home Day Care Liability and Medical Payments', 'style': 'home-daycare' },
            'nw_lead': { 'translated': 'Lead', 'style': 'prop-lead' },
            'riotandcivil': { 'translated': 'Riot or Civil Commotion', 'style': 'riot' }
        };

        const lossCauseObject = lossCauseMap[lossCause];
        return lossCauseObject;
    }

    getSessionObjectValue(sessionObjName): string {
        let sessionObjValue;
        if (sessionStorage.getItem(sessionObjName)) {
            sessionObjValue = sessionStorage.getItem(sessionObjName);
        }
        return sessionObjValue;
    }

    getClaimId(): any {
        return this.getSessionObjectValue('Claim-Number-Stored-In-Session') ||
            this.getSessionObjectValue('claimNumber');
    }

    getUserAgent(): any {
        const userAgent = window.navigator.userAgent;
        return userAgent;
    }

    getClaimantContactId(): any {
        return this.getSessionObjectValue(PersonalConstants.MFA_LOGIN_COMPONENT.CONTACTID);
    }

    callInsuranceClaimPage(): any {
        window.location.href = environment.NATIONWIDE.insuranceClaim;
    }

    getNameObj(displayName: string): any {
        const name = displayName ? displayName.split(/\s+/) : ['NA', 'NA'];
        const nameObj = {
            firstName: '',
            lastName: ''
        };

        let middleInitialIndex = -1;

        for (let nameIndex = 0; nameIndex < name.length; nameIndex++) {
            const nextName = name[nameIndex];
            if (nextName.length === 1) {
                middleInitialIndex = nameIndex;
                break;
            }
        }
        // revisit this logic to find FName and Lname from else where
        if (middleInitialIndex === -1) {
            nameObj.firstName = name.slice(0, 1).join(' ');
            nameObj.lastName = name.slice(1).join(' ');
        } else if (middleInitialIndex > -1) {
            nameObj.firstName = name.slice(0, middleInitialIndex).join(' ');
            nameObj.lastName = name.slice(middleInitialIndex + 1).join(' ');
        }
        return nameObj;
    }

    getPolicyOwnerRole(policyNumber): any {
        this.authService.getIdToken().subscribe((jsonWebToken) => {
            this.isPolicyOwnerRole(policyNumber, jsonWebToken );
        });
    }

    isPolicyOwnerRole(policyNumber, jsonWebToken): boolean {
        if (jsonWebToken && jsonWebToken.realm === 'member' && jsonWebToken.agreements) {
            return jsonWebToken.agreements.some((agreement) => {
                const formattedJWTAgreementNumber = agreement.agreement_number.replace(/\s/gi, '').toLowerCase();
                const formattedPolicyNumber = (policyNumber || '').replace(/\s/gi, '').toLowerCase();
                return formattedJWTAgreementNumber === formattedPolicyNumber;
            });
        }
    }
}
