import { PersonalConstants } from '../common/personalConstants';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import { LoggerService } from '../shared/services/logger.service';

@Injectable()
export class ProductionGuard {
    constructor(private router: Router, private logger: LoggerService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (environment.production !== 'true') {
            this.logger.debug('ProductionGuard passed.');
            return true;
        }
        this.logger.error('ProductionGuard failed. User redirected to search page.');
        // this.router.navigate([PersonalConstants.URLS.quickPaySearch]);
        return false;
    }
}
