import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthModule, StsConfigLoader, StsConfigStaticLoader } from 'angular-auth-oidc-client';
import { ClaimsOidcAuthConfigService } from './claims-oidc-auth-config.service';
import { ClaimsOidcAuthComponent } from './claims-oidc-auth.component';

const authFactory: any = (configService: ClaimsOidcAuthConfigService) => new StsConfigStaticLoader(configService.getConfig());

const routes: Routes = [{
    path: '', component: ClaimsOidcAuthComponent, data: { title: 'Authentication' }, pathMatch: 'full'
}];

@NgModule({
    imports: [
        CommonModule,
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: authFactory,
                deps: [ClaimsOidcAuthConfigService]
            }
        }),
        RouterModule.forChild(routes)
    ],
    exports: [AuthModule],
    providers: [],
    declarations: [
    ClaimsOidcAuthComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ClaimsOidcAuthModule {}
