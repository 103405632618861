import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { SessionService } from 'app/shared/services/session.service';

@Injectable({
    providedIn: 'root'
})
export class RouterService {
    constructor (
        private sessionService: SessionService) { }


    getPermissions(url: string, state: RouterStateSnapshot): boolean {
        let urlIntercepted = false;
        let loginPage = false;
        let refreshStatus = false;
        let samePageLoad = false;

        if (url === '/') { // check for user intervention
            urlIntercepted = true;
        }
        // Check for login Page URL
        if (state?.url?.indexOf('/fileclaim/info/identification') > -1 && state?.root?.queryParams['productType'] === 'Auto') {
            loginPage = true;
        }

        refreshStatus = this.sessionService.getPageRefresh();// check for page refresh

        const sessionUrlObj = this.sessionService.getSessionItemDecrypted('urlObj') || null;
        if (sessionUrlObj?.currentUrl?.indexOf(window.location.pathname) > -1) { // comparing current path with previous route
            samePageLoad = true;
        }

        if (urlIntercepted) {
            return refreshStatus && samePageLoad || loginPage;
        } else {
            return true;
        }
    }
}
