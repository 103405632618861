export const PersonalConstants = {

    VALIDATION_CLUSTER: false,
    PRODUCT_TYPE: {
        AUTO: 'auto',
        PROPERTY: 'property'
    },
    REGISTRATION_TYPE: {
        REGISTEREDMEMBER: 'registered',
        REGISTRATIONTYPE: 'registrationType',
        UNREGISTEREDMEMBER: 'unregistered-member',
        UNREGISTEREDNONMEMBER: 'unregistered-non-member',
        UNREGISTEREDMEMBERSUPPORT: 'unregistered-member-support',
        UNREGISTEREDNONMEMBERSUPPORT: 'unregistered-non-member-support'
    },

    LOSSCAUSE: [
        'lockout',
        'glassbreakage'
    ],

    LOGINTYPE: {
        REGISTERED: 'registeredLogin',
        UNREGISTERED: 'unregisteredLogin',
        UNREGISTEREDEXPANDUSER: 'ExpandUserLogin'
    },

    REDIRECT_USERTYPES: [
        'registered',
        'unregistered-express',
        'unregistered-non-member-deeplinking',
        'unregistered-member-property',
        'unregistered-member-support-property'
    ],
    SKIPSERVICEOFFERINGVEHICLETYPE: [
        'new-vehicle',
        'rented',
        'borrowed'
    ],
    CONDITION_FOR_AUTHFACTOR: {
        NWAGENT: 'nwAgent',
        NWATTORNEY: 'nwAttorney',
        RELATIVENOTLISTED: 'relativeNotListed',
        LIENHOLDER: 'lienholder',
        RENTAL: 'rental',
        OTHERNW: 'otherNW'
    },
    OIDCTOKENTIME: {
        TOKENEXPIRYTIME: '1180',
        MEMBERTOKENREFRESHTIME: 120,
        UNREGISTEREDTOKENREFRESHTIME: 60
    },
    FILE_CLAIM_COMPONENT: {
        IDTOKEN: 'id_token',
        REPORTER: 'reporter',
        RELATIONS: 'relations',
        NULL: 'null',
        INVOLVEDNW: 'InvolvedNW',
        MYNWPOLICY: 'MyNwPolicy',
        OTHER: 'Other',
        ACCESS_DENIED: 'access_denied',
        CUSTOMERSEARCH: 'customer-search',
        ACCESSTOKEN: 'AccessToken',
        ACCESSTOKENEXPIRATION: 'ACCESS_TOKEN_EXPIRATION',
        PING: 'ping',
        DATEFORMAT: 'yyyy-MM-dd',
        ID: '#pageLevelErrorNotification',
        NWQUICKSTARTMODAL: 'nw-quickstart-model',
        POLICYNUMBER: 'policyNumber',
        RETURNINGPOLICYNUMBER: 'returning-policyNumber',
        CLAIMTYPE: 'claimType',
        TEXTOPTIN: 'text-opt-in',
        USERTYPE: 'userType',
        CLAIMNUMBER: 'claimNumber'
    },

    URLPATHPARAMS: {
        FILE_CLAIM: 'fileclaim',
        INFO: 'info',
        PRODUCTTYPE: 'auto',
        POSTFNOL: 'claimsubmit',
        MODERNIZATTION: 'modernization',
        PRE_EFNOL: 'pre-efnol',
        EFNOL: 'efnol',
        AUTHENTICATE: 'authenticate'
    },
    VEHICLE_INVOLVED_COMPONENT: {
        VEHICLE_LISTED: 'listed',
        VEHICLE_INVOLVED: 'vehicle_involved',
        BORROWED: 'borrowed',
        NON_MEMBER_VEHICLE: 'non-member-vehicle',
        RENTED: 'rented',
        NEW_VEHICLE: 'new-vehicle',
        ANOTHER_VEHICLE: 'another-vehicle',
        ID: '#pageLevelErrorNotification',
        VEHICLE_YEAR: 'year',
        VEHICLE_MAKE: 'make',
        VEHICLE_MODEL: 'model'
    },
    INCIDENT_PAGE: {
        CLAIMSSELFSERVICE: 'claims_self_service',
        INJURYREPORTED: 'Injury Reported',
        TOPICNAME: 'FNOL'
    },
    READY_TO_FILE: {
        CHECKBOXCHECK: '#checkBox',
        ATTRIBUTEREMOVE: 'checked'
    },
    EFNOL_SERVICE: {
        FORM: 'form',
        RESPONSE: 'response'
    },
    USER_TYPES: {
        UNIDENTIFIED: 'unidentified',
        REGISTERED: 'registered',
        NON_MEMBER: 'nonmember'
    },
    appProps: {
        appClientId: 'QnISP3870keIvn1sqegLFjTUeuDo3LUt',
        appClientSecret: 'sBTGcGMZ63Jfc9I3',
        DEV_SERVER_HEADER: '3'
    },
    splunkProps: {
        token: '70E775A5-F5C8-479B-B0FC-B5C8280E248E',
        cloudToken: 'FAC4F5C2-637A-43E2-B3E2-68511DC102E2',
        appName: 'dgs-internet-servicing-claims',
        cloudAppName: 'ISC-PLDS',
        userId: 'userId',
        sourceType: 'dgs_isc_json',
        cloudSourceType: 'dgs-cloud-isc_json',
        maxLogLength: 200000,
        retryLogCurrentContentsTime: 500
    },
    oAuthProps: {
        oAuthResponseType: 'id_token token',
        ctAuthState: 'ctState',
        knowledgeAuthState: 'knowledgeState',
        oAuthNonce: 'myNonce'
    },
    esriMapProps: {
        apiKey: 'AAPK76b5c611e92847af93eb17cac41e0495z8N_CI8yxcul2cFboEIKcEubmeiNwAGNGMk37IKPu7FZknZLE-UTJpe15PKkBHEa',
        locatorUrl: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer'
    },
    EUA: {
        PING: 'ping',
        CUSTOMERSEARCH: 'customer-search',
        CLAIMSERVICING: 'claims-servicing'
    },
    APPLICATION_TYPE: {
        APPLICATION_JSON: 'application/json',
        TEXT: 'text'
    },
    ebiEventIds: {
        sampleEbiId: '406800118'
    },

    LOGIN_ENTRY_POINTS: {
        IDENTIFICATION_PAGE: 'identification',
        COVERAGES_PAGE: 'coverages',
        FILE_CLAIM_PAGE: 'file-claim',
        NO_POLICY_FOUND_PAGE: 'no-policy-found',
        RETURNING_LINK: 'identification-returning',
        IAMBYPASS: 'montyBot',
        EXTERNAL_APP_ENTRY: 'external',
        DUPLICATE_RETURNING_MEMBER: 'duplicate-returning-member',
        CLAIMS_TRACKER: 'tracker',
        INCIDENT_DETAILS: 'incident-details',
        FILE_UPLOAD: 'file-upload',
        PAYMENT_OPTIONS: 'payment-option',
        MFA_LOGIN: 'login',
        PROPERTY_FIRST_NOTICE_OF_LOSS: 'first-notice-of-loss'
    },
    Application_Variables: {
        YES: 'Yes',
        NO: 'No',
        Y: 'Y',
        N: 'N',
        FULL: 'FULL',
        RBP: 'RBP',
        ESA_VERSION: '2.0',
        REG: 'REG',
        POLICY: ' policy',
        BANK_ACCOUNT: 'Bank Account',
        EFT: ['electronic fund transfer', 'recurring bank eft', 'eft'],
        I_DONT_KNOW: 'I don\'t know',
        UNKNOWN: 'Unknown',
        UNOCCUPIED: 'Unoccupied',
        APPLE_PAY: 'Apple Pay',
        ATTENTION: 'Attention',
        HEARTBEAT_COOKIE: 'iscHeartbeat'
    },
    headerProps: {
        APPLICATION_JSON: 'application/json',
        AUTHORIZATION: 'Authorization',
        BEARER: 'Bearer ',
        BASIC: 'Basic',
        STATIC_CLIENTID: 'client_id',
        MESSAGE_ID: 'X-Nw-MessageId',
        SERVER_ID_NUMBER: 'X-NW-Server-ID-Number',
        BILLING_MESSAGE_ID: 'X-NW-MessageID',
        CONNECTIONTYPE: 'ConnectionType',
        AFI_CONNECTIONTYPE: 'CIAD',
        TARGETROUTING: 'X-NW-Target-Routing',
        NW_MESSAGE_ID: 'X-NW-Message-ID',
        REAL_TIME_RESPONSE_INDICATOR: 'RealTimeResponseIndicator',
        CTM_REAL_TIME_RESPONSE: 'N',
        PRAGMA: 'Pragma',
        CACHE_CONTROL: 'Cache-Control',
        NO_CACHE: 'no-cache',
        NW_CONSUMER_ID: 'X-NWD-ConsumerId',
        CLAIMS: 'Claims'
    },
    ebiEventProperties: {
        contentType: 'application/json',
        appId: 'NW-CSS',
        accept: 'application/json',
        appCookie: 'Internet Servicing Claims',
        unauthenticatedClientType: 'Unauthenticated',
        authenticatedClientType: 'Authenticated',
        ecn: 'ecif.ecn',
        productType: 'Claims',
        ip: 'client.ip',
        state: 'ecif.state',
        agreement_role: 'jwt.agreement_role',
        guid: 'jwt.guid',
        userId: 'jwt.userId'
    },
    CTMValues: {
        PROPERTY: 'Property',
        CUSTOMER: 'Customer',
        OTHER: 'Other',
        CLAIMS_QS_INITIATION: 'InternetServicing-0020'
    },

    CTMConstants: {
        CLAIMS_INITIATION: 'A self service claim was initiated by',
        CLAIMS_INITIATION_POLICY: 'via MyNationwide for policy',
        CLAIM_INITIATED: 'A self service claim was initiated'
    },

    MFA_LOGIN_COMPONENT: {
        ACCESS_DENIED: 'access_denied',
        CLAIMS_SERVICING: 'claims-servicing',
        ACCESSTOKEN: 'AccessToken',
        ACCESSTOKENEXPIRATION: 'ACCESS_TOKEN_EXPIRATION',
        PING: 'ping',
        IDTOKEN: 'id_token',
        ID: '#pageLevelErrorNotification',
        POLICYNUMBER: 'nw-policyNumber',
        CLAIMNUMBER: 'claimNumber',
        CONTACTID: 'claimsContactPublicId',
        FROMMFA: 'fromMFA',
        INVALID_CODE: 'invalid_code'
    },

    MFA_PAGE_NAMES: {
        LOGIN: 'login',
        CONTACT: 'contact',
        ENTER_CODE: 'enter-code',
        REMEMBER_DEVICE: 'remember-device',
        TRACKER: 'Tracker'

    },

    MFA_PAGE_LIST: [
        'login',
        'contact',
        'enter-code',
        'remember-device'
    ],

    searchPageErrors: {
        access_denied: {
            message:
                'We cannot locate an account associated with this information. ' +
                'Please check the information below and try again, or Log in/Sign up for an online account to make your payment.',
            type: 'danger'
        },
        server_error: {
            message:
                'We cannot locate an account associated with this information. ' +
                'Please check the information below and try again, or Log in/Sign up for an online account to make your payment.',
            type: 'danger'
        },
        SessionExpired: {
            message: 'Your session has expired. Please try again',
            type: 'info'
        },
        authTimeout: {
            message:
                'An error occurred while processing your request. Please try again later.',
            type: 'danger',
            timeoutLength: 60000
        }
    },
    timeout: {
        excludedRoutes: ['/quickpay/search', '/quickIdCards']
    },
    CLAIMS_ERROR_MSGS: {
        BEFORE_POLICY_EFFECT_DATE: 'We need to talk to you to keep things moving. Give us a call at 1-800-421-3535.',
        DUPLICATE_CLAIM: 'It looks like this claim may have already been reported.' +
            'Please contact the claims call center at 1-800-421-3535 for more information.',
        WENT_WRONG: 'We were unable to process your request due to a system error. Please try again later.'
    },
    COMMON_ERRORS: {
        SESSION_TIME_OUT: 'Session timed out! Please try again',
        WENT_WRONG: 'Something went wrong! Server is not responding'
    },
    HAS_PHOTOS_TO_UPLOAD: {
        TRUE: 'Great! we\'ll send you a text message with instructions on how to upload any picture you have.',
        FALSE: 'That\'s okay, you can opt into virtual appraisal, We\'ll help you take photos to send to us to get a convenient estimate.'
    },
    countries: [
        { value: 'USA', name: 'United States' },
        { value: 'PR', name: 'Puerto Rico' },
        { value: 'CA', name: 'Canada' },
        { value: 'MX', name: 'Mexico' }
    ],
    states: [
        { value: 'AL', name: 'Alabama' },
        { value: 'AK', name: 'Alaska' },
        { value: 'AZ', name: 'Arizona' },
        { value: 'AR', name: 'Arkansas' },
        { value: 'CA', name: 'California' },
        { value: 'CO', name: 'Colorado' },
        { value: 'CT', name: 'Connecticut' },
        { value: 'DE', name: 'Delaware' },
        { value: 'DC', name: 'District of Columbia' },
        { value: 'FL', name: 'Florida' },
        { value: 'GA', name: 'Georgia' },
        { value: 'HI', name: 'Hawaii' },
        { value: 'ID', name: 'Idaho' },
        { value: 'IL', name: 'Illinois' },
        { value: 'IN', name: 'Indiana' },
        { value: 'IA', name: 'Iowa' },
        { value: 'KS', name: 'Kansas' },
        { value: 'KY', name: 'Kentucky' },
        { value: 'LA', name: 'Louisiana' },
        { value: 'ME', name: 'Maine' },
        { value: 'MD', name: 'Maryland' },
        { value: 'MA', name: 'Massachusetts' },
        { value: 'MI', name: 'Michigan' },
        { value: 'MN', name: 'Minnesota' },
        { value: 'MS', name: 'Mississippi' },
        { value: 'MO', name: 'Missouri' },
        { value: 'MT', name: 'Montana' },
        { value: 'NE', name: 'Nebraska' },
        { value: 'NV', name: 'Nevada' },
        { value: 'NH', name: 'New Hampshire' },
        { value: 'NJ', name: 'New Jersey' },
        { value: 'NM', name: 'New Mexico' },
        { value: 'NY', name: 'New York' },
        { value: 'NC', name: 'North Carolina' },
        { value: 'ND', name: 'North Dakota' },
        { value: 'OH', name: 'Ohio' },
        { value: 'OK', name: 'Oklahoma' },
        { value: 'OR', name: 'Oregon' },
        { value: 'PA', name: 'Pennsylvania' },
        { value: 'RI', name: 'Rhode Island' },
        { value: 'SC', name: 'South Carolina' },
        { value: 'SD', name: 'South Dakota' },
        { value: 'TN', name: 'Tennessee' },
        { value: 'TX', name: 'Texas' },
        { value: 'UT', name: 'Utah' },
        { value: 'VT', name: 'Vermont' },
        { value: 'VA', name: 'Virginia' },
        { value: 'WA', name: 'Washington' },
        { value: 'WV', name: 'West Virginia' },
        { value: 'WI', name: 'Wisconsin' },
        { value: 'WY', name: 'Wyoming' }
    ],
    canadaStates: [
        { value: 'AB', name: 'Alberta' },
        { value: 'BC', name: 'British Columbia' },
        { value: 'MB', name: 'Manitoba' },
        { value: 'NB', name: 'New Brunswick' },
        { value: 'NL', name: 'Newfoundland and Labrador' },
        { value: 'NS', name: 'Nova Scotia' },
        { value: 'ON', name: 'Ontario' },
        { value: 'PE', name: 'Prince Edward Island' },
        { value: 'QC', name: 'Quebec' },
        { value: 'SK', name: 'Saskatchewan' }
    ],
    mexicoStates: [
        { value: 'AG', name: 'Aguascalientes' },
        { value: 'BN', name: 'Baja California' },
        { value: 'BS', name: 'Baja California Sur' },
        { value: 'CM', name: 'Campeche' },
        { value: 'CP', name: 'Chiapas' },
        { value: 'CH', name: 'Chihuahua' },
        { value: 'CI', name: 'Coahuila' },
        { value: 'CL', name: 'Colima' },
        { value: 'DU', name: 'Durango' },
        { value: 'DF', name: 'Federal District' },
        { value: 'GT', name: 'Guanajuato' },
        { value: 'GR', name: 'Guerrero' },
        { value: 'HL', name: 'Hidalgo' },
        { value: 'JA', name: 'Jalisco' },
        { value: 'M1', name: 'México' },
        { value: 'MY', name: 'Mexico City' },
        { value: 'MH', name: 'Michoacán' },
        { value: 'MR', name: 'Morelos' },
        { value: 'NA', name: 'Nayarit' },
        { value: 'NO', name: 'Nuevo Leon' },
        { value: 'OA', name: 'Oaxaca' },
        { value: 'PU', name: 'Puebla' },
        { value: 'QE', name: 'Querétaro' },
        { value: 'QR', name: 'Quintana Roo' },
        { value: 'SL', name: 'San Luis Potosi' },
        { value: 'SI', name: 'Sinaloa' },
        { value: 'SO', name: 'Sonora' },
        { value: 'TB', name: 'Tabasco' },
        { value: 'TM', name: 'Tamaulipas' },
        { value: 'TL', name: 'Tlaxcala' },
        { value: 'VE', name: 'Veracruz' },
        { value: 'YU', name: 'Yucatán' },
        { value: 'ZA', name: 'Zacatecas' }
    ],
    months: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12'
    ],
    hours: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12'
    ],
    minutes: [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31',
        '32',
        '33',
        '34',
        '35',
        '36',
        '37',
        '38',
        '39',
        '40',
        '41',
        '42',
        '43',
        '44',
        '45',
        '46',
        '47',
        '48',
        '49',
        '50',
        '51',
        '52',
        '53',
        '54',
        '55',
        '56',
        '57',
        '58',
        '59'
    ],
    meridiems: [
        'AM',
        'PM'
    ],
    EBI_EVENT_LABEL: {
        UNKNOWN: 'UNKNOWN',
        APP_COOKIE: 'Nationwide Mobile',
        CLAIMS: 'CLAIMS',
        AUTHENTICATED: 'AUTHENTICATED',
        UNAUTHENTICATED: 'UNAUTHENTICATED',
        WEB: 'Web',
        MEMBER_SUPPORT: 'MEMBERS SUPPORT',
        MEMBER: 'MEMBER',
        NONMEMBER: 'NON MEMBER',
        NA: 'NA',
        TRACKER: 'TRACKER'
    },
    EBI_EVENTS: {
        CLAIM_INTRO_LANDING: '405810024',
        CLAIM_INTRO_CONTINUE: '405810025',
        CLAIMS_QS_CONTACT_INCIDENT_LANDING: '405810026',
        CLAIMS_QS_GIS_ERROR_LOSS_LOCATION: '405810028', // GIS Errors on Loss Location
        CLAIMS_QS_CONTACT_INCIDENT_CONTINUE: '405810027',
        CLAIMS_QS_MEMBER_PROPERTY_INFORMATION_LANDING: '405810098',
        CLAIMS_QS_MEMBER_PROPERTY_INFORMATION_CONTINUE: '405810099',
        CLAIMS_QS_REVIEW_LANDING: '405810033',
        CLAIMS_QS_REVIEW_CONTINUE: '405810034',
        CLAIMS_QS_EDIT_DELETE: '405810035',
        CLAIMS_QS_SUBMIT_SUCCESFUL: '405810036',
        CLAIMS_QS_CONFIRM_CANCEL: '405810040',
        CLAIMS_QS_BACK: '405810041',
        CLAIMS_QS_VALIDATION_FAILED: '405810042',
        LOGOUT_DUE_TO_IDLE_TIMEOUT: '405810043',
        CLAIMS_QS_CONTINUE_FAIL: '405810044',
        CLAIMS_QS_CONFIRMATION_EMAIL_CLICK: '405810008',
        CLAIMS_QS_PROP_CONTACT_AND_INCIDENT_CLAIM_ACKNOWLEDGE: '405810100',
        CLAIMS_PAYMENT_OPTIONS_LANDING: '405810131',
        CLAIMS_PAYMENT_OPTIONS_ACCOUNT_DETAILS_SUBMIT: '405810132',
        CLAIM_INCIDENT_DETAILS: '405810010',
        DOC_UPLOAD_SUCCESS: '405810014',
        DOC_UPLOAD_FAILURE: '405810015',
        SELECT_FILE_EVENT: '405810016', // Not using
        FILE_VALIDATION_FAILED: '405810017',
        CLAIM_INCIDENT_DETAILS_CANCEL_BUTTON_CLICKED: '405810020', // May not use
        RETRIEVE_DOCUMENT_DETAILS: '405810023',
        LOAD_TRACKER: '405810007',
        CLAIMS_COVERAGE_SUMMARY: '405810022',
        LOAD_FAQ: '405810012',
        LOAD_CLAIMS_CONTACTS: '405810021',
        OYS_SELECTION_CONFIRMATION: '405810070',
        CHANGE_OYS: '405810071',
        DRIVE_IN_SELECTION_CONFIRMATION: '405810085',
        CHANGE_DRIVE_IN: '405810087'
    },
    EBI_DESCRIPTIONS: {
        '406800077': 'Commercial users clicks on make a payment in commercial bill collection page',
        '406800082': 'PMOF landing page loads and the user has no saved payment method.',
        '406800083': 'PMOF landing page loads and the user has existing saved payment methods',
        '406800084': 'Where customer can input what method of Payment to keep on File',
        '406800086': 'This event counts users that submit the add payment method page',
        '406800087': 'This event counts users that reach the add payment method confirmation page',
        '406800089': 'Customers removing their payment method when the method is not the default.',
        '406800090': 'Customers removing their payment method when the method is the a default.',
        '406800091': 'Customers who received confirmation or failure on remove process',
        '406800093': 'Cust. selecting to edit a PMOF and capture the type of payment method edited',
        '406800094': 'Customers who submit the edit payment method confirmation page',
        '406800096': 'Customers who land on the edit payment method confirmation page',
        '406800098': 'Number of customers who viewed the Authorization Agreement Form',
        '406800099': 'Number of customers selecting to enroll in automatic payments',
        '406800103': 'Number of customers who got confirmation/failure on enrollment into EasyPay',
        '406800104': 'Number of customers who Edit Easypay',
        '406800106': 'Number of customers who edit or switch financial information for Easy Pay',
        '406800108': 'Number of customers who got confirmation/failure on Manage EasyPay',
        '406800112': 'Track number of customers who confirmed cancellation of EasyPay',
        '406800124': 'Track the number of customers who agreed to the Authorization Form',
        '406800206': 'Count customers that go to the suspend landing page and the URL they came from',
        '406800207': 'Track customers that proceeded on in the suspend process from the landing page',
        '406800298': 'Customers who attempted to submit a payment exceeding the payment cap',
        '405810131': 'load of Claim Payment Options page',
        '405810132': 'User has indicated the bank account to which they wish to receive payment for their claim, and submitted the form'
    },
    PROPERTY_CLAIMS_NOTES: {
        VEHICLE_DO_NOT_KNOW_LOCATION: 'The address selected for vehicle was I don\'t know',
        VEHICLE_ADDRESS_VALIDATION: 'Confirm vehicle location',
        VEHICLE_RENTAL_CONTRACT: 'The Rental contract number for the vehicle driven by the Insured is',
        ADDITIONAL_INFO_LATER: 'Reporter to provide additional information upon contact',
        VEHICLE_3RDPARTY_SKIPPED_INFO: 'Reporter to provide Claimant Vehicle information upon contact',
        VEHICLE_3RDPARTY_REMOVED_INFO: 'Reporter removed Claimant Vehicle information upon review',
        INCIDENT_DO_NOT_KNOW_LOCATION: 'The address selected for incident was I don\'t know',
        INCIDENT_ADDRESS_VALIDATION: 'Confirm incident location',
        PROPERTY_DO_NOT_KNOW_LOCATION: 'The address selected for property was I don\'t know',
        PROPERTY_ADDRESS_VALIDATION: 'Confirm property location',
        PROPERTY_ADDITIONAL_INFO: 'The user has selected \'Later\' on a Property Damage incident',
        SELECTED_INJURY:
            'The customer indicated that there was an injury on the claim, please follow up with the reporter for more details.',
        PROPERTY_ROOFLEAK_UNKOWN: 'The claim reporter indicated that the roof damage was unknown',
        PROPERTY_ENTERED_LOSSLOCATION:
            'The customer selected a new Incident Location for the Loss Location;' +
            ' we have defaulted the property incident location to the 1st Location listed' +
            ' on the policy and this may need to be corrected.' +
            'Please review and update the property incident accordingly.',
        PROPERTY_UNKNOWN_LOSSLOCATION:
            'The customer selected I Don’t Know for Loss Location;' +
            'we have defaulted the incident location and the property incident location to the' +
            ' 1st Location listed on the policy and may need to be corrected. ' +
            'Please review and update the property incident accordingly.',
        PROPERTY_CONTRACTOR: 'The claim reporter indicated that the Contractor name/number was ',
        PROPERTY_CONTRACTOR_ESTIMATE: 'and Repair Estimate was ',
        PROPERTY_OTHER_STRUCTURE_ADDITIONAL_INFO: 'Additional information of other structure damage:',
        PROPERTY_PERSONAL_PROP_ADDITIONAL_INFO: 'Additional information of personal property that was lost, stolen, or damaged:'
    },
    EBI_CLAIMS_PAGES: {
        PropertyClaimsIntro: 'PROPERTY CLAIMS INTRO',
        PropertyClaimsContactIncidentDetails: 'PROPERTY CLAIMS CONTACT INCIDENT DETAILS',
        PropertyClaimsMemberPropInfo: 'PROPERTY CLAIMS MEMBER PROPERTY INFORMATION',
        PropertyClaimsSummary: 'PROPERTY CLAIMS SUMMARY REVIEW',
        PropertyClaimsConfirmation: 'PROPERTY CLAIMS CONFIRMATION',
        IncidentDetails: 'INCIDENT DETAILS',
        PropertyIncidentDetails: 'PROPERTY INCIDENT DETAILS',
        DocumentList: 'DOCUMENT LIST',
        Tracker: 'TRACKER',
        PaymentOptions: 'PAYMENT OPTIONS'
    },
    EBI_EVENT_TAGS: {
        COM_DENOM: 'COM_DENOM',
        DRAFT_CLAIM_NB: 'DRAFT_CLAIM_NB',
        FULL_POLICY_NB: 'FULL_POLICY_NB',
        INCIDENT_DATE: 'INCIDENT_DATE',
        PAGE_NAME: 'PAGE_NAME',
        POLICY_OWNER: 'POLICY_OWNER',
        POLICY_TYPE: 'POLICY_TYPE',
        PREVIOUS_URL: 'PREVIOUS_URL',
        REPORTER_PNI: 'REPORTER_PNI',
        USER_FLOW: 'USER_FLOW',
        USER_ROLE: 'USER_ROLE',
        CLAIMS_DISPLAY_COUNT: 'CLAIMS_DISPLAY_COUNT',
        DISPLAY_TEST_DRIVE: 'DISPLAY_TEST_DRIVE',
        ECN: 'ECN',
        JSESSION_ID: 'JSESSION_ID',
        LOB: 'LOB',
        MEMBER: 'MEMBER',
        PH_FIRST_NM: 'PH_FIRST_NM',
        PH_LAST_NM: 'PH_LAST_NM',
        PH_STATE_ALPHA_CD: 'PH_STATE_ALPHA_CD',
        USER_FIRST_NM: 'USER_FIRST_NM',
        USER_ID: 'USER_ID',
        USER_LAST_NM: 'USER_LAST_NM',
        UUID: 'UUID',
        BROWSER_TYPE: 'BROWSER_TYPE',
        BROWSER_VERSION: 'BROWSER_VERSION',
        CLAIM_NB: 'CLAIM_NB',
        CLAIM_STATUS: 'CLAIM_STATUS',
        DEVICE_TYPE: 'DEVICE_TYPE',
        LOSS_CAUSE: 'LOSS_CAUSE',
        OS_TYPE: 'OS_TYPE',
        OS_VERSION: 'OS_VERSION',
        LINK_NAME: 'LINK_NAME',
        SOURCE_PAGE: 'SOURCE_PAGE',
        APP_NAME: 'APP_NAME',
        REPAIR_SHOP_CATEGORY: 'REPAIR_SHOP_CATEGORY',
        SEARCH_LOCATION: 'SEARCH_LOCATION',
        SEARCH_NAME: 'SEARCH_NAME',
        SEARCH_RESULTS_COUNT: 'SEARCH_RESULTS_COUNT',
        SEARCH_TYPE: 'SEARCH_TYPE',
        TAB_NAME: 'TAB_NAME',
        USER_MIDDLE_NM: 'USER_MIDDLE_NM',
        IP_ADDRESS: 'IP_ADDRESS',
        TRIGGER_CHANNEL: 'TRIGGER_CHANNEL',
        BANK_ACCOUNT_TYPE: 'BANK_ACCOUNT_TYPE',
        BANK_NAME: 'BANK_NAME',
        COMMON_RETURN_MESSAGE: 'COMMONRETURNMESSAGE',
        DOC_TYPE: 'DOC_TYPE',
        FAILURE_REASON: 'FAILURE_REASON',
        FAILURE_DESCRIPTION: 'FAILURE_DESCRIPTION',
        TEST_LEARN: 'TEST_LEARN',
        NUM_REFT_ACCTS: 'NUM_REFT_ACCTS',
        MONEY_PROCESS_PCI_API_ERROR: 'MONEY_PROCESS_PCI_API_ERROR',
        ACCT_TYPE_SELECTED: 'ACCT_TYPE_SELECTED',
        LINK_SELECTED: 'LINK_SELECTED'
    },
    GA_EVENT_ACTIONS: {
        PAGE_LOAD: 'Page Load',
        PRESS_BUTTON: 'Press Button',
        ADDRESSVALIDATION_FAILED: 'Address Validation Fail',
        CONTINUE_FAIL_CLIENT_SIDE: 'Continue Fail - Client side',
        CONTINUE_FAIL_SERVICE_SIDE: 'Continue Fail - Service side',
        CONTINUE_SUCCESS: 'Continue Success'
    },
    validationMessages: {
        ALLOW_ONLY_ALPHABETS: 'Special characters/numbers are not valid. Please re-enter.',
        ALLOW_ALPHABET_NUMBERS_WITH_HYPHEN: 'Special characters are not valid. Please re-enter.',
        ALLOW_ALPHABET_NUMBERS: 'Special characters are not allowed. Please re-enter.',
        PHONE_NUMBER_INVALID: 'Phone number is invalid.'
    },
    regexValidators: {
        positiveDecimal: /^[0-9]*\.?[0-9]*$/,
        positiveInteger: /^\d+$/,
        isNumeric: /^[0-9]*$/,
        isAccountNUmber: /^((([0-9])+[0-9]{4})|(([*])+[0-9]{4}))$/,
        isAlphaNumeric: /^[a-zA-Z0-9\s]*$/,
        isAlphaNumericWithHyphens: /^[a-zA-Z0-9\s-]*$/,
        isAlpha: /^[a-zA-Z\s]*$/,
        isAlphaNoSpacesNoSpecialCharacter: /^[a-zA-Z]*$/,
        isAlphaNumericSpacesHyphenSlash: /^[a-zA-Z0-9./\\ -]*$/,
        isAlphaNumberWithHash: /^[a-zA-Z0-9#,-. ]*$/,
        isAlphaNumberWithSlash: /^[a-zA-Z0-9#,-./ ]*$/,
        isAlphaNoSpaces: /[a-zA-Z]+/,
        startsWithAlpha: /^[a-zA-Z]/,
        nameField: /^([A-Za-z](?!_^)[A-Za-z\s-]?)*$/,
        addressField: /^[a-zA-Z0-9\s./-]*$/,
        isValidEmail: /^[a-zA-Z0-9][a-zA-Z0-9._%+-]*[a-zA-Z0-9]@[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]{2,6})+$/,
        mutipleEmail: /^(([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3})(\s*,\s*|\s*$)){1,2}$/,
        isValidPhoneNumber: /^(?!(?:(.)\1{2}\-?){3}\1)(?=([2-9]{1}\d{2}-?\d{3}-?\d{4}$))/,
        bankAccountHolderName: /^[^*`\\~^]+$/,
        unitedStatesPhone: /^\(?[2-9]\d{2}\)?[- ]?[2-9]\d{2}[- ]?\d{4}$/,
        dateOfBirth: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
        isValidDate: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
        isTextDescription: /^[a-zA-Z0-9#,-.'$%&\[\]()_:;?\n ]*$/,
        isCity: /^[a-zA-Z,-. ]*$/,
        isRoutingNumber: /^[0-9]{9}$/,
        isUSAZip: /^[0-9]{5}(-[0-9]{4})?$/,
        isCanadaZip: /[a-zA-Z][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]/,
        isOtherCountry: /^[a-zA-Z0-9]{0,15}$/,
        isExtn: /^([0-9*]){0,7}$/,
        isMfaCode: /^(\d){6}$/,
        isValidClock: /^(0?[1-9]|1[0-2]):[0-5][0-9]$/,
        isExtnEfnol: /^([0-9]){0,5}$/,
        isPhoneNumberEfnol: /^(1-)?\d{3}-\d{3}-\d{4}$/,
        isPhoneEfnol: /^(([2-9]{1}\d{2}-?\d{3}-?\d{4})|([*]{3}-[*]{3}-[0-9]{4}))$/,
        isEmailEfnol: /^[\w.-/*]+@[A-Za-z]+([-]?[A-Za-z]+)+([\.][A-Za-z]+([-]?[A-Za-z]+)+)+$/,
        isValidPoliceReportNumber: /^[a-zA-Z0-9#,-. ]*$/,
        isValidPoliceDepartment: /^[a-zA-Z0-9#,-. ]*$/,
        isAddressLine: /^[a-zA-Z0-9#,-./ ]*$/,
        isValidVehicleYear: /^19[0-9]{2}|2[0-9]{3}$/,
        isAlphaWithHyphen: /^[a-zA-Z- ]*$/,
        isAlphaNumericWithHyphenSlash: /^[a-zA-Z0-9-/ ]*$/,
        isValidPolicyNumber: /^[a-zA-Z0-9 ]+$/,
        isNotAnAutoPolicy: /^(?!.*([Mm][Cc]|[Rr][Vv]|[Bb][Tt]|[Mm][Ss]|[Aa][Cc][Pp]|[Ff][Pp][Kk]|[Bb][Aa]|[Cc][Pp]|[Cc][Oo][Pp]|[Gg][Ll]|[Ww][Cc]|[Cc][Aa]|[Ff][Mm][Pp]|[Pp][Aa]|[Hh][Oo]|[Hh][Rr]|[Dd][Qq]|[Uu][Pp]|[Hh][Ss]|[Hh][Pp]|[Dd][Pp]|[Uu][Qq]|[Tt]|[Bb][Pp]|[Bb][Oo][Pp]|[Cc][Rr])).*/,
        isCommercialPolicy: /^(.*([Ff][Pp][Kk]|[Aa][Cc][Pp]|[Bb][Aa]|[Ff][Mm][Pp])|[Ww][Cc]|[Cc][Aa]|[Cc][Pp]|[Cc][Oo][Pp]|[Gg][Ll]|[Bb][Pp]|[Bb][Oo][Pp]|[Cc][Rr]).*/,
        isValidPhoneMasked: /^\*{3}-\*{3}-\d{4}/,
        isValidEmailMasked: /^[a-zA-Z0-9_.%-][a-zA-Z0-9_.%-*]*@[A-Za-z0-9]+(-?[A-Za-z0-9]+)*(\.[A-Za-z0-9]+(-?[A-Za-z]+)+)+$/
    },
    MFA_RememberDevice: {
        radioOption: 'radioOption',
        yesOption: 'yes',
        yesCaption: 'Yes. I want to stay logged in for 20 minutes after leaving the page.',
        noCaption: 'No. I want to log in again if I leave the page.',
        noOption: 'no'
    },

    AppKey: {
        key: 'NWServicingDigitalClaims'
    },
    CLEARTRUST: {
        ENDPOINT: 'https://api-int-dev.nwie.net/ct_run/v1/createToken/member/'
    },
    VIN_SERVICE: {
        ENDPOINT: 'https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/',
        PARAMS: '?format=json&modelyear='
    },
    SEARCH_CLAIMS: {
        URI: 'claims/search?limit=30&incidentOnly=true'
    },
    SEARCH_DRAFT_CLAIMS: {
        URI: 'claims/search?claimState=draft&limit=30&incidentOnly=true'
    },
    URLS: {
        error500: '/error-500',
        duplicateError: '/duplicateError',
        errorCommon: '/error-common',
        stub: '/stub',
        fileAClaim: '/propertyclaims/first-notice-of-loss',
        errorProp: '/error-Prop',
        contactAndIncident: '/propertyclaims/first-notice-of-loss/contact-incident-details',
        memberPropertyInformation: '/propertyclaims/first-notice-of-loss/member-property-information',
        claimsSummary: '/propertyclaims/first-notice-of-loss/claims-summary',
        claimsConfirmation: '/propertyclaims/first-notice-of-loss/claims-confirmation'
    },
    CLAIMS_PAGE_NAMES: {
        BASE_AUTO_PAGE: 'autoclaims',
        BASE_PROPERTY_PAGE: 'propertyclaims',
        AUTO: {
            START_CLAIM: 'start-claim',
            FILE_CLAIM: 'file-claim',
            INCIDENT_PAGE: 'incident',
            ACCIDENT_OVERVIEW: 'accident-overview',
            REPORTER_PAGE: 'reporter',
            LOSSCAUSE_PAGE: 'losscause',
            DESCRIPTION_PAGE: 'description',
            READYTOFILE_PAGE: 'ready-to-file',
            VEHICLE_INVOLVED: 'vehicle-involved',
            ABOUT_YOUR_VEHICLE: 'about-your-vehicle',
            BORROWED_VEHICLE: 'borrowed-vehicle',
            LOOK_UP_VEHICLE: 'look-up-vehicle',
            RENTED_VEHICLE: 'rented-vehicle',
            UPDATE_REPORTER: 'update-reporter'
        },
        PROPERTY: {
            FILE_CLAIM: 'file-claim'
        }
    },
    CLAIMS_FNOL_URLS: {
        AUTO: {
            BASE_URL: '/autoclaims/'
        },
        FILE_CLAIM: '/autoclaims/file-claim'
    },
    CLAIMS_OLD_URLS: {
        ACCOUNT_SUMMARY_PAGE: 'myaccount/portfolio/RetrievePortfolio.action',
        CLAIMS_LIST: '#/claims-list',
        CLAIMS_LIST_PROPERTY: '#/property-claims-list',
        CLAIMS_OVERVIEW: '#/claim-overview',
        CLAIMS_PROPERTY_OVERVIEW: '#/property-claim-overview',
        PROPERTY_CLAIMS_LIST: '#/property-claims-list',
        PROPERTY_CLAIMS_OVERVIEW: '#/property-claim-overview',
        OYS_FIND_REPAIR_SHOP: 'claim-oys-find-repair-shop',
        DRIVE_IN_FIND_SHOP: 'claim-drive-in-find-shop'
    },
    CLAIMS_URLS: {
        CLAIM_LIST_PAGE: '/claim-list-page'
    },
    Estimate_Sorting_Order: [
        'Vehicle Valuation Report',
        'Estimate of Damage',
        'Personal Property Estimate',
        'Mitigation Estimate'
    ],

    PAGES: {
        contactAndIncident: 'Contact And Incident Details',
        memberPropertyDetails: 'Member Property Details'
    },
    Application_Uri: {
        FIND_ADDRESS: 'findAddress?singleLine=',
        SUGGESTIONS: 'suggest?text=',
        LOCATION: 'rating/locationservice/v1/',
        CREATE_FNOL: 'notices-of-loss',
        DRIVE_IN_FACILITY: 'drivein-facilities/search',
        VEHICLE_INCIDENT: 'vehicle-incidents',
        CLAIM_CONTACTS: 'claim-contacts',
        NOTES: 'notes',
        CLAIMS: 'claims',
        AUTOSUBMIT: 'autosubmit',
        BASIC_CLAIM_DETAILS: 'basic-claim-details',
        MAIN_CONTACT: 'main-contact',
        REPORTER: 'reporter',
        LOSS_LOCATION: 'loss-location',
        LOSS_DESCRIPTION: 'loss-description',
        POLICY: 'policy',
        CONTACTS: 'contacts',
        REPAIRSHOP: 'repair-shops/search',
        INCIDENTS: 'incidents',
        SERVICEOFFERINGS: 'service-offerings',
        VEHICLERISKUNIT: 'vehicle-risk-units',
        SERVICE_OFFERINGS: 'service-offerings',
        FIXED_PROPERTY_INCIDENTS: 'fixed-property-incidents',
        INJURY_INCIDENTS: 'injury-incidents',
        UPLOAD_LINKS: 'upload-links',
        POLICIES: 'policies',
        COVERAGES: 'coverages',
        FILES: 'files',
        INJURY_INCIDENT: 'injury-incidents',
        VEHICLES: 'vehicles',
        JOURNEY_INDICATOR: 'fnol-journey-indicator'
    },
    APPLICATION_LABELS: {
        auto: {
            reporterOptions: [
                { value: 'nwAgent', name: 'Nationwide member\'s agent' },
                { value: 'nwAttorney', name: 'Nationwide member\'s attorney' },
                { value: 'relativeNotListed', name: 'Relative not listed on the policy' },
                { value: 'otherAgent', name: 'Agent for other party' },
                { value: 'claimantAttorney', name: 'Attorney for other party' },
                { value: 'claimantRelative', name: 'Relative of other party' },
                { value: 'lienholder', name: 'Lienholder' },
                { value: 'rental', name: 'Rental car company representative' },
                { value: 'otherNW', name: 'Other (representing Nationwide member)' },
                { value: 'otherClaimant', name: 'Other (representing other party)' }
            ]
        },
        property: {
            reporterOptions: [
                { value: 'nwAgent', name: 'Nationwide member\'s agent' },
                { value: 'nwAttorney', name: 'Nationwide member\'s attorney' },
                { value: 'relativeNotListed', name: 'Relative not listed on the policy' },
                { value: 'otherNW', name: 'Other (representing Nationwide member)' }
            ]
        },
        contactAndIncident: {
            submitterFirstName: 'First name',
            submitterLastName: 'Last name',
            submitterEmail: 'Email',
            memberHomePhone: 'Home phone',
            memberCellPhone: 'Cell phone',
            memberWorkPhone: 'Business phone',
            multiTextAlert: 'Would you like to receive text alerts that contain basic information about the claim?',
            textAlert: 'Would you like to receive a text alert that contains basic information about the claim?',
            preferedPhone: 'Preferred contact number for this claim',
            acknowledgement: 'I understand I am filing a claim and Nationwide will follow up after an initial review.'
        },
        memberPropertyDetails: {
            memberName: 'Member name',
            email: 'Email',
            homePhone: 'Home phone',
            cellPhone: 'Cell phone',
            businessPhone: 'Business phone',
            extn: 'Ext.',
            preferedPhone: 'Preferred contact number for this claim',
            propertyInformationGroup: {
                dwlInvolvedInd: 'Home was damaged or a building on the property, such as a garage, barn or shed.',
                otherStrInvolvedInd: 'Other structure was damaged, such as a pool, fence, deck or gazebo.',
                contentInvolvedInd: 'Personal property was lost, stolen or damaged, such as furniture, electronics, appliances or jewelry.',
                iDTheftInd: 'Identity theft occurred, such as theft of documents or credit card information.',
                vehicleOrBoatInd: 'Vehicle or boat was damaged.',
                spoiledFood: 'Only food was spoiled as a result of the equipment breakdown or power outage.',
                otherInd: 'Any other loss or damage not already covered.'
            },
            homeDamagedGroup: {
                roofDamaged: 'Roof',
                gutterDamaged: 'Gutters/Downspouts',
                windowsDoorsDamaged: 'Windows/Doors',
                interiorDamages: 'Interior damages',
                awningDamaged: 'Awning',
                powerMeterDamaged: 'Power meter/Lines/Utility pole',
                shedDamaged: 'Shed',
                otherDamaged: 'Other/I don\'t know'
            },
            extentofRoofDamageMissing: 'Missing or damaged roofing material',
            extentofRoofDamageBeyondMissing: 'Structural damage beyond missing or damaged roofing material',
            contractorInvolvedInd: 'Has the member arranged for a contractor or repair person yet?',
            contractorNamePhoneNumber: 'Contractor name/phone number',
            estimatesReceived: 'Has the member received an estimate?',
            estRepairCost: 'If you received multiple total estimates from different contractors, please choose one to provide here.',
            FallenTreeIndicator: 'Did a tree cause damage?',
            allRepairsCompleted: 'Have all the repairs been completed?',
            treeDamageDescriptionFallen: 'Tree fell on a building or is blocking access to a building or property',
            treeDamageDescriptionOther: 'Other',
            missingRoofDamage: 'Missing or damaged roofing material',
            beyondMissingRoofDamage: 'Structural damage beyond missing or damaged roofing material'
        }
    },
    incidentValueConst: {
        HAIL: 'hail',
        WIND_DAMAGE: 'wind',
        WATER_DAMAGE_NOT_FROM_WEATHER: 'nw_waternonweather',
        WATER_DAMAGE_FROM_WEATHER: 'nw_water',
        WEIGHT_OF_ICE: 'nw_weightofice',
        FREEZING: 'nw_freezing',
        LIGHTNING: 'nw_lightning',
        THEFT: 'nw_theft',
        THEFT_OF_ITEMS_VEHICLE: 'nw_theftfromauto',
        LOST_ITEM: 'nw_lostitem',
        IDENTITY_THEFT: 'nw_identitytheft',
        VANDALISM_MALICIOUS: 'vandalism',
        FIRE: 'fire',
        SMOKE: 'nw_smoke',
        DAMAGE_VEHICLE_WATER_OR_AIR: 'nw_vehiclewaterandair',
        EQUIPMENT_BREAK_DOWN_POWER_SURGE: 'nw_equipmentbreakdown',
        EARTH_QUAKE: 'earthquake',
        OTHER_PHYSICAL_DAMAGE: 'nw_allother'
    },

    trackerCoverageWhiteList: [
        '540',
        '543',
        '544',
        '545',
        '548',
        '549',
        '552',
        '552-b',
        '553',
        '553-b',
        '554',
        '554-b',
        '555',
        '555-b',
        '556',
        '556-b',
        '557',
        '559',
        '559-b',
        '569',
        '581',
        '58F',
        'BCOLL',
        'COLL',
        'COMP',
        'COMP-2',
        'UIMPD',
        'UMPD'
    ],

    PHONE_TYPE_LIST: {
        MOBILE: 'mobile',
        HOME: 'home',
        BUSINESS: 'work',
        OTHER: 'other'
    },

    EFNOL_PHONE_TYPE_LIST: {
        Mobile: 'mobile',
        Home: 'home',
        Office: 'work'
    },

    CLAIMS_TRACKER: {
        CONTACT_PROXY_DEFAULT: '800-421-3535',
        CONTACT_SECTION: {
            GENERAL_TITLE: 'General claim questions',
            VEHICLE_DAMAGE_TITLE: 'Vehicle damage questions',
            INJURY_TITLE: 'Injury questions'
        },
        CONTENT_RESPONSE_LABELS: {
            EXPOSURE_OWNER: 'exposureOwner',
            INJURY: 'injury',
            VEHICLE_DAMAGE: 'vehicleDamage',
            CLAIM_OWNER: 'claimOwner'
        },
        DYNAMIC_QUESTIONS: {
            READY_TO_ANSWER: 'READYTOANSWER',
            ANSWERED: 'ANSWERED',
            UNANSWERED: 'UNANSWERED',
            PENDING: 'PENDING',
            SKIPPED: 'SKIPPED',
            VIEWED: 'VIEWED',
            BOOLEAN: 'BOOLEAN',
            DATE_TIME: 'DATE_TIME',
            DATE: 'DATE',
            TIME: 'TIME',
            TEXT: 'TEXT',
            TEXT_AREA: 'TEXT_AREA',
            TYPELIST: 'TYPELIST',
            TYPELIST_MULTI_SELECT: 'TYPELIST_MULTI_SELECT',
            FIXED_DISPLAY: 'FIXED_DISPLAY'
        },
        PAYMENT_SECTION: {
            paidPaymentStatuses: [
                'requesting',
                'requested',
                'notifying',
                'issued',
                'cleared'
            ],
            stoppedPaymentStatus: [
                'pendingvoid',
                'voided',
                'pendingstop',
                'stopped'
            ],
            paymentIssued: 'issued',
            paymentStopped: 'payment stopped'
        }
    }
};
export const tripPurpose: { [ key: string ]: string }[] = [
    { key: 'nw_personal', value: 'Personal' },
    { key: 'nw_business', value: 'Business' },
    // { key: 'business', value: 'Business(Delivery, While working)' }, TODO after UI bug Fix
    { key: 'nw_ridesharing', value: 'Ridesharing(Uber,Lyft,etc.)' },
    { key: 'nw_emergencyservice', value: 'Emergency Service' },
    { key: 'nw_parked', value: 'Parked' },
    { key: 'nw_other', value: 'Other' }
];
export const WaterDamageDropdown: { [ key: string ]: string }[] = [
    { key: 'plumbing_appliances', value: 'Plumbing or Appliances' },
    { key: 'roof', value: 'Roof leak' },
    { key: 'nw_sumppumpbackupoverflow', value: 'Sump pump' },
    { key: 'nw_sewerdrain', value: 'Sewer, Drain, Toilet backup or Overflow' },
    { key: 'nw_floodgroundwater', value: 'Flood, Groundwater or Foundation' },
    { key: 'other', value: 'Other' }
];
export const AccidentTypeList = [
    {
        'title': 'Accident',
        'desc': 'Damage caused by a collision with another vehicle (regardless of road conditions), an animal, or even a fixed object like a utility pole.'
    },
    {
        'title': 'Weather-related',
        'desc': 'Damage caused by weather-like hail-or resulting from a natural disaster- like a flood, earthquake, or landslide.'
    },
    {
        'title': 'Other damage/theft',
        'desc': 'Damage or loss from someone stealing or vandalizing your vehicle, or for other situations like a tree or rock falling on your vehicle.'
    }
];
export const IncidentDetailsArray = [
    {
        value: PersonalConstants.incidentValueConst.HAIL,
        caption: 'Hail Damage',
        hint: '',
        selected: false,
        icon: 'icon--hail-property'
    },
    {
        value: PersonalConstants.incidentValueConst.WIND_DAMAGE,
        caption: 'Wind Damage',
        hint: '(e.g., wind, hurricane or tornado)',
        icon: 'icon--wind-damage',
        selected: false
    },

    {
        value: PersonalConstants.incidentValueConst.WATER_DAMAGE_NOT_FROM_WEATHER,
        caption: 'Water Damage (Not from weather)',
        hint: '(e.g., plumbing or water backup)',
        icon: 'icon--water-notweather',
        selected: false
    },
    {
        value: PersonalConstants.incidentValueConst.WATER_DAMAGE_FROM_WEATHER,
        caption: 'Water Damage (from weather)',
        hint: '',
        selected: false,
        icon: 'icon--water-weather'
    },
    {
        value: PersonalConstants.incidentValueConst.WEIGHT_OF_ICE,
        caption: 'Weight of Ice, Snow or Sleet',
        hint: '(e.g., collapsed roof)',
        selected: false,
        icon: 'icon--snow-weight'
    },
    {
        value: PersonalConstants.incidentValueConst.FREEZING,
        caption: 'Freezing',
        hint: '(e.g., pipes burst)',
        selected: false,
        icon: 'icon--freezing'
    },
    {
        value: PersonalConstants.incidentValueConst.LIGHTNING,
        caption: 'Lightning',
        hint: '',
        selected: false,
        icon: 'icon--lightning'
    },
    {
        value: PersonalConstants.incidentValueConst.THEFT,
        caption: 'Theft',
        hint: '',
        selected: false,
        icon: 'icon--theft-home'
    },
    {
        value: PersonalConstants.incidentValueConst.THEFT_OF_ITEMS_VEHICLE,
        caption: 'Theft of Items From Vehicle',
        hint: '',
        selected: false,
        icon: 'icon--theft-vehicle'
    },
    {
        value: PersonalConstants.incidentValueConst.LOST_ITEM,
        caption: 'Lost Item or Mysterious Disappearance',
        hint: '(e.g., missing jewelry or other personal property)',
        selected: false,
        icon: 'icon--lost-item'
    },
    {
        value: PersonalConstants.incidentValueConst.IDENTITY_THEFT,
        caption: 'Identity Theft',
        hint: '',
        selected: false,
        icon: 'icon--id-theft'
    },
    {
        value: PersonalConstants.incidentValueConst.VANDALISM_MALICIOUS,
        caption: 'Vandalism and Malicious Mischief',
        hint: '',
        selected: false,
        icon: 'icon--vandalism-mischief'
    },
    {
        value: PersonalConstants.incidentValueConst.FIRE,
        caption: 'Fire',
        hint: '',
        selected: false,
        icon: 'icon--fire-property'
    },
    {
        value: PersonalConstants.incidentValueConst.SMOKE,
        caption: 'Smoke',
        hint: '',
        selected: false,
        icon: 'icon--smoke'
    },
    {
        value: PersonalConstants.incidentValueConst.DAMAGE_VEHICLE_WATER_OR_AIR,
        caption: 'Damage from Vehicle, Watercraft or Aircraft',
        hint: '',
        selected: false,
        icon: 'icon--damage-vwa'
    },
    {
        value: PersonalConstants.incidentValueConst.EQUIPMENT_BREAK_DOWN_POWER_SURGE,
        caption: 'Equipment Breakdown or Power Surge',
        hint: '(not from weather)',
        selected: false,
        icon: 'icon--equipment'
    },
    {
        value: PersonalConstants.incidentValueConst.EARTH_QUAKE,
        caption: 'Earthquake, Earth Movement or Landslide',
        hint: '',
        selected: false,
        icon: 'icon--earthquake'
    },
    {
        value: PersonalConstants.incidentValueConst.OTHER_PHYSICAL_DAMAGE,
        caption: 'All Other Physical Damage',
        hint: '',
        selected: false,
        icon: 'icon--other-phys-damage'
    }
];
export interface RadioOptions {
    icon: string;
    helptext?: string;
    helptextheading?: string;
    value: string;
    caption: string;
    setSprites?: string;
}
export const accidentlossCauseOptions: Array<RadioOptions> = [
    {
        value: 'vehcollision',
        caption: 'Accident with other vehicle(s)',
        setSprites: 'losscausesprites',
        icon: 'vehcollision'
    },
    {
        value: 'animal',
        caption: 'Hit an animal',
        setSprites: 'losscausesprites',
        icon: 'animal'
    },
    {
        value: 'fixedobjcoll',
        caption: 'Hit a fixed object (e.g., pole, guard rail)',
        setSprites: 'losscausesprites',
        icon: 'fixedobject'
    },
    {
        value: 'shoppingcart',
        caption: 'Hit a shopping cart',
        setSprites: 'losscausesprites',
        icon: 'shoppingcart'
    },
    {
        value: 'ranOffRoad',
        caption: 'Ran off the road',
        setSprites: 'losscausesprites',
        icon: 'ranOffRoad'
    },
    {
        value: 'collisionAllOther',
        caption: 'It\'s complicated',
        helptextheading: 'Collision all other',
        helptext: 'Collision with multiple sources or unknown source.',
        setSprites: 'losscausesprites',
        icon: 'collisionAllOther'
    }
];
export const weatherRelatedlossCauseOptions: Array<RadioOptions> = [
    {
        value: 'weatherHail',
        caption: 'Hail damage',
        setSprites: 'losscausesprites',
        icon: 'accident-hail'
    },
    {
        value: 'weatherFlood',
        caption: 'Flood damage',
        setSprites: 'losscausesprites',
        icon: 'flood'
    },
    {
        value: 'earthmovement',
        caption: 'Earth movement',
        setSprites: 'losscausesprites',
        icon: 'earthquake'
    },
    {
        value: 'fire',
        caption: 'Fire',
        setSprites: 'losscausesprites',
        icon: 'fire'
    }
];
export const otherlossCauseOptions: Array<RadioOptions> = [
    {
        value: 'FallingObject',
        caption: 'Object hit vehicle (e.g., rocks, branch)',
        setSprites: 'losscausesprites',
        icon: 'accident-object'
    },
    {
        value: 'vandalism',
        caption: 'Vandalism (e.g., tire slashed, paint scratch)',
        setSprites: 'losscausesprites',
        icon: 'carvandelism'
    },
    {
        value: 'partialTheft',
        caption: 'Partial theft (e.g., wheels stolen)',
        setSprites: 'losscausesprites',
        icon: 'partialTheft'
    },
    {
        value: 'theftentire',
        caption: 'Theft (e.g., car stolen)',
        setSprites: 'losscausesprites',
        icon: 'lock'
    },


    {
        value: 'compAllOther',
        caption: 'All other damages',
        helptextheading: 'Comprehensive all other',
        helptext: 'Unknown source of damage.',
        setSprites: 'losscausesprites',
        icon: 'compAllOther'
    }
];
export const MFA_ERROR_MAP = {
    required: '@@fieldname@@ is required.',
    pattern: 'Format for @@fieldname@@ is invalid.',
    claimAccessCodeError: 'Claims access code is invalid.',
    sixDigitCodeError: 'Six digit code is required.'
};

export const ERROR_MAP_COPYTEXTS = {
    maxlength: '@@fieldname@@ should not exceed with specified characters.',
    required: '@@fieldname@@ is required.',
    phoneNumberError: '@@fieldname@@ is incorrect.',
    patternError: 'Format for @@fieldname@@ is invalid.',
    pattern: '@@fieldname@@ is invalid.',
    emailError: '@@fieldname@@ is incorrect.',
    timeValueError: 'format is invalid.',
    zipCodePattern: 'Invalid characters. Enter a five digit zip code.',
    textBoxUntouchedError: 'Type a written description or speak into your mobile device\'s microphone.',
    selectOptionPattern: 'Select a description  for how you\'re involved.',
    acknowledgmentError: 'Please confirm that you\'re ready to file this claim.',
    insuredInjuredError: 'Select yes or no.',
    phoneFormatError: 'Format for phone number is invalid.',
    policyMismatchError: 'Please check your policy number and try again.',
    claimAccessCodeError: 'Claims access code is invalid.',
    sixDigitCodeError: 'Six digit code is required.',
    checkYear: '@@fieldname@@ is invalid.',
    nameFieldError: 'Special characters/numbers are not valid. Please re-enter.',
    fieldMessage: 'Special characters are not valid. Please re-enter.',
    areaMessage: 'You can only use these special characters ; ’ - _  & . ? , : # $ % ( ) [ ]',
    invalidPolicy: 'Check that number & try again. Be sure it\'s an auto policy number.',
    commercialPolicy: 'That\'s not a personal auto policy number. If you\'re trying to file a commercial claim, use the link below.'
};


export const POLICY_PAGE_ERROR_MAP = {
    auto: `We can’t find an auto policy for the policy number you entered. <br> <br> Did you mean to start a
        <a href=\'/propertyclaims/file-claim\'>property</a> or
        <a href=\'https://www.nationwide.com/business/insurance/claims/\'>commercial</a> claim?`,
    property: `We can’t find a property policy for the policy number you entered. <br> <br> Did you mean to start an
        <a href=\'/autoclaims/file-claim\'>auto</a> or
        <a href=\'https://www.nationwide.com/business/insurance/claims/\'>commercial</a> claim?`

};

export const fieldsForDirectErrorMessages = ['selectOptionPattern', 'timeValueError', 'acknowledgmentError',
    'phoneFormatError', 'insuredInjuredError', 'claimAccessCodeError', 'sixDigitCodeError', 'nameFieldError', 'fieldMessage', 'areaMessage', 'invalidPolicy'];

export const fieldConstants = { thisField: 'This field' };

export const exceptionLowerCaseErrors = ['ZIP'];

export const CLAIMS_CONSTANT = {
    YES: 'yes',
    NO: 'no',
    CELL_NUMBER: 'cellNumber',
    HOME_NUMBER: 'homeNumber',
    BUSINESS_NUMBER: 'businessNumber',
    PERSON: 'person'
};

export interface Person {
    firstName?: string;
    lastName?: string;
    cellNumber?: string;
    primaryPhoneType?: string;
    subtype?: string;
    contactName?: string;
    isFormValid?: boolean;
}

export interface Vehicle {
    year?: string;
    make?: string;
    model?: string;
    licensePlate?: string;
    licenseState?: string;
}

export const lastName = {
    bicyclist: 'Bicyclist',
    pedestrian: 'Pedestrian',
    unknown: 'Unknown'
};

export const otherAccidentCausesFields = {

    statusOfPersons: {
        walkRun: 'Walking/Running',
        onBicycle: 'On a Bicycle',
        aroundVehicle: 'Around or entering a vehicle',
        other: 'Other'
    },

    statusOfVehicles: {
        parked: 'Parked',
        stopped: 'Completely stopped-e.g. stop light, stop sign, etc',
        changingLanes: 'Turning/changing lanes',
        forwardMove: 'Moving forward',
        backup: 'Backing up'
    },

    parkedStatusOfVehicles: {
        parked: 'Parked',
        stopped: 'Completely stopped-e.g. stop light, stop sign, etc'
    },

    vehicleParkingSpots: {
        parkingGarage: 'Parking lot/Garage',
        streetAlley: 'Street/Alley',
        driveway: 'Driveway',
        other: 'Other'
    },

    vehicleStoppingPlace: {
        trafficLight: 'Traffic Light',
        stopSign: 'Stop Sign',
        inTraffic: 'While in traffic/driving',
        other: 'Other'
    },

    whileMovingForward: {
        driveway: 'Driveway',
        parkingLot: 'Parking lot',
        residentialStreet: 'Residential street',
        highway: 'Highway/divided roadway/interstate',
        stopSign: 'Stop sign'
    },

    situationReasons: {
        mirrorDamage: 'Only damage to my mirror',
        otherVehicleBacked: 'Other vehicle backed into my vehicle',
        rearEnded: 'Rear-ended',
        vehicleDamageAlongside: 'Damage along the side of the vehicle (side-swiped)',

        otherVehicleHitSideFront:
            'Other vehicle directly hit the side or front of my vehicle (t-boned, head-on collision, etc.)'

    },

    situationStatusAVF1: {
        otherVehicleMirrorDamage: 'Only damage to the other vehicle\'s mirror',
        otherVehicleBacked: 'The other vehicle backed into my vehicle',
        rearEnded: 'Rear-ended',
        otherVehicleDamageAlongside: 'Damage along the side of the other vehicle (side-swiped)',

        myVehicleHitOtherVehicle:
            'My vehicle directly hit the side or front of the other vehicle (t-boned, head-on collision, etc)'

    },

    situationReasonsAVF1: {
        otherVehicleMirrorDamage: 'Only damage to the other vehicle\'s mirror',
        otherVehicleBacked: 'The other vehicle backed into my vehicle',
        damageAlongsideOtherVehicle: 'Damage along the side of the other vehicle (side-swiped)',
        otherVehicleDamageAlongside: 'Damage along the side of the other vehicle (side-swiped)',
        rearEnded: 'Rear-ended',

        myVehicleHitOtherVehicle: 'My vehicle directly hit the side or front of the other vehicle (t-boned, head-on collision, etc)',
        myVehicleHitRear: 'My vehicle directly hit the rear of the other vehicle'
    },

    bestDescribedSituationAVB1: {
        otherVehicleMirrorDamage: 'Only damage to the other vehicle\'s mirror',
        myVehicleBackedOther: 'My vehicle backed into the other vehicle',
        damageAlongsideOtherVehicle: 'Damage along the side of the other vehicle (side-swiped)',
        myVehicleHitOtherVehicle: 'My vehicle directly hit the side or front of the other vehicle (t-boned, head-on collision, etc)',
        myVehicleHitFront: 'My vehicle directly hit the front of the other vehicle'
    },

    vehicleHitTimes: {
        driverfront: 'Driver side front',
        drivermiddle: 'Driver side middle',
        driverrear: 'Driver side rear',
        passengerfront: 'Passenger side front',
        passengermiddle: 'Passenger side middle',
        passengerrear: 'Passenger side rear',
        front: 'Front side end',
        rear: 'Rear end'
    },
    AVB3MirrorDamage: {
        driverfront: 'Driver side front',
        drivermiddle: 'Driver side middle',
        driverrear: 'Driver side rear',
        passengerfront: 'Passenger side front',
        passengermiddle: 'Passenger side middle',
        passengerrear: 'Passenger side rear',
        front: 'Front side end',
        rear: 'Rear end',
        hood: 'Hood'
    },
    AVB3VehicleBackedOther: {
        rear: 'Rear end',
        trunk: 'Trunk',
        driverrear: 'Driver side rear',
        passengerrear: 'Passenger side rear'
    },
    AVB3DamageAlongsideOtherVehicle: {
        driverfront: 'Driver side front',
        drivermiddle: 'Driver side middle',
        driverrear: 'Driver side rear',
        passengerfront: 'Passenger side front',
        passengermiddle: 'Passenger side middle',
        passengerrear: 'Passenger side rear'
    },
    AVB3myVehicleHitFront: {
        driverfront: 'Driver side front',
        passengerfront: 'Passenger side front',
        front: 'Center Front',
        hood: 'Hood'
    },

    whereVehicleHitAVU1: {
        driverfront: 'Driver side front',
        drivermiddle: 'Driver side middle',
        driverrear: 'Driver side rear',
        passengerfront: 'Passenger side front',
        passengermiddle: 'Passenger side middle',
        passengerrear: 'Passenger side rear'
    },

    whereVehicleHitAVS1: {
        driverfront: 'Driver side front',
        drivermiddle: 'Driver side middle',
        driverrear: 'Driver side rear',
        passengerfront: 'Passenger side front',
        passengermiddle: 'Passenger side middle',
        passengerrear: 'Passenger side rear',
        rear: 'Rear end'
    },

    whereVehicleHitAVU1AndAVS1: {
        driverfront: 'Driver side front',
        drivermiddle: 'Driver side middle',
        driverrear: 'Driver side rear',
        passengerfront: 'Passenger side front',
        passengermiddle: 'Passenger side middle',
        passengerrear: 'Passenger side rear',
        front: 'Front side end'
    },

    whereVehicleHitAVM1: {
        driverfront: 'Driver side front',
        drivermiddle: 'Driver side middle',
        driverrear: 'Driver side rear',
        passengerfront: 'Passenger side front',
        passengermiddle: 'Passenger side middle',
        passengerrear: 'Passenger side rear',
        front: 'Front side end',
        nw_All_Over: 'All over',
        hood: 'Hood',
        rear: 'Rear end',
        nw_Rollover: 'Rollover',
        roof: 'Roof',
        trunk: 'Trunk',
        nw_Undercarriage: 'Undercarriage'
    },

    onlyMirrorDamage: {
        driverfront: 'Driver side front',
        passengerfront: 'Passenger side front'
    },

    rearEnded: {
        driverrear: 'Driver side rear',
        passengerrear: 'Passenger side rear',
        rear: 'Rear end'
    }
};
export const identificationList = {
    'registered': 'I am a Nationwide member, filing a claim on my policy',
    'unregistered-non-member': 'I was involved in an accident with a Nationwide member',
    'unregistered-member-support': 'I am filing a claim on behalf of a Nationwide member',
    'unregistered-non-member-support': 'I am filing a claim on behalf of a non-member'
};
export const identificationReturnList = {
    'registered': 'I\'m a Nationwide member continuing to file a claim on my policy',
    'unregistered-non-member': 'I\'m continuing to report an accident with a Nationwide member',
    'unregistered-member-support': 'I\'m continuing to file a claim on behalf of a Nationwide member',
    'unregistered-non-member-support': 'I\'m continuing to file a claim on behalf of a non-member'
};
export const locationHitAVM5 = {
    'unknown': 'I\'m not sure',
    'notapplicable': 'Not applicable',
    'nw_All_Over': 'All over',
    'driverfront': 'Driver side front',
    'drivermiddle': 'Driver side middle',
    'driverrear': 'Driver side rear',
    'passengerfront': 'Passenger side front',
    'passengermiddle': 'Passenger side middle',
    'passengerrear': 'Passenger side rear',
    'front': 'Front side end',
    'hood': 'Hood',
    'rear': 'Rear end',
    'nw_Rollover': 'Rollover',
    'roof': 'Roof',
    'trunk': 'Trunk',
    'nw_Undercarriage': 'Undercarriage'
};
export const nonmemberList = {
    claimantinsco: 'I’m the insurance agent for the non-member',
    claimantrelative: 'I’m a relative of a non-member',
    claimantother: 'Other (representing a non-member)'
};
export const memberList = {
    agent: 'I’m a Nationwide member’s agent',
    relative: 'I’m a relative not listed on the Nationwide member’s policy',
    other: 'Other (representing a Nationwide member)'
};
export const topVehicleHitList = {
    front: 'Front end',
    Hood: 'Hood',
    driverfront: 'Driver side front',
    passengerfront: 'Passenger Side front'
};

export const leftVehicleHitList = {
    driverfront: 'Driver side front',
    drivermiddle: 'Driver side middle',
    driverrear: 'Driver side rear'
};
export const rightVehicleHitList = {
    passengerfront: 'Passenger side front',
    passengermiddle: 'Passenger side middle',
    passengerrear: 'Passenger side rear'
};
export const bottomVehicleHitList = {
    rear: 'Center rear',
    trunk: 'Trunk',
    driverrear: 'Driver side rear',
    passengerrear: 'Passenger side rear'
};

export const bottomVehicleHitListB2 = {
    rear: 'Center rear',
    trunk: 'Trunk',
    passengerrear: 'Passenger side rear',
    driverrear: 'Driver side rear'
};

export const bottomVehicleHitListB3 = {
    rear: 'Center rear',
    trunk: 'Trunk',
    passengerrear: 'Passenger side rear',
    driverrear: 'Driver side rear'
};

export const bottomVehicleHitListB4 = {
    rear: 'Center rear',
    trunk: 'Trunk',
    passengerrear: 'Passenger side rear',
    driverrear: 'Driver side rear'
};

export const topVehicleHitListAVB = {
    front: 'Front end',
    hood: 'Hood',
    driverfront: 'Driver side front',
    passengerfront: 'Passenger side front'
};
export const leftVehicleHitListAVB = {
    passengerfront: 'Passenger side front',
    passengermiddle: 'Passenger side middle',
    passengerrear: 'Passenger side rear'
};
export const rightVehicleHitListAVB = {
    driverfront: 'Driver side front',
    drivermiddle: 'Driver side middle',
    driverrear: 'Driver side rear'
};
export const bottomVehicleHitListAVB = {
    driverrear: 'driver rear',
    rear: 'center rear',
    passengerrear: 'passenger rear',
    trunk: 'trunk'
};
export const damageMirrorAVBList = {
    driverfront: 'Driver side front',
    drivermiddle: 'Driver side middle',
    driverrear: 'Driver side rear',
    passengerfront: 'Passenger side front',
    passengermiddle: 'Passenger side middle',
    passengerrear: 'Passenger side rear',
    front: 'Front end',
    hood: 'Hood'
};
export const sideSwipedList = {
    driverfront: 'Driver side front',
    drivermiddle: 'Driver side middle',
    driverrear: 'Driver side rear',
    passengerfront: 'Passenger side front',
    passengermiddle: 'Passenger side middle',
    passengerrear: 'Passenger side rear',
    front: 'Front side'
};
export const rearEndAVFList = {
    driverrear: 'Driver rear',
    passengerrear: 'Passenger rear',
    rear: 'Rear end'
};
export const otherVehicleBackedIntoMyVehicleAVFList = {
    driverfront: 'Driver side front',
    drivermiddle: 'Driver side middle',
    driverrear: 'Driver side rear',
    passengerfront: 'Passenger side front',
    passengermiddle: 'Passenger side middle',
    passengerrear: 'Passenger side rear',
    rear: 'Center rear'
};
export const otherVehicleBackedIntoMyVehicleAVBList = {
    rear: 'Center rear',
    trunk: 'Trunk',
    driverrear: 'Driver side rear',
    passengerrear: 'Passenger side rear'
};
export const rearEndedSideOrFrontDamageAVBlist = {
    front: 'Center front',
    Hood: 'Hood',
    driverfront: 'Driver side front',
    passengerfront: 'Passenger side front'
};
export const damageToMirrorAVFList = {
    driverfront: 'Driver side front',
    passengerfront: 'Passenger side front'
};
export const otherVehicleBackedIntoMyVehicleWhileBackingUpAVFList = {
    rear: 'Center rear',
    driverrear: 'Driver rear',
    passengerrear: 'Passenger rear'
};
export const otherVehicleBackedIntoMyVehicleAVF3List = {
    driverfront: 'Driver side front',
    drivermiddle: 'Driver side middle',
    driversiderear: 'Driver side rear',
    passengerfront: 'Passenger side front',
    passengermiddle: 'Passenger side middle',
    passengersiderear: 'Passenger side rear',
    rear: 'Center rear',
    driverrear: 'Driver rear',
    passengerrear: 'Passenger rear'
};

export const inspectionOptionList = ['I already have a shop',
    'I need more time to think',
    'I need time to talk with someone else (spouse, family member or friend)',
    'I want to talk to a Nationwide associate'];

export const accidentTypes = {
    vehcollision: 'Collision with Vehicle/Watercraft',
    fixedobjcoll: 'Collision with Fixed Object',
    animal: 'Contact with Animal',
    shoppingcart: 'Contact with Shopping Cart',
    earthmovement: 'Earth Movement',
    FallingObject: 'Falling/Flying Object',
    partialTheft: 'Partial Theft',
    ranOffRoad: 'Ran off road',
    theftentire: 'Total Theft',
    lockout: 'Tow Only, Lockout or Key Replacement',
    glassbreakage: 'Glass Breakage only',
    pedcollision: 'Collision with Pedestrian',
    bikecollision: 'Collision with Bicycle',
    injured_around_veh: 'Injured on, around, entering, or exiting vehicle',
    weatherFlood: 'Weather - Flood',
    weatherHail: 'Weather - Hail',
    hail: 'Weather - Hail',
    weatherAllOther: 'Weather All Other',
    collisionAllOther: 'Collision All Other',
    compAllOther: 'Comprehensive All Other',
    fire: 'Fire',
    vandalism: 'Vandalism and Malicious Mischief'
};

export const ErrorCodes = [0, 400, 401, 403, 404, 429, 500, 504, 503];

export const ErrorMessage = 'Oops! Looks like something went wrong.';

export const UnknownError = 'Unknown error occurred.';

export const TOLL_FREE_NUMBER = '1-800-421-3535';

export const CHECK_FAQ = 'Check FAQs';

export const INTERCEPTOR_EXCLUDE_URLS = ['pnc-claims-consolidated-timeline', 'tridion', 'group1.json', 'group4.json'];
