import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class NwInputErrorService {
    common = false;
    constructor () { }
    validateEstRepairCostBoltTextfield(parentGroup: UntypedFormGroup, inputFormControl: any): any {
        if (inputFormControl === 'estRepairCost' &&
            parentGroup.controls[inputFormControl].touched &&
            parentGroup.controls[inputFormControl].invalid) {
            return 'Required';
        }
    }
    validateBoltTextfield(parentGroup: UntypedFormGroup, inputFormControl: any): any {
        if (!this.common && parentGroup.controls[inputFormControl].touched &&
            parentGroup.controls[inputFormControl].errors?.required) {
            return 'Required';
        }
        if (!this.common &&
            parentGroup.controls[inputFormControl].touched &&
            parentGroup.controls[inputFormControl].errors?.emailError) {
            return 'Enter valid email address';
        }
        if (!this.common && !parentGroup.controls[inputFormControl].errors?.required &&
            parentGroup.controls[inputFormControl].dirty &&
            parentGroup.controls[inputFormControl].touched &&
            parentGroup.controls[inputFormControl].errors?.phoneNumberError) {
            return 'Enter valid phone number';
        }
        if (!this.common && !parentGroup.controls[inputFormControl].errors?.required &&
            inputFormControl !== 'extn' && parentGroup.controls[inputFormControl].touched &&
            parentGroup.controls[inputFormControl].errors?.pattern) {
            return 'Enter valid characters';
        }
        if (!this.common && !parentGroup.controls[inputFormControl].errors?.required &&
            parentGroup.controls[inputFormControl].dirty &&
            parentGroup.controls[inputFormControl].touched &&
            parentGroup.controls[inputFormControl].errors?.confirmFieldError) {
            return 'Fields do not match';
        }


        if (!this.common && !parentGroup.controls[inputFormControl].errors?.required &&
            parentGroup.controls[inputFormControl].dirty &&
            parentGroup.controls[inputFormControl].touched &&
            parentGroup.controls[inputFormControl].errors?.invalidRoutingNumberError) {
            return 'Routing number not found';
        }

        if (!this.common && !parentGroup.controls[inputFormControl].errors?.required &&
            parentGroup.controls[inputFormControl].dirty &&
            parentGroup.controls[inputFormControl].touched &&
            parentGroup.controls[inputFormControl].errors?.routingNumberError) {
            return 'Enter valid routing number';
        }

        if (!this.common && !parentGroup.controls[inputFormControl].errors?.required &&
            parentGroup.controls[inputFormControl].errors?.checkBoxError) {
            return 'Please accept the declaration';
        }


        if (!this.common && !parentGroup.controls[inputFormControl].errors?.required &&
            inputFormControl === 'extn' && parentGroup.controls[inputFormControl].touched &&
            parentGroup.controls[inputFormControl].errors?.pattern) {
            return 'Enter the valid extension number';
        }


        if (this.common) {
            return 'Please check the fields!';
        } else {
 return '';
}
    }
}
