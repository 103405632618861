import { Injectable } from '@angular/core';
import { PersonalConstants } from 'app/common/personalConstants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { EnvironmentpickerService } from './environmentpicker.service';
import { HttpService } from './http.service';
import { HttpHeaderService } from './httpheader.service';

@Injectable({
    providedIn: 'root'
})
export class PolicyCoveragesService {
    constructor (private environmentPickerService: EnvironmentpickerService,
        private httpHeaderService: HttpHeaderService,
        private httpService: HttpService
    ) { }

    buildgetPolicyCoverages(policyNumber: string): Observable<any> {
           const uri = `${PersonalConstants.Application_Uri.POLICIES}/${policyNumber}/${PersonalConstants.Application_Uri.COVERAGES}`;
            const httpRequest = {
                uri: uri,
                type: '',
                requestPayload: '',
                baseUrl: this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.PolicyAPIs.policyCoverages,
                GUID: '',
                headers: this.httpHeaderService.getNWPolicyCoverageHeaders(environment.CLAIMS_DEV)
            };
            return this.httpService.invokeHttpGet(httpRequest);
    }
}
