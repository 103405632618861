
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APICommonService } from '@nationwide/core-component-library';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonalConstants } from '../../common/personalConstants';
import { LoggerService } from './logger.service';
import { UtilService } from './util.service';


@Injectable()
export class AuthorizationService {
    ctToken: string;

    constructor (
        private http: HttpClient,
        private LOGGER: LoggerService,
        private util: UtilService,
        private datePipe: DatePipe,
        private apiCommonService: APICommonService
    ) { }

    ctRun(userID: string): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = PersonalConstants.CLEARTRUST.ENDPOINT + userID;

        return this.http.get(url, { headers: headers }).pipe(map((response: Response) => response));
    }

    getQuickIdOAuthCall(agreementNumber, postalCode, birthDate, lastName): any {
        const scope = 'test';
        const currentUrl = [location.protocol, '//', location.host, location.pathname].join('');

        const formattedDateOfBirth = this.datePipe.transform(new Date(birthDate), 'yyyy-MM-dd');
        const addendumString = `&auth_id_agreementNumber=${agreementNumber
            }&auth_id_postalCode=${postalCode
            }&auth_id_birthDate=${formattedDateOfBirth
            }&auth_id_lastName=${lastName
            }&auth_method=customer-search`;


        return this.getKnowledgeBasedOAuthCall(addendumString);
    }

    getQuickPayOAuthCall(accountNumber, zipCode, minimumAmountDue): string {
        const scope = 'test';
        const currentUrl = [location.protocol, '//', location.host, location.pathname].join('');
        const addendumString = `&auth_id_accountNumber=${accountNumber
            }&auth_id_zipCode=${zipCode
            }&auth_id_minimumAmountDue=${minimumAmountDue
            }&auth_method=commercial-lines-billing`;

        return this.getKnowledgeBasedOAuthCall(addendumString);
    }

    getKnowledgeBasedOAuthCall(addendumString): any {
        const scope = 'test';
        const currentUrl = [location.protocol, '//', location.host, location.pathname].join('');
        const oAuthEndpoint = `${environment.OAUTH.AUTH_2_ENDPOINT
            }?client_id=${PersonalConstants.appProps.appClientId
            }&nonce=${PersonalConstants.oAuthProps.oAuthNonce
            }&redirect_uri=${currentUrl
            }&response_type=${PersonalConstants.oAuthProps.oAuthResponseType
            }&scope=${scope
            }&realm=unidentified` +
            `&message_id=${this.apiCommonService.generateTransactionId()
            }&state=${PersonalConstants.oAuthProps.knowledgeAuthState
            }${addendumString}`;
        return oAuthEndpoint;
    }

    makeKnowledgeBasedOAuthCall(accountNumber, zipCode, minimumAmountDue): void {
        window.location.href = this.getQuickPayOAuthCall(accountNumber, zipCode, minimumAmountDue);
    }

    getClearTrustBasedOAuthCall(clearTrustToken): string {
        clearTrustToken = clearTrustToken.replace(/([+])/g, '%2B');

        const scope = 'test';
        const currentUrl = window.location.href.split('#')[0];
        const oAuthEndpoint = `${environment.OAUTH.AUTH_2_ENDPOINT
            }?client_id=${PersonalConstants.appProps.appClientId
            }&nonce=${PersonalConstants.oAuthProps.oAuthNonce
            }&redirect_uri=${currentUrl
            }&response_type=${PersonalConstants.oAuthProps.oAuthResponseType
            }&scope=${scope
            }&state=${PersonalConstants.oAuthProps.ctAuthState
            }&realm=member` +
            `&auth_method=cleartrust` +
            `&auth_id_user_token=${clearTrustToken}`;

        if (this.isStub()) {
            const userId = window.sessionStorage.getItem('userId');
            return `${environment.WIREMOCK_ENDPOINT}eua/${userId}`;
        }

        return oAuthEndpoint;
    }

    makeClearTrustBasedOAuthCall(clearTrustToken): void {
        window.location.href = this.getClearTrustBasedOAuthCall(clearTrustToken);
    }

    isStub(): boolean {
        return (window.sessionStorage.getItem('stubbed') === 'true' || environment.stubbed);
    }

    private extractContent = function (response): any {
        const body = response;
        this.LOGGER.debug(body.content, this.environment.loggerLevel);
        this.ctToken = body.content;
        return body.content || {};
    };
}
