import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'is-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframeComponent {
    @Input() src: string;
    constructor(private sanitizer: DomSanitizer) { }

    trustedSrc(): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
    }
}
