import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PersonalConstants } from 'app/common/personalConstants';
import { SharedModule } from 'app/shared/shared.module';

const PropertyClaimsRoutes: Routes = [
    {
        path: PersonalConstants.CLAIMS_PAGE_NAMES.BASE_PROPERTY_PAGE,
        children: [{
            path: '',
            loadChildren: (): any => import('./property-claims-unauth/property-claims-unauth.module')
                .then((m) => m.PropertyClaimsUnauthModule)
        }]
    }
];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(PropertyClaimsRoutes)
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [],
    providers: []
})
export class PropertyClaimsModule { }
