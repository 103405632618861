import { Injectable } from '@angular/core';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { PersonalConstants } from 'app/common/personalConstants';
import { environment } from 'environments/environment';


@Injectable({
    providedIn: 'root'
})

export class ClaimsOidcAuthConfigService {
    constructor () {
         // This is intentional
    }

    getConfig(): OpenIdConfiguration[] {
        return [
            {
                configId: PersonalConstants.LOGINTYPE.REGISTERED,
                authority: environment.OAUTH.AUTH_2_ENDPOINT,
                redirectUrl: `${window.location.origin}/authenticate`,
                postLogoutRedirectUri: environment.AUTH_HEADER_LINKS.logout,
                clientId: PersonalConstants.appProps.appClientId,
                scope: 'openid',
                responseType: 'id_token token',
                triggerAuthorizationResultEvent: true,
                silentRenew: true,
                logLevel: environment.production ? LogLevel.None : LogLevel.Debug,
                silentRenewUrl: `${window.location.origin}/assets/html/silent-renew.html`,
                renewTimeBeforeTokenExpiresInSeconds: PersonalConstants.OIDCTOKENTIME.MEMBERTOKENREFRESHTIME, // before 9min
                autoUserInfo: false,
                customParamsAuthRequest: {
                    realm: 'member',
                    auth_method: 'ping'
                }
            },
            {
                configId: PersonalConstants.LOGINTYPE.UNREGISTERED,
                authority: environment.OAUTH.AUTH_2_ENDPOINT,
                unauthorizedRoute: `/fileclaim/info/identification?error=accessDenied`,
                redirectUrl: `${window.location.origin}/authenticate`,
                postLogoutRedirectUri: environment.AUTH_HEADER_LINKS.logout,
                clientId: PersonalConstants.appProps.appClientId,
                scope: 'openid',
                responseType: 'id_token token',
                triggerAuthorizationResultEvent: true,
                silentRenew: true,
                logLevel: environment.production ? LogLevel.None : LogLevel.Debug,
                silentRenewUrl: `${window.location.origin}/assets/html/silent-renew.html`,
                renewTimeBeforeTokenExpiresInSeconds: PersonalConstants.OIDCTOKENTIME.UNREGISTEREDTOKENREFRESHTIME, // 12 min
                autoUserInfo: false
            },
            {
                configId: PersonalConstants.LOGINTYPE.UNREGISTEREDEXPANDUSER,
                authority: environment.OAUTH.AUTH_2_ENDPOINT,
                unauthorizedRoute: `/dashboard/mfa/login?error=accessDenied`,
                redirectUrl: `${window.location.origin}/authenticate`,
                postLogoutRedirectUri: environment.AUTH_HEADER_LINKS.logout,
                clientId: PersonalConstants.appProps.appClientId,
                scope: 'openid',
                responseType: 'id_token token',
                triggerAuthorizationResultEvent: true,
                silentRenew: false,
                logLevel: environment.production ? LogLevel.None : LogLevel.Debug,
                autoUserInfo: false
            }
        ];
    }
}


