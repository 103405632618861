<div [ngClass]="navBarValidation ? 'show-nav' : 'hide-nav'">

    <!-- Header section starts -->
    <header>
    <a href="javascript:void(0);" class="nw-header__skip" (click)='skipToMainContent()' translate>Skip to main content</a>
    <is-pre-efnol-header *ngIf="isPreEfnol && !tracker  || (checklist && !isAuthenticated) || (understandCoverages && !isAuthenticated)"></is-pre-efnol-header>
    <is-efnol-header *ngIf="!isPreEfnol && !claimList && ((tracker && !isRegisteredAuthenticated && isAuthenticated) || !tracker) && !checklist && !understandCoverages"></is-efnol-header>
        <is-tracker-header *ngIf="tracker && !isAuthenticated"></is-tracker-header>
        <authenticated-header *ngIf="isRegisteredAuthenticated && headerData && showHeader && (claimList || tracker || checklist || understandCoverages)" [headerData]="headerData" (linkClick)="linkClicked($event)">
        </authenticated-header>
    </header>
    <!-- Header section ends-->


    <is-nw-test-tool *ngIf="!isProd()" aria-hidden="true"></is-nw-test-tool>
    <div #mainContent tabindex="-1"></div>
    <router-outlet></router-outlet>
    <is-timeout-outlet *ngIf="!appWindow.frameElement"></is-timeout-outlet>

    <footer>
        <div>
            <is-footer *ngIf="showFooter && footerData" [footerData]="footerData" (linkClick)="linkClicked($event)"></is-footer>
        </div>
    </footer>
    <is-oauth-iframe style="display: none;"></is-oauth-iframe>

</div>
