import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
@Injectable()
export class SvgService {
    imageName: string;
    imagePath: string;
    imageList = {
        'business-auto': './assets/images/claimsSvgs/icon-business-auto.svg',
        'business-owners': './assets/images/claimsSvgs/icon-business-owners.svg',
        'commercial-umbrella': './assets/images/claimsSvgs/icon-farm-comm-umbrella.svg',
        'Farm Umbrella': './assets/images/claimsSvgs/icon-farm-comm-umbrella.svg',
        'Inland Marine': './assets/images/claimsSvgs/icon-inland-marine.svg',
        'Commercial Output': './assets/images/claimsSvgs/icon-commercial-output.svg',
        'Commercial Property': './assets/images/claimsSvgs/icon-commercial-property.svg',
        'Crime': './assets/images/claimsSvgs/icon-crime.svg',
        'Farm': './assets/images/claimsSvgs/icon-farm.svg',
        'General Liability': './assets/images/claimsSvgs/icon-general-liability.svg',
        'Workers Compensation': './assets/images/claimsSvgs/icon-workers-comp.svg',
        'glass-damage': './assets/images/claimsSvgs/icon-glass-damage.svg',
        'roadside-assistance': './assets/images/claimsSvgs/icon-roadside-asst.svg',
        'claim': './assets/images/claimsSvgs/icon-claim.svg',
        'representative': './assets/images/claimsSvgs/icon-claim-rep.svg',
        'incident-details': './assets/images/claimsSvgs/icon-incident-details.svg',
        'contacts': './assets/images/claimsSvgs/icon-claim-contacts.svg',
        'payments': './assets/images/claimsSvgs/icon-payments.svg'
    };
    constructor (
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) { }

    init(): any {
        for (const key in this.imageList) {
            if (this.imageList.hasOwnProperty(key)) {
                this.imageName = key;
                this.imagePath = this.imageList[key];
                this.matIconRegistry.addSvgIcon(this.imageName, this.domSanitizer.bypassSecurityTrustResourceUrl(this.imagePath));
            }
        }
    }
}
