<bolt-waiting-indicator class="bolt-space-inner-xl bolt-space-bottom-lg" *ngIf='isLoading'>Please wait while we process your request</bolt-waiting-indicator>
<div *ngIf='!isLoading'
    class="bolt-container">
    <div class="bolt-container bolt-space-inner-lg">
        <div class="bolt-table bolt-table-striped bolt-table-vibrant-blue">
            <h2 id="mydesc"
                class="bolt-heading-blue-medium">Latest build information</h2>
            <table aria-describedby="mydesc">
                <thead>
                    <tr>
                        <th id="head1blue1"
                            scope="col">Name</th>
                        <th id="head2blue1"
                            scope="col">Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <h4>Date</h4>
                        </td>
                        <td>{{build_date}}</td>
                    </tr>
                    <tr>
                        <td>
                            <h4>Branch</h4>
                        </td>
                        <td>{{branchName}}</td>
                    </tr>
                    <tr>
                        <td>
                            <h4>Router</h4>
                        </td>
                        <td>{{aws_bucket}}</td>
                    </tr>
                    <tr>
                        <td>
                            <h4>Environment</h4>
                        </td>
                        <td>{{bucket_Env}}</td>
                    </tr>
                    <tr>
                        <td>
                            <h4>Build type</h4>
                        </td>
                        <td>{{build_type}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
