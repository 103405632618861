<div class="nw-test-tool" tabindex="-1">
  <div class="nw-test-tool-form" *ngIf="nwToolBarIsDisplay">
    <form [formGroup]="environmentPickForm" style="display:inline-block">
      <label>Change environment:</label> &nbsp;
      <select formControlName="environment" aria-label="environment">
        <option value="dev">Dev</option>
        <option value="test">Test</option>
      </select>
      &nbsp;
      <select formControlName="serverNumber">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>

      </select>

      <button (click)='setCookie()' class="nw-tool-button"  aria-label="set environment">Set</button>
    </form>
        &nbsp;&nbsp;
    <form [formGroup]="awsenvironment" style="display:inline-block">
        <label>Set Router:</label> &nbsp;
        <select formControlName="awsenv"  aria-label="aws environment">
          <option value="a">montenegro-a</option>
          <option value="b">montenegro-b</option>
          <option value="c">montenegro-c</option>
          <option value="d">montenegro-d</option>
          <option value="e">montenegro-e</option>
          <option value="f">montenegro-f</option>
          <option value="g">montenegro-g</option>
          <option value="h">montenegro-h</option>
          <option value="i">montenegro-i</option>
          <option value="j">montenegro-j</option>
          <option value="k">montenegro-k</option>
          <option value="l">montenegro-l</option>
          <option value="fedex-a">fedex-a</option>
          <option value="fedex-b">fedex-b</option>
          <option value="fedex-c">fedex-c</option>
          <option value="fedex-d">fedex-d</option>
          <option value="fedex-e">fedex-e</option>
          <option value="fedex-f">fedex-f</option>
          <option value="fedex-g">fedex-g</option>
          <option value="fedex-h">fedex-h</option>
          <option value="fedex-j">fedex-j</option>
          <option value="fedex-k">fedex-k</option>
        </select>

        <button (click)='setRouter()' class="nw-tool-button"  aria-label="set aws">Set</button>
      </form>
      &nbsp;
      <form [formGroup]="fileClaimTest" style="display:inline-block">
        <select formControlName="users"  aria-label="users for file claim">
            <option *ngFor="let fc of fileClaimTestData" [value]='fc.user'>{{fc.user}}</option>
        </select>
        <button (click)='setFileClaimData()'  class="nw-tool-button">Go!</button>
        </form>
  </div>
  <div class="nw-test-tool-close">
    <button class="close-button" (click)="toggleToolBar()" aria-hidden="true" tabindex="-1">{{nwCloseCaption}}</button>
    <br />
    <span class="nw-tool-test-tip" *ngIf='!nwToolBarIsDisplay'>Testing Tool</span>
  </div>
</div>
