import { Injectable } from '@angular/core';
import { PersonalConstants } from '../../common/personalConstants';

@Injectable()
export class SearchDataService {
    accountNumber: string;
    zipCode: number;
    paymentDue: string;
    remainingBalance: string;
    paymentDueDate: string;
    paymentType: string;
    paymentNote: string;
    policyNumber: string;
    confirmationNum: string;
    isRegistered: boolean;

    errorMessage: string;
    errorType: string;

    constructor () {
        this.setSearchDataFromStorage();
    }

    setSearchDataFromStorage(): any {
        const accountDetails = window.sessionStorage.getItem('accountDetails');
        if (accountDetails) {
            const accountDetailsArray: string[] = accountDetails.split(',');
            this.accountNumber = accountDetailsArray[0];
            this.zipCode = Number.parseInt(accountDetailsArray[1], 10);
            this.paymentDue = accountDetailsArray[2];
            this.confirmationNum = accountDetailsArray[3];
        }
        const quickIdCardPolicyNumber = window.sessionStorage.getItem('quickIdCardPolicyNumber');
        if (quickIdCardPolicyNumber) {
            this.policyNumber = quickIdCardPolicyNumber;
        }
    }

    setSearchErrorDetails(accountErrorCode: string): void {
        if (PersonalConstants.searchPageErrors.hasOwnProperty(accountErrorCode) &&
            PersonalConstants.searchPageErrors[accountErrorCode].hasOwnProperty('message')) {
            this.errorMessage = PersonalConstants.searchPageErrors[accountErrorCode].message;
            this.errorType = PersonalConstants.searchPageErrors[accountErrorCode].type;
        }
    }

    removeSearchErrorDetails(): void {
        this.errorMessage = '';
        this.errorType = '';
    }
}
