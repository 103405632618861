export const HeaderFooterContent = {
    DISCLOSURECONTENT: 'Products underwritten by Nationwide Mutual Insurance Company and affiliates. Home Office: Columbus, OH. In Washington, Auto insurance is underwritten by Nationwide Mutual Insurance Company and Homeowner insurance is underwritten by National General Insurance Company. Life Insurance and Annuities are issued by Nationwide Life Insurance Company and/or Nationwide Life and Annuity Insurance Company, Columbus, Ohio. The general distributor for variable insurance products is Nationwide Investment Services Corporation, member <a href="https://www.finra.org">FINRA</a>. Subject to underwriting guidelines, review and approval. Products and discounts not available to all persons in all states. © 2023. Nationwide Mutual Insurance Company.',
    EFNOL_DISCLOSURECONTENT: 'Products underwritten by Nationwide Mutual Insurance Company and Affiliated Companies. Not all Nationwide affiliated companies are mutual companies, and not all Nationwide members are insured by a mutual company. Subject to underwriting guidelines, review and approval. Products and discounts not available to all persons in all states. Nationwide Investment Services Corporation, member <a href="https://www.finra.org/">FINRA</a>. Home Office: One Nationwide Plaza, Columbus, OH. Nationwide, the Nationwide N and Eagle and other marks displayed on this page are service marks of Nationwide Mutual Insurance Company, unless otherwise disclosed. &copy;2021. Nationwide Mutual Insurance Company.',
    DONOTSELLMYPERSONALINFORMATION: '<span id="teconsent"></span>',
    CLAIMNUMBERPHONENUMBERTEXT: '1-800-421-3535',
    FACEBOOK: {
        URL: 'https://www.facebook.com/nationwide',
        SOURCE: 'assets/images/tridion/static/soc_facebook.svg',
        ALTTEXT: 'Facebook Chiclet Icon'
    },
    INSTAGRAM: {
        URL: 'https://www.instagram.com/nationwide/',
        SOURCE: 'assets/images/tridion/static/soc_instagram.svg',
        ALTTEXT: 'Instagram Chiclet Icon'
    },
    LINKEDIN: {
        URL: 'https://www.linkedin.com/company/2340',
        SOURCE: 'assets/images/tridion/static/soc_linkedin.svg',
        ALTTEXT: 'Linkedin Chiclet Icon'
    },
    YOUTUBE: {
        URL: 'https://www.youtube.com/nationwide',
        SOURCE: 'assets/images/tridion/static/soc_youtube.svg',
        ALTTEXT: 'Youtube Chiclet Icon'
    },
    TWITTER: {
        URL: 'https://twitter.com/nationwide',
        SOURCE: 'assets/images/tridion/static/soc_twitter.svg',
        ALTTEXT: 'Twitter Chiclet Icon'
    },
    NATIONWIDE: {
        TITLE: 'Nationwide',
        SOURCE: 'assets/images/tridion/static/bolt-logo-nw-vertical-white.svg',
        ALTTEXT: 'Nationwide'
    },
    NATIONWIDEHEADERLOGO: {
        TITLE: 'Nationwide Logo',
        SOURCE: 'assets/images/tridion/static/bolt-logo-nw-horizontal-full.svg',
        ALTTEXT: 'Nationwide Logo'
    },
    EQUALHOUSING: {
        URL: 'https://portal.hud.gov/hudportal/HUD?src=/program_offices/fair_housing_equal_opp',
        TITLE: 'Equal Hosing Opportunity Insurer',
        SOURCE: 'assets/images/tridion/static/equal-housing.svg',
        ALTTEXT: 'Equal Hosing Opportunity Insurer'
    },
    TRUSTE: {
        URL: 'https://privacy.truste.com/privacy-seal/Nationwide-Mutual-Insurance-Company/validation?rid=d4973755-5da9-4f06-bf72-f0fe1644c5af',
        TITLE: 'TRUSTe',
        SOURCE: 'assets/images/tridion/static/truste.svg',
        ALTTEXT: 'TRUSTe'
    }
};
