import { Injectable } from '@angular/core';
import { PersonalConstants } from 'app/common/personalConstants';
import { environment } from 'environments/environment';
import { ClaimsHelperService } from './claims-helper.service';
import { EnvironmentpickerService } from './environmentpicker.service';
import { HttpService } from './http.service';
import { HttpHeaderService } from './httpheader.service';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';

@Injectable()
export class PaymentService {
    constructor (
        private httpService: HttpService,
        private logger: LoggerService,
        private claimsHelperService: ClaimsHelperService,
        private httpHeaderService: HttpHeaderService,
        private sessionService: SessionService,
        private environmentPicker: EnvironmentpickerService
    ) { }

    updateBankAccountDetails(bankDetailsObj): Promise<any> {
        const sessionlogparams = this.sessionService.getSessionLogParams();
        const request = {
            uri: `claims/${bankDetailsObj.claimNumber}/claim-contacts/${bankDetailsObj.contactID}/bank-accounts`,
            type: '',
            requestPayload: bankDetailsObj,
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.claimsContacts,
            GUID: '',
            headers: this.httpHeaderService.getNWClaimServiceHeaders(environment.CLAIMS_DEV)
        };

        return new Promise(
            (resolve: (value: Response) => void, reject) => {
                this.logger.info('ClaimsPaymentService - Update Bank Account Details request from CC',
                    { request: request }, sessionlogparams);
                this.httpService.invokeHttpPost(request, true).subscribe(
                    (response) => {
                        this.logger.info('ClaimsPaymentService - Update Bank Account Details success response from CC',
                            { response: response }, sessionlogparams);
                        if (response.status === 200) {
                            resolve(response.body);
                        } else {
                            reject(response.body);
                        }
                    },
                    (error: any) => {
                        this.logger.error('FnolService - Create Claim Error Response', { error: error }, sessionlogparams);
                        reject(error);
                    }
                );
            });
    }

    saveReftAccountDetails(claimDetails): Promise<any> {
        const sessionlogparams = this.sessionService.getSessionLogParams();
        const request = {
            uri: `claims/${claimDetails.claimNumber}/claim-contacts/${claimDetails.contactID}/bank-accounts`,
            type: '',
            requestPayload: claimDetails,
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.claimsContacts,
            GUID: '',
            headers: this.httpHeaderService.getNWClaimServiceHeaders(environment.CLAIMS_DEV)
        };

        return new Promise(
            (resolve: (value: Response) => void, reject) => {
                this.logger.info('ClaimsPaymentService - Save REFT Account Details to CC request',
                    { request: request }, sessionlogparams);
                this.httpService.invokeHttpPost(request, true).subscribe(
                    (response) => {
                        if (response.status === 200) {
                            this.logger.info('ClaimsPaymentService - Save REFT Account Details to CC success response',
                                { response: response }, sessionlogparams);
                            resolve(response.body);
                        } else {
                            this.logger.info('ClaimsPaymentService - Save REFT Account Details to CC non-200 response',
                                { response: response }, sessionlogparams);
                            reject(response.body);
                        }
                    },
                    (error: any) => {
                        this.logger.error('ClaimsPaymentService - Save REFT Account Details to CC Error Response',
                            { error: error }, sessionlogparams);
                        reject(error);
                    }
                );
            });
    }

    getAgreementFinancialInfo(policyNumber: string): any {
        const sessionlogparams = this.sessionService.getSessionLogParams();
        const headers = environment.CLAIMS_DEV ?
            this.httpHeaderService.getBillingServiceHeaders()
                .set(PersonalConstants.headerProps.CONNECTIONTYPE, PersonalConstants.headerProps.AFI_CONNECTIONTYPE) :
            this.httpHeaderService.getBillingServiceHeaders();
        const request = {
            uri: `/personal-lines-accounts/${policyNumber}/agreement-financial-info?agreementFinancialInfo=REFUNDMETHOD`,
            type: '',
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.billingMoneyProcessing,
            GUID: '',
            headers: headers
        };
        return new Promise(
            (resolve: (value: Response) => void, reject: (reason: any) => void): void => {
                this.logger.info('ClaimsPaymentService - get agreement financial info request', { request: request }, sessionlogparams);
                this.httpService.invokeHttpGet(request).subscribe(
                    (response: any) => {
                        this.logger.info('ClaimsPaymentService - get agreement financial info success response ', { response: response },
                            sessionlogparams);
                        resolve(response);
                    },
                    (error: any) => {
                        this.logger.error('ClaimsPaymentService - Error while getting agreement financial info', { error: error },
                            sessionlogparams);
                        reject(error);
                    }
                );
            }
        );
    }

    getBankAccountDetails(fnolClaimNumber: string, contactPublicId: string): Promise<any> {
        const sessionlogparams = this.sessionService.getSessionLogParams();
        const headers = this.httpHeaderService.getNWClaimServiceHeaders(environment.CLAIMS_DEV);
        const request = {
            uri: `claims/${fnolClaimNumber}/claim-contacts/${contactPublicId}/bank-accounts`,
            type: '',
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.claimsContacts,
            GUID: '',
            headers: headers
        };

        return new Promise(
            (resolve: (value: Response) => void, reject: (reason: any) => void): void => {
                this.logger.info('ClaimsPaymentService - get Bank Account Details request', { request: request }, sessionlogparams);
                this.httpService.invokeHttpGet(request).subscribe(
                    (response) => {
                        this.logger.info('ClaimsPaymentService - get Bank Account Details success response ', { response: response },
                            sessionlogparams);
                        resolve(response);
                    },
                    (error: any) => {
                        this.logger.error('ClaimsPaymentService - Error while getting Bank Account Details', { error: error },
                            sessionlogparams);
                        reject(error);
                    }
                );
            }
        );
    }

    getBankDetails(routingNumber: string): Promise<any> {
        const sessionlogparams = this.sessionService.getSessionLogParams();
        const request = {
            uri: `/banks/${routingNumber}`,
            type: '',
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.billingMoneyProcessing,
            GUID: '',
            headers: this.httpHeaderService.getBillingServiceHeaders()
        };

        return new Promise((resolve, reject) => {
            this.logger.info('CLaimsPaymentService - Retrieve Bank Details  request', { request: request }, sessionlogparams);
            this.httpService.invokeHttpGet(request).subscribe(
                (response) => {
                    this.logger.info('CLaimsPaymentService - Retrieve Bank Details success response',
                        { response: response }, sessionlogparams);
                    resolve(response);
                },
                (error: any) => {
                    this.logger.error('CLaimsPaymentService -  Retrieve Bank Details error response:',
                        { error: error }, sessionlogparams);
                    reject(error);
                }
            );
        });
    }

    getClaimId(): any {
        return this.getSessionObjectValue('Claim-Number-Stored-In-Session') ||
            this.getSessionObjectValue('claimNumber');
    }

    getSessionObjectValue(sessionObjName): string {
        let sessionObjValue;
        if (sessionStorage.getItem(sessionObjName)) {
            sessionObjValue = sessionStorage.getItem(sessionObjName);
        }
        return sessionObjValue;
    }

    setClaimDetails(claimsDetails): any {
        if (claimsDetails.policy) {
            sessionStorage.setItem('nw-policyNumber', claimsDetails.policy.policyNumber);
            sessionStorage.setItem('Last-Queried-Policy-Number', claimsDetails.policy.policyNumber);
            let policyType = claimsDetails.policy.policyType;
            if (policyType.indexOf('auto') > -1) {
                policyType = 'Auto';
            } else if (policyType.indexOf('prop') > -1) {
                policyType = 'Homeowners';
            }
            sessionStorage.setItem('policy-type', policyType);
            // policyType is returned as 'Homeowners' for most property policies. few are returned as 'prop_dwlfire'.
        }
        sessionStorage.setItem('claimState', claimsDetails.claimState);
        sessionStorage.setItem('Claim-Adjuster', JSON.stringify(claimsDetails.adjuster));
        if (claimsDetails.claimContacts !== undefined) {
            const claimContact = this.claimsHelperService.getInsuredContact(claimsDetails.claimContacts);
            if (claimContact !== undefined) {
                sessionStorage.setItem('claimsContactPublicId', claimContact.contactDTO.publicID);
                sessionStorage.setItem('firstName', claimContact.contactDTO.firstName);
                sessionStorage.setItem('lastName', claimContact.contactDTO.lastName);
            }
        }
    }
}
