import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PersonalConstants } from 'app/common/personalConstants';
import { HttpRequest } from 'app/models/http-request';
import { TypeKey } from 'app/models/location/type-key';
import { environment } from 'environments/environment';
import * as removeAccents from 'remove-accents';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationMatch, LocationValidationResponse } from '../../models/location/location-validation-response';
import { LocationValidationResult } from '../../models/location/location-validation-result';
import { EnvironmentpickerService } from './environmentpicker.service';
import { HttpService } from './http.service';
import { HttpHeaderService } from './httpheader.service';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';
import { UtilService } from './util.service';

declare let parseAddress;

@Injectable()
export class LocationService {
    static readonly SOURCE_COUNTRY_PARAM = 'sourceCountry';
    static readonly SOURCE_COUNTRY_LIST = 'USA,PRI,MEX';
    static readonly PUERTO_RICO_COUNTRY_CODE = 'PR';
    static readonly PUERTO_RICO_STATE_CODE = 'OP';
    static readonly STATE_KEY_FILE = 'assets/data/state-typekeys.json';
    static readonly COUNTRY_KEY_FILE = 'assets/data/country-typekeys.json';
    static readonly DEFAULT_COUNTRY_KEY = 'OT';

    constructor (
        private httpService: HttpService,
        private http: HttpClient,
        private httpHeaderService: HttpHeaderService,
        private logger: LoggerService,
        private utils: UtilService,
        private sessionService: SessionService,
        private environmentPicker: EnvironmentpickerService
    ) { }

    async validateAddress(address: string): Promise<LocationValidationResult> {
        const response = await this.getValidateAddressResponse(address);
        const result: LocationValidationResult = { isValid: false, location: null };
        const decodedAddress = decodeURIComponent(address);
        const addressArray = parseAddress.parseLocation(decodedAddress);
        if (response.candidates) {
            const locationMatches: Array<LocationMatch> = (<LocationValidationResponse>response).candidates.filter((locationMatch) =>
                locationMatch.score === 100);
            if (locationMatches.length >= 1) {
                result.isValid = true;
                result.location = locationMatches[0];
                return this.getLocationMatches(result).then((attributeResponse) => attributeResponse);
            } else if ((this.utils.isValidPOBoxAddress(decodedAddress) || locationMatches.length === 0) &&
                (!!addressArray.state || !!addressArray.city)) {
                const addressState = addressArray.state.toUpperCase();
                const addressLine1 = decodedAddress.split(',');
                const region = PersonalConstants.states.find((state) => state.value === addressState);
                const locationResult: any = {
                    location: {
                        address: decodedAddress,
                        attributes: {
                            Region: region.name,
                            CountryCode: 'USA',
                            City: addressArray.city,
                            State: addressArray.state,
                            Postal: addressArray.zip,
                            StAddr: addressLine1 ? addressLine1[0] : '',
                            SubRegion: ''
                        }
                    }
                };
                locationResult.isValid = true;
                return this.getLocationMatches(locationResult).then((attributeResponse) => attributeResponse).catch((error) => error);
            }
        } else {
            return result;
        }
    }

    getLocationMatches(result: any): Promise<any> {
        return Promise.all([
            this.getCodeFromDisplayName(result.location.attributes.Region, LocationService.STATE_KEY_FILE),
            this.getCodeFromDisplayName(result.location.attributes.CountryCode, LocationService.COUNTRY_KEY_FILE)
        ]).then(([stateCode, countryCode]) => {
            result.location.attributes.Region = stateCode || this.getPRIStateCode(countryCode);
            result.location.attributes.CountryCode = countryCode || LocationService.DEFAULT_COUNTRY_KEY;
            this.cleanseLocationValidationResults(result);
            if (result.location.attributes.CountryCode.indexOf('US') >= 0) {
                return this.validateAddressForHexId(result);
            } else {
                return result;
            }
        });
    }

    private validateAddressForHexId(locationValidationObj: LocationValidationResult): Promise<LocationValidationResult> {
        // TODO: implement session params
        const sessionlogparams = this.sessionService.getSessionLogParams();
        const body = {
            addressLine1: locationValidationObj.location.attributes.StAddr,
            city: locationValidationObj.location.attributes.City,
            state: locationValidationObj.location.attributes.Region,
            country: 'US',
            postalCode: locationValidationObj.location.attributes.Postal
        };

        const request = {
            uri: 'address/validate',
            type: '',
            requestPayload: body,
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.oldClaimsBaseAPI,
            GUID: '',
            headers: this.httpHeaderService.getClaimServiceHeaders(environment.CLAIMS_DEV)
        };
        return new Promise((resolve, reject) => {
            this.logger.info('Location Service - validateAddressForHexId request', { request: request }, sessionlogparams);
            this.httpService.invokeHttpPost(request).subscribe(
                (response) => {
                    locationValidationObj.location.attributes.HexId = response.hexID;
                    if (!locationValidationObj.location.attributes.hasOwnProperty('SubRegion') ||
                        !locationValidationObj.location.attributes.SubRegion) {
                        locationValidationObj.location.attributes.SubRegion = response.county ? response.county : 'unknown';
                    }
                    console.log('validateAddressForHexId success response:', response);
                    this.logger.debug('Location Service - validateAddressForHexId response', { response: response }, sessionlogparams);
                    if (this.utils.isEmpty(locationValidationObj.location.attributes.Postal)) {
                        if (response.postalCode.indexOf('-') > -1) {
                            const postalCode = response.postalCode.split('-');
                            locationValidationObj.location.attributes.Postal = postalCode[0];
                        } else {
                            locationValidationObj.location.attributes.Postal = response.postalCode;
                        }
                    }
                    locationValidationObj.location.location = {
                        x: response.longitude,
                        y: response.latitude
                    };
                    resolve(locationValidationObj);
                },
                (error: any) => {
                    console.log('validateAddressForHexId error response:', error);
                    this.logger.error('Location Service - validateAddressForHexId error response:', { error: error }, sessionlogparams);
                    locationValidationObj.isValid = false;
                    locationValidationObj.location = null;
                    reject(error);
                }
            );
        });
    }

    getCodeFromDisplayName(displayName: string, fileLocation: string): Promise<string> {
        const sessionlogparams = this.sessionService.getSessionLogParams();
        this.logger.info('Location Service - getCode from Display Name request', null, sessionlogparams);
        return new Promise((resolve, reject) => {
            this.http.get(fileLocation).subscribe(
                (typeKeys) => {
                    this.logger.info('Location Service - getCode from Display Name response', { response: typeKeys }, sessionlogparams);
                    const typeKeysArray: TypeKey[] = <TypeKey[]>typeKeys;
                    const results: TypeKey[] = typeKeysArray.filter((typeKey) => typeKey.displayName === displayName);
                    resolve(results.length === 1 ? results[0].code : '');
                },
                (error) => {
                    this.logger.error('Location Service - getCode from Display Name error', { error: error }, sessionlogparams);
                    reject(error);
                }
            );
        });
    }

    private cleanseLocationValidationResults(result: LocationValidationResult): void {
        result.location.attributes.City = removeAccents(result.location.attributes.City);
        result.location.attributes.StAddr = removeAccents(result.location.attributes.StAddr);
        result.location.attributes.SubRegion = removeAccents(result.location.attributes.SubRegion);
        result.location.attributes.Postal = removeAccents(result.location.attributes.Postal || '');
    }

    getPRIStateCode(countryCode: string): string {
        if (countryCode === LocationService.PUERTO_RICO_COUNTRY_CODE) {
            return LocationService.PUERTO_RICO_STATE_CODE;
        }
        return '';
    }

    getValidateAddressResponse(address: string): Promise<LocationValidationResponse | any> {
        // TODO: implement session params
        const sessionlogparams = this.sessionService.getSessionLogParams();
        const validateRequest: HttpRequest = {
            uri: `${PersonalConstants.Application_Uri.FIND_ADDRESS + address
                }${LocationService.SOURCE_COUNTRY_PARAM}=${LocationService.SOURCE_COUNTRY_LIST}`,
            type: PersonalConstants.Application_Uri.LOCATION,
            GUID: '',
            baseUrl: environment.API_ENVIRONMENT,
            headers: this.httpHeaderService.getAPILocationServiceHeaders()
        };

        return new Promise((resolve, reject) => {
            this.logger.info('Location Service - Validate Address Request', { request: validateRequest }, sessionlogparams);
            this.httpService.invokeHttpGet(validateRequest).subscribe(
                (response) => {
                    this.logger.info('Location Service - Validate Address Response', { response: response }, sessionlogparams);
                    resolve(response);
                },
                (error: any) => {
                    this.logger.error('Location Service - Validate Address Error', { error: error }, sessionlogparams);
                    reject(error);
                }
            );
        });
    }

    getSuggestions(address: any, country: string, state?: string): Observable<any> {
        if (country === 'USA') {
            let uri = `${PersonalConstants.Application_Uri.SUGGESTIONS}${address}`;
            uri += `&${LocationService.SOURCE_COUNTRY_PARAM}=USA`;

            if (state) {
                uri += `&state=${state}`;
            }
            const validateRequest: HttpRequest = {
                uri: uri,
                type: PersonalConstants.Application_Uri.LOCATION,
                GUID: '',
                baseUrl: environment.API_ENVIRONMENT,
                headers: this.httpHeaderService.getAPILocationServiceHeaders()
            };
            this.logger.info('Suggestions service is being called');
            return this.httpService.invokeHttpGet(validateRequest).pipe(
                map((response: any) => response));
        } else {
            return of([]);
        }
    }

    getTypeAHeadSuggestions(address: any): Observable<any> {
        const uri = `${PersonalConstants.Application_Uri.SUGGESTIONS}${address}`;

        const validateRequest: HttpRequest = {
            uri: uri,
            type: PersonalConstants.Application_Uri.LOCATION,
            GUID: '',
            baseUrl: environment.API_ENVIRONMENT,
            headers: this.httpHeaderService.getAPILocationServiceHeaders()
        };
        this.logger.info('TypeAHeadSuggestions service is being called', JSON.stringify(validateRequest));
        return this.httpService.invokeHttpGet(validateRequest).pipe(
            map((response: any) => {
                this.logger.info('TypeAHeadSuggestions service response', JSON.stringify(response));
                return response;
            }));
    }
}
