<header>
    <div class="bolt-container mobiles-only">
        <div class="header-sec">
            <span class="claims-btn">
                <a class="claims-link"
                    (click)="goToClaim('claim')">Claims</a>
            </span>
            <bolt-logo size="sm"
                type="nw-mark"
                href="{{logoUrl}}"></bolt-logo>
            <span class="help-icon">
                <a onclick="window.helpModal.openModal()">Get help</a>
            </span>
        </div>
    </div>

    <div class="desktops-only">
        <div class="header-sec">
            <bolt-logo href="{{logoUrl}}"></bolt-logo>
            <div>
                <bolt-button size="lg" class="claims-button"
                    type="primary"
                    (click)="goToClaim('claim')">Claims</bolt-button>
                <bolt-button size="lg" class="get-help-button"
                    onclick="window.helpModal.openModal()">Get help</bolt-button>
            </div>
        </div>
    </div>

    <bolt-modal id="helpModal"
        heading="Get help">
        <a href="{{environment.NATIONWIDE.nwFaq}}"
            target="_blank" rel="noopener">Frequently Asked Questions -
            Nationwide<br></a>
        <p>Claims call center <a class="phone-number"
                href="tel:1-800-421-3535">1-800-421-3535</a><br></p>
        <a href="{{environment.NATIONWIDE.agency}}"
            target="_blank" rel="noopener">Find an agent<br></a>
    </bolt-modal>
</header>
<section aria-label="Claim steps">
    <is-header-progress-bar></is-header-progress-bar>
</section>
<div id="cancelButtonModal">
    <is-cancel-button-popup></is-cancel-button-popup>
</div>
