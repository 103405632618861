import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from './session.service';

@Injectable()
export class HttpService {
    constructor (
        private http: HttpClient,
        private httpBackend: HttpBackend,
        private _session: SessionService
    ) { }

    invokeHttpPost(object: any, responseStatus = false, isSkipInterceptor?: boolean): any {
        object.serviceUrl = object.baseUrl + object.uri;
        if (responseStatus) {
            if (isSkipInterceptor === true) {
                const newHttpClient = new HttpClient(this.httpBackend);
                return newHttpClient.post(object.serviceUrl,
                    object.requestPayload, {
                    headers: object.headers,
                    responseType: object.responseType || 'json', observe: 'response'
                }).pipe(
                    catchError(errorHandler));
            } else {
                return this.http.post(object.serviceUrl,
                    object.requestPayload, {
                    headers: object.headers,
                    responseType: object.responseType || 'json', observe: 'response'
                }).pipe(
                    catchError(errorHandler));
            }
        }
        if (isSkipInterceptor === true) {
            const httpClient = new HttpClient(this.httpBackend);
            return httpClient.post(object.serviceUrl,
                object.requestPayload, { headers: object.headers, responseType: object.responseType || 'json' }).pipe(
                    catchError(errorHandler));
        } else {
            return this.http.post(object.serviceUrl,
                object.requestPayload, { headers: object.headers, responseType: object.responseType || 'json' }).pipe(
                    catchError(errorHandler));
        }
    }

    invokeHttpPatch(object: any, responseStatus = false): any {
        object.serviceUrl = object.baseUrl + object.uri;
        if (responseStatus) {
            return this.http.patch(object.serviceUrl,
                object.requestPayload, { headers: object.headers, responseType: object.responseType || 'json', observe: 'response' }).pipe(
                    catchError(errorHandler));
        }
        return this.http.patch(object.serviceUrl,
            object.requestPayload, { headers: object.headers, responseType: object.responseType || 'json' }).pipe(
                catchError(errorHandler));
    }
    invokeHttpPut(object: any): any {
        object.serviceUrl = object.baseUrl + object.uri;
        return this.http.put(object.serviceUrl,
            object.requestPayload, { headers: object.headers, responseType: object.responseType || 'json' }).pipe(
                catchError(errorHandler));
    }

    invokeHttpGet(object: any): any {
        object.serviceUrl = object.baseUrl + object.type + object.GUID + object.uri;
        return this.http.get(object.serviceUrl, { headers: object.headers, responseType: object.responseType || 'json' }).pipe(
            catchError(errorHandler));
    }

    invokeHttpDelete(object: any): any {
        object.serviceUrl = object.baseUrl + object.type + object.GUID + object.uri;
        return this.http.delete(object.serviceUrl, { headers: object.headers, responseType: object.responseType || 'json' }).pipe(
            catchError(errorHandler));
    }
}

export function errorHandler(errorResponse: HttpErrorResponse): Observable<any> {
    // pretty much everywhere in the app expects the JSON object returned by the server
    // client side code error
    if (errorResponse.error instanceof Error) {
        return observableThrowError(errorResponse);
    } else {
        // error response from server we convert the response to json
        let json = errorResponse.error;
        try {
            json = JSON.parse(errorResponse.error);
        } catch (e) { }
        return observableThrowError(json);
    }
}
