import { Injectable } from '@angular/core';
import { PersonalConstants } from 'app/common/personalConstants';
import { environment } from 'environments/environment';
import { EnvironmentpickerService } from './environmentpicker.service';
import { HttpService } from './http.service';
import { HttpHeaderService } from './httpheader.service';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';
import { UtilService } from './util.service';
@Injectable()
export class FnolService {
    constructor (
        private httpService: HttpService,
        private logger: LoggerService,
        private httpHeaderService: HttpHeaderService,
        private utilService: UtilService,
        private sessionService: SessionService,
        private environmentPicker: EnvironmentpickerService
    ) { }

    createFNOL(fnolObj): Promise<any> {
        // TODO: implement session params
        const sessionlogparams = this.sessionService.getSessionLogParams();
        const body = {
            'policyNumber': fnolObj.policyNumber,
            'lossType': fnolObj.lossType,
            'lossDate': fnolObj.date
        };
        const request = {
            uri: PersonalConstants.Application_Uri.CREATE_FNOL,
            type: '',
            requestPayload: body,
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.oldClaimsBaseAPI,
            GUID: '',
            headers: this.httpHeaderService.getClaimServiceHeaders(environment.CLAIMS_DEV)
        };

        return new Promise(
            (resolve: (value: Response) => void, reject) => {
                this.logger.info('FnolService - Create Claim FNOL request to CC', { request: request }, sessionlogparams);
                this.httpService.invokeHttpPost(request).subscribe(
                    (response) => {
                        this.logger.info('FnolService - Create Claim FNOL success response from CC',
                            { response: response }, sessionlogparams);
                        const responseData = this.checkForStub(response);
                        resolve(responseData);
                    },
                    (error: any) => {
                        this.logger.error('FnolService - Create Claim Error Response', { error: error }, sessionlogparams);
                        reject(error);
                    }
                );
            });
    }

    updateFnol(fnolData, fnolClaimNumber): Promise<any> {
        const sessionlogparams = this.sessionService.getSessionLogParams();
        fnolData = this.utilService.rebuildClaimsNotes(fnolData);
        const request = {
            uri: `notices-of-loss/${fnolClaimNumber}`,
            type: '',
            requestPayload: fnolData,
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.oldClaimsBaseAPI,
            GUID: '',
            headers: this.httpHeaderService.getClaimServiceHeaders(environment.CLAIMS_DEV)
        };
        return new Promise(
            (resolve: (value: Response) => void, reject) => {
                this.logger.info('FnolService - Save/update Claim FNOL request to CC', { request: request }, sessionlogparams);
                this.httpService.invokeHttpPut(request).subscribe(
                    (response) => {
                        this.logger.info('FnolService - Save/update Claim FNOL response from CC', { response: response },
                            sessionlogparams);
                        resolve(response);
                    },
                    (error: any) => {
                        this.logger.error('FnolService - Save/update Claim Error response', { error: error },
                            sessionlogparams);
                        reject(error);
                    }
                );
            });
    }

    deleteDraftClaim(fnolData, fnolClaimNumber): Promise<any> {
        const sessionlogparams = this.sessionService.getSessionLogParams();
        const request = {
            uri: `notices-of-loss/${fnolClaimNumber}`,
            type: '',
            requestPayload: fnolData,
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.oldClaimsBaseAPI,
            GUID: '',
            headers: this.httpHeaderService.getClaimServiceHeaders(environment.CLAIMS_DEV)
        };
        return new Promise(
            (resolve: (value: Response) => void, reject) => {
                this.logger.info('FnolService - delete draft Claim FNOL request to CC', { request: request }, sessionlogparams);
                this.httpService.invokeHttpDelete(request).subscribe(
                    (response) => {
                        this.logger.info('FnolService - delete draft Claim FNOL response from CC', { response: response },
                            sessionlogparams);
                        resolve(response);
                    },
                    (error: any) => {
                        this.logger.error('FnolService - error while deleting draft claim from CC', { error: error },
                            sessionlogparams);
                        reject(error);
                    }
                );
            });
    }

    getUpdatedClaimFNOL(fnolClaimNumber: string, fnolPolicyNumber: string): Promise<any> {
        const sessionlogparams = this.sessionService.getSessionLogParams();
        const request = {
            uri: `notices-of-loss/${fnolClaimNumber}?policy_number=${encodeURI(fnolPolicyNumber)}`,
            type: '',
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.oldClaimsBaseAPI,
            GUID: '',
            headers: this.httpHeaderService.getClaimServiceHeaders(environment.CLAIMS_DEV)
        };

        return new Promise(
            (resolve: (value: Response) => void, reject: (reason: any) => void): void => {
                this.logger.info('FnolService - get updated Claim FNOL request to CC', { request: request }, sessionlogparams);
                this.httpService.invokeHttpGet(request).subscribe(
                    (response) => {
                        this.logger.info('FnolService - get updated Claim FNOL response from CC', { response: response },
                            sessionlogparams);
                        resolve(response);
                    },
                    (error: any) => {
                        this.logger.error('FnolService - Error while getting updated Claim FNOL from CC', { error: error },
                            sessionlogparams);
                        reject(error);
                    }
                );
            }
        );
    }

    submitClaim(fnolClaimNumber: string, fnolPolicyNumber: string): Promise<any> {
        const sessionlogparams = this.sessionService.getSessionLogParams();
        const encodedPolicyNumber = encodeURI(fnolPolicyNumber);
        const submitClaimRequest = {
            fnolHref: `${this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.oldClaimsBaseAPI
                }notices-of-loss/${fnolClaimNumber}?policy_number=${encodedPolicyNumber}`
        };
        const ctx = this;
        const tempObject = {
            uri: '',
            type: 'claims',
            GUID: '',
            baseUrl: `${this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.oldClaimsBaseAPI}claims`,
            requestPayload: submitClaimRequest,
            headers: this.httpHeaderService.getClaimServiceHeaders(environment.CLAIMS_DEV)
        };
        this.logger.info('FNOL Service - submitClaim request to CC', { request: tempObject }, sessionlogparams);
        return new Promise(
            (resolve, reject) => {
                ctx.httpService.invokeHttpPost(tempObject).subscribe(
                    (response) => {
                        if (response.claimNumber) {
                            sessionlogparams.draftClaim = sessionlogparams.claimNumber;
                            sessionlogparams.claimNumber = response.claimNumber;
                            this.sessionService.setSessionLogParams(sessionlogparams);
                        }
                        this.logger.info('FNOL Service - submitClaim response from CC',
                            { response: response }, sessionlogparams);
                        resolve(response);
                    },
                    (error: any) => {
                        this.logger.error('FNOL Service - submitClaim error response from CC',
                            { error: error }, sessionlogparams);
                        reject(error);
                    }
                );
            }
        );
    }

    getMetaData(name: string): Promise<any> {
        // TODO: implement session params
        const sessionlogparams = {
            claimNumber: 'NA',
            policyNumber: 'NA',
            uniqueId: 'NA',
            lossType: 'NA'
        };
        const request = {
            uri: `notices-of-loss/metadata/typelist?name=typekey.${name}`,
            type: '',
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.oldClaimsBaseAPI,
            GUID: '',
            headers: this.httpHeaderService.getClaimServiceHeaders(environment.CLAIMS_DEV)
        };

        return new Promise((resolve, reject) => {
            this.logger.info('FnolService - metadata request', { request: request }, sessionlogparams);
            this.httpService.invokeHttpGet(request).subscribe(
                (response) => {
                    this.logger.info('FnolService - metadata response', { response: response }, sessionlogparams);
                    resolve(response);
                },
                (error: any) => {
                    this.logger.error('FnolService - metadata error response:', { error: error }, sessionlogparams);
                    reject(error);
                }
            );
        });
    }

    checkForStub(response): any {
        if (!!environment.useStubForFnol && environment.useStubForFnol) {
            response.fnol.claimContacts.forEach((element, index) => {
                response.fnol.claimContacts[index].contactDTO.homeNumber = '***-***-1234';
                response.fnol.claimContacts[index].contactDTO.workNumber = '***-***-2234 x123';
                response.fnol.claimContacts[index].contactDTO.cellNumber = '***-***-3234';
                response.fnol.claimContacts[index].contactDTO.emailAddress1 = 'fak*****@notreal.org';
            });
        }
        return response;
    }

    checkForStubOnlyInsured(response): any {
        if (!!environment.useStubForFnol && environment.useStubForFnol) {
            response.fnol.claimContacts.forEach((element, index) => {
                if (element.contactRoles.indexOf('insured') > -1) {
                    if (!!!response.fnol.claimContacts[index].contactDTO.homeNumber &&
                        !!!response.fnol.claimContacts[index].contactDTO.workNumber &&
                        !!!response.fnol.claimContacts[index].contactDTO.cellNumber &&
                        !!!response.fnol.claimContacts[index].contactDTO.emailAddress1) {
                        response.fnol.claimContacts[index].contactDTO.homeNumber = '***-***-1234';
                        response.fnol.claimContacts[index].contactDTO.workNumber = '***-***-2234';
                        response.fnol.claimContacts[index].contactDTO.cellNumber = '***-***-3234';
                        response.fnol.claimContacts[index].contactDTO.emailAddress1 = 'fak*****@notreal.org';
                    }
                }
            });
        }
        return response;
    }
}
