import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimsOidcAuthService } from 'app/efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { environment } from 'environments/environment';
import { SessionService } from './session.service';

@Injectable()
export class SaveExitService {
    constructor (public router: Router,
        private authService: ClaimsOidcAuthService,
        private sessionService: SessionService
    ) { }

    navigate(userType): void {
        this.sessionService.blockBrowserBack(false);
        if (userType === 'registered') {
            this.router.navigate(['/fileclaim/info/claim-list-page']);
        } else {
            this.navigateAway();
        }
    }
    navigateAway(): void {
        this.authService.logOffUnRegisteredUser();
        window.open(environment.NATIONWIDE.AutoClaimsLandingPage, '_self');
    }
}
