import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PersonalConstants } from 'app/common/personalConstants';
import { SessionService } from './session.service';
import { UtilService } from './util.service';

interface PropertyWaterDamageDTOFields {
    publicID?: string;
    waterSource?: string;
    hasWaterBeenTurnedOff?: boolean;
    isRoofProtected?: boolean;
    otherDescription?: string;
}

@Injectable()
export class ClaimsMapperService {
    naturalCausesItems: string[] = ['weatherHail', 'weatherFlood', 'weatherAllOther', 'earthmovement', 'hailDamage',
        'windDamage', 'waterDamage', 'waterWeatherDamage', 'iceDamage', 'freezing', 'lightning', 'earthquake',
        'hail', 'wind', 'nw_waternonweather', 'nw_water', 'earthquake', 'nw_freezing', 'nw_allother'];
    localNotes: string;
    incidentOwnerDetails: any = {};
    memberPropertyDetails: string[] = [
        'dwlInvolvedInd',
        'uninhabitableInd',
        'otherStrInvolvedInd',
        'contentInvolvedInd',
        'uninhabitableInd',
        'iDTheftInd',
        'vehicleOrBoatInd',
        'spoiledFood',
        'otherInd',
        'roofDamaged',
        'gutterDamaged',
        'windowsDoorsDamaged',
        'interiorDamages',
        'awningDamaged',
        'powerMeterDamaged',
        'shedDamaged',
        'otherDamaged',
        'extentofRoofDamage',
        'iDTheftDesc',
        'vehicleOrBoatDesc',
        'spoiledFoodDesc',
        'otherDescrption',
        'fallenTreeIndicator',
        'treeFallenOntoBuilding',
        'otherTreeDamage',
        'contractorInvolvedInd',
        'estimatesReceived',
        'estRepairCost',
        'allRepairsCompeleted'];
    constructor (
        private sessionService: SessionService,
        private utilService: UtilService
        ) {
    }

    mapContactAndIncidentDetails(form, fnolData, memberDetails): any {
        const isMember = this.sessionService.isMember();
        const submitterDetails = this.sessionService.getMemberDTOByPublicID(form.insuredMember);
        const currentLobList = Object.keys(fnolData.lobs);
        const phoneTypeMap = { cell: 'mobile', work: 'work', home: 'home' };
        let reporter: any = {};
        if (isMember) {
            reporter = submitterDetails[0].contactDTO;
        }
        if (!isMember || (isMember && !submitterDetails[0].contactDTO.isInsured)) {
            reporter.preferredPhone = form.phoneGroup.number;
            if (form.phoneGroup.type === 'home') {
                reporter.homeNumber = form.phoneGroup.number;
                reporter.preferredPhone = 'home';
            } else if (form.phoneGroup.type === 'work') {
                reporter.workNumber = form.phoneGroup.number;
                reporter.preferredPhone = 'work';
            } else if (form.phoneGroup.type === 'cell') {
                reporter.cellNumber = form.phoneGroup.number;
                reporter.preferredPhone = 'cell';
            }
            if ((reporter.preferredPhone === 'work') && !this.utilService.checkAllEmpty(form.phoneGroup.extension) &&
                (!(form.phoneGroup.extension.indexOf('*') > -1))) {
                reporter.workNumber = this.formatPhoneNumber(reporter.workNumber);
                reporter.workExt = form.phoneGroup.extension;
                reporter.workNumber = `${reporter.workNumber} x${form.phoneGroup.extension}`;
            }
        } else {
            reporter.preferredPhone = form.prefered;
            if (!this.utilService.checkAllEmpty(form.memberHomePhone) && (!(form.memberHomePhone.indexOf('*') > -1))) {
                reporter.homeNumber = form.memberHomePhone;
            }
            if (!this.utilService.checkAllEmpty(form.memberCellPhone) && (!(form.memberCellPhone.indexOf('*') > -1))) {
                reporter.cellNumber = form.memberCellPhone;
            }
            if (!this.utilService.checkAllEmpty(form.memberWorkPhone) && (!(form.memberWorkPhone.indexOf('*') > -1))) {
                reporter.workNumber = form.memberWorkPhone;
            }
            if (this.utilService.checkAllEmpty(form.memberExtn)) {
                reporter.workExt = '';
            }
            if (!this.utilService.checkAllEmpty(form.memberExtn) &&
                (!(form.memberExtn.indexOf('*') > -1))) {
                reporter.workExt = form.memberExtn;
                reporter.workNumber = `${form.memberWorkPhone} x${reporter.workExt}`;
            }
        }
        reporter.primaryPhoneType = phoneTypeMap[reporter.preferredPhone];
        if (form.hasOwnProperty('textAlert')) {
            reporter.optInTextMessage = form.textAlert === 'Yes';
        }
        reporter.subtype = 'Person';

        if (!isMember) {
            reporter.firstName = form.submitterFirstName;
            reporter.lastName = form.submitterLastName;
        } else {
            reporter.firstName = submitterDetails[0].contactDTO.firstName || 'XXXX';
            reporter.lastName = submitterDetails[0].contactDTO.lastName || 'XXXX';
            if (!!submitterDetails[0].contactDTO.middleName) {
                reporter.middleName = submitterDetails[0].contactDTO.middleName;
            }
        }
        // add email and text alerts from the form
        reporter.primaryContactName = `${reporter.firstName} ${reporter.lastName}`;
        reporter.emailAddress1 = form.submitterEmail;
        const FNOLClaimRequest = this.updateFNOLMapper(reporter, form);
        const updatedFNOL = FNOLClaimRequest.updateFNOLClaimRequest;
        updatedFNOL.lossLocation = this.sessionService.getLossLocation();
        updatedFNOL.lossCause = form.incidentDetails;
        updatedFNOL.description = form.description;
        if (form.policeNotified === 'Yes') {
            updatedFNOL.policeNotified = true;
            updatedFNOL.policeDepartment = form.policeOrFireDept;
            updatedFNOL.policeReportNumber = form.policeOrFireDeptReportNo;
        } else {
            updatedFNOL.policeNotified = false;
        }
        const causeValue = form.incidentDetails;
        if (this.naturalCausesItems.indexOf(causeValue) > -1) {
            updatedFNOL.policeNotified = false;
            delete updatedFNOL.policeDepartment;
            delete updatedFNOL.policeReportNumber;
        }
        if (causeValue === 'nw_waternonweather' || causeValue === 'nw_water') {// water damage losscauses
            let currentPropertyWaterDamageNode: PropertyWaterDamageDTOFields = {};
            let waterDamagePublicID = '';
            const navBackFromPreviousPage = this.sessionService.getNavBackItem('NAV_BACK_FROM_PROP_INFO');
            const editingMode = this.sessionService.getEditingMode();
            // edit from summary or back navigation
            if (navBackFromPreviousPage || editingMode) {
                if (updatedFNOL.lobs[currentLobList[0]].hasOwnProperty('propertyWaterDamage')) {
                    const propertyWaterDamageNode = updatedFNOL.lobs[currentLobList[0]].propertyWaterDamage;
                    const propertyWaterDamageItems =
                        this.sessionService.getPropertyWaterDamageIncidentItems('propertyWaterDamageIncidentItems');
                    waterDamagePublicID = propertyWaterDamageItems.publicID;
                    if (propertyWaterDamageNode.publicID === waterDamagePublicID) {
                        currentPropertyWaterDamageNode = propertyWaterDamageNode;
                    }
                }
                updatedFNOL.lobs[currentLobList[0]].propertyWaterDamage =
                    this.mapWaterDamageFields(currentPropertyWaterDamageNode, form);
            } else {
                updatedFNOL.lobs[currentLobList[0]].propertyWaterDamage =
                    this.mapWaterDamageFields(currentPropertyWaterDamageNode, form);
            }
        } else if (updatedFNOL.lobs[currentLobList[0]].hasOwnProperty('propertyWaterDamage')) {
                delete updatedFNOL.lobs[currentLobList[0]].propertyWaterDamage;
            }
        return updatedFNOL;
    }



    mapWaterDamageFields(propertyWaterDamageNode: PropertyWaterDamageDTOFields, form): PropertyWaterDamageDTOFields {
        propertyWaterDamageNode.waterSource = form.primarySourceWaterDamage;
        if (form.primarySourceWaterDamage === 'plumbing_appliances') {
            if (form.plumbingOrAppliances === 'Yes') {
                propertyWaterDamageNode.hasWaterBeenTurnedOff = true;
            } else {
                propertyWaterDamageNode.hasWaterBeenTurnedOff = false;
            }
        } else if (form.primarySourceWaterDamage === 'roof') {
            if (form.roofLeak === 'Yes') {
                propertyWaterDamageNode.isRoofProtected = true;
            } else {
                propertyWaterDamageNode.isRoofProtected = false;
            }
        } else if (form.primarySourceWaterDamage === 'other') {
            propertyWaterDamageNode.otherDescription = form.primarySourceWaterOthers;
        }
        return propertyWaterDamageNode;
    }

    updateFNOLMapper(reporter: any, form: UntypedFormGroup): any {
        const howInvolved = {
            relativeNotListed: 'relative',
            nwAttorney: 'attorney',
            nwAgent: 'agent',
            self: 'self',
            other: 'other'
        };
        const isMember = this.sessionService.isMember();

        const FNOLClaimRequest = {};
        const createClaimData = this.sessionService.getFnolObject();
        const updateFNOLClaimRequest = { ...createClaimData };
        const currentLobList = Object.keys(createClaimData.lobs);
        const navBackFromPreviousPage = this.sessionService.getNavBackItem('NAV_BACK_FROM_PROP_INFO');
        const editingMode = this.sessionService.getEditingMode();
        let reportedByType = this.sessionService.getSessionItem('howInvolved');
        const submitterDetails = this.sessionService.checkFirstNameAndLastName(reporter.primaryContactName);
        FNOLClaimRequest['fnolClaimNumber'] = createClaimData.claimNumber;
        FNOLClaimRequest['reporter'] = reporter;
        if (isMember) {
            reportedByType = (submitterDetails[0].contactDTO.isInsured) ? 'self' : 'relativeNotListed';
        }
        // setting the required objects in the request object
        updateFNOLClaimRequest.mainContact = this.sessionService.getInsuredPNI();
        updateFNOLClaimRequest.claimReporter = reporter;
        updateFNOLClaimRequest.isAutoSubmitEligible = true;
        // need to check on this reportedByType and set the correct type
        updateFNOLClaimRequest.reportedByType = howInvolved[reportedByType];
        updateFNOLClaimRequest.lobs[currentLobList[0]].fixedPropertyIncidents = [];
        updateFNOLClaimRequest.lobs[currentLobList[0]].fixedPropertyIncidents.push(this.mapFixedProperty());
        FNOLClaimRequest['updateFNOLClaimRequest'] = updateFNOLClaimRequest;
        return FNOLClaimRequest;
    }

    formatPhoneNumber(s: string): string | null {
        if (!s) {
            return '';
        }
        const s2 = (`${s}`).replace(/\D/g, '');
        const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return !m ? null : `${m[1]}-${m[2]}-${m[3]}`;
    }

    /** tempID/Public ID scenario starts */
    mapFixedProperty(): any {
        const lossCause = this.sessionService.getFnolForm('contactincident').incidentDetails;
        let fixedPropertyIncidentObjItem: any = {};
        //  const fixedPropertyIncidentItem = [];
        const claimCreatedData = this.sessionService.getFnolObject();
        const currentLobList = Object.keys(claimCreatedData.lobs);
        let fixedPropertyIncidentsNode = claimCreatedData.lobs[currentLobList[0]].fixedPropertyIncidents;
        const navBackFromPreviousPage = this.sessionService.getNavBackItem('NAV_BACK_FROM_PROP_INFO');
        const editingMode = this.sessionService.getEditingMode();
        if (navBackFromPreviousPage || editingMode) {
            if (lossCause === PersonalConstants.incidentValueConst.IDENTITY_THEFT) {
                fixedPropertyIncidentObjItem.iDTheftInd = true;
                fixedPropertyIncidentObjItem.incidentOwner = this.mapFixedPropertyIncidentOwner();
                fixedPropertyIncidentObjItem.location = this.mapFixedPropertyIncidentLocation();
                fixedPropertyIncidentObjItem.lossParty = 'insured';
                this.clearNotesForMemberProperty();
                return fixedPropertyIncidentObjItem;
            } else if (lossCause === PersonalConstants.incidentValueConst.LOST_ITEM) {
                fixedPropertyIncidentObjItem.contentInvolvedInd = true;
                fixedPropertyIncidentObjItem.uninhabitableInd = false;
                fixedPropertyIncidentObjItem.incidentOwner = this.mapFixedPropertyIncidentOwner();
                fixedPropertyIncidentObjItem.location = this.mapFixedPropertyIncidentLocation();
                fixedPropertyIncidentObjItem.lossParty = 'insured';
                this.clearNotesForMemberProperty();
                return fixedPropertyIncidentObjItem;
            } else {
                const index = 0; // need to set the index dynamically when coming back from member info page
                fixedPropertyIncidentObjItem = fixedPropertyIncidentsNode[index];
                fixedPropertyIncidentObjItem.incidentOwner = this.mapFixedPropertyIncidentOwner();
                fixedPropertyIncidentObjItem.location = this.mapFixedPropertyIncidentLocation();
                this.sessionService.setFixedPropertyIncidentItems('fixedPropertyIncidentItem', fixedPropertyIncidentObjItem);
                return fixedPropertyIncidentObjItem;
            }
        } else {
            fixedPropertyIncidentsNode = fixedPropertyIncidentObjItem;
            fixedPropertyIncidentsNode.incidentOwner = this.mapFixedPropertyIncidentOwner();
            fixedPropertyIncidentsNode.location = this.mapFixedPropertyIncidentLocation();
            fixedPropertyIncidentsNode.lossParty = 'insured';
            if (lossCause === PersonalConstants.incidentValueConst.IDENTITY_THEFT) {
                fixedPropertyIncidentsNode.iDTheftInd = true;
            } else if (lossCause === PersonalConstants.incidentValueConst.LOST_ITEM) {
                fixedPropertyIncidentsNode.contentInvolvedInd = true;
                fixedPropertyIncidentsNode.uninhabitableInd = false;
            }// incident owner as PNI and location mapping
            this.sessionService.setFixedPropertyIncidentItems('fixedPropertyIncidentItem', fixedPropertyIncidentObjItem);
            return fixedPropertyIncidentsNode;
        }
    }
    clearNotesForMemberProperty(): any {
        const propertyNotes = this.sessionService.getPropertyNotes().filter((property) => property.page !== 'Member Property Details');
    }

    mapFixedPropertyIncidentLocation(): any {
        const policyAddress = this.sessionService.getPolicyAddress('policyAddress');
        const lossLocation = this.sessionService.getLossLocation();
        if (this.sessionService.isMember()) {
            const createClaimData = this.sessionService.getFnolObject();
            const updateFNOLClaimRequest = { ...createClaimData };
            const currentLobList = Object.keys(createClaimData.policy.lobs);
            const propertyLocations = updateFNOLClaimRequest.policy.lobs[currentLobList[0]].locations;
            let propertyIncidentLocation;
            for (let i = 0; i < propertyLocations.length; i++) {
                const propertyLocation = propertyLocations[i];
                if ((lossLocation.addressLine1.toUpperCase() !== propertyLocation.locationAddress.addressLine1.toUpperCase() &&
                    lossLocation.city.toUpperCase() !== propertyLocation.locationAddress.city.toUpperCase() &&
                    lossLocation.state.toUpperCase() !== propertyLocation.locationAddress.state.toUpperCase())) {
                    propertyIncidentLocation = this.sessionService.getPolicyAddress('policyAddress');
                    break;
                }
            }
            if (!!propertyIncidentLocation) {
                return propertyIncidentLocation;
            } else {
                return this.sessionService.getLossLocation();
            }
        } else {
            return policyAddress;
        }
    }

    resetMemberPropertyNodes(propNodes): void {
        const propKeys = Object.keys(propNodes[0]);
        propKeys.forEach((key, value) => {
            if (this.memberPropertyDetails.indexOf(key) > -1) {
                delete propNodes[0][key];
            }
        });
        return propNodes;
    }

    mapMemberPropertyDetails(formValues, fnolData): any {
        this.localNotes = '';
        const claimCreatedData = this.sessionService.getFnolObject();
        const currentLobList = Object.keys(claimCreatedData.lobs);
        let fixedPropertyIncidentsNode = claimCreatedData.lobs[currentLobList[0]].fixedPropertyIncidents;
        fixedPropertyIncidentsNode = this.resetMemberPropertyNodes(fixedPropertyIncidentsNode);
        const isMember = this.sessionService.isMember();
        if (!isMember) {
            if (!this.utilService.checkAllEmpty(formValues.homePhone) && (!(formValues.homePhone.indexOf('*') > -1))) {
                fixedPropertyIncidentsNode[0].incidentOwner.homeNumber = formValues.homePhone;
            }
            if (!this.utilService.checkAllEmpty(formValues.cellPhone) && (!(formValues.cellPhone.indexOf('*') > -1))) {
                fixedPropertyIncidentsNode[0].incidentOwner.cellNumber = formValues.cellPhone;
            }
            if (!this.utilService.checkAllEmpty(formValues.businessPhone) && (!(formValues.businessPhone.indexOf('*') > -1))) {
                const extensionNumber = (!this.utilService.checkAllEmpty(formValues.extn) &&
                    (!(formValues.extn.indexOf('*') > -1))) ? ` x${formValues.extn}` : '';
                fixedPropertyIncidentsNode[0].incidentOwner.workNumber = formValues.businessPhone + extensionNumber;
            }
            if (!this.utilService.checkAllEmpty(formValues.extn) &&
                (!(formValues.extn.indexOf('*') > -1))) {
                const extensionNumber = ` x${formValues.extn}`;
                fixedPropertyIncidentsNode[0].incidentOwner.workNumber = formValues.businessPhone + extensionNumber;
            }
            const preferedPhone = (formValues.prefered === 'business') ? 'work' :
                ((formValues.prefered === 'cell') ? 'mobile' : formValues.prefered);
            fixedPropertyIncidentsNode[0].incidentOwner.primaryPhoneType = preferedPhone;
        }
        if (!this.utilService.checkAllEmpty(formValues.email) && (!(formValues.email.indexOf('*') > -1))) {
            fixedPropertyIncidentsNode[0].incidentOwner.emailAddress1 = formValues.email;
        }
        const fixedPropertyIncidentsNodeDamage = this.mapPropertyDamageDetails(formValues);
        // fixedPropertyIncidentsNodeDamage.uninhabitableInd = true;

        //       const previousFixedProperty = claimCreatedData.lobs[currentLobList[0]].fixedPropertyIncidents[0];
        if (!!formValues.propertyInformationGroup.dwlInvolvedInd || !!formValues.propertyInformationGroup.contentInvolvedInd) {
            fixedPropertyIncidentsNodeDamage.uninhabitableInd = false;
        }

        const fixedPropertyFinalObject = { ...fixedPropertyIncidentsNode[0], ...fixedPropertyIncidentsNodeDamage };
        claimCreatedData.lobs[currentLobList[0]].fixedPropertyIncidents[0] = fixedPropertyFinalObject;
        return claimCreatedData;
    }

    mapPropertyDamageDetails(formValues): any {
        const constLabels = PersonalConstants.APPLICATION_LABELS.memberPropertyDetails;
        let fixedPropertyIncidentsNode = this.initializeTheFixedPropertyIncidentsNode(formValues);
        fixedPropertyIncidentsNode =
            this.arrangeAndMapForFixedProperty(fixedPropertyIncidentsNode, formValues, ['propertyInformationGroup', 'homeDamageGroup']);

        // Tree Damage
        if (formValues.fallenTreeIndicator === 'Yes') {
            fixedPropertyIncidentsNode.fallenTreeIndicator = true;
            if (formValues.damagedBestDescription === 'TreeFallenOntoBuilding') {
                fixedPropertyIncidentsNode.treeFallenOntoBuilding = true;
            } else if (formValues.damagedBestDescription === 'OtherTreeDamage') {
                fixedPropertyIncidentsNode.otherTreeDamage = true;
            }
        } else {
            fixedPropertyIncidentsNode.fallenTreeIndicator = false;
            delete fixedPropertyIncidentsNode.otherTreeDamage;
        }

        // contractors
        const contractor = {
            contractorInvolvedInd: false,
            estimatesReceived: 'No',
            estRepairCost: 0.00,
            allRepairsCompleted: false
        };
        fixedPropertyIncidentsNode['contractorInvolvedInd'] =
            (formValues.contractorInvolvedInd === 'Yes');

        if (formValues.contractorInvolvedInd === 'Yes') {
            fixedPropertyIncidentsNode['estimatesReceived'] = 'No';
            let repairEstimateText = '';
            if (formValues.estimatesReceived === 'Yes') {
                repairEstimateText = `${PersonalConstants.PROPERTY_CLAIMS_NOTES.PROPERTY_CONTRACTOR_ESTIMATE
                     } ${formValues.estRepairCost.toString()}`;
                fixedPropertyIncidentsNode['estimatesReceived'] = 'Yes';
            }
            this.localNotesMapping(`${PersonalConstants.PROPERTY_CLAIMS_NOTES.PROPERTY_CONTRACTOR} ${
                 formValues.contractorNamePhoneNumber} ${repairEstimateText}`);
            fixedPropertyIncidentsNode['allRepairsCompeleted'] = false;
            if (formValues.estimatesReceived === 'Yes') {
                fixedPropertyIncidentsNode['estRepairCost'] = {
                    amount: '0.00',
                    currency: 'usd'
                };
                fixedPropertyIncidentsNode['estRepairCost'].amount =
                    (formValues.estRepairCost !== '' || formValues.estRepairCost !== null) ? formValues.estRepairCost.toString() : '0.00';
                fixedPropertyIncidentsNode['allRepairsCompeleted'] =
                    (formValues.allRepairsCompleted === 'Yes');
            }
        }

        // descriptions
        if (formValues.propertyInformationGroup.vehicleOrBoatInd) {
            if (!this.utilService.isEmpty(formValues.vehicleOrBoatDesc)) {
                fixedPropertyIncidentsNode['vehicleOrBoatDesc'] = formValues.vehicleOrBoatDesc;
            }
        }

        if (formValues.propertyInformationGroup.spoiledFood) {
            fixedPropertyIncidentsNode['spoiledFoodDesc'] = formValues.spoiledFoodDesc;
        }
        if (formValues.propertyInformationGroup.otherInd) {
            fixedPropertyIncidentsNode['otherDescription'] = formValues.otherDescrption;
        }
        if (formValues.propertyInformationGroup.iDTheftInd) {
            if (!this.utilService.isEmpty(formValues.iDTheftDesc)) {
                fixedPropertyIncidentsNode['iDTheftDesc'] = formValues.iDTheftDesc;
            }
        }

        // roofdamage group
        if (!!formValues.homeDamageGroup && formValues.homeDamageGroup.roofDamaged === true) {
            fixedPropertyIncidentsNode['extentofRoofDamage'] = formValues.extentofRoofDamage || '';
        }

        // notes mapping
        if (formValues.propertyInformationGroup.otherStrInvolvedInd) {
            if (!this.utilService.isEmpty(formValues.otherStructureDescription)) {
                this.localNotesMapping(`${PersonalConstants.PROPERTY_CLAIMS_NOTES.PROPERTY_OTHER_STRUCTURE_ADDITIONAL_INFO
                     } ${formValues.otherStructureDescription}`);
            }
        }
        if (formValues.propertyInformationGroup.contentInvolvedInd) {
            if (!this.utilService.isEmpty(formValues.otherStructureDescription)) {
                this.localNotesMapping(`${PersonalConstants.PROPERTY_CLAIMS_NOTES.PROPERTY_PERSONAL_PROP_ADDITIONAL_INFO
                     } ${formValues.propertyStolenDescription}`);
            }
        }
        this.utilService.setFNOLnotesItem(this.localNotes, PersonalConstants.PAGES.memberPropertyDetails);
        return fixedPropertyIncidentsNode;
    }

    localNotesMapping(notesMessage): void {
        this.localNotes = `${this.localNotes + notesMessage}\n`;
    }

    arrangeAndMapForFixedProperty(fixedPropertyIncidentsNode, formValues, groups): any {
        groups.forEach((value, index) => {
            if (formValues[value] !== undefined) {
                const subGroup = Object.keys(formValues[value]);
                subGroup.map((key) => fixedPropertyIncidentsNode[key] = formValues[value][key] || false);
            }
        });
        return fixedPropertyIncidentsNode;
    }

    initializeTheFixedPropertyIncidentsNode(formValues): any {
        const treeMapping = {
            fallenTreeIndicator: false,
            treeFallenOntoBuilding: false,
            otherTreeDamage: false
        };
        const groupValues = {
            ...this.utilService.returnKeysAndValues(formValues.propertyInformationGroup),
            ...this.utilService.returnKeysAndValues(formValues.homeDamageGroup), ...treeMapping
        };
        return groupValues;
    }

    mapFixedPropertyIncidentOwner(): any {
        let incidentOwnerNode: any = {};
        const isMember = this.sessionService.isMember();
        incidentOwnerNode = this.sessionService.getInsuredPNI();
        if (isMember) {
            const formValues = this.sessionService.getFnolForm('contactincident');
            if (!this.utilService.checkAllEmpty(formValues.memberHomePhone) && (!(formValues.memberHomePhone.indexOf('*') > -1))) {
                incidentOwnerNode.homeNumber = formValues.memberHomePhone;
            }
            if (!this.utilService.checkAllEmpty(formValues.memberCellPhone) && (!(formValues.memberCellPhone.indexOf('*') > -1))) {
                incidentOwnerNode.cellNumber = formValues.memberCellPhone;
            }
            if (!this.utilService.checkAllEmpty(formValues.memberWorkPhone) && (!(formValues.memberWorkPhone.indexOf('*') > -1))) {
                incidentOwnerNode.workNumber = formValues.memberWorkPhone;
            }
            if ((!this.utilService.checkAllEmpty(formValues.memberExtn) &&
                (!(formValues.memberExtn.indexOf('*') > -1)))) {
                const extensionNumber = ` x${formValues.memberExtn}`;
                incidentOwnerNode.workNumber = formValues.memberWorkPhone + extensionNumber;
            }
            if (!this.utilService.checkAllEmpty(formValues.submitterEmail) && (!(formValues.submitterEmail.indexOf('*') > -1))) {
                incidentOwnerNode.emailAddress1 = formValues.submitterEmail;
            }
            if (!!formValues.prefered) {
                incidentOwnerNode.primaryPhoneType = ((formValues.prefered === 'cell') ? 'mobile' : formValues.prefered);
            }
        }
        return incidentOwnerNode;
    }

    processPropertyWaterDamageItems(): void {
        const claimCreatedData = this.sessionService.getFnolObject();
        const lossCause = this.sessionService.getFnolForm('contactincident').incidentDetails;
        let propertyWaterDamageNode = {};
        const currentLobList = Object.keys(claimCreatedData.lobs);
        if (lossCause === PersonalConstants.incidentValueConst.WATER_DAMAGE_FROM_WEATHER ||
            lossCause === PersonalConstants.incidentValueConst.WATER_DAMAGE_NOT_FROM_WEATHER) {
            if (claimCreatedData.lobs[currentLobList[0]].hasOwnProperty('propertyWaterDamage')) {
                propertyWaterDamageNode = claimCreatedData.lobs[currentLobList[0]].propertyWaterDamage;
                this.sessionService.setPropertyWaterDamageIncidentItems('propertyWaterDamageIncidentItems', propertyWaterDamageNode);
            }
        }
    }
}

