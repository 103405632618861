import { Injectable } from '@angular/core';
import { PersonalConstants } from 'app/common/personalConstants';
import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CryptService {
    encrypt: boolean = environment.encrypt || false;
    constructor () { }

    private encryptData(content): any {
        if (typeof content === 'boolean') {
            return content;
        }
        if (content !== '' && content !== null) {
            return CryptoJS.AES.encrypt(content, PersonalConstants.AppKey.key);
        }
        return null;
    }

    private decryptData(content: string): any {
        if (content !== '' && content !== null) {
            const bytes = CryptoJS.AES.decrypt(content.toString(), PersonalConstants.AppKey.key);
            return bytes.toString(CryptoJS.enc.Utf8);
        }
        return null;
    }

    setSessionItem(sessionName, value): any {
        if (this.encrypt) {
            sessionStorage.setItem(sessionName, this.encryptData(value));
            return;
        } else {
            sessionStorage.setItem(sessionName, value);
        }
    }

    getSessionItem(sessionName): any {
        const data = sessionStorage.getItem(sessionName);
        if (typeof data === 'boolean') {
            return data;
        }
        if (data !== '' && data !== null && data !== undefined) {
            if (this.encrypt) {
                return this.decryptData(sessionStorage.getItem(sessionName));
            }
            return sessionStorage.getItem(sessionName);
        }
        return false;
    }

    getSessionItemJSON(sessionName: string): any {
        return JSON.parse(this.getSessionItem(sessionName));
    }

    setSessonItemJSON(sessionName: string, value: any): void {
        this.setSessionItem(sessionName, JSON.stringify(value));
    }
}
