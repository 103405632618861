<div class="breadcrumb--top"></div>
<div class="inner-container clearfix" id="skip-main-content" role="main">
    <h1 id="heading">Give Us A Call</h1>
    <div class="form-content clearfix">
        <bolt-notification >
            If anyone was injured, someone else's property was damaged, or the home is unsafe, then please call Nationwide Claims Service at 1-800-421-3535. These claims may not be electronically submitted.
        </bolt-notification>
    </div>
    <div class="button-spacing pull-right">
        <bolt-button (click)="callNationwide()">Go to Nationwide.com</bolt-button>
    </div>
</div>
