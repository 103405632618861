import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NwDirectivesModule } from 'app/shared/directives/nw-directives.module';
import { PhoneMaskDirective } from '../../../shared/directives/phone-mask/phone-mask.directive';
import { FormHelper } from '../../../shared/services/form-helper.service';
import { ValidatorService } from '../../../shared/services/validator.service';
import { NwAddressComponent } from './nw-address/nw-address.component';
import { NwErrorComponent } from './nw-error/nw-error.component';
import { NwHelptextComponent } from './nw-helptext/nw-helptext.component';
import { NwIncidentdetailsComponent } from './nw-incidentdetails/nw-incidentdetails.component';
import { NwIncidentsComponent } from './nw-incidents/nw-incidents.component';
import { NwInputComponent } from './nw-input/nw-input.component';
import { NwPhoneComponent } from './nw-phone/nw-phone.component';
import { NwSelectComponent } from './nw-select/nw-select.component';
import { NwTextareaComponent } from './nw-textarea/nw-textarea.component';
import { NwYesnoComponent } from './nw-yesno/nw-yesno.component';
@NgModule({
    declarations: [
        NwInputComponent,
        NwPhoneComponent,
        NwIncidentsComponent,
        NwAddressComponent,
        NwIncidentdetailsComponent,
        NwYesnoComponent,
        NwTextareaComponent,
        NwSelectComponent,
        NwErrorComponent,
        NwHelptextComponent,
        PhoneMaskDirective
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NwDirectivesModule
    ],
    exports: [
        NwInputComponent,
        NwPhoneComponent,
        NwAddressComponent,
        NwIncidentdetailsComponent,
        NwYesnoComponent,
        NwTextareaComponent,
        NwSelectComponent,
        NwErrorComponent,
        NwIncidentsComponent,
        NwHelptextComponent,
        PhoneMaskDirective
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        FormHelper,
        ValidatorService
    ]
})
export class NwFormsModule { }
