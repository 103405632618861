import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateformatDirective } from './date-format/dateformat.directive';
import { PhoneNumberMaskDirective } from './phone-mask/phonenumber-mask.directive';
import { TimeMaskDirective } from './time-mask/time-mask.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [DateformatDirective, PhoneNumberMaskDirective, TimeMaskDirective],
    providers: [],
    exports: [DateformatDirective, PhoneNumberMaskDirective, TimeMaskDirective]
})
export class NwDirectivesModule { }
