import { Injectable } from '@angular/core';
import { PersonalConstants } from 'app/common/personalConstants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable } from 'rxjs';
import { CryptService } from './crypt.service';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private urldetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public urldetails$: Observable<any> = this.urldetails.asObservable();

    constructor (
        private cryptService: CryptService,
        private deviceDetectorService: DeviceDetectorService
    ) { }

    setUrlObj(urlObj: any): void {
        this.cryptService.setSessionItem('urlObj', JSON.stringify(urlObj));
        this.urldetails.next(urlObj);
    }

    getUrlObj(): Observable<any> {
        return this.urldetails.asObservable();
    }

    setLossLocation(lossLocation: string, incidentData: any): void {
        this.cryptService.setSessionItem(lossLocation, JSON.stringify(incidentData));
    }

    getLossLocation(): any {
        return JSON.parse(this.cryptService.getSessionItem('lossLocation'));
    }

    setFnolForm(fnolValue: any, fnolSectionName: string): any {
        this.cryptService.setSessionItem(fnolSectionName, JSON.stringify(fnolValue));
    }

    getFnolForm(fnolSectionName: string): any {
        return JSON.parse(this.cryptService.getSessionItem(fnolSectionName)) || {};
    }

    setFnolObject(fnol: string, response: any): any {
        this.cryptService.setSessionItem(fnol, JSON.stringify(response));
    }

    getFnolObject(): any {
        return JSON.parse(this.cryptService.getSessionItem('fnol')) || {};
    }

    setMemberDetails(): void {
        let memberDetails = this.getMemberDetails();
        if (!this.isMember()) {
            this.cryptService.setSessionItem('members', JSON.stringify(memberDetails));
            return;
        }
        memberDetails = this.getMemberDetails(false);
        this.cryptService.setSessionItem('members', JSON.stringify(memberDetails));
    }

    getInsuredMembers(): any {
        return JSON.parse(this.cryptService.getSessionItem('members'));
    }

    getMemberDetails(insuredOnly: boolean = true): any {
        const fnolObject = JSON.parse(this.cryptService.getSessionItem('fnol')).claimContacts;
        return this.getInsuredOrQualifiedClaimReporters(fnolObject, insuredOnly);
    }

    getFirstUpdatedMemberDetails(insuredOnly: boolean = true): any {
        const fnolObject = JSON.parse(this.cryptService.getSessionItem('firstupdatedFNOL')).claimContacts;
        return this.getInsuredOrQualifiedClaimReporters(fnolObject, insuredOnly);
    }

    getInsuredOrQualifiedClaimReporters(fnolObject, insuredOnly): any {
        const contactDtos = [];
        fnolObject.forEach((element) => {
            const contactRoles = element.contactRoles;
            if (insuredOnly) {
                if (element.contactDTO.isInsured) {
                    contactDtos.push(element.contactDTO);
                }
            } else if (this._isQualifiedClaimReporter(contactRoles)) {
                contactDtos.push(element.contactDTO);
            }
        });
        return contactDtos;
    }

    getMemberDTO(name): any {
        const fnolObject = JSON.parse(this.cryptService.getSessionItem('fnol')).claimContacts;
        return fnolObject.filter((member) => member.contactDTO.displayName === name &&
            (this._isQualifiedClaimReporter(member.contactRoles)));
    }

    getMemberDTOByPublicID(publicID): any {
        const fnolObject = JSON.parse(this.cryptService.getSessionItem('fnol')).claimContacts;
        return fnolObject.filter((member) => member.contactDTO.publicID === publicID &&
            (this._isQualifiedClaimReporter(member.contactRoles)));
    }

    checkFirstNameAndLastName(insuredName): any {
        const fnolObject = JSON.parse(this.cryptService.getSessionItem('fnol')).claimContacts;
        return fnolObject.filter((member) => {
            const insuredNameConCat = `${member.contactDTO.firstName} ${member.contactDTO.lastName}`;
            return (insuredNameConCat === insuredName);
        });
    }

    _isQualifiedClaimReporter(roles: any): boolean {
        const isQualifiedRole = false;
        const searchRoleItems = ['insured', 'coveredparty', 'excludedparty'];
        for (let i = 0; i < roles.length; i++) {
            if (searchRoleItems.indexOf(roles[i]) !== -1) {
                return true;
            }
        }
        return isQualifiedRole;
    }

    setEditingMode(editingMode: any): void {
        this.cryptService.setSessionItem('editingMode', JSON.stringify(editingMode));
    }

    getEditingMode(): any {
        return JSON.parse(this.cryptService.getSessionItem('editingMode'));
    }

    setPageRefresh(browserRefresh): void {
        this.cryptService.setSessionItem('browserRefresh', JSON.stringify(browserRefresh));
    }


    getPageRefresh(): any {
        return JSON.parse(this.cryptService.getSessionItem('browserRefresh'));
    }

    blockBrowserBack(browserBack: boolean): void {
        sessionStorage.setItem('stopBackNavigation', JSON.stringify(browserBack));
    }

    getBlockBrowserBack(): any {
       return JSON.parse(sessionStorage.getItem('stopBackNavigation'));
    }

    getInsuredPNI(): any {
        const claimContacts = this.getMemberDetails();
        let insuredPNIContactObj: any = {};
        if (claimContacts.length > 1) {
            let isInsuredPNIPosition = 0;
            isInsuredPNIPosition = claimContacts.findIndex((obj) => obj.isInsured === true || obj.policyRole === 'insured');
            insuredPNIContactObj = claimContacts[isInsuredPNIPosition];
        } else {
            insuredPNIContactObj = claimContacts[0];
        }
        return insuredPNIContactObj;
    }

    setFixedPropertyIncidentItems(fixedPropertyIncidentItem: string, fixedPropertyIncidentObjItem: any): void {
        const fixedPropertyIncidentItemValue: any = [];
        const fixedPropertyIncidentItm = JSON.parse(this.cryptService.getSessionItem(fixedPropertyIncidentItem));
        if (!!fixedPropertyIncidentItm) {
            sessionStorage.setItem(fixedPropertyIncidentItem, fixedPropertyIncidentItemValue);
            this.cryptService.setSessionItem(fixedPropertyIncidentItem, JSON.stringify(fixedPropertyIncidentItemValue));
        }
        this.cryptService.setSessionItem(fixedPropertyIncidentItem, JSON.stringify(fixedPropertyIncidentObjItem));
    }

    setNavBackItem(pageName: string, value: string): any {
        this.cryptService.setSessionItem(pageName, JSON.stringify(value));
    }

    getNavBackItem(pageName: string): any {
        return JSON.parse(this.cryptService.getSessionItem(pageName));
    }

    setPropertyWaterDamageIncidentItems(propertyWaterDamageIncidentItems: string, propertyWaterDamageNode: any): any {
        const propertyWaterDamageIncidentValue: any = [];
        if (!!sessionStorage.getItem(propertyWaterDamageIncidentItems)) {
            this.cryptService.setSessionItem(propertyWaterDamageIncidentItems, JSON.stringify(propertyWaterDamageIncidentValue));
        }
        this.cryptService.setSessionItem(propertyWaterDamageIncidentItems, JSON.stringify(propertyWaterDamageNode));
    }

    getPropertyWaterDamageIncidentItems(propertyWaterDamageIncidentItems: string): any {
        return JSON.parse(this.cryptService.getSessionItem(propertyWaterDamageIncidentItems));
    }

    setPolicyAddress(locationAddress): void {
        this.cryptService.setSessionItem('policyAddress', JSON.stringify(locationAddress));
    }

    setVehicleDetails(vehicleDetails): void {
        this.cryptService.setSessionItem('vehicleDetails', JSON.stringify(vehicleDetails));
    }

    getVehicleDetails(): any {
        return JSON.parse(this.cryptService.getSessionItem('vehicleDetails'));
    }

    getPolicyAddress(policyAddress: string): any {
        return JSON.parse(this.cryptService.getSessionItem(policyAddress));
    }

    checkFnol(): boolean {
        const fnolObj = Object.keys(this.getFnolObject());
        const fnolForm = Object.keys(this.getFnolForm('fnolForm'));
        return fnolObj.length > 0 && fnolForm.length > 0;
    }

    setFnol(fnolObject): void {
        this.cryptService.setSessionItem('fnol', JSON.stringify(fnolObject));
    }

    getPropertyNotes(): any {
        return JSON.parse(this.cryptService.getSessionItem('propertyNotes')) || [];
    }

    setPropertyNotes(value): void {
        this.cryptService.setSessionItem('propertyNotes', JSON.stringify(value));
    }

    getPolicyNumber(): string {
        try {
            const policyNumber = sessionStorage.getItem('nw-policyNumber') || this.getSessionItemDecrypted('policyNumber');
            if (policyNumber && Object.keys(policyNumber).length !== 0) {
                return policyNumber.toUpperCase();
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    getClaimantId(): any {
        return JSON.parse(this.cryptService.getSessionItem('contactId')) || {};
    }
    setEBIFields(key: string, value: any): void {
        this.cryptService.setSessionItem(key, JSON.stringify(value));
    }

    getEBIFields(key: string): any {
        return JSON.parse(this.cryptService.getSessionItem(key)) || {};
    }

    setSessionItem(key: string, value: any): void {
        this.cryptService.setSessionItem(key, JSON.stringify(value));
    }

    getSessionItemDecrypted(key: string): any {
        return JSON.parse(this.cryptService.getSessionItem(key)) || {};
    }

    getSessionItem(item): any {
        return sessionStorage.getItem(item);
    }

    setSessionLogParams(logparams): void {
        this.cryptService.setSessionItem('logparams', JSON.stringify(logparams));
    }

    getSessionLogParams(): any {
        return JSON.parse(this.cryptService.getSessionItem('logparams')) || {};
    }

    setIncidentLocation(incidentLocation): void {
        this.cryptService.setSessionItem('incidentLocation', JSON.stringify(incidentLocation));
    }

    getIncidentLocation(): any {
        return JSON.parse(this.cryptService.getSessionItem('incidentLocation')) || {};
    }

    clearFnolSessions(): any {
        sessionStorage.removeItem('contactincident');
        sessionStorage.removeItem('memberpropertyinformation');
        sessionStorage.removeItem('fnolForm');
        sessionStorage.removeItem('fnol');
        sessionStorage.removeItem('members');
        sessionStorage.removeItem('lossLocation');
        sessionStorage.removeItem('propertyNotes');
        sessionStorage.removeItem('fixedPropertyIncidentItem');
        sessionStorage.removeItem('NAV_BAK_FROM_CONTACT');
        sessionStorage.removeItem('NAV_BACK_FROM_PROP_INFO');
        sessionStorage.removeItem('NAV_TO_MEMBER_INFO');
        sessionStorage.removeItem('NAV_TO_CONTACT_AND_INCIDENT');
    }

    setButtonClicked(value): void {
        this.cryptService.setSessionItem('buttonClicked', JSON.stringify(value));
    }

    getButtonClicked(): string {
        return JSON.parse(this.cryptService.getSessionItem('buttonClicked'));
    }

    getUserType(): any {
        let userType = '';
        // look for better solution while working on local with decrypted session values
        if (PersonalConstants.REDIRECT_USERTYPES.indexOf(sessionStorage.getItem('userType')) >= 0) {
            userType = window.sessionStorage.getItem('userType').replace(/['"]+/g, '');
            this.setSessionItem('userType', userType);
        } else {
            userType = JSON.parse(this.cryptService.getSessionItem('userType')) || '';
        }
        return userType;
    }

    getClaimNumber(): any {
        try {
            return this.getSessionItemDecrypted('claimNumber');
        } catch (e) {
            return sessionStorage.getItem('claimNumber') || sessionStorage.getItem('Claim-Number-Stored-In-Session') || '';
        }
    }

    getLoginState(): any {
        return sessionStorage.getItem('loginState');
    }

    isMember(): any {
        return (sessionStorage.getItem('registrationType') === 'unregistered-member-property') ||
                (sessionStorage.getItem('registrationType') === 'registered');
    }

    isNonMember(): any {
        return (sessionStorage.getItem('registrationType') === 'unregistered-member-support-property');
    }

    isLoggedInMember(): any {
        return (sessionStorage.getItem('registrationType') === 'registered-property');
    }

    isMemberOrNonMember(): any {
        return (sessionStorage.getItem('registrationType') === 'unregistered-member-property') ||
            (sessionStorage.getItem('registrationType') === 'unregistered-member-support-property') ||
            false;
    }

    isMemberOrloggedInMember(): any {
        return (sessionStorage.getItem('registrationType') === 'registered-property') ||
            (sessionStorage.getItem('registrationType') === 'registered-property') || false;
    }

    setFirstUpdatedFNOL(fnolObj): any {
        this.cryptService.setSessionItem('firstupdatedFNOL', JSON.stringify(fnolObj));
    }
    getFirstUpdatedFNOL(fnolObj): any {
        return JSON.parse(this.cryptService.getSessionItem('firstupdatedFNOL'));
    }

    getDeviceInfo(): any {
        try {
            const browser = this.deviceDetectorService.browser;
            const browser_version = this.deviceDetectorService.browser_version;
            const os = this.deviceDetectorService.os;
            const os_version = this.deviceDetectorService.os_version;

            return {
                browser: browser,
                browser_version: browser_version,
                deviceType: this.deviceType(),
                os: os,
                os_version: os_version
            };
        } catch (error) {
            console.log('deviceinfo error');
            return {
                browser: 'unknown',
                browser_version: 'unknown',
                deviceType: 'unknown',
                os: 'unknown',
                os_version: 'unknown'
            };
        }
    }

    deviceType(): string {
        if (this.deviceDetectorService.isMobile()) {
            return 'Mobile';
        } else if (this.deviceDetectorService.isDesktop()) {
            return 'Desktop';
        } else if (this.deviceDetectorService.isTablet()) {
            return 'Tablet';
        } else {
            return '';
        }
    }
}
