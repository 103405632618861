import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimsOidcAuthService } from 'app/efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { SessionService } from 'app/shared/services/session.service';
import { environment } from 'environments/environment';
import { CancelButtonPopupComponent } from '../../shared/components/cancel-button-popup/cancel-button-popup.component';
import { PersonalConstants } from '../personalConstants';
@Component({
    selector: 'is-efnol-header',
    templateUrl: './efnol-header.component.html',
    styleUrls: ['./efnol-header.component.scss']
})
export class EfnolHeaderComponent implements OnInit {
    @ViewChild(CancelButtonPopupComponent) cancelButtonModal: CancelButtonPopupComponent;
    userType: any;
    policyType: any;
    policyNumber: any;
    loggedIn = false;
    environment = environment;
    logoUrl = environment.NATIONWIDE.HOME;
    currentUrl: any;
    constructor (public router: Router,
        private sessionService: SessionService,
        private authService: ClaimsOidcAuthService
    ) { }

    ngOnInit(): void {
        this.policyNumber = this.sessionService.getPolicyNumber();
        this.userType = this.sessionService.getUserType();
        this.authService.getAuthStatus().subscribe((status) => {
            if (status.isRegistered && status.isAuthorized) {
                this.loggedIn = true;
                this.logoUrl = environment.NATIONWIDE.REGISTERED_LOGO_URL;
            }
        });
    }

    goToClaim(tag): void {
        this.sessionService.blockBrowserBack(false);
        this.policyNumber = this.sessionService.getPolicyNumber();
        this.policyType = sessionStorage.getItem('policy-type') || sessionStorage.getItem('claimType');
        if (this.loggedIn && this.userType === 'registered' && typeof this.policyNumber !== 'object') {
            switch (this.policyType) {
                case 'Property':
                case 'property':
                case 'Homeowners':
                    this.navigateToUrl(environment.CLAIMS_OLD_APP.BASE_URL + PersonalConstants.CLAIMS_OLD_URLS.PROPERTY_CLAIMS_LIST);
                    break;
                default:
                    this.router.navigate(['/fileclaim/info/claim-list-page']);
                    break;
            }
        } else {
            this.unauthRedirect(tag);
        }
    }

    unauthRedirect(tag): void {
        this.sessionService.blockBrowserBack(false);
        this.policyType = sessionStorage.getItem('policy-type') || sessionStorage.getItem('claimType');
        if (
            this.currentUrl && (this.currentUrl.includes('/fileclaim/info/') || this.currentUrl.includes('/modernization/pre-efnol/') || this.currentUrl.includes('/fileclaim/auto'))
        ) {
            this.cancelButtonModal.cancelButtonModalPop(tag);
        } else if (this.policyType === 'Property' || this.policyType === 'Homeowners' || this.policyType === 'property') {
            this.navigationToNewTab(environment.NATIONWIDE.PROPERTY_CLAIMS);
        } else if (this.policyType === 'Auto') {
            this.navigationToNewTab(environment.NATIONWIDE.AutoClaimsLandingPage);
        } else {
            this.navigationToNewTab(environment.NATIONWIDE.ClaimsLandingPage);
        }
    }
    navigationToNewTab(url): void {
        window.open(url, '_blank');
    }

    navigateToUrl(url: string): void {
        window.location.href = url;
    }
}
