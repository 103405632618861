import {
    HttpErrorResponse,
    HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { HostListener, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorCodes, ErrorMessage, UnknownError } from 'app/common/personalConstants';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { LoggerService } from '../services/logger.service';
import { SessionService } from '../services/session.service';
import { INTERCEPTOR_EXCLUDE_URLS } from './../../common/personalConstants';

@Injectable()
export class HttperrorInterceptor implements HttpInterceptor {
    url: string;
    isServiceOfferingAfterSubmitNol: any;
    fromMFA: string;
    constructor (private router: Router,
        private logger: LoggerService,
        private sessionService: SessionService
    ) {
        this.fromMFA = sessionService.getSessionItemDecrypted('fromMFA');
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event): any {
        this.logger.logCurrentContents();
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    const policyNumber = this.sessionService.getPolicyNumber();
                    const policyType = sessionStorage.getItem('policy-type') || sessionStorage.getItem('claimType');
                    const pageDetails = this.sessionService.getSessionItemDecrypted('urlObj');
                    this.logger.info(`Claim-Modernization: Raw Error`, { policyNumber: policyNumber, rawError: error, pageUrls: pageDetails });

                    if (this.shouldThrowError(policyType)) {
                        return throwError(() => error);
                    }

                    let errorMessage = UnknownError;
                    sessionStorage.removeItem('triggeredErrorCode');
                    sessionStorage.setItem('triggeredErrorCode', `${error.status}`);

                    if (INTERCEPTOR_EXCLUDE_URLS.some((url) => request.url.includes(url))) {
                        return throwError(() => error);
                    } else if (error.error.message && error.error instanceof ErrorEvent) {
                        errorMessage = error.error.message;
                    } else {
                        const errorCodes = ErrorCodes;
                        this.url = document.URL;
                        errorMessage = this.handleTestPageError(error) || this.handleErrorCodeError(error, errorCodes, policyNumber, pageDetails) || errorMessage;
                    }
                    return throwError(() => new Error(errorMessage));
                })
            );
    }

    shouldThrowError(policyType: string): boolean {
        return this.fromMFA === 'true' || ['Property', 'property', 'Homeowners'].includes(policyType);
    }

    handleTestPageError(error: any): string {
        return this.url.includes('testPage') ? error.error : '';
    }

    handleErrorCodeError(error: any, errorCodes: any[], policyNumber: string, pageDetails: any): string {
        if (errorCodes.includes(error.status) && this.shouldRedirect(error) && this.shouldRedirectForServiceOfferings(error)) {
            const errorMsg = error.error.userMessage || error.statusText;
            this.logger.info(`Claim-Modernization: Error ${error.status} - ${errorMsg}`, error.url,
                { policyNumber: policyNumber, errorCode: `${error.status} - ${errorMsg}`, pageUrls: pageDetails });
            this.router.navigate(['/fileclaim/info/page-not-found']);
            return ErrorMessage;
        }
        return '';
    }

    shouldRedirect(error): boolean {
        const conditions = [
            { apiUrl: 'loss-description' },
            { apiUrl: 'fraud-language' },
            { apiUrl: 'vehicle-appraisal-links' },
            { apiUrl: 'service-offerings', pageUrl: 'claim-summary' },
            { apiUrl: '/files', pageUrl: 'claim-summary' },
            { apiUrl: 'policymanagement', pageUrl: 'claim-list-page' },
            { apiUrl: 'service-offerings', pageUrl: 'claim-list-page', status: [401, 500] },
            { apiUrl: 'vehicle-incidents', pageUrl: 'claim-list-page', status: [401, 500] },
            { apiUrl: 'vehicle-incidents', pageUrl: 'areas-of-damage' },
            { pageUrl: 'dashboard' },
            { pageUrl: 'auto/tracker' },
            { pageUrl: 'claimspayment' },
            { apiUrl: 'client-logs-cloud' },
            { apiUrl: 'upload-links', pageUrl: 'photo-upload' },
            { apiUrl: 'policy-management/auto-policies', subUrls: ['coverages', 'vehicles'] },
            { pageUrl: 'find-repair-shop' }
        ];

        return !conditions.some((condition) => this.meetsCondition(error, condition));
    }

    meetsCondition(error, condition): boolean {
        return !((condition.apiUrl && !error.url.includes(condition.apiUrl)) ||
            (condition.pageUrl && !this.url.includes(condition.pageUrl)) ||
            (condition.status && !condition.status.includes(error.status)) ||
            (condition.subUrls && !condition.subUrls.some((subUrl) => error.url.includes(subUrl)))
        );
    }

    shouldRedirectForServiceOfferings(error): boolean {
        this.isServiceOfferingAfterSubmitNol = this.sessionService.getSessionItemDecrypted('isServiceOfferingAfterSubmitNol');
        return !(error.url.includes('drivein-facilities') || error.status === 403 && (error.url.includes('service-offerings') && this.isServiceOfferingAfterSubmitNol === 'Yes' &&
        (this.url.includes('vehicle-location') || this.url.includes('other-damages') ||
            this.url.includes('inspection-options') || this.url.includes('repair-shops-oys') ||
            this.url.includes('drive-in-inspection'))));
    }
}
