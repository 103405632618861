import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from 'app/shared/services/logger.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'is-external-navigate',
    templateUrl: './external-navigate.component.html',
    styleUrls: ['./external-navigate.component.scss']
})
export class ExternalNavigateComponent implements OnInit {
    url: string;
    env = environment;
    showPage = false;

    constructor (
        private route: ActivatedRoute,
        private logger: LoggerService
    ) {
    }

    ngOnInit(): void {
        this.url = this.route.snapshot.queryParamMap.get('url');
        if (this.getEnv() !== 'prod') {
            this.showPage = true;
        } else {
            // eslint-disable-next-line prefer-template
            this.logger.error('Somehow PROD hit the External Navigate page: ' + this.getEnv() + ' url: ' + this.url);
            this.go(this.url);
        }
    }

    navigate(): void {
        // eslint-disable-next-line prefer-template
        this.logger.info('User clicked the External Navigate button: ' + this.getEnv() + ' url: ' + this.url);
        this.go(this.url);
    }

    go(url: any): void {
        window.location.href = url;
    }

    getEnv(): any {
        return this.env.envName;
    }
}
