import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoggerService } from '../services/logger.service';


@Injectable()
export class LoggerInterceptor implements HttpInterceptor {
    apiStartTimes = {};
    apiNames = {
        tridionLink: 'tridion',
        ...environment.ClaimsAPIs,
        ...environment.commonServiceAPIs,
        ...environment.mfaAPIs,
        ...environment.PolicyAPIs,
        ...environment.claimsComponentAPIs
    };
    constructor (
        private LOGGER: LoggerService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const excluded = RegExp(environment.excludedAPIIntercepts.join('|')).test(request.url);
        if (!excluded) {
            const apiName = this.getAPIName(request.url, request.method, request.body);
            this.apiStartTimes[apiName] = (new Date()).getTime();
            this.LOGGER.info(`API request - ${apiName}`, request.body);

            return next.handle(request).pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        const additionalParams = this.getAdditionalParams(apiName);
                        this.LOGGER.info(`API success response - ${apiName}.`, event, additionalParams);
                    }
                    return event;
                }),
                catchError((errorResponse) => {
                    if (errorResponse instanceof HttpErrorResponse) {
                        const additionalParams = this.getAdditionalParams(apiName);
                        this.LOGGER.info(`API error response - ${apiName}`, errorResponse, additionalParams);
                    }
                    return throwError(errorResponse);
                }));
        } else {
            return next.handle(request).pipe(catchError((errorResponse) => {
                if (request.url.indexOf('client-logging') !== -1) {
                    if (request.url.indexOf('cloud') !== -1) {
                        this.LOGGER.info('Cloud Client logging call Failed', errorResponse);
                    } else {
                        this.LOGGER.info('On-Prem Client logging call Failed', errorResponse);
                    }
                }
                return throwError(errorResponse);
            }));
        }
    }

    private getAdditionalParams(apiName: string): { apiTime: number; currentPage: string } {
        let apiTime = -1;
        if (this.apiStartTimes[apiName]) {
            apiTime = ((new Date()).getTime() - this.apiStartTimes[apiName]) / environment.MILLISECONDS_IN_SECOND;
            delete this.apiStartTimes[apiName];
        }
        return { apiTime, currentPage: 'IncludeFindPageNameMethodHere' }; // TODO eFNOL: write method to return page Name
    }


    getAPIName(url: string, method: string, body: any): string {
        for (const property in this.apiNames) {
            if (url.indexOf(this.apiNames[property]) !== -1) {
                if (this.isClaimsProperty(property)) {
                    return this.getDetailedAPIName(url, method, property);
                } else if (property === 'ebiEndPoint' && body) {
                    return `${property}:${body.eventId}`;
                } else if (property === 'ctmEndpoint' && body && body.interactionGrouping) {
                    return `${property}:${body.interactionGrouping.mappingId}`;
                }
                return property;
            }
        }
        return environment.unknownAPI;
    }

    private isClaimsProperty(property: string): boolean {
        console.log(`isClaimsProperty: ${property}`);
        return environment.ClaimsAPIs[property];
    }

    private isClaimsOperationProperty(property: string): boolean {
        console.log(`isClaimsOperationProperty: ${property}`, environment.claimsOperations[property]);
        return environment.claimsOperations[property];
    }

    private isSingleClaimOperationProperty(property: string): boolean {
        console.log(`isSingleClaimsOperationProperty: ${property}`, environment.singleclaimOperations[property]);
        return environment.singleclaimOperations[property];
    }

    private getDetailedAPIName(url: string, method: string, property: string): string {
        try {
            let apiName = `${property}:`;
            const splitURL = url.split('/');
            const lastPathToken = splitURL[splitURL.length - 1];
            const splitforQueryParams = lastPathToken.split('?'); // check for any Query params
            const urlLastpathtoken1 = splitforQueryParams ? splitforQueryParams[0] : url.substr(url.lastIndexOf('/'));

            if (urlLastpathtoken1) {
                if (this.isClaimsOperationProperty(urlLastpathtoken1)) {
                    apiName += urlLastpathtoken1 === 'search' ?
                        `${urlLastpathtoken1}` : `${environment.servicingAPIVerbs[method]}-${urlLastpathtoken1}`;
                } else {
                    const urlLastpathtoken2 = splitURL[splitURL.length - 2];
                    if (this.isSingleClaimOperationProperty(urlLastpathtoken2)) {
                        apiName += `${urlLastpathtoken2 === 'notices-of-loss' ?
                            environment.claimsVerbs[method] : environment.servicingAPIVerbs[method]}` +
                            `-${environment.singleclaimOperations[urlLastpathtoken2]}`;
                    } else {
                        apiName += `${environment.servicingAPIVerbs[method]}-${urlLastpathtoken1}`;
                    }
                }
            }
            return apiName;
        } catch (error) {
            return `${property}-${method} `;
        }
    }
}
