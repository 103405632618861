<header>
    <div>
        <div class="desktop-row">
            <span class="type-business third">
                <div class="bolt-row bolt-w-100">
                    <p class = "go-to-home" (click)="goToHome()" ><strong>Personal</strong></p>
                    <a class="business-tab" href="{{businessUrl}}">Business</a>
                    <div class = "bolt-row text">
                        <div class = "bolt-col-sm-7 buffer-text"></div>
                        <div class = "bolt-col-sm-12 business-text"
                            (click)="goToBusiness()"
                        >
                            <span class="business-header">Business</span>
                        </div>
                    </div>
                </div>
            </span>
            <div class="header-sec third bolt-container">
                <div class="secondary-header-primary mobile-menu">
                    <bolt-icon name="menu" onclick="window.menuModal.openModal()" color="medium-blue"></bolt-icon>
                    <a class="claims-link" onclick="window.menuModal.openModal()">MENU</a>
                </div>
                <bolt-modal id="menuModal">
                    <a href="{{insuranceHomeUrl}}">Insurance & protection<br></a>
                    <a href="{{investingUrl}}">Investing & retirement<br></a>
                    <a href="{{bankingUrl}}">Banking & borrowing<br></a>
                    <a href="{{resourcesHomeUrl}}">Life events & planning<br></a>
                </bolt-modal>
                <bolt-logo class="mobile-menu" size="sm" href="{{homeUrl}}"></bolt-logo>
                <bolt-logo class="desktops-only" href="{{homeUrl}}"></bolt-logo>

                <div class="secondary-header-secondary mobile-menu">
                    <bolt-icon name="person" class="go-to-personal-login" (click)="goToPersonalLogin()"
                    color="medium-blue"></bolt-icon>
                    <a class="claims-link" href="{{personalLoginUrl}}">LOG IN</a>
                </div>
            </div>
            <div class="bolt-row third icon-holder">
                <bolt-icon class="desktops-only go-to-personal-login" name="person" color="medium-blue"
                    (click)="goToPersonalLogin()"></bolt-icon>
                <span class="desktops-only-login" (click)="goToPersonalLogin()">Log in</span>
            </div>
        </div>
        <span class="claim-bill">
            <div class="bolt-row bolt-w-100 menu-under-header">
                <div class="desktop-list bolt-row">
                    <a href="{{insuranceHomeUrl}}">Insurance & protection</a>
                    <a href="{{investingUrl}}">Investing & retirement</a>
                    <a href="{{bankingUrl}}">Banking & borrowing</a>
                    <a href="{{resourcesHomeUrl}}">Life events & planning</a>
                </div>
                <div class="desktop-claims-bill bolt-row">
                    <div class="bolt-col-sm-5 pay-bill" (click)="claimRedirect()">
                        <bolt-icon name="clipboard" color="medium-blue"></bolt-icon>
                        <span>Claims</span>
                    </div>
                    <div class="bolt-col-sm-7 pay-bill" (click)="payBillRedirect()">
                        <bolt-icon name="credit-card" color="medium-blue"></bolt-icon>
                        <span> Pay a bill</span></div>
                </div>
                <div class="useful-links mobile-menu">
                    <bolt-icon name="clipboard" color="medium-blue" (click)="claimRedirect()"></bolt-icon>
                    <a class="claims-link" (click)="claimRedirect()">Claims</a>
                </div>
                <div class="useful-links mobile-menu">
                    <bolt-icon name="credit-card" color="medium-blue" (click)="payBillRedirect()"></bolt-icon>
                    <a href="https://www.nationwide.com/personal/member-services/pay-bill">Pay a Bill</a>
                </div>
            </div>
        </span>
    </div>
</header>
<section aria-label="Claim steps">
    <is-header-progress-bar></is-header-progress-bar>
</section>
