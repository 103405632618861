import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'is-tracker-header',
  templateUrl: './tracker-header.component.html',
  styleUrls: ['./tracker-header.component.scss']
})
export class TrackerHeaderComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
