import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PersonalConstants } from 'app/common/personalConstants';
import { EBIService } from './ebi.service';
@Injectable()
export class SoftfallService {
    static readonly CLAIMS_SERVICE_DUPLICATE_CLAIM = 606;
    static readonly CLAIMS_SERVICE_NO_POLICY = 404;

    constructor (private router: Router,
        private ebiService: EBIService) { }

    gotoSoftFall(errorCodeDesc, pageName: string, option: any = {}): any {
        this.ebiService.fireEBIEvent(PersonalConstants.EBI_EVENTS.LOGOUT_DUE_TO_IDLE_TIMEOUT, pageName);
        switch (errorCodeDesc) {
            case '401':
                this.router.navigate([PersonalConstants.URLS.error500]);
                break;
            case '500':
                this.router.navigate([PersonalConstants.URLS.error500]);
                break;
            case 'duplicateError':
                this.router.navigate([PersonalConstants.URLS.duplicateError]);
                break;
            case 'propertyError':
                this.router.navigate([PersonalConstants.URLS.errorProp]);
                break;
            case 'commonError':
                this.gotoCommonError(option);
                break;
            default:
                this.router.navigate([PersonalConstants.URLS.error500]);
                break;
        }
    }

    gotoCommonError(option): any {
        switch (option.errorCode) {
            case SoftfallService.CLAIMS_SERVICE_DUPLICATE_CLAIM:
                this.router.navigate([PersonalConstants.URLS.errorCommon], { queryParams: { error: 'duplicateClaims' } });
                break;
            case SoftfallService.CLAIMS_SERVICE_NO_POLICY:
                this.router.navigate([PersonalConstants.URLS.errorCommon], { queryParams: { error: 'noPolicy' } });
                break;
            default:
                this.router.navigate([PersonalConstants.URLS.error500]);
                break;
        }
    }
}
