<bolt-modal id="infoModal"
    type="info"
    class="timeout-popup"
    (bolt-modal-primary-button-click)="closeModalPopup()"
    (bolt-modal-secondary-button-click)="sendExit()"
    primarybutton="Keep Working"
    secondarybutton="Exit Now"
    #timeOutModalPopup>
    <h3>Your session is about to expire in <strong>{{timeoutMin}}</strong> min <strong>{{timeoutSec}}</strong> sec.</h3>
    <p>Would you like to keep working on your claim or exit now?</p>
</bolt-modal>
