<main>
    <div class="testPageContainer">
        <div class="bolt-container">
            <h1>
                IAM Bypass
            </h1>
            <bolt-notification *ngIf="showError">Come on! Fill out the whole form lazy!</bolt-notification>
            <bolt-notification *ngIf="showServiceError">{{serviceErrMsg}}</bolt-notification>
            <div class="iamBypassForm">
                <form [formGroup]="iamBypassForm">
                    <div class=" bolt-row select-style bolt-space-bottom-lg nw-form-control">
                        <div class="bolt-col-md-5">
                            <bolt-textfield name="usernameText"
                                ngDefaultControl
                                formControlName="usernameText"
                                label="Username"
                                id="usernameText" required>
                                <bolt-contextual-help slot="help" type="push" heading="Did you know?">
                                    This question is required and if left unanswered, the first offense is punishable up to 4 years in prison!
                                </bolt-contextual-help>
                            </bolt-textfield>
                        </div>
                    </div>
                    <div class=" bolt-row select-style bolt-space-bottom-lg nw-form-control">
                        <div class="bolt-col-md-5">
                            <div class="historyList">
                                <div class="historyListItem" *ngFor="let historyItem of historyList" >
                                        <a href="#" (click)="prefill(historyItem)">
                                            {{historyItem}}
                                        </a>
                                        <a class="deleteLink" href="#" (click)="deleteItem(historyItem)">
                                            <bolt-icon color="cherry-red" name="xmark-circle" size="sm"></bolt-icon>
                                        </a>
                                        <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" bolt-row select-style bolt-space-bottom-lg nw-form-control">
                        <div class="bolt-col-md-5">
                            <bolt-radio-group label="Environment"
                                name="environment"
                                ngDefaultControl
                                formControlName="environment"
                                horizontal
                                id="environmentGroup" required>
                                <bolt-contextual-help slot="help" type="push" heading="Did you know?">
                                    If you click Continue without answering this question a small child somewhere in the world won't get presents on their birthday?
                                </bolt-contextual-help>
                                <bolt-radio id="envLocal" value="LOCAL">LOCAL</bolt-radio>
                                <bolt-radio id="envDev" value="DEV">DEV</bolt-radio>
                                <bolt-radio id="envTest" value="TEST">TEST</bolt-radio>
                            </bolt-radio-group>
                        </div>
                    </div>
                    <div class=" bolt-row select-style bolt-space-bottom-lg nw-form-control">
                        <div class="bolt-col-md-5">
                            <bolt-select label="CC Env #"
                                name="ccEnv"
                                ngDefaultControl
                                formControlName="ccEnv"
                                horizontal
                                id="ccEnvGroup">
                                <bolt-contextual-help slot="help" type="push" heading="Fact">
                                    This is only needed when navigating to Property Dashboard in the Legacy app. But you already knew that, right?
                                </bolt-contextual-help>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                            </bolt-select>
                        </div>
                    </div>
                    <bolt-button-bar>
                        <bolt-button id="clearBtn" (click)="clear()" slot="cancel">Clear All History</bolt-button>
                        <bolt-button id="envBtn" (click)="openModal()" slot="back">Show Environments</bolt-button>
                        <bolt-button id="continueBtn" (click)="continue()" slot="forward" type="primary">Continue</bolt-button>
                    </bolt-button-bar>
                </form>
            </div>
            <bolt-waiting-indicator class="bolt-space-inner-xl bolt-space-bottom-lg" *ngIf="showWait" id="waitSpinner">Preparing for glory...</bolt-waiting-indicator>
            <div class="iamBypassResults" *ngIf="showResults" id="searchResults">
                <h2>Results</h2>
                <div [id]="'linkItemBox'+i" class="linkItem" *ngFor="let link of links; let i = index">
                    <a [id]="'linkItemLink'+i" [href]="link.url" target="_blank">{{link.env}}: {{link.productType}} - {{link.agreementNumber}}</a>
                </div>
            </div>
        </div>
    </div>
    <bolt-modal #environmentModal id="contentModal" type="content" heading="ISClaims Bucket Allocation" primarybutton="Close">
        <div class="bolt-table bolt-table-striped">
            <table>
                <tr>
                    <th>Bucket</th>
                    <th>Branch</th>
                </tr>
                <tr *ngFor="let env of environmentList; let i = index">
                    <td><b>{{env.name}}</b></td>
                    <td>
                        {{env.branch}} <span *ngIf="env.commitLink !== undefined">
                            <a target="_blank" class="commit-link" [href]="env.commitLink">( latest commit )</a>
                        </span>
                    </td>
                </tr>
            </table>
        </div>
    </bolt-modal>
</main>
