<nav aria-label="breadcrumbs">
    <is-bread-crumbs class="breadCrumbs"
        [homeIconLink]="homeIconLink"
        [breadCrumbs]="breadCrumbs"
        [currentPage]="currentPage"
        position="top"
        [isClaims]="true">
    </is-bread-crumbs>
</nav>
<main>
    <all-faqs
        [clientId]="clientId"
        [accessToken]="accessToken"
        [selectedCategory]="selectedCategory"
        [env]="environment"
        [serverId]="serverId"
        [claimNumber]="claimNumber"
        [contactId]="contactPublicId"
        [isThirdParty]="isThirdParty"
        [useCaching]="true"
    ></all-faqs>
</main>
<nav aria-label="breadcrumbs">
    <is-bread-crumbs class="breadCrumbs"
        [homeIconLink]="homeIconLink"
        [breadCrumbs]="breadCrumbs"
        [currentPage]="currentPage"
        position="bottom"
        [isClaims]="true">
    </is-bread-crumbs>
</nav>
