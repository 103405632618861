import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from './session.service';

@Injectable()
export class NavigationGuardService {
    constructor (private sessionService: SessionService) { }
    canDeactivate(): boolean {
        if (this.sessionService.getButtonClicked()) {
            this.sessionService.setButtonClicked(false);
            // push current state again to prevent further attempts.
            history.pushState(null, null, location.href);
            window.scrollTo(0, 0);
            return false;
        }
        return true;
    }
}
@Injectable()
export class BackButtonGuard {
    constructor (private router: Router,
        private sessionService: SessionService) { }
    canDeactivate( ): boolean {
        if (this.sessionService.getBlockBrowserBack()) {
            history.pushState(null, '');
            window.scrollTo(0, 0);
            return false;
        }
        window.addEventListener('beforeunload', (event) => {
            if (window.location.href.indexOf('identification') > -1 && window.sessionStorage.getItem('showDefaultDialogue') === 'false' ||
                window.sessionStorage.getItem('dontShowPopup') === 'true') {
                return;
            }
            event.preventDefault();
            event.returnValue = ``;
        });
        return true;
    }
}
