import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { LoggerService } from '../shared/services/logger.service';

@Component({
    selector: 'is-error-404',
    templateUrl: 'error404.component.html'
})

export class Error404Component implements OnInit {
    constructor (private LOGGER: LoggerService) { }

    ngOnInit(): any {
        this.LOGGER.error(`User navigated to 404 page via route ${window.location.hash}`);
    }

    callNationwide(): void {
        window.location.href = environment.NATIONWIDE.HOME;
    }
}
