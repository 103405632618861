<div class="breadcrumb--top"></div>
<div class="inner-container clearfix"
    id="skip-main-content"
    role="main">
    <div class="bolt-container">
        <h1 id="heading">Page Not Found</h1>
        <div class="bolt-space-bottom-lg">
            <bolt-notification>
                The page you requested does not exist.
            </bolt-notification>
        </div>
        <div class="button-spacing pull-right">
            <bolt-button (click)="callNationwide()">Go to Nationwide.com</bolt-button>
        </div>
    </div>
</div>
