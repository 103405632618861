import { Injectable } from '@angular/core';
import { PersonalConstants } from 'app/common/personalConstants';
import { environment } from 'environments/environment';
import { UtilService } from './util.service';
@Injectable({
    providedIn: 'root'
})
export class EnvironmentpickerService {
    constructor (private utils: UtilService) { }

    getEnvironmentClaimsCenterApiURL(): any {
        if (environment.production === 'false' || !environment.production) {
            return environment.CLAIM_CENTER_API_ENVIRONMENT.replace('__claims_environment__', this.getEnvironmentCookie());
        }
        return environment.CLAIM_CENTER_API_ENVIRONMENT;
    }

    getEnvironmentLoginURL(): any {
        if (environment.production === 'false' || !environment.production) {
            return environment.NATIONWIDE.LOGIN_PAGE;
        }
        return environment.NATIONWIDE.LOGIN_PAGE;
    }

    getEnvironmentServerNumber(): any {
        if (environment.production === 'false' || !environment.production) {
            const claimsEnv = this.getCookiebyName(environment.claimsCookies.claimsEnvNumber);
            if (this.utils.isStrictNull(claimsEnv)) {
                document.cookie = `${environment.claimsCookies.claimsEnvNumber}=${environment.ClaimsEnvironment.serverId
                     };path=${environment.claimsCookies.path
                     };domain=${environment.claimsCookies.domain}`;
                return environment.ClaimsEnvironment.serverId;
            }
            return claimsEnv;
        }
        return environment.ClaimsEnvironment.serverId;
    }

    getEnvironmentCookie(): any {
        const claimsEnv = this.getCookiebyName(environment.claimsCookies.claimsEnv);
        if (this.utils.isStrictNull(claimsEnv)) {
            document.cookie = `${environment.claimsCookies.claimsEnv}=${environment.ClaimsEnvironment.envName
                 };path=${environment.claimsCookies.path
                 };domain=${environment.claimsCookies.domain}`;
            return environment.ClaimsEnvironment.envName;
        }
        return claimsEnv;
    }

    getCookiebyName(name): any {
        const pair = document.cookie.match(new RegExp(`${name}=([^;]+)`));
        return !!pair ? pair[1] : null;
    }
}
