import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { PersonalConstants } from 'app/common/personalConstants';
import { BoltFormHelper } from 'app/shared/services/bolt-form-helper';

const routes: Routes = [
    {
        path: PersonalConstants.URLPATHPARAMS.FILE_CLAIM,
        children: [{
            path: PersonalConstants.URLPATHPARAMS.INFO,
            loadChildren: (): any => import('./pre-efnol/pre-efnol.module').then((m) => m.PreEfnolModule)
        }]
    },
    {
        path: PersonalConstants.URLPATHPARAMS.MODERNIZATTION,
        children: [{
            path: PersonalConstants.URLPATHPARAMS.PRE_EFNOL,
            loadChildren: (): any => import('./pre-efnol/pre-efnol.module').then((m) => m.PreEfnolModule)
        }]
    },
    {
        path: PersonalConstants.URLPATHPARAMS.FILE_CLAIM,
        children: [{
            path: PersonalConstants.URLPATHPARAMS.PRODUCTTYPE,
            loadChildren: (): any => import('./efnol/efnol.module').then((m) => m.EfnolModule)
        }]
    },
    {
        path: PersonalConstants.URLPATHPARAMS.FILE_CLAIM,
        children: [{
            path: PersonalConstants.URLPATHPARAMS.POSTFNOL,
            loadChildren: (): any => import('./post-efnol/post-efnol.module').then((m) => m.PostEfnolModule)
        }]
    }
];

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        FormsModule
    ],
    declarations: [],
    providers: [BoltFormHelper],
    exports: [RouterModule]
})
export class EfnolModernizationModule { }
