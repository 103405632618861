import { Component, HostListener, OnInit } from '@angular/core';
import { Analytics } from '@nationwide/claims-component-library/app/models/analytics.model';
import { BreadCrumb } from '@nationwide/internet-servicing-angular-components';
import { PersonalConstants } from 'app/common/personalConstants';
import { EnvironmentpickerService } from 'app/shared/services/environmentpicker.service';
import { LoggerService } from 'app/shared/services/logger.service';
import { environment } from 'environments/environment';
@Component({
  selector: 'is-find-repair-shop',
  templateUrl: './find-repair-shop.component.html',
  styleUrls: ['./find-repair-shop.component.scss']
})
export class FindRepairShopComponent implements OnInit {
    breadCrumbLinks;
    breadCrumbTexts;
    breadCrumbs: BreadCrumb[];
    homeIconLink = environment.NATIONWIDE.HOME;
    clientId = PersonalConstants.appProps.appClientId;
    currentPage = 'Find a Repair Shop';
    env;

    constructor(
        private environmentpickerService: EnvironmentpickerService,
        private logger: LoggerService
    ) {}

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event): any {
        this.logger.logCurrentContents();
    }

    ngOnInit(): void {
        this.setBreadCrumbs();
        this.logger.setPageName('findRepairShop');
        this.env = environment.CLAIMS_DEV ? this.environmentpickerService.getEnvironmentCookie() : 'prod';
    }

    setBreadCrumbs(): void {
        this.homeIconLink = environment.NATIONWIDE.HOME;
        this.breadCrumbs = [
            {
                name: 'Claims Center',
                link: environment.NATIONWIDE.CLAIM_CENTER_URL
            }
        ];
    }

    handleAnalytics(analytics: Analytics): void {
        this.logger.info(analytics.name, analytics.value);
    }

    handleError(component: string, error: any): void {
        this.logger.error(`Claims Component Error`, error, {component: component});
    }
}
