import { HttpParams } from '@angular/common/http';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractTimeoutComponent } from '@nationwide/internet-servicing-angular-components';
import { PersonalConstants } from 'app/common/personalConstants';
import { ClaimsOidcAuthService } from 'app/efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { IamBypass } from 'app/shared/services/iam-bypass.service';
import { MfaService } from 'app/shared/services/mfa.service';
import { SessionService } from 'app/shared/services/session.service';
import { windowToken } from 'app/shared/services/window';
import { TimeoutPopupComponent } from 'app/timeout-popup/timeout-popup.component';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LoggerService } from '../shared/services/logger.service';
type ModalStatuses = 'notifyingSessionIsExpiringSoon'
    | 'refreshingSession'
    | 'refreshSessionErrorOccurred';

@Component({
    selector: 'is-timeout-outlet',
    templateUrl: './timeout.component.html',
    styleUrls: ['./timeout.component.scss']
})
export class TimeoutModalComponent extends AbstractTimeoutComponent implements OnInit {
    @ViewChild(TimeoutPopupComponent, { static: true }) timeOutModalPopup: TimeoutPopupComponent;

    status: ModalStatuses = 'notifyingSessionIsExpiringSoon';
    lastSessionActivity = new Date().getTime();
    activityThreshold: number;
    userType;
    fromMFA: string = 'true';
    isTimeOutModalOpen = false;
    forceRefreshMember = false;
    constructor (
        protected router: Router,
        private logger: LoggerService,
        private sessionService: SessionService,
        private claimsAuthService: ClaimsOidcAuthService,
        private iamBypass: IamBypass,
        private mfaService: MfaService,
        @Inject(windowToken) private window: Window
    ) {
        super(router);
        this.userType = this.sessionService.getUserType();
    }

    @HostListener('window:mousedown', ['$event'])
    @HostListener('window:mouseup', ['$event'])
    @HostListener('window:scroll', ['$event'])
    @HostListener('window:keypress', ['$event'])
    @HostListener('window:wheel', ['$event'])
    @HostListener('window:mousemove', ['$event'])
    @HostListener('window:touchstart', ['$event'])

    onSessionActivity(): void {
        this.lastSessionActivity = new Date().getTime();
    }

    ngOnInit(): void {
        this.fromMFA = this.sessionService.getSessionItemDecrypted('fromMFA');
        const twentySeconds = 20000;
        this.pollInterval = twentySeconds;

        const fiveMin = 5 * 60 * 1000;
        this.activityThreshold = fiveMin;

        this.minutesToTimeoutWarning = 3;
        super.ngOnInit();
    }

    checkIfTimedOut(): void {
        this.printSessionActivity();
        if (this.notOnExcludedRoute()) {
            if (this.shouldTimeoutUser()) {
                this.isTimeOutModalOpen = false;
                this.endUserSession(false);
            } else if (this.userIsActive() && !this.isTimeOutModalOpen && !this.sessionRefreshInProgress && this.shouldWarnUser()) {
                if (this.userType.indexOf('unregistered') >= 0 || sessionStorage.getItem('user') === 'mfa-member' || this.forceRefreshMember) {
                    this.refreshUserSession();
                }
            } else if (this.shouldWarnUser() && !this.userIsActive() && !this.sessionRefreshInProgress && !this.isTimeOutModalOpen) {
                this.timeOutModalPopup.openModalPopup();
                this.isTimeOutModalOpen = true;
            }
        }
    }

    private printSessionActivity(): void {
        const printDiagnostic = environment.timeoutDiagnosticEnabled;
        if (printDiagnostic) {
            console.log('---------------------------------');
            console.log('RUNNING TIMEOUT CHECKER');
            console.log('LAST ACTIVITY', new Date(this.lastSessionActivity));
            console.log('USER IS ACTIVE', this.userIsActive(), '\n');
            console.log('SHOULD WARN USER', this.shouldWarnUser());
            console.log('TOKEN EXPIRATION', new Date(this.tokenExpiration()));
            console.log('ACTIVE USER THRESHOLD', this.activityThreshold);
            console.log('MINUTES TO TIMEOUT WARNING', this.minutesToTimeoutWarning);
            console.log('REFRESH TIME', new Date(this.tokenExpiration() -
                (this.minutesToTimeoutWarning * 60 * 1000)));
            console.log('isTimeOutModalOpen', this.isTimeOutModalOpen);
            console.log('POLL INTERNVAL', this.pollInterval);
            console.log('---------------------------------');
        }
    }

    private userIsActive(): boolean {
        const rightNow = new Date().getTime();
        const lastSessionActivity = Number(sessionStorage.getItem('LAST_SESSION_ACTIVITY_OVERRIDE')) || this.lastSessionActivity;
        return lastSessionActivity > rightNow - this.activityThreshold;
    }

    shouldWarnUser(): boolean {
        const secondsInAMinute = 60;
        const millisecondsInASecond = 1000;
        if (this.tokenExpiration()) {
            return (new Date().getTime() > (this.tokenExpiration() - (this.minutesToTimeoutWarning * secondsInAMinute * millisecondsInASecond)));
        } else {
            return false;
        }
    }

    shouldTimeoutUser(): boolean {
        if (this.tokenExpiration()) {
            return (new Date().getTime() > this.tokenExpiration());
        } else {
            return false;
        }
    }

    tokenExpiration(): number | undefined {
        const expirationTimeString = window.sessionStorage.getItem('ACCESS_TOKEN_EXPIRATION');
        return parseInt(expirationTimeString, 10);
    }

    endUserSession(voluntary): void {
        this.logger.debug(`Session expired. Voluntary flag is ${voluntary}`);
        this.logger.logCurrentContents();
        let timeoutDestination = '';
        this.claimsAuthService.getIdToken().subscribe((idToken) => {
            const authMethod = idToken.auth_method;
            timeoutDestination = this.getTimeoutDestination(authMethod);
        });
        this.resetSessionID();
        this.claimsAuthService.logOffLocal();
        this.window.location.href = timeoutDestination;
    }

    resetSessionID(): void {
        const sessionId = this.window.sessionStorage.getItem('sessionId');
        this.window.sessionStorage.clear();
        window.sessionStorage.setItem('dontShowPopup', 'true');
        this.window.sessionStorage.setItem('sessionId', sessionId);
    }

    preInitUserSession(): void {
        this.window.sessionStorage.clear();
    }

    getTimeoutDestination(authMethod: string): string {
        const partialAuthConfig = this.claimsAuthService.getCustomAuthConfig();
        let destination;
        switch (authMethod) {
            case 'customer-search':
                destination = environment.NATIONWIDE.AutoClaimsLandingPage;
                break;
            case 'claims-servicing':
                if (this.fromMFA === 'true') {
                    let userType = '';
                    if (this.claimsAuthService.getMFAUserType() === 'unregistered-non-member') {
                        userType = 'nonmember';
                    } else {
                        userType = 'member';
                    }
                    const mfaLoginQueryString =
                        `?guid=${partialAuthConfig.auth_id_claimAccessIdentifier}&` +
                        `accessCode=${partialAuthConfig.auth_id_claimAccessCode}&` +
                        `policyType=Auto&` +
                        `userType=${userType}`;
                    destination = environment.NATIONWIDE.MFA_LOGIN_PAGE + mfaLoginQueryString;
                } else {
                const claimNumber = this.sessionService.getSessionItemDecrypted('Claim-Number-Stored-In-Session');
                const nonMemberLoginQueryString =
                    `?guid=${partialAuthConfig.auth_id_claimAccessIdentifier}&` +
                    `claimNumber=${claimNumber}&` +
                    `accessCode=${partialAuthConfig.auth_id_claimAccessCode}`;
                destination = environment.CLAIMS_OLD_APP.NON_MEMBER_LOGIN + nonMemberLoginQueryString;
                }
                break;
            case 'external-login':
                const mfaMemberLoginQueryString =
                    `?guid=${partialAuthConfig.auth_id_claimAccessIdentifier}&` +
                    `accessCode=${partialAuthConfig.auth_id_claimAccessCode}&` +
                    `policyType=Auto&` +
                    `userType=member`;
                destination = environment.NATIONWIDE.MFA_LOGIN_PAGE + mfaMemberLoginQueryString;
                break;
            default:
                destination = environment.NATIONWIDE.SESSION_TIMEOUT;
                break;
        }

        return destination;
    }

    refreshUserSession(): void {
        if (!this.sessionRefreshInProgress) {
            this.status = 'refreshingSession';
            if ((this.sessionService.getSessionItemDecrypted('fromMFA') === 'true') && this.isValidExpandUserURL()) {
                this.refreshMFAToken();
            } else {
            const authconfig = this.getAuthConfig();
            const refreshSession$ = this.iamBypass.userArrivedViaBypass() ?
                this.iamBypass.refreshSession(this.getUserId()) :
                this.claimsAuthService.forceRefresh(authconfig.configId, authconfig.customParams);
            refreshSession$.subscribe(
                    (response) => {
                        if (response.isAuthenticated) {
                            sessionStorage.removeItem('LAST_SESSION_ACTIVITY_OVERRIDE');
                            this.isTimeOutModalOpen = false;
                            this.status = 'notifyingSessionIsExpiringSoon';
                        } else {
                            this.onSessionRefreshError();
                        }
                    },
                    (error) => this.onSessionRefreshError()
                );
            }
        }
    }

    private isValidExpandUserURL(): boolean {
        return window.location.href.indexOf('tracker') > -1 || window.location.href.indexOf('remember-device') > -1;
    }

    getUserId(): string {
        let userId = '';
        this.claimsAuthService.getUserInfo().subscribe((userInfo) => {
            userId = userInfo.userId;
        });
        return userId;
    }

    private getAuthConfig(): any {
        const config = {
            configId: '',
            customParams: null
        };
        if (this.sessionService.getUserType().indexOf('unregistered') >= 0) {
            config.configId = this.sessionService.getSessionItemDecrypted('fromMFA') === 'true' ? PersonalConstants.LOGINTYPE.UNREGISTEREDEXPANDUSER : PersonalConstants.LOGINTYPE.UNREGISTERED;
            config.customParams = this.claimsAuthService.getCustomAuthConfig();
        } else if (this.sessionService.getUserType() === 'registered') {
            config.configId = PersonalConstants.LOGINTYPE.REGISTERED;
        }
        return config;
    }

    get sessionRefreshInProgress(): boolean {
        return this.status === 'refreshingSession' ||
            this.status === 'refreshSessionErrorOccurred';
    }

    onSessionRefreshError(): void {
        this.status = 'refreshSessionErrorOccurred';
        const fiveSeconds = 5000;
        this.window.setTimeout(() => {
            const voluntary = false;
            this.endUserSession(voluntary);
        }, fiveSeconds);
    }

    refreshSession(): void {
        if (this.userType === 'registered') {
            this.forceRefreshMember = true;
        }
        this.isTimeOutModalOpen = false;
        this.checkIfTimedOut();
    }

    refreshMFAToken(): any {
        this.refreshMFASession().subscribe((response) => {
                if (response.access_token) {
                    sessionStorage.setItem('ExpandUserLogin', JSON.stringify(response));
                    this.claimsAuthService.setExpiration(PersonalConstants.OIDCTOKENTIME.TOKENEXPIRYTIME);
                    this.sessionService.setSessionItem('MFAToken', response);
                    sessionStorage.removeItem('LAST_SESSION_ACTIVITY_OVERRIDE');
                    this.isOpen = false;
                    this.status = 'notifyingSessionIsExpiringSoon';
                } else {
                    this.logger.debug('EUA Refresh Token: Timeout Component- refresh API call errored ');
                    this.onSessionRefreshError();
                }
        }, (error) => {
            this.logger.error('Timeout Component - refreshAccessToken is errored', error);
            this.logger.debug('EUA Refresh Token: Timeout Component- refresh API call errored ');
            this.onSessionRefreshError();
        });
    }

    refreshMFASession(): Observable<any> {
        const refreshToken = (this.sessionService.getSessionItemDecrypted('MFAToken')).refresh_token;
        const urlEncodedParams = new HttpParams()
            .append('grant_type', 'refresh_token')
            .append('refresh_token', refreshToken);
        const baseUrl = environment.API_ENVIRONMENT + environment.ClaimsAPIs.euaRefreshTokenExperienceApi;
        const uri = 'token';
        return this.mfaService.invokeObservablePostEUAService(baseUrl, uri, urlEncodedParams);
    }
}
