import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ClaimsOidcAuthService } from 'app/efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { EfnolCacheService } from 'app/shared/services/efnol-cache.service';
import { LoggerService } from 'app/shared/services/logger.service';
import { SessionService } from 'app/shared/services/session.service';
import { environment } from 'environments/environment';
@Component({
    selector: 'is-cancel-button-popup',
    templateUrl: './cancel-button-popup.component.html',
    styleUrls: ['./cancel-button-popup.component.scss']
})
export class CancelButtonPopupComponent implements OnInit {
    @ViewChild('cancelButtonModal', { static: true }) cancelButtonModal: ElementRef;

    environment: any = environment;
    AutoClaimsLandingPage: string = environment.NATIONWIDE.AutoClaimsLandingPage;
    ClaimsLandingPage: string = environment.NATIONWIDE.ClaimsLandingPage;
    isMember: boolean;
    claimType: any;
    logParams: any;
    userType = '';
    cancelReasonsForm: UntypedFormGroup;
    displayOptions = [];
    cancelReasonsObj = [
        { description: 'I need to gather more info', value: 'gatherMoreInfo', userType: 'common' },
        { description: 'I want to talk to someone first', value: 'talkToSomeone', userType: 'cancel' },
        { description: 'I want to talk to someone first', value: 'talkToSomeone', userType: 'header' },
        { description: 'I\’d rather not answer', value: 'skip', userType: 'common' },
        { description: 'Other', value: 'other', userType: 'common' }
   ];
    cancelReasons: any;
    claimNumber: any;
    clickLocation: any;
    constructor (
        public router: Router,
        public sessionService: SessionService,
        private authService: ClaimsOidcAuthService,
        private efnolCacheService: EfnolCacheService,
        private logger: LoggerService,
        private fb: UntypedFormBuilder

    ) { }

    ngOnInit(): void {
        this.logParams = {
            pageUrl: this.router.url
        };
        try {
            this.claimNumber = this.sessionService.getSessionItemDecrypted('claimNumber');
        } catch (e: any) {
            this.claimNumber = this.sessionService.getSessionItem('claimNumber');
        }
        this.displayOptions = this.buildFormElements();
        this.cancelReasonsForm = this.fb.group({
            cancelReason: [''],
            otherReason: ['']
        });
        window.sessionStorage.setItem('showDefaultDialogue', 'true');
    }

    buildFormElements(): Array<any> {
        let formElementsToDisplay = [];
        formElementsToDisplay = this.cancelReasonsObj.filter((cancelReason) =>
            cancelReason.userType === 'common' || cancelReason.userType === 'header'
        );
        return formElementsToDisplay;
    }

    cancelButtonModalPop(tag?: any): void {
        this.sessionService.blockBrowserBack(false);
        this.clickLocation = tag;
        if (typeof tag !== 'undefined' && (tag === 'logo' || tag === 'claim' ||
            tag === 'header' || tag === 'Save&Exit')) {
            this.displayOptions = this.cancelReasonsObj.filter((cancelReason) =>
                cancelReason.userType === 'common' || cancelReason.userType === 'header'
            );
        } else {
            this.displayOptions = this.cancelReasonsObj.filter((cancelReason) =>
                cancelReason.userType === 'common' || cancelReason.userType === 'cancel'
            );
        }
        this.logger.error('CANCEL BUTTON POPUP OPEN', '', this.logParams);
        this.claimType = this.efnolCacheService.getBasicInfoValue('claimType');
        this.cancelButtonModal.nativeElement.openModal();
    }

    closeModalPopup(): void {
        this.logger.error('CANCEL BUTTON POPUP SELECTED REASONS ON CLICK OF CONTINUE WITH CLAIM', '', this.logParams);
        this.cancelButtonModal.nativeElement.closeModal();
    }

    sendExit(): void {
        this.sessionService.blockBrowserBack(false);
        this.userType = this.sessionService.getUserType();
        const policyNumber = this.sessionService.getPolicyNumber();
        const selectedReasons = {
            selectedReason: this.cancelReasonsForm.controls.cancelReason.value,
            otherReason: this.cancelReasonsForm.controls?.otherReason?.value
        };
        window.sessionStorage.setItem('showDefaultDialogue', 'false');
        this.logger.setClaimNumber(this.claimNumber);
        this.logger.error('CANCEL BUTTON POPUP SELECTED REASONS ON CLICK OF SEND', selectedReasons, this.logParams);
        this.logger.logCurrentContents();
        if (
            this.userType &&
            Object.keys(this.userType).length > 0 &&
            this.userType.indexOf('unregistered') === -1
        ) {
            if (policyNumber) {
                this.router.navigate(['/fileclaim/info/claim-list-page']);
            } else {
                this.redirect(environment.NATIONWIDE.REGISTERED_LOGO_URL);
            }
        } else if (this.clickLocation === 'logo') {
            this.redirect(environment.NATIONWIDE.HOME);
        } else {
            this.closeModalPopup();
            this.authService.logOffUnRegisteredUser();
            if (this.claimType !== null && this.claimType === 'Auto') {
                this.redirect(this.AutoClaimsLandingPage);
            } else {
                this.redirect(this.ClaimsLandingPage);
            }
        }
    }

    redirect(pageURL: string): void {
        window.location.href = pageURL;
    }
}
