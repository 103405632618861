import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ClaimsHelperService } from './claims-helper.service';
import { ClaimsTrackerService } from './claims-tracker.service';
import { MfaService } from './mfa.service';
import { PolicyCoveragesService } from './policy-coverages.service';
import { SessionService } from './session.service';
import { UtilService } from './util.service';

declare function encode_deviceprint();

@Injectable({
  providedIn: 'root'
})
export class ClaimsOidcHelperService {
    constructor (
        private utils: UtilService,
        private claimsHelperService: ClaimsHelperService,
        private coverageService: PolicyCoveragesService,
        private claimsTrackerService: ClaimsTrackerService,
        private cookieService: CookieService,
        private sessionService: SessionService,
        private mfaService: MfaService
    ) { }

    async getActivePolicies(agreementList: any): Promise<any> {
        return new Promise((resolve) => {
            const activePolicyArray$: Observable<any>[] = [];
            for (const agreement of agreementList) {
                const policyNumber = this.utils.formatPolicyNumber(agreement.agreement_number);
                if (agreement.product_type === 'Auto') {
                    const findActivePolicies$: Observable<any> = this.coverageService.buildgetPolicyCoverages(policyNumber).pipe(
                        map(((coverageResponse) => {
                            if (coverageResponse) {
                                return { coverageResponse, agreement_number: policyNumber };
                            } else {
                                return undefined;
                            }
                        })),
                        catchError(() => of(undefined))
                    );
                    activePolicyArray$.push(findActivePolicies$);
                }
            }
            if (activePolicyArray$.length === 0) {
                resolve({
                    activePolicyCoverages: [],
                    activePolicyCount: 0
                });
            } else {
                combineLatest(activePolicyArray$).subscribe((coverageResponses) => {
                    const filteredPolicies = coverageResponses.filter((item) => item !== undefined);
                    if (filteredPolicies.length > 0) {
                        resolve({
                            activePolicyCoverages: filteredPolicies,
                            activePolicyCount: filteredPolicies.length
                        });
                    } else {
                        resolve({
                            activePolicyCoverages: [],
                            activePolicyCount: 0
                        });
                    }
                });
            }
        });
    }

    getClaimNuberForReturningRegisteredUser(userType?: string): string {
        const claimIdFromURL = sessionStorage.getItem('claimIdFromReturnLink');
        if (!userType) {
            this.claimsTrackerService.getPolicySummary(claimIdFromURL).subscribe((response) => {
                sessionStorage.setItem('nw-policyNumber', response.policyNumber);
            });
        }
        return claimIdFromURL;
    }

    getMFALoginErrorURL(userType: string): any {
        return {
            guid: this.sessionService.getSessionItemDecrypted('CLAIMS_GUID'),
            accessCode: this.sessionService.getSessionItemDecrypted('CLAIMS_ACCESSCODE'),
            policyType: this.sessionService.getSessionItemDecrypted('policyType'),
            userType: userType,
            error: 'accessDenied'
        };
    }

    getDeviceInfoforMFA(): any {
        return {
            channelIndicator: this.claimsHelperService.isMobile() ? 'MOBILE' : 'WEB',
            devicePrint: encode_deviceprint(), // NOSONAR
            deviceToken: this.cookieService.get('deviceToken') || ''
        };
    }

    async processDeviceAnalyze(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.mfaService.deviceAnalyze(this.getDeviceInfoforMFA()).subscribe((response) => {
                this.sessionService.setSessionItem('deviceInfo', response);
                resolve(response);
            }, (error) => {
                reject(error);
            });
        });
    }
}
