import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthOptions } from 'angular-auth-oidc-client';
import { PersonalConstants } from 'app/common/personalConstants';
import { ClaimsOidcAuthService } from 'app/efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { LoggerService } from 'app/shared/services/logger.service';
import { RouterService } from 'app/shared/services/router.service';
import { SessionService } from 'app/shared/services/session.service';
import { Observable } from 'rxjs';

@Injectable()
export class AccessGuard {
    fromMFA: string;
    constructor (
        private router: Router,
        private sessionService: SessionService,
        private authService: ClaimsOidcAuthService
    ) { }

    canActivate(): Observable<boolean> {
        this.fromMFA = this.sessionService.getSessionItemDecrypted('fromMFA');
        return new Observable<boolean>((obs) => {
            this.authService.getAuthStatus().subscribe((status) => {
                if (status.isAuthorized) {
                    obs.next(true);
                } else {
                    obs.next(false);
                    this.router.navigate([PersonalConstants.URLS.error500]);
                }
            });
        });
    }
}

@Injectable()
export class UnauthPropertyEntryGuard {
    constructor (private router: Router, private logger: LoggerService, private authService: ClaimsOidcAuthService, private sessionService: SessionService) { }
    canActivate(): boolean | Observable<boolean> {
        if (this.sessionService.getLoginState() === PersonalConstants.LOGIN_ENTRY_POINTS.EXTERNAL_APP_ENTRY) {
            return this.authService.checkActiveSessionOnExternalRoute(PersonalConstants.LOGIN_ENTRY_POINTS.EXTERNAL_APP_ENTRY, 'unregistered');
        } else if (this.sessionService.getLoginState() === PersonalConstants.LOGIN_ENTRY_POINTS.PROPERTY_FIRST_NOTICE_OF_LOSS) {
            return this.authService.checkActiveSessionOnExternalRoute(PersonalConstants.LOGIN_ENTRY_POINTS.PROPERTY_FIRST_NOTICE_OF_LOSS);
        } else if (!!window.sessionStorage.getItem('AccessToken') && !!window.sessionStorage.getItem('id_token') && !!window.sessionStorage.getItem('AUTH_CONFIG')) {
            this.logger.debug('UnauthPropertyEntryGuard passed.');
            sessionStorage.removeItem('id_token');
            sessionStorage.removeItem('AccessToken');
            sessionStorage.removeItem('ACCESS_TOKEN_EXPIRATION');
            const authConfig = JSON.parse(window.sessionStorage.getItem('AUTH_CONFIG'));
            this.sessionService.setSessionItem('AUTH_CONFIG', authConfig);
            window.sessionStorage.setItem('loginState', PersonalConstants.LOGIN_ENTRY_POINTS.EXTERNAL_APP_ENTRY);
            const authOptions: AuthOptions = { customParams: this.authService.getCustomAuthConfig() };
            if (this.sessionService.getUserType() === 'registered') {
                window.sessionStorage.setItem('loginState', PersonalConstants.LOGIN_ENTRY_POINTS.PROPERTY_FIRST_NOTICE_OF_LOSS);
                this.authService.checkActiveSessionOnExternalRoute(PersonalConstants.LOGIN_ENTRY_POINTS.PROPERTY_FIRST_NOTICE_OF_LOSS);
            } else {
                this.authService.login(PersonalConstants.LOGINTYPE.UNREGISTERED, authOptions);
            }
            return false;
        } else {
            this.logger.error('UnauthPropertyEntryGuard failed. Redirecting to error page');
            this.router.navigate([PersonalConstants.URLS.error500]);
            return false;
        }
    }
}

@Injectable()
export class DirectAccessGuard {
    constructor (
        private router: Router,
        private routeService: RouterService
    ) { }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.routeService.getPermissions(this.router.url, state) ) {
            return true;
        } else {
            this.router.navigate(['/fileclaim/info/page-not-found']);
            return false;
        }
    }
}
