import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    AllFaqsModule,
    ClaimNumberHeaderModule,
    ClaimSummaryModule,
    SharedModule as ClaimsTrackerSharedModule,
    CloseAccordionModule,
    ContactToggleModule,
    ContactUsModule,
    InteractiveFaqsModule,
    MemberTimelineModule,
    PayoutAccordionModule,
    RepairsAccordionModule,
    ReportAccordionModule,
    ServicesAccordionModule
} from '@nationwide/claims-component-library';
import { InternetServicingAngularComponentsModule } from '@nationwide/internet-servicing-angular-components';
import { ClaimsTrackerGuard } from 'app/route_guards/claims-tracker.guard';
import { AccessGuard } from 'app/route_guards/router-access.guard';
import { ClaimTracker } from './claim-tracker.component';
import { FaqComponent } from './faq/faq.component';

const routes: Routes = [
    {
        path: '',
        component: ClaimTracker,
        canActivate: [ClaimsTrackerGuard, AccessGuard],
        data: { title: 'Track Your Claim' }
    },
    {
        path: 'all-faq/:category',
        component: FaqComponent,
        canActivate: [AccessGuard],
        data: { title: 'All FAQ' }
    }
];

@NgModule({
    declarations: [ClaimTracker, FaqComponent],

    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        ClaimsTrackerSharedModule,
        PayoutAccordionModule,
        InteractiveFaqsModule,
        InternetServicingAngularComponentsModule,
        RepairsAccordionModule,
        ReportAccordionModule,
        ServicesAccordionModule,
        ContactToggleModule,
        CloseAccordionModule,
        ClaimSummaryModule,
        MemberTimelineModule,
        ClaimNumberHeaderModule,
        ContactUsModule,
        AllFaqsModule,
        CommonModule,
        RouterModule.forChild(routes)
    ]
})
export class ClaimTrackerModule {}
