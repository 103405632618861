import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Analytics } from '@nationwide/claims-component-library/app/models/analytics.model';
import { UserClick } from '@nationwide/claims-component-library/app/models/userclick.model';
import { BreadCrumb } from '@nationwide/internet-servicing-angular-components';
import { PersonalConstants } from 'app/common/personalConstants';
import { ROUTING } from 'app/common/routeConstants';
import { ClaimsOidcAuthService } from 'app/efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { ClaimsHelperService } from 'app/shared/services/claims-helper.service';
import { ClaimsTrackerService } from 'app/shared/services/claims-tracker.service';
import { EnvironmentpickerService } from 'app/shared/services/environmentpicker.service';
import { HttpHeaderService } from 'app/shared/services/httpheader.service';
import { IamBypass } from 'app/shared/services/iam-bypass.service';
import { LoggerService } from 'app/shared/services/logger.service';
import { SessionService } from 'app/shared/services/session.service';
import { TrackerEbiHelperService } from 'app/shared/services/tracker-ebi-helper.service';
import { UtilService } from 'app/shared/services/util.service';
import { environment } from 'environments/environment';

interface ISelectedCategory {
    isSelected: boolean;
    categoryName: string;
}

@Component({
    selector: 'is-claim-tracker',
    templateUrl: './claim-tracker.component.html',
    styleUrls: ['./claim-tracker.component.scss']
})
export class ClaimTracker implements OnInit, AfterViewInit {
    accessToken;
    clientId = PersonalConstants.appProps.appClientId;
    env;
    claimNumber;
    serverId;
    serviceOfferings: any = '';
    showAll = true;
    showToggle = true;
    policyNumber: string = '';
    vehicleIncident: any;
    window: any;
    isThirdParty = false;
    breadCrumbs: BreadCrumb[];
    homeIconLink = environment.NATIONWIDE.REGISTERED_LOGO_URL;
    currentPage = 'Claim Tracker';
    cacheCategories = ['REPORT', 'REPAIRS', 'SERVICES', 'PAYOUT', 'CLOSE', 'FAQ'];
    questionResponse: any;
    showDynamicQuestion = false;
    showSuccessNotification = false;
    contactPublicId: any;
    confirmationHeader: string;
    serviceSelected: string;
    hasTow: boolean;
    claimFraudLanguage;
    fraudLanguageFont;
    fraudLanguageSize;
    fromMFA: string;
    @ViewChild('boltModalConfirm') boltModalConfirm: ElementRef;
    constructor(
        private environmentpickerService: EnvironmentpickerService,
        private claimsHelperService: ClaimsHelperService,
        private router: Router,
        private logger: LoggerService,
        private sessionService: SessionService,
        private trackerEbiHelperService: TrackerEbiHelperService,
        private utilService: UtilService,
        private iamByPassService: IamBypass,
        private claimTrackerService: ClaimsTrackerService,
        private activatedRoute: ActivatedRoute,
        private httpHeader: HttpHeaderService,
        private claimsOidcAuthService: ClaimsOidcAuthService
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => {
        this.showServiceConfirmationModal();
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event): any {
        this.logger.logCurrentContents();
    }

    allFaqButtonClick = false;
    allFaqCategory!: string;
    contactId!: string;
    selectedCategory: ISelectedCategory = {
        isSelected: false,
        categoryName: ''
    };

    FAQselectedCategory: ISelectedCategory = {
        isSelected: false,
        categoryName: ''
    };

    tellUsMoreVisibilityHandler(event: any): void {
        this.showDynamicQuestion = !event.tellUsMoreVisibility;
        if (this.showDynamicQuestion) {
            this.logger.info('Tracker Load with question ready to be answered', {newTracker: true});
        }
    }

    goToDynamicQuestions(): void {
        this.logger.info('TRACKER - DYNAMIC QUESTIONS CLICKED', {newTracker: true});
        this.router.navigate([
            `${ROUTING.DASHBOARD.BASE_PATH}/${ROUTING.DASHBOARD.CHILD_PATH_TRACKER}/${ROUTING.DASHBOARD.CHILD_PATH_TRACKER_DYNAMIC_QUESTIONS}`
        ],
        {
            queryParams: {
                backTo: ROUTING.QUERY.FROM_NEW_TRACKER
            }
        });
    }

    setBreadCrumbs(): void {
        if (this.fromMFA === 'true') {
            this.homeIconLink = ROUTING.AUTO.BASE_PATH + ROUTING.AUTO.CHILD_PATH_TRACKER;
            this.breadCrumbs = [];
        } else {
        sessionStorage.setItem('claimType', 'Auto');
            this.breadCrumbs = [
                {
                    name: 'All accounts',
                    link: environment.CLAIMS_OLD_APP.ALL_ACCOUNTS + PersonalConstants.CLAIMS_OLD_URLS.ACCOUNT_SUMMARY_PAGE

                },
                {
                    name: 'Auto claims',
                    link: `${environment.CLAIMS_NEW_APP.BASE_URL + PersonalConstants.CLAIMS_URLS.CLAIM_LIST_PAGE}?policyNumber=${encodeURIComponent(this.policyNumber)}`

                }
            ];
        }
    }

    handleServiceOffering(offerings: any): void {
        this.serviceOfferings = offerings;
    }

    handleTimelineData(data: any): void {
        if (data === 'loadStaticTimeline') {
            this.showToggle = false;
        }
    }

    allFaqHandler(faqCategory: string): void {
        if (faqCategory === 'CLOSE') faqCategory = 'PAYOUT';
        this.allFaqCategory = faqCategory;
        this.allFaqButtonClick = true;
        this.router.navigate([
            `${ROUTING.AUTO.BASE_PATH}/${ROUTING.AUTO.CHILD_PATH_TRACKER}/${ROUTING.AUTO.CHILD_PATH_TRACKER_ALL_FAQ}/`,
            faqCategory
        ]);
    }

    faqSelectedCategoryHandler(faqSelectedCategory: string): void {
        this.FAQselectedCategory.categoryName = faqSelectedCategory;
        this.FAQselectedCategory.isSelected = true;
    }

    goToTellUsMore(): void {
        this.logger.info('TRACKER - TELL US MORE CLICKED', {newTracker: true});
        this.router.navigate(
            [
                `${ROUTING.DASHBOARD.BASE_PATH}/${ROUTING.DASHBOARD.CHILD_PATH_TRACKER}/${ROUTING.DASHBOARD.CHILD_PATH_TRACKER_TELL_US_MORE}`
            ],
            {
                queryParams: {
                    from: ROUTING.QUERY.FROM_TELL_US_MORE,
                    backTo: ROUTING.QUERY.FROM_NEW_TRACKER
                }
            }
        );
    }

    goToPaymentOptionsPage(): void {
        const ACH = ROUTING.ACH;
        const route = [ACH.BASE_PATH, ACH.CHILD_PATH];

        const from = ROUTING.QUERY.FROM;
        const tracker = ROUTING.QUERY.FROM_NEW_TRACKER;
        const queryParams = {};
        queryParams[from] = tracker;

        this.logger.logCurrentContents();
        this.router.navigate(route, { queryParams });
    }

    goToViewAndUploadDocumentsPage(): void {
        const DASHBOARD = ROUTING.DASHBOARD;
        const route = [DASHBOARD.BASE_PATH, DASHBOARD.CHILD_PATH_INCIDENT];

        const from = ROUTING.QUERY.FROM;
        const tracker = ROUTING.QUERY.FROM_NEW_TRACKER;
        const queryParams = {};
        queryParams[from] = tracker;

        this.logger.logCurrentContents();
        this.router.navigate(route, { queryParams });
    }

    changeInspectionServiceOffering(
        shopName: 'claim-oys-find-repair-shop' | 'claim-drive-in-find-shop'
    ): void {
        this.sendChangeInspectionServiceEBI();
        const navigate = (): any => this.navigateToSelectService(shopName);
        setTimeout(navigate, 500);
    }

    navigateToSelectService(destinationPageName): void {
        this.cacheCategories.forEach((category: string) => window.sessionStorage.removeItem(`${category}_DATA`));
        const policyNumber = encodeURIComponent(this.policyNumber);
        const sessionId = sessionStorage.getItem('sid');
        let queryString = `&trackerIncidentID=${this.serviceOfferings?.incidentId}`;

        if (destinationPageName === 'claim-oys-find-repair-shop') {
            queryString += `&oysID=${this.serviceOfferings?.oysAuto?.publicID}`;
            if (this.serviceOfferings?.secondaryTow?.isEligible) {
                queryString += `&towingID=${this.serviceOfferings?.secondaryTow?.publicID}`;
            }
            if (this.serviceOfferings?.driveIn?.isEligible) {
                queryString += '&driveIn=true';
            }
        }
        if (destinationPageName === 'claim-drive-in-find-shop') {
            queryString += `&driveInID=${this.serviceOfferings?.driveIn?.publicID}`;
        }
        sessionStorage.setItem('isTracker', 'true');
        sessionStorage.removeItem('vehicleDetails');
        sessionStorage.removeItem('userSelection');
        sessionStorage.removeItem('claimsEBIfields');
        sessionStorage.removeItem('policyEBIfields');
        sessionStorage.removeItem('aws_router');
        sessionStorage.removeItem('deviceInfo');
        sessionStorage.removeItem('nwcsassl34');

        this.utilService.buildStorageCookie();

        let url = `${
            environment.CLAIMS_OLD_APP.BASE_URL
        }?pkey=${policyNumber}&sid=${sessionId}${queryString}&destinationPageName=claims-list#/${destinationPageName}?policyNumber=${policyNumber}&claimId=${encodeURIComponent(
            this.claimNumber
        )}`;

        if (this.iamByPassService.userArrivedViaBypass()) {
            url = `${url}&IAM_BYPASS=true`;
        }
        this.goToUrl(url);
    }

    goToUrl(url: string): void {
        window.location.href = url;
    }

    get fromDynamicQuestionsSuccess(): boolean {
        const { queryParamMap } = this.activatedRoute.snapshot;
        return (queryParamMap.get('from') === ROUTING.QUERY.TELL_US_MORE_SUCCESS ||
            queryParamMap.get('from') === ROUTING.QUERY.DYNAMIC_QUESTION_SUCCESS);
    }

    get fromOysSelection(): boolean {
        const { queryParamMap } = this.activatedRoute.snapshot;
        return (
            queryParamMap.get('from') === ROUTING.QUERY.FROM_OYS_REVIEW ||
            queryParamMap.get('from') === ROUTING.QUERY.FROM_OYS_REVIEW_TOWING
        );
    }
    get fromDriveInSelection(): boolean {
        const { queryParamMap } = this.activatedRoute.snapshot;
        return queryParamMap.get('from') === ROUTING.QUERY.FROM_DRIVE_IN_REVIEW;
    }

    sendChangeInspectionServiceEBI(): void {
        if (this.serviceOfferings?.selectedServiceType === 'oys') {
            this.trackerEbiHelperService.sendChangeOYSEBI('New Tracker');
        } else if (this.serviceOfferings?.selectedServiceType === 'driveIn') {
            this.trackerEbiHelperService.sendChangeDriveInEBI('New Tracker');
        }
    }

    getDirections(address: any): any {
        const dirUrl = `https://www.google.com/maps/place/${address.addressLine1}+${address.city}+${address.state}+${address.country}+${address.postalCode}`;

        window.open(dirUrl, '_blank');
    }

    handleRepairsClick(clickInfo: UserClick): void {
        if (clickInfo.description.includes('VIEW ON MAP')) {
            this.getDirections({
                addressLine1:
                    this.serviceOfferings?.selectedService?.serviceProvider
                        ?.primaryAddress?.addressLine1,
                city: this.serviceOfferings?.selectedService?.serviceProvider
                    ?.primaryAddress?.city,
                state: this.serviceOfferings?.selectedService?.serviceProvider
                    ?.primaryAddress?.state,
                country:
                    this.serviceOfferings?.selectedService?.serviceProvider
                        ?.primaryAddress?.country,
                postalCode:
                    this.serviceOfferings?.selectedService?.serviceProvider
                        ?.primaryAddress?.postalCode
            });
        } else if (clickInfo.description.includes('RESCHEDULE APPOINTMENT') || clickInfo.description.includes('SWITCH TO DRIVE-IN')) {
            this.changeInspectionServiceOffering('claim-drive-in-find-shop');
        } else if (clickInfo.description.includes('SWITCH TO ON YOUR SIDE® FACILITY') || clickInfo.description.includes('CHANGE REPAIR FACILITY')) {
            this.changeInspectionServiceOffering('claim-oys-find-repair-shop');
        } else if (clickInfo.description.includes('FIND AN ON YOUR SIDE® FACILITY')) {
            this.navigateToSelectService('claim-oys-find-repair-shop');
        } else if (clickInfo.description.includes('FIND A DRIVE-IN')) {
            this.navigateToSelectService('claim-drive-in-find-shop');
        } else if (clickInfo.description.includes('ALL FAQS')) {
            this.allFaqHandler(clickInfo.data);
        } else {
            this.logger.info(clickInfo.description, clickInfo.data);
        }
    }

    toggleHandler(state: string): void {
        this.showAll = state === 'SHOW';
    }
    handleClick(clickInfo): void {
        this.logger.info(clickInfo.description, clickInfo.data);
        if (clickInfo.description === 'INTERACTIVE FAQS - ALL FAQS BUTTON CLICKED') {
            this.allFaqHandler(clickInfo.data);
        } else if (clickInfo.description === 'INTERACTIVE FAQS - FAQ CATEGORY CHANGED') {
            this.faqSelectedCategoryHandler(clickInfo.data);
        } else if (clickInfo.description === 'TOGGLE - SHOW/HIDE DETAILS TOGGLE CLICKED') {
            this.toggleHandler(clickInfo.data);
        } else if (clickInfo.description.includes('CLAIM SUMMARY - VIEW & UPLOAD DOCUMENTS')) {
            this.goToViewAndUploadDocumentsPage();
        } else if (clickInfo.description.includes('CLAIM SUMMARY - ELECTRONIC PAYMENT OPTIONS')) {
            this.goToPaymentOptionsPage();
        } else if (clickInfo.description.includes('CLAIM SUMMARY - TELL US MORE CLICKED')) {
            this.goToTellUsMore();
        } else if (clickInfo.description.includes('CATEGORY SELECTED')) {
            if (clickInfo.data.selectedCategory.isSelected) {
                this.selectedCategory.categoryName =
                    clickInfo.data.selectedCategory.categoryName;
                this.selectedCategory.isSelected = clickInfo.data.selectedCategory.isSelected;
            }
        }
    }
    handleAnalytics(analytics: Analytics): void {
        this.logger.info(analytics.name, analytics.value);
    }
    handleComponentError(component: string, error: any): void {
        this.logger.error(`Claims Component Error`, error, {component: component, claimNumber: this.claimNumber, policyNumber: this.policyNumber});
    }

    ngOnInit(): void {
        this.fromMFA = this.sessionService.getSessionItemDecrypted('fromMFA');
        this.policyNumber = this.sessionService.getPolicyNumber();
        this.claimNumber = this.claimsHelperService.getClaimId();
        window.sessionStorage.setItem('dontShowPopup', 'true');
        if (!!this.claimNumber) {
            this.logger.setMember(this.sessionService.getUserType());
            this.logger.setComponentName('New Tracker');
            this.logger.setClaimNumber(this.claimNumber);
            this.isThirdParty = sessionStorage.getItem('user') === 'mfa-non-member';
            if (!this.isThirdParty) {
            this.claimTrackerService
                .getClaimsContacts(this.claimNumber)
                .subscribe((res) => {
                    const contactIds = res.claimContacts.filter(
                        (contact: any) =>
                            contact.roles.some((roleObj: any) =>
                                (roleObj.role === 'coveredparty' || roleObj.role === 'insured' || roleObj.role === 'insuredrep')
                            ) &&
                            contact.roles.some((roleObj: any) =>
                                roleObj.role === 'incidentowner')
                    );
                    this.contactPublicId = contactIds.length ? contactIds[0].contactDTO.publicID : 'no-contact-id';
                    window.sessionStorage.setItem('claimsContactPublicId', this.contactPublicId);
                }, (error: any) => {
                    this.contactPublicId = 'no-contact-id';
                });
            } else {
                this.contactPublicId = sessionStorage.getItem(PersonalConstants.MFA_LOGIN_COMPONENT.CONTACTID);
            }


            this.accessToken = this.httpHeader.getAccessToken();
            this.env = environment.CLAIMS_DEV ? this.environmentpickerService.getEnvironmentCookie() : 'prod';
            this.serverId = environment.CLAIMS_DEV ? this.environmentpickerService.getEnvironmentServerNumber() : '';
            this.setBreadCrumbs();
            const url = window.location.href;
            this.hasTow = url.indexOf('oys-review-towing') > -1;
            if (this.fromDriveInSelection || this.fromOysSelection) {
                this.selectedCategory.categoryName = 'REPAIRS';
                this.selectedCategory.isSelected = true;
            }
            this.claimsOidcAuthService.getIdToken().subscribe((jwt) => {
                const additionalParameters = {
                    'ecn': jwt.ecn || ''
                };
                this.logger.info('Analytics: Tracker successfully loaded', additionalParameters );
            });
            this.claimTrackerService
                .getFraudLanguage({ claimId: this.claimNumber })
                .subscribe((res: any) => {
                    this.claimFraudLanguage = res?.language;
                    this.fraudLanguageFont = res?.font;
                    this.fraudLanguageSize = `${res?.size}px`;
                });
            this.checkDynamicQuestionSuccess();
        } else {
            this.logger.info('No Claim number found');
            this.router.navigate([PersonalConstants.URLS.error500]);
        }
    }

    checkDynamicQuestionSuccess(): void {
        if (this.fromDynamicQuestionsSuccess) {
            this.showSuccessNotification = true;
        }
    }

    showServiceConfirmationModal(): void {
        if (this.fromDriveInSelection) {
            this.serviceSelected = 'driveIn';
            this.confirmationHeader = 'Thank you! Your drive-in appointment is set.';
            this.logger.info('Analytics: User successfully selected DriveIn');
            if (typeof this.boltModalConfirm?.nativeElement?.openModal === 'function') {
            this.boltModalConfirm?.nativeElement?.openModal();
            }
        } else if (this.fromOysSelection) {
            this.serviceSelected = 'oys';
            this.confirmationHeader = 'Thank you for selecting an On Your Side® repair shop!';
            this.logger.info('Analytics: User successfully selected OYS shop');
            if (typeof this.boltModalConfirm?.nativeElement?.openModal === 'function') {
                this.boltModalConfirm?.nativeElement?.openModal();
            }
        }
    }
}
