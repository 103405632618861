import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { PersonalConstants } from 'app/common/personalConstants';
import { ROUTING } from 'app/common/routeConstants';
import { ClaimsOidcAuthService } from 'app/efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { LoggerService } from 'app/shared/services/logger.service';
import { SessionService } from 'app/shared/services/session.service';
import { UtilService } from 'app/shared/services/util.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ClaimsTrackerLoadGuard {
    fromMFA: string;
    canActivate(router: any): any {
        throw new Error('Method not implemented.');
    }
    constructor (
        private authService: ClaimsOidcAuthService,
        private sessionService: SessionService
    ) { }

  canLoad(
    route: Route,
      segments: UrlSegment[]): Observable<boolean> | boolean {
        return this.authService.checkActiveSessionOnExternalRoute(PersonalConstants.LOGIN_ENTRY_POINTS.CLAIMS_TRACKER);
    }
}

@Injectable({
    providedIn: 'root'
})
export class IncidentDetailsLoadGuard {
    fromMFA: string;
    constructor (
        private authService: ClaimsOidcAuthService,
        private sessionService: SessionService
    ) { }

    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean> | boolean {
            return this.authService.checkActiveSessionOnExternalRoute(PersonalConstants.LOGIN_ENTRY_POINTS.INCIDENT_DETAILS);
    }
}

@Injectable({
    providedIn: 'root'
})
export class PaymentOptionsLoadGuard {
    fromMFA: string;
    constructor (
        private authService: ClaimsOidcAuthService,
        private sessionService: SessionService
    ) { }

    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean> | boolean {
            return this.authService.checkActiveSessionOnExternalRoute(PersonalConstants.LOGIN_ENTRY_POINTS.PAYMENT_OPTIONS);
    }
}

@Injectable({
    providedIn: 'root'
})
export class FileUploadLoadGuard {
    fromMFA: string;
    constructor (
        private authService: ClaimsOidcAuthService,
        private sessionService: SessionService
    ) { }

    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean> | boolean {
            return this.authService.checkActiveSessionOnExternalRoute(PersonalConstants.LOGIN_ENTRY_POINTS.FILE_CLAIM_PAGE);
        }
}

@Injectable({
    providedIn: 'root'
})

export class ClaimsTrackerGuard {
    constructor (
        private sessionService: SessionService,
        private logger: LoggerService,
        private router: Router,
        private util: UtilService
    ) { }
    claimNumber: string = '';
    policyNumber: string = '';
    canActivate(
        route: ActivatedRouteSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        this.policyNumber = this.sessionService.getPolicyNumber();
        this.claimNumber = this.sessionService.getClaimNumber();
        if (
            this.policyNumber && this.claimNumber &&
            this.util.isABredirect(this.policyNumber, 'tracker')
        ) {
            this.router.navigate(
                [
                    `${ROUTING.DASHBOARD.BASE_PATH}/${ROUTING.DASHBOARD.CHILD_PATH_TRACKER}`
                ],
                { queryParams: route.queryParams }
            );
            return false;
        } else if (this.policyNumber && this.claimNumber) {
            return true;
        } else {
            this.logger.error('ClaimsTrackerGuard failed. Redirecting to error page.', {policyNumber: this.policyNumber, claimNumber: this.claimNumber});
            this.router.navigate([PersonalConstants.URLS.error500]);
            return false;
        }
    }
}
