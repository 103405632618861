<bolt-progress-bar *ngIf="currentTab != null" value="{{progressBarPercentage}}" class="mobiles-only"></bolt-progress-bar>
<section role="region" aria-label="Claim steps">
    <bolt-tabset *ngIf="currentTab != null" class="mobiles-only">
        <bolt-tablabel class="bolt-row bolt-w-100 progress-bar-header">
            <div class="bolt-col-9">
                <p *ngIf="!menu" class="currentTab">{{currentTab}}</p>
                <p *ngIf="!menu" class="currentSubTab">{{currentTabSection}}</p>
                <p *ngIf="menu" class="extendedHeader">Your claims progress</p>
            </div>
            <div class="align-icons">
                <bolt-icon class="bolt-col-6" *ngIf="!menu" name="chevron-down" color="black" (click)="showDropDown()"></bolt-icon>
                <bolt-icon class="bolt-col-6" *ngIf="menu" name="chevron-up" color="black" (click)="showDropDown()"></bolt-icon>
            </div>
        </bolt-tablabel>
        <bolt-tabpanel *ngIf="menu" class="progress-bar-menu">
            <div class="bolt-w-100 bolt-row" [ngClass]="{'expanded-background' : showBasicInfo}"
            (click)="showSubMenus(tabsList[0].title)">
                <div class="bolt-col-6">
                    <p class="tab-title">{{tabsList[0].title}}</p>
                </div>
                <bolt-icon *ngIf="!showBasicInfo" class="bolt-col-6" name="plus" color="medium-blue" class="align-icons"></bolt-icon>
                <bolt-icon *ngIf="showBasicInfo" class="bolt-col-6" name="minus" color="medium-blue" class="align-icons"></bolt-icon>
            </div>
            <div  *ngIf="showBasicInfo" class="expanded-background">
                <div *ngFor="let item of basicInfoList" class="sub-menu-wrapper">
                    <div class="bolt-row bolt-w-100 sub-menu-item">
                        <bolt-icon *ngIf="item.past" name="checkmark" color="blue"></bolt-icon>
                        <div [ngClass]="{'current-circle' : item.current,'future-circle': item.future}"></div>
                        <p class="title" [ngClass]="{'current-title' : item.past,'future-title': item.future}" *ngIf="!item.current">{{item.title}}</p>
                        <p class="title" *ngIf="item.current"><strong>{{item.title}}</strong></p>
                    </div>
                </div>
            </div>
            <div class="bolt-w-100 bolt-row" [ngClass]="{'expanded-background' : showIncidentDetail}"
            (click)="showSubMenus(tabsList[1].title)">
                <div class="bolt-col-6">
                    <p class="tab-title">{{tabsList[1].title}}</p>
                </div>
                <bolt-icon *ngIf="!showIncidentDetail" class="bolt-col-6" name="plus" color="medium-blue" class="align-icons" ></bolt-icon>
                <bolt-icon *ngIf="showIncidentDetail" class="bolt-col-6" name="minus" color="medium-blue" class="align-icons"></bolt-icon>
            </div>
            <div *ngIf="showIncidentDetail" class="expanded-background">
                <div *ngFor="let item of accidentDetailsList" class="sub-menu-wrapper">
                    <div class="bolt-row bolt-w-100 sub-menu-item">
                        <bolt-icon *ngIf="item.past" name="checkmark" color="medium-blue"></bolt-icon>
                        <div [ngClass]="{'current-circle' : item.current,'future-circle': item.future}"></div>
                        <p class="title" [ngClass]="{'current-title' : item.past,'future-title': item.future}" *ngIf="!item.current">{{item.title}}</p>
                        <p class="title" *ngIf="item.current"><strong>{{item.title}}</strong></p>
                    </div>
                </div>
            </div>
            <div class="bolt-w-100 bolt-row" [ngClass]="{'expanded-background' : showServices}"
            (click)="showSubMenus(tabsList[2].title)">
                <div class="bolt-col-6">
                    <p class="tab-title">{{tabsList[2].title}}</p>
                </div>
                <bolt-icon *ngIf="!showServices" class="bolt-col-6" name="plus" color="medium-blue" class="align-icons"></bolt-icon>
                <bolt-icon *ngIf="showServices" class="bolt-col-6" name="minus" color="medium-blue" class="align-icons"></bolt-icon>
            </div>
            <div  *ngIf="showServices" class="expanded-background">
                <div *ngFor="let item of servicesList" class="sub-menu-wrapper">
                    <div class="bolt-row bolt-w-100 sub-menu-item">
                        <bolt-icon *ngIf="item.past" name="checkmark" color="medium-blue"></bolt-icon>
                        <div [ngClass]="{'current-circle' : item.current,'future-circle': item.future}"></div>
                        <p class="title" [ngClass]="{'current-title' : item.past,'future-title': item.future}" *ngIf="!item.current">{{item.title}}</p>
                        <p class="title" *ngIf="item.current"><strong>{{item.title}}</strong></p>
                    </div>
                </div>
            </div>
            <div class="bolt-w-100 bolt-row" [ngClass]="{'expanded-background' : showSummary}"
            (click)="showSubMenus(tabsList[3].title)">
                <div class="bolt-col-6">
                    <p class="tab-title">{{tabsList[3].title}}</p>
                </div>
                <bolt-icon *ngIf="!showSummary" class="bolt-col-6" name="plus" color="medium-blue" class="align-icons"></bolt-icon>
                <bolt-icon *ngIf="showSummary" class="bolt-col-6" name="minus" color="medium-blue" class="align-icons"></bolt-icon>
            </div>
            <div  *ngIf="showSummary" class="expanded-background">
                <div *ngFor="let item of claimsSummaryList" class="sub-menu-wrapper">
                    <div class="bolt-row bolt-w-100 sub-menu-item">
                        <bolt-icon *ngIf="item.past" name="checkmark" color="medium-blue"></bolt-icon>
                        <div [ngClass]="{'current-circle' : item.current,'future-circle': item.future}"></div>
                        <p class="title" [ngClass]="{'current-title' : item.past,'future-title': item.future}" *ngIf="!item.current">{{item.title}}</p>
                        <p class="title" *ngIf="item.current"><strong>{{item.title}}</strong></p>
                    </div>
                </div>
            </div>
        </bolt-tabpanel>
    </bolt-tabset>
</section>

<div *ngIf="currentTab != null" class="desktops-only" role="region">
    <bolt-tabset class="bolt-row tabs">
        <bolt-tablabel *ngFor="let tab of tabsList" class="bolt-col-3 no-hover" (click)="changeTab(tab.title)">
            <div  *ngIf="tab.completed && tab.Current" class="bolt-row no-margin selected-tab" (click)="changeTab(tab.title)">
                <bolt-icon class="completed-checkmark" name="checkmark" color="medium-blue" aria-label="Completed step"></bolt-icon>
                <p *ngIf="tab.Current" aria-selected="true">{{tab.title}}</p>
            </div>
            <div  *ngIf="tab.completed && tab.unselectedPast" class="bolt-row no-margin unselected-past-tab">
                <bolt-icon class="completed-checkmark" name="checkmark" color="medium-blue" aria-label="Completed step"></bolt-icon>
                <p>{{tab.title}}</p>
            </div>
            <div  *ngIf="tab.completed && tab.unselectedFuture" class="bolt-row no-margin unselected-future-tab">
                <bolt-icon class="completed-checkmark" name="checkmark" color="medium-blue" aria-label="Completed step"></bolt-icon>
                <p class="unselected-future-tab">{{tab.title}}</p>
            </div>
            <p *ngIf="tab.unselectedPast && !tab.completed" class="center unselected-past-tab">{{tab.title}}</p>
            <p *ngIf="tab.Current && !tab.completed" class="center selected-tab">{{tab.title}}</p>
            <p *ngIf="tab.unselectedFuture && !tab.completed" class="center unselected-future-tab">{{tab.title}}</p>
        </bolt-tablabel>
        <bolt-tabpanel class="bolt-row sub-menu">
            <div *ngFor="let item of list; index as i; last as isLast" class="bolt-row sub-menu-repeator">
                <bolt-icon *ngIf="item.past"
                    name="checkmark"
                    color="medium-blue">
                </bolt-icon>
                <bolt-icon *ngIf="item.current"
                    class="current-circle"
                    style= "color:#0067c5;">
                </bolt-icon>
                <bolt-icon *ngIf="item.future"
                    class="future-circle"
                    style= "color:#afa9a0;">
                </bolt-icon>
                <p [ngClass]="{'current-title' : item.current,'future-title': item.future}">{{item.title}}</p>
                <div *ngIf="!isLast" class="line-between"></div>
            </div>
        </bolt-tabpanel>
    </bolt-tabset>
</div>
