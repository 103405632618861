import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Bolt from '@nationwide-bolt/bundle-core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare global {
    interface Window {
        WebComponents: {
            ready: boolean;
        };
    }
}

// Mandatory google analytics code
loadScripts(environment.BOOTSTRAP_ENDPOINT);
loadScripts(environment.BAMNR);
if (environment.production === 'true') {
    enableProdMode();
}
// document.getElementsByTagName('head')[ 0 ].appendChild(script);

Bolt.initialize().then(() => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.log(err));
});

function loadScripts(endpoint: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = endpoint;
    document.getElementsByTagName('head')[0].appendChild(script);
}
