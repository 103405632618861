export const ROUTING = {
    ACH: {
        BASE_PATH: 'claimspayment',
        CHILD_PATH: 'claims-payment-option'
    },
    DOCUMENTS: {
        BASE_PATH: 'dashboard',
        CHILD_PATH: 'incident-details'
    },
    DASHBOARD: {
        BASE_PATH: 'dashboard',
        CHILD_PATH_INCIDENT: 'incident-details',
        CHILD_PATH_TRACKER: 'tracker',
        CHILD_PATH_TRACKER_FAQ: 'faq',
        CHILD_PATH_MFA: 'mfa',
        CHILD_PATH_TRACKER_DYNAMIC_QUESTIONS: 'dynamic-questions',
        CHILD_PATH_TRACKER_TELL_US_MORE: 'tell-us-more'
    },
    AUTO: {
        BASE_PATH: 'auto',
        CHILD_PATH_TRACKER: 'tracker',
        CHILD_PATH_TRACKER_ALL_FAQ: 'all-faq'
    },
    QUERY: {
        BACK_TO: 'backTo',
        FROM: 'from',
        FROM_MFA: 'mfa',
        FROM_TRACKER: 'tracker',
        FROM_LOGIN: 'login',
        FROM_NEW_TRACKER: 'new-tracker',
        FROM_ACH: 'claims-payment-option',
        FROM_OYS_REVIEW: 'oys-review',
        FROM_OYS_REVIEW_TOWING: 'oys-review-towing',
        FROM_DRIVE_IN_REVIEW: 'drive-in-review',
        FROM_DOCUMENTS: 'incident-details',
        FROM_FAQ: 'faq',
        FROM_DYNAMIC_QUESTIONS: 'dynamic-questions',
        FROM_TELL_US_MORE: 'tell-us-more',
        TELL_US_MORE_SUCCESS: 'tell-us-more-success',
        DYNAMIC_QUESTION_SUCCESS: 'dynamic-question-success',
        FROM_URL_MAP: {
            'claims-list': '/claims-list',
            'claims-payment-option': '/claimspayment/claims-payment-option',
            'tracker': '/dashboard/tracker',
            'new-tracker': '/auto/tracker',
            'oys-review': 'components/autoClaimsDashboard/claimOYSReview/claim-oys-review.html',
            'drive-in-review': 'components/autoClaimsDashboard/claimDriveInReview/claim-drive-in-review.html',
            'incident-details': '/dashboard/incident-details'
        }
    },
    UPLOAD: {
        TYPE: 'type',
        ID: 'id',
        BASE_PATH: 'file-upload'
    },
    MFA: {
        LOGIN_MFA: 'login',
        CONTACT_MFA: 'contacts',
        ENTERCODE_MFA: 'enter-code',
        REMEMBERDEVICE_MFA: 'remember-device'
    },
    LOGIN: {
        CLAIMS_GUID: 'guid',
        CLAIMS_ACCESSCODE: 'accessCode',
        BASE_PATH: 'login',
        ERROR: 'error',
        USERTYPE: 'userType',
        POLICY_TYPE: 'policyType'
    },

    CLAIMTYPE: {
        PRODUCTTYPE: 'productType'
    }
};
