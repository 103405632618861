
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FileClaimTestData } from './nw-test-constant';

@Injectable({
    providedIn: 'root'
})
export class NwTestToolService {
    constructor () { }
    fileClaimComponent: BehaviorSubject<any> = new BehaviorSubject({});
    fcd = FileClaimTestData;

    setFileClaimData(data): void {
        this.fileClaimComponent.next(
            this.fcd.filter((resp) => resp.user === data)[0]);
    }

    getFileClaimData(): Observable<any> {
        return this.fileClaimComponent.asObservable();
    }
}
