import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class TokenDataService {
    ctToken = 'CTBasedTokenDetails';
    token = 'tokenDetails';

    sanitizeSessionList = [
        // 'isTracker',
        // 'AccessToken',
        // 'id_token'
    ];

    private tokenSubject = new Subject<any>();
    tokenEmitter = this.tokenSubject.asObservable();

    constructor (
        private jwtHelper: JwtHelperService
    ) { }

    getToken(): any {
        return this._getToken(true);
    }

    getFullToken(): any {
        return this._getToken(false);
    }

    private _getToken(split): any {
        let token = this.getTokenByKey(this.ctToken, split);
        if (token === undefined) {
            token = this.getTokenByKey(this.token, split);
        }
        return token;
    }


    getTokenByKey(value, split = false): any {
        const token = window.sessionStorage.getItem(value);
        let tokenValue;
        if (token !== null) {
            try {
                if (split) {
                    tokenValue = token.split(',')[0];
                } else {
                    tokenValue = token;
                }
            } catch (e) { }
        }
        return tokenValue;
    }

    getLoginParams(): any {
        const quickStartModel = JSON.parse(this.getTokenByKey('nw-quickstart-model'));
        return quickStartModel.loginParams || {};
    }

    getAuthConfig(): any {
        let authConfig;
        try {
            authConfig = JSON.parse(sessionStorage.getItem('AUTH_CONFIG'));
        } catch (error) {
            authConfig = {};
        }
        return authConfig;
    }

    setToken(key, value): any {
        window.sessionStorage.setItem(key, value);
        if (key === this.ctToken || key === this.token) {
            window.sessionStorage.removeItem((key === this.ctToken) ? this.token : this.ctToken);
        }
        this.tokenSubject.next(value);
    }

    saveAuthConfig(authConfig): void {
        sessionStorage.setItem('AUTH_CONFIG', JSON.stringify(authConfig));
    }


    emit(): any {
        this.tokenSubject.next(this.getTokenByKey(this.ctToken, true));
    }

    setDataFromCookieIfPresent(): any {
        const sessionStorageCookie1 = this.getCookiebyName('widgetSessionStorage1');
        const sessionStorageCookie2 = this.getCookiebyName('widgetSessionStorage2');
        const sessionStorageCookie3 = this.getCookiebyName('widgetSessionStorage3');
        const sessionStoragecookieFull = sessionStorageCookie1 + sessionStorageCookie2 + sessionStorageCookie3;
        if (!!sessionStoragecookieFull) {
            const parsedCookie = (Object.keys(sessionStoragecookieFull).length > 0) ? JSON.parse(sessionStoragecookieFull) : {};
            const keys = Object.keys(parsedCookie);
            for (const key of keys) {
                let skipItem = false;
                for (const sessionItem of this.sanitizeSessionList) {
                    if (key.indexOf(sessionItem) >= 0 ) {
                        skipItem = true;
                    }
                }
                if (!skipItem && parsedCookie[key] !== null) {
                   this.setToken(key, parsedCookie[key]);
                }
            }
        }

        this.deleteCookieByName('widgetSessionStorage1');
        this.deleteCookieByName('widgetSessionStorage2');
        this.deleteCookieByName('widgetSessionStorage3');
    }


    getCookiebyName(name): any {
        const pair = document.cookie.match(new RegExp(`${name}=([^;]+)`));
        return !!pair ? pair[1] : null;
    }

    deleteCookieByName(name): any {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${environment.claimsCookies.domain};path=/;`;
        document.cookie = name.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    }

    get jsonWebToken(): any {
        const encodedJwt = this.getTokenByKey('id_token');
        return encodedJwt ?
            this.jwtHelper.decodeToken(encodedJwt) :
            {};
    }
}
