import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonalConstants } from 'app/common/personalConstants';
import { SessionService } from './session.service';
import { UtilService } from './util.service';

@Injectable()
export class AccessGuardServiceContact {
    constructor (private sessionService: SessionService, private router: Router,
        private utils: UtilService, private actRoute: ActivatedRoute) { }

    canActivate(): any {
        const fnol = this.sessionService.getFnolObject();
        return !this.utils.isEmpty(fnol);
    }
}

@Injectable()
export class AccessGuardServiceMemberProperty {
    constructor (private sessionService: SessionService, private router: Router,
        private utils: UtilService, private actRoute: ActivatedRoute) { }

    canActivate(): any {
        const fnol = this.sessionService.getFnolObject();
        const contactDetails = this.sessionService.getFnolForm('contactincident');
        const confirmationResponse = this.sessionService.getFnolForm('confirmationResponse');
        switch (this.router.url) {
            case PersonalConstants.URLS.contactAndIncident:
                if (this.utils.isEmpty(fnol)) {
                    return false;
                }
                break;
            case PersonalConstants.URLS.memberPropertyInformation:
            case PersonalConstants.URLS.claimsSummary:
                if (this.utils.isEmpty(fnol) || (this.utils.isEmpty(contactDetails))) {
                    return false;
                }
                break;
            case PersonalConstants.URLS.claimsConfirmation:
                if (this.utils.isEmpty(fnol) || this.utils.isEmpty(contactDetails) || this.utils.isEmpty(confirmationResponse)) {
                    return false;
                }
                break;
        }
        return true;
    }
}

@Injectable()
export class AccessGuardServiceSummary {
    constructor (private sessionService: SessionService, private router: Router,
        private utils: UtilService, private actRoute: ActivatedRoute) { }

    canActivate(): any {
        let navCheckForMemberProperty = false;
        const fnol = this.sessionService.getFnolObject();
        const contactDetails = this.sessionService.getFnolForm('contactincident');
        const memberPropertyDetails = this.sessionService.getFnolForm('memberpropertyinformation');
        if (contactDetails.incidentDetails === PersonalConstants.incidentValueConst.IDENTITY_THEFT ||
            contactDetails.incidentDetails === PersonalConstants.incidentValueConst.LOST_ITEM ||
            !(this.utils.isEmpty(memberPropertyDetails))) {
            navCheckForMemberProperty = true;
        }
        return (!this.utils.isEmpty(fnol)) && (!this.utils.isEmpty(contactDetails)) && navCheckForMemberProperty;
    }
}
