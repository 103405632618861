import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PersonalConstants } from 'app/common/personalConstants';
import { ClaimsOidcAuthService } from 'app/efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { environment } from 'environments/environment';
import { EnvironmentpickerService } from './environmentpicker.service';
import { SessionService } from './session.service';

@Injectable()
export class HttpHeaderService {
    private transactionId: string;
    deviceInfo: any;
    userType: any;

    constructor (
        private environmentPicker: EnvironmentpickerService,
        private authService: ClaimsOidcAuthService,
        private sessionService: SessionService
    ) {
    }

    getAPILocationServiceHeaders(): HttpHeaders {
        return new HttpHeaders({ client_id: PersonalConstants.appProps.appClientId });
    }


    getClaimServiceHeaders(devServer?: boolean): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': PersonalConstants.headerProps.APPLICATION_JSON });
        headers = headers.set(PersonalConstants.headerProps.AUTHORIZATION, PersonalConstants.headerProps.BEARER + this.getAccessToken());
        headers = headers.set(PersonalConstants.headerProps.STATIC_CLIENTID, PersonalConstants.appProps.appClientId);
        headers = headers.set(PersonalConstants.headerProps.MESSAGE_ID, this.getTransactionId());
        if (devServer) {
            headers = headers.set(PersonalConstants.headerProps.SERVER_ID_NUMBER, this.environmentPicker.getEnvironmentServerNumber());
        }
        return headers;
    }

    getPublicAPIClaimServiceHeaders(devServer?: boolean): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': PersonalConstants.headerProps.APPLICATION_JSON });
        headers = headers.set(PersonalConstants.headerProps.STATIC_CLIENTID, PersonalConstants.appProps.appClientId);
        headers = headers.set(PersonalConstants.headerProps.NW_MESSAGE_ID, this.getTransactionId());
        if (devServer) {
            headers = headers.set(PersonalConstants.headerProps.SERVER_ID_NUMBER, this.environmentPicker.getEnvironmentServerNumber());
        }
        return headers;
    }

    getNWClaimServiceHeaders(devServer?: boolean): HttpHeaders {
        this.userType = this.sessionService.getUserType();
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': PersonalConstants.headerProps.APPLICATION_JSON });
        headers = headers.set(PersonalConstants.headerProps.AUTHORIZATION, PersonalConstants.headerProps.BEARER + this.getAccessToken());
        headers = headers.set(PersonalConstants.headerProps.STATIC_CLIENTID, PersonalConstants.appProps.appClientId);
        headers = headers.set(PersonalConstants.headerProps.NW_MESSAGE_ID, this.getTransactionId());
        if (devServer) {
            headers = headers.set(PersonalConstants.headerProps.SERVER_ID_NUMBER, this.environmentPicker.getEnvironmentServerNumber());
        } else if (!devServer && this.userType === 'unregistered-express') {
            headers = headers.set(PersonalConstants.headerProps.SERVER_ID_NUMBER, 'null');
        }
        return headers;
    }

    getSafeliteMFAServiceHeaders(devServer?: boolean): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': PersonalConstants.headerProps.APPLICATION_JSON });
        headers = headers.set(PersonalConstants.headerProps.AUTHORIZATION, PersonalConstants.headerProps.BEARER + this.getAccessToken());
        headers = headers.set(PersonalConstants.headerProps.STATIC_CLIENTID, PersonalConstants.appProps.appClientId);
        headers = headers.set(PersonalConstants.headerProps.NW_MESSAGE_ID, this.getTransactionId());
        return headers;
    }

    getNWPolicyCoverageHeaders(devServer?: boolean): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': PersonalConstants.headerProps.APPLICATION_JSON });
        headers = headers.set(PersonalConstants.headerProps.AUTHORIZATION, PersonalConstants.headerProps.BEARER + this.getAccessToken());

        headers = headers.set(PersonalConstants.headerProps.STATIC_CLIENTID, PersonalConstants.appProps.appClientId);
        headers = headers.set(PersonalConstants.headerProps.NW_MESSAGE_ID, this.getTransactionId());
        if (devServer) {
            headers = headers.set('X-NW-Target-Env', environment.PolicyAPIs.policyServerNumber);
        }

        return headers;
    }

    getBillingServiceHeaders(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': PersonalConstants.headerProps.APPLICATION_JSON });
        headers = headers.set(PersonalConstants.headerProps.AUTHORIZATION, PersonalConstants.headerProps.BEARER + this.getAccessToken());
        headers = headers.set(PersonalConstants.headerProps.STATIC_CLIENTID, PersonalConstants.appProps.appClientId);
        headers = headers.set(PersonalConstants.headerProps.BILLING_MESSAGE_ID, this.getTransactionId());

        return headers;
    }

    getCtmServiceHeaders(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': PersonalConstants.headerProps.APPLICATION_JSON });
        headers = headers.set(PersonalConstants.headerProps.AUTHORIZATION, PersonalConstants.headerProps.BEARER + this.getAccessToken());
        headers = headers.set(PersonalConstants.headerProps.STATIC_CLIENTID, PersonalConstants.appProps.appClientId);
        headers = headers.set(PersonalConstants.headerProps.REAL_TIME_RESPONSE_INDICATOR,
            PersonalConstants.headerProps.CTM_REAL_TIME_RESPONSE);
        headers = headers.set(PersonalConstants.headerProps.MESSAGE_ID, this.getTransactionId());
        return headers;
    }

    getEUAServiceHeaders(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        headers = headers.set(PersonalConstants.headerProps.AUTHORIZATION, PersonalConstants.headerProps.BEARER + this.getAccessToken());
        headers = headers.set(PersonalConstants.headerProps.STATIC_CLIENTID, PersonalConstants.appProps.appClientId);
        headers = headers.set(PersonalConstants.headerProps.NW_MESSAGE_ID, this.getTransactionId());
        return headers;
    }

    getTransactionId(): string {
        if (!this.transactionId) {
            const currentDate: number = new Date().getTime();
            const transId: string = 'xxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, function (): string {
                // tslint:disable-next-line:no-bitwise
                const randDigit: number = (currentDate + Math.random() * 10) % 10 | 0;
                return `${randDigit}`;
            });
            this.transactionId = transId;
        }
        return this.transactionId;
    }

    getAccessToken(): string {
        let accessToken;
        const mfaToken = ((this.sessionService.getSessionItemDecrypted('MFAToken')).access_token);
        if (mfaToken) {
            accessToken = mfaToken;
        } else {
            this.authService.getAccessToken().subscribe((res) => {
            accessToken = res;
            });
        }
        return accessToken;
    }
}
