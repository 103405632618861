import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonalConstants } from 'app/common/personalConstants';
import { ROUTING } from 'app/common/routeConstants';
import { ClaimsHelperService } from './claims-helper.service';
import { EBIService } from './ebi.service';
import { SessionService } from './session.service';
import { TokenDataService } from './token-data.service';

@Injectable({
    providedIn: 'root'
})
export class TrackerEbiHelperService {
    constructor (

        private activatedRoute: ActivatedRoute,
        private datePipe: DatePipe,
        private ebiService: EBIService,
        private sessionService: SessionService,
        private claimsHelperService: ClaimsHelperService,
        private tokenDataService: TokenDataService,
    ) { }

    buildTrackerDefaultEBIProps(event?: string): any {
        const { DASHBOARD } = ROUTING;
        const ebiObj = {};
        const claimId = this.claimsHelperService.getClaimId();
        const claimFields = this.getEBIClaimsfields();
        ebiObj[(PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB + 1)] = claimFields.claimNumber || 'UNKNOWN';
        ebiObj[(PersonalConstants.EBI_EVENT_TAGS.CLAIM_STATUS + 1)] = claimFields.claimStatus || 'UNKNOWN';
        ebiObj[(PersonalConstants.EBI_EVENT_TAGS.INCIDENT_DATE + 1)] = claimFields.incidentDate || '1970/01/01';
        ebiObj[(PersonalConstants.EBI_EVENT_TAGS.LOSS_CAUSE + 1)] = claimFields.lossCause || 'UNKNOWN';
        const policyFields = this.getEBIPolicyfields();
        ebiObj[PersonalConstants.EBI_EVENT_TAGS.PH_FIRST_NM] = policyFields.policyHolderFirstName || 'UNKNOWN';
        ebiObj[PersonalConstants.EBI_EVENT_TAGS.PH_LAST_NM] = policyFields.policyHolderLastName || 'UNKNOWN';
        ebiObj[PersonalConstants.EBI_EVENT_TAGS.PH_STATE_ALPHA_CD] = policyFields.policyState || 'NA';
        ebiObj[PersonalConstants.EBI_EVENT_TAGS.POLICY_OWNER] = policyFields.isPolicyOwner || 'UNKNOWN';
        ebiObj[PersonalConstants.EBI_EVENT_TAGS.TEST_LEARN] = this.tokenDataService.getCookiebyName('experience') || 'INACTIVE';
        ebiObj[PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL] = this.getPreviousUrl();

        switch (event) {
            case PersonalConstants.EBI_EVENTS.LOAD_FAQ:
            case PersonalConstants.EBI_EVENTS.LOAD_CLAIMS_CONTACTS:
                ebiObj[PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL] = `/${DASHBOARD.BASE_PATH}/${DASHBOARD.CHILD_PATH_TRACKER}`;
                break;
            case PersonalConstants.EBI_EVENTS.CLAIMS_QS_CONFIRMATION_EMAIL_CLICK:
                ebiObj[PersonalConstants.EBI_EVENT_TAGS.LINK_NAME] = 'Contact Claim Associate';
                break;
            case PersonalConstants.EBI_EVENTS.RETRIEVE_DOCUMENT_DETAILS:
                delete ebiObj[PersonalConstants.EBI_EVENT_TAGS.TEST_LEARN];
                break;
            case PersonalConstants.EBI_EVENTS.OYS_SELECTION_CONFIRMATION:
            case PersonalConstants.EBI_EVENTS.DRIVE_IN_SELECTION_CONFIRMATION:
                this.deleteForServiceOfferings(ebiObj);
                ebiObj[PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB] = claimId;
                ebiObj[PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL] = `${this.getPreviousUrl()}?from=tracker`;
                break;
            case PersonalConstants.EBI_EVENTS.CHANGE_OYS:
            case PersonalConstants.EBI_EVENTS.CHANGE_DRIVE_IN:
                this.deleteForServiceOfferings(ebiObj);
                ebiObj[PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB] = claimId;
                ebiObj[PersonalConstants.EBI_EVENT_TAGS.LINK_SELECTED] = 'change selection';
                ebiObj[PersonalConstants.EBI_EVENT_TAGS.PAGE_NAME] = 'Tracker';
                break;
        }
        return ebiObj;
    }

    sendTrackerLoadEBIEvent(trackerVersion: string): void {
        this.ebiService.fireEBIEvent(PersonalConstants.EBI_EVENTS.LOAD_TRACKER, trackerVersion, 'Tracker', this.buildTrackerDefaultEBIProps());
    }

    sendCoveragesLoadEBI(): void {
        this.ebiService.fireEBIEvent(
            PersonalConstants.EBI_EVENTS.CLAIMS_COVERAGE_SUMMARY,
            'Tracker',
            'Tracker',
            this.buildTrackerDefaultEBIProps()
        );
    }

    sendFAQLoadEBI(): void {
        this.ebiService.fireEBIEvent(
            PersonalConstants.EBI_EVENTS.LOAD_FAQ,
            'Claims-faq',
            'Tracker',
            this.buildTrackerDefaultEBIProps(PersonalConstants.EBI_EVENTS.LOAD_FAQ)
        );
    }

    sendContactsLoadedEBI(): void {
        this.ebiService.fireEBIEvent(
            PersonalConstants.EBI_EVENTS.LOAD_CLAIMS_CONTACTS,
            'Tracker',
            'Tracker',
            this.buildTrackerDefaultEBIProps(PersonalConstants.EBI_EVENTS.LOAD_CLAIMS_CONTACTS)
        );
    }

    sendEmailClaimAssociateEBI(): void {
        this.ebiService.fireEBIEvent(
            PersonalConstants.EBI_EVENTS.CLAIMS_QS_CONFIRMATION_EMAIL_CLICK,
            'Tracker',
            'Tracker',
            this.buildTrackerDefaultEBIProps(PersonalConstants.EBI_EVENTS.CLAIMS_QS_CONFIRMATION_EMAIL_CLICK)
        );
    }

    sendDocSelectionEbi(pageName): void {
        this.ebiService.fireEBIEvent(
            PersonalConstants.EBI_EVENTS.RETRIEVE_DOCUMENT_DETAILS,
            pageName,
            pageName,
            this.buildTrackerDefaultEBIProps(PersonalConstants.EBI_EVENTS.RETRIEVE_DOCUMENT_DETAILS)
        );
    }

    sendOYSConfirmationEBI(pageName: string): void {
        this.ebiService.fireEBIEvent(
            PersonalConstants.EBI_EVENTS.OYS_SELECTION_CONFIRMATION,
            pageName,
            pageName,
            this.buildTrackerDefaultEBIProps(PersonalConstants.EBI_EVENTS.OYS_SELECTION_CONFIRMATION)
        );
    }
    sendChangeOYSEBI(pageName: string): void {
        this.ebiService.fireEBIEvent(
            PersonalConstants.EBI_EVENTS.CHANGE_OYS,
            pageName,
            pageName,
            this.buildTrackerDefaultEBIProps(PersonalConstants.EBI_EVENTS.CHANGE_OYS)
        );
    }
    sendChangeDriveInEBI(pageName: string): void {
        this.ebiService.fireEBIEvent(
            PersonalConstants.EBI_EVENTS.CHANGE_DRIVE_IN,
            pageName,
            pageName,
            this.buildTrackerDefaultEBIProps(PersonalConstants.EBI_EVENTS.CHANGE_DRIVE_IN)
        );
    }
    sendDriveInConfirmationEBI(pageName: string): void {
        this.ebiService.fireEBIEvent(
            PersonalConstants.EBI_EVENTS.DRIVE_IN_SELECTION_CONFIRMATION,
            pageName,
            pageName,
            this.buildTrackerDefaultEBIProps(PersonalConstants.EBI_EVENTS.DRIVE_IN_SELECTION_CONFIRMATION)
        );
    }

    getPreviousUrl(): string {
        const { queryParamMap } = this.activatedRoute.snapshot;
        const previousPage = queryParamMap.has('from') ? queryParamMap.get('from') : 'UNKNOWN';
        const prevPageToPrevURL = ROUTING.QUERY.FROM_URL_MAP;
        return previousPage in prevPageToPrevURL ? prevPageToPrevURL[previousPage] : 'UNKNOWN';
    }

    setEBIClaimsfields(response: any): void {
        const claimDetails = {
            incidentDate: this.datePipe.transform(new Date(response.lossDate), 'yyyy/MM/dd'),
            claimStatus: response.claimState,
            lossCause: response.lossCause,
            claimNumber: response.claimNumber
        };
        this.sessionService.setEBIFields('claimsEBIfields', claimDetails);
    }

    getEBIClaimsfields(): any {
        return this.sessionService.getEBIFields('claimsEBIfields');
    }

    setEBIPolicyfields(response: any): void {
        const nameObj = this.claimsHelperService.getNameObj(response.insured?.displayName);
        const foundPolicyOwnerRole: boolean = this.claimsHelperService.getPolicyOwnerRole(this.sessionService.getPolicyNumber());
        const policyDetails = {
            policyState: response.nw_RateState || 'UNKNOWN',
            policyHolderFirstName: nameObj?.firstName,
            policyHolderLastName: nameObj?.lastName,
            isPolicyOwner: foundPolicyOwnerRole ? 'Yes' : 'No'
        };
        this.sessionService.setEBIFields('policyEBIfields', policyDetails);
    }

    getEBIPolicyfields(): any {
        return this.sessionService.getEBIFields('policyEBIfields');
    }
    deleteForServiceOfferings(ebiObj: any): any {
        delete ebiObj[(PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB + 1)];
        delete ebiObj[(PersonalConstants.EBI_EVENT_TAGS.CLAIM_STATUS + 1)];
        delete ebiObj[(PersonalConstants.EBI_EVENT_TAGS.INCIDENT_DATE + 1)];
        delete ebiObj[(PersonalConstants.EBI_EVENT_TAGS.LOSS_CAUSE + 1)];
        delete ebiObj[PersonalConstants.EBI_EVENT_TAGS.POLICY_OWNER];
        return ebiObj;
    }
}
