<div class="breadcrumb--top"></div>
<div class="inner-container clearfix" id="skip-main-content" role="main">
    <h1 id="heading">Unable to process request</h1>
    <div class="form-content clearfix">
        <bolt-notification>
            We have a claim on file for that date. Call us so we can help at 1-800-421-3535.
        </bolt-notification>
    </div>
    <div class="button-spacing pull-right">
        <bolt-button  (click)="callNationwide()">Go to Nationwide.com</bolt-button>
    </div>
</div>
