const environments = {
    'https://localhost:4200/': 'assets/envConfig/environment.local.json',
    'https://claimsnewapp.nwie.net:4200/': 'assets/envConfig/environment.local.json',
    'https://claimsservicing.nationwide.com/': 'assets/envConfig/environment.prod.json',
    'https://claims.awspubliccloud.nationwide.com/': 'assets/envConfig/environment.prod.json',
    'https://www.claims.t.nationwide.com/': 'assets/envConfig/environment.st.json'
};

function getEnvConfig(): any {
    const absoluteDomain = findEnvAbsoluteDomain() || '';
    const envLocation = environments[absoluteDomain] || 'assets/envConfig/environment.local.json';
    const jsonResponse = getJsonData(absoluteDomain + envLocation);
    const environmentProperties = JSON.parse(jsonResponse);
    try {
        environmentProperties.absoluteDomain = absoluteDomain;
        environmentProperties.tridion['endpoint'] = `${absoluteDomain}tridion`;
    } catch (e) {
        console.log('error loading tridion info', e);
    }
    return environmentProperties;
}

function findEnvAbsoluteDomain(): any {
    const windowURL = window.location.href;
    let absoluteDomain;
    for (const key in environments) {
        if (windowURL.indexOf(key) > -1) {
            absoluteDomain = key;
        }
    }
    return absoluteDomain;
}

function getJsonData(file): string {
    const jsonRequest = new XMLHttpRequest();
    let jsonResponse: string;
    jsonRequest.overrideMimeType('application/json');
    jsonRequest.open('GET', file, false);
    jsonRequest.onreadystatechange = function (): any {
        if (jsonRequest.readyState === 4 && jsonRequest.status === 200) {
            jsonResponse = jsonRequest.responseText;
        }
    };
    jsonRequest.send(null);
    return jsonResponse;
}



export const environment = getEnvConfig();
