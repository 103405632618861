import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FnolService } from '../../shared/services/fnol.service';
import { LocationService } from '../../shared/services/location.service';
import { AuthorizationService } from '.././services/authorization.service';
import { ClaimsDashboardService } from '.././services/claims-dashboard.service';
import { ClaimsHelperService } from '.././services/claims-helper.service';
import { ClaimsMapperService } from '.././services/claims-mapper.service';
import { PaymentService } from '.././services/claims-payments.service';
import { CloudLoggerService } from '.././services/cloud-logger.service';
import { EBIService } from '.././services/ebi.service';
import { ErrorDataService } from '.././services/error-data.service';
import { InternetRegistrationService } from '.././services/internet-registration.service';
import { LoggerService } from '.././services/logger.service';
import { NwInputErrorService } from '.././services/nw-input-error.service';
import { SaveExitService } from '.././services/save-exit.service';
import { SessionService } from '.././services/session.service';
import { TokenDataService } from '.././services/token-data.service';
import { UserInfoService } from '.././services/user-info.service';
import { UtilService } from '.././services/util.service';
import { MfaService } from './mfa.service';
@NgModule({
    imports: [CommonModule],
    providers: [
        AuthorizationService,
        ClaimsDashboardService,
        ClaimsHelperService,
        ClaimsMapperService,
        TokenDataService,
        FnolService,
        UserInfoService,
        PaymentService,
        CloudLoggerService,
        EBIService,
        ErrorDataService,
        LocationService,
        InternetRegistrationService,
        LoggerService,
        { provide: 'logger', useClass: LoggerService },
        SessionService,
        MfaService,
        UtilService,
        NwInputErrorService,
        SaveExitService
    ]
})

export class SharedServicesModule { }
