<div class="breadcrumb--top"></div>
<main>
    <div class="inner-container clearfix" id="skip-main-content" role="main" *ngIf="showPage">
        <div class="form-content clearfix">
            <div id="urlSurroundingContainer" class="media alert alert-warning">
            <div class="media-left">
                <div class="nw-icon-error" id="urlContainer"></div>
            </div>
            <div class="media-body" id="url">
                <h4>STOP! You are navigating to a PRODUCTION environment.</h4>
                <div class="urldisplay">{{url}}</div>
                <div>The Continue link below will take you to the above location but please do not continue to file or alter a claim in any way.</div>
            </div>
            </div>
            <bolt-button class="claims-button" (click)="navigate()" id="prodLink">Continue to <b>PRODUCTION</b> environment</bolt-button>
        </div>
    </div>
</main>
