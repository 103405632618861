import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ClaimsHelperService } from './claims-helper.service';
import { HttpService } from './http.service';
import { HttpHeaderService } from './httpheader.service';
import { LoggerService } from './logger.service';

@Injectable()
export class MfaService {
    contactId: any;
    contactPointId: string;

    constructor (
        private httpHeaderService: HttpHeaderService,
        private logger: LoggerService,
        private httpService: HttpService,
        private claimsHelperService: ClaimsHelperService
    ) {
    }

    deviceAnalyze(deviceInfo: any): Observable<any> {
        this.contactId = this.claimsHelperService.getClaimantContactId();
        const requestObj = {
            name: 'deviceAnalyze()',
            body: deviceInfo
        };
        const uri = `analyze/partytype/claimant/${this.contactId}/devices`;
        const baseUrl = environment.API_ENVIRONMENT + environment.mfaAPIs.mfalogin;
        return this.invokeObservablePostService(uri, baseUrl, requestObj);
    }

    getContactPoints(): Observable<any> {
        this.contactId = this.claimsHelperService.getClaimantContactId();
        const uri = `challenges/claimant/${this.contactId}/contact-points`;
        const baseUrl = environment.API_ENVIRONMENT + environment.mfaAPIs.customerOTP;
        return this.invokeGetService(uri, baseUrl, 'getContactPoints()');
    }

    sendOTP(deviceInfo: any, contactPointId: any): Observable<any> {
        this.contactId = this.claimsHelperService.getClaimantContactId();
        this.contactPointId = contactPointId;
        const requestObj = {
            name: 'sendOTP()',
            body: deviceInfo
        };
        const uri = `challenges/${this.contactId}/otp/${this.contactPointId}`;
        const baseUrl = environment.API_ENVIRONMENT + environment.mfaAPIs.customerOTP;
        return this.invokeObservablePostService(uri, baseUrl, requestObj);
    }

    verifyOTP(deviceInfo: any): Observable<any> {
        this.contactId = this.claimsHelperService.getClaimantContactId();
        const requestObj = {
            name: 'verifyOTP()',
            body: deviceInfo
        };
        const uri = `authorize/partytype/claimant/${this.contactId}/otp`;
        const baseUrl = environment.API_ENVIRONMENT + environment.mfaAPIs.mfalogin;
        return this.invokeObservablePostService(uri, baseUrl, requestObj);
    }

    rememberDevice(deviceInfo: any): Observable<any> {
        this.contactId = this.claimsHelperService.getClaimantContactId();
        const requestObj = {
            name: 'rememberDevice()',
            body: deviceInfo
        };
        const uri = `partytype/claimant/${this.contactId}/devices`;
        const baseUrl = environment.API_ENVIRONMENT + environment.mfaAPIs.mfalogin;
        return this.invokeObservablePostService(uri, baseUrl, requestObj);
    }

    invokeGetService(uri: any, baseUrl: any, name: any): Observable<any> {
        baseUrl = this.getErrorScenarioBaseUrl(name, baseUrl);
        const headers = this.httpHeaderService.getSafeliteMFAServiceHeaders();
        const request = {
            uri: uri,
            type: '',
            requestPayload: {},
            baseUrl: baseUrl,
            GUID: '',
            headers: headers
        };
        const response$ = this.httpService.invokeHttpGet(request);
        return response$.pipe(
            map((response: any) => response),
            catchError((error) => {
                this.logger.error(`MfaService: ERROR in MfaService.${
                     name} call:${JSON.stringify(error)}`, error);
                return error;
            }));
    }

    invokeObservablePostService(uri: any, baseUrl: any, requestObj: any): Observable<any> {
        baseUrl = this.getErrorScenarioBaseUrl(requestObj.name, baseUrl);
        const headers = this.httpHeaderService.getSafeliteMFAServiceHeaders();
        const request = {
            uri: uri,
            requestPayload: requestObj.body,
            baseUrl: baseUrl,
            headers: headers
        };
        const response$ = this.httpService.invokeHttpPost(request);
        return response$.pipe(
            map((response: any) => {
                this.logger.info(`MfaService: Successful response in MfaService.${JSON.stringify(response)}`);
                return response;
            },
                catchError((error) => {
                    this.logger.error(`MfaService: ERROR in MfaService.${
                         requestObj.name} call:${JSON.stringify(error)}`, error);
                    return error;
                })));
    }

    invokeObservablePostEUAService(baseUrl: any, uri: any, urlEncodedParams: any): Observable<any> {
        const headers = this.httpHeaderService.getEUAServiceHeaders();
        const url = baseUrl;
        const request = {
            requestPayload: urlEncodedParams,
            baseUrl: url,
            uri: uri,
            headers: headers
        };
        const response$ = this.httpService.invokeHttpPost(request);
        return response$.pipe(
            map((response: any) => {
                this.logger.info(`EUARefreshService: Successful response in EUARefreshService.${JSON.stringify(response)}`);
                return response;
            },
                catchError((error) => {
                    this.logger.error(`EUARefreshService: ERROR in EUARefreshService.${
                         error}`);
                    return error;
                })));
    }

    getErrorScenarioBaseUrl(serviceName: any, baseUrl: any): any {
        let configuredUrl = baseUrl;
        serviceName = serviceName.replace('()', '');

        const errorList = {
            downServices: {
                'deviceAnalyze': false,
                'getContactPoints': false,
                'verifyOTP': false,
                'rememberDevice': false
            }
        };

        if (errorList.downServices.hasOwnProperty(serviceName) && errorList.downServices[serviceName] === true) {
            this.logger.info(`getErrorScenarioBaseUrl: ${serviceName}() is triggered to error`);
            configuredUrl = configuredUrl.replace('/claimant/', '/claimant/z');
        }

        return configuredUrl;
    }
}

