<div class="breadcrumb--top"></div>
<div class="inner-container clearfix" id="skip-main-content" role="main">
    <div class="bolt-container">
        <h1 *ngIf="!noPolicy" id="heading">Unable to process request</h1>
        <h1 *ngIf="noPolicy"id="heading">Call us</h1>
        <div class="bolt-space-bottom-lg">
            <bolt-notification>
                {{error.message}}
            </bolt-notification>
        </div>
        <div class="button-spacing pull-right">
            <bolt-button (click)="callNationwide()">Go to Nationwide.com</bolt-button>
        </div>
    </div>
</div>
