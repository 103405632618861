<bolt-modal id="contentModal"
    heading="Before you go"
    #cancelButtonModal
    (bolt-modal-primary-button-click)="sendExit()"
    (bolt-modal-secondary-button-click)="closeModalPopup()"
    primarybutton="Send & Exit"
    secondarybutton="Continue Claim">
    <div class="bolt-subheading-md bolt-space-bottom-xl">Your claim doesn’t have to be perfect to submit it. You can
        finish up now & fix any mistakes with us later</div>
    <div class="bolt-space-bottom-xl">
        <bolt-button size="sm"
            href="https://www.nationwide.com/personal/member-services/nationwide-faq"
            target="_blank">Let the FAQs help</bolt-button>
    </div>
    <div class="bolt-space-bottom-md">
        <form [formGroup]="cancelReasonsForm">
                <bolt-radio-group ngDefaultControl
                    formControlName="cancelReason"
                    label="But if you have to go, tell us why"
                    name="cancelReason">
                    <bolt-radio id={{displayReason?.value}}
                        value={{displayReason?.value}}
                        *ngFor="let displayReason of displayOptions">
                        {{displayReason.description}}
                    </bolt-radio>
                </bolt-radio-group>
                <bolt-textarea   *ngIf="cancelReasonsForm.controls.cancelReason.value === 'other'"
                    ngDefaultControl
                    name="otherReason"
                    formControlName="otherReason"
                    label="Reason"
                    showcount
                    id="cancelButtonPopup_otherTxtField">
                </bolt-textarea>
        </form>
    </div>
</bolt-modal>
