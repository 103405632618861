<nav aria-label="breadcrumbs">
    <is-bread-crumbs
        class="breadCrumbs"
        [homeIconLink]="homeIconLink"
        [breadCrumbs]="breadCrumbs"
        [currentPage]="currentPage"
        position="top"
        [isClaims]="true">
    </is-bread-crumbs>
</nav>
<main>
    <div class="bolt-container">
        <find-repair-shop id="findRepairShop" [clientId]="clientId"
        [env]="env" (analytics)="handleAnalytics($event)" (onError)="handleError('Find repair shop', $event)"></find-repair-shop>
    </div>
</main>
