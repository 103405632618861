import { PersonalConstants } from '../common/personalConstants';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from '../shared/services/logger.service';


@Injectable()

export class AuthTokenGuard {
    constructor(private router: Router, private logger: LoggerService) { }

    canActivate(): boolean {
        if (window.sessionStorage.getItem('tokenDetails')) {
            this.logger.debug('AuthTokenGuard passed.');
            return true;
        }
        this.logger.error('AuthTokenGuard failed. Auth token not present in user session storage. Redirecting to search page.');
        // this.router.navigate([PersonalConstants.URLS.quickPaySearch]);
        return false;
    }
}
