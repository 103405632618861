<nav aria-label="breadcrumbs">
    <is-bread-crumbs
            class="breadCrumbs"
            [homeIconLink]="homeIconLink"
            [breadCrumbs]="breadCrumbs"
            [currentPage]="currentPage"
            position="top"
            [isClaims]="true">
        </is-bread-crumbs>
</nav>
<main>
    <div class="tracker-main-container">
        <div class="tracker-mobile" *ngIf="showSuccessNotification">
            <bolt-notification type="success">
                Thank you. We will contact you if there have been any changes to
                your claim.
            </bolt-notification>
        </div>
        <div class="tracker-mobile" *ngIf="showDynamicQuestion">
            <bolt-notification type="warning">
                We need your help to get your claim completed! Please
                <a (click)="goToDynamicQuestions()">answer these questions</a>
                so we can keep your claim moving and get you back to normal.
            </bolt-notification>
        </div>
        <div class="tracker-left-block">
            <div class="tracker-margin-1rem claim-summary-wrapper">
                <claim-summary
                    (analytics)="handleAnalytics($event)"
                    (userClick)="handleClick($event)"
                    (data)="tellUsMoreVisibilityHandler($event)"
                    (onError)="handleComponentError('Claim Summary', $event)"
                    [clientId]="clientId"
                    [accessToken]="accessToken"
                    [env]="env"
                    [claimNumber]="claimNumber"
                    [serverId]="serverId"
                    [isThirdParty]="isThirdParty" [contactId]="contactPublicId"
                ></claim-summary>
            </div>
            <div class="tracker-margin-1rem">
                <contact-us
                    class="tracker-desktop"
                    (analytics)="handleAnalytics($event)"
                    (userClick)="handleClick($event)"
                    (onError)="handleComponentError('Contact us', $event)"
                    [clientId]="clientId"
                    [accessToken]="accessToken"
                    [env]="env"
                    [claimNumber]="claimNumber"
                    [serverId]="serverId"
                ></contact-us>
            </div>
        </div>
        <div class="tracker-right-block tracker-header-top-margin">
            <div class="tracker-desktop" *ngIf="showSuccessNotification">
                <bolt-notification type="success">
                    Thank you. We will contact you if there have been any
                    changes to your claim.
                </bolt-notification>
            </div>
            <div class="tracker-desktop" *ngIf="showDynamicQuestion">
                <bolt-notification type="warning">
                    We need your help to get your claim completed! Please
                    <a (click)="goToDynamicQuestions()"
                        >answer these questions</a
                    >
                    so we can keep your claim moving and get you back to normal.
                </bolt-notification>
            </div>
            <div class="tracker-margin-left claim-number-header-wrapper">
                <claim-number-header
                    [claimNumber]="claimNumber"
                ></claim-number-header>
                <toggle
                    *ngIf="showToggle"
                    (userClick)="handleClick($event)"
                ></toggle>
            </div>
            <member-timeline
                *ngIf="contactPublicId"
                (analytics)="handleAnalytics($event)"
                (userClick)="handleClick($event)"
                (data)="handleTimelineData($event)"
                (onError)="handleComponentError('Timeline', $event)"
                [clientId]="clientId"
                [accessToken]="accessToken"
                [env]="env"
                [contactId]="contactPublicId"
                [claimNumber]="claimNumber"
                [showAll]="showAll"
                [serverId]="serverId"
                (selectedCategoryEvent)="handleClick($event)"
            ></member-timeline>
            <div class="tracker-margin-top">
                <ng-container>
                    <report-accordion
                        *ngIf="
                            selectedCategory.categoryName === 'REPORT' &&
                            selectedCategory.isSelected
                        "
                        (analytics)="handleAnalytics($event)"
                        (userClick)="handleClick($event)"
                        (onError)="
                            handleComponentError('Report Accordion', $event)
                        "
                        [clientId]="clientId"
                        [accessToken]="accessToken"
                        [env]="env"
                        [claimNumber]="claimNumber"
                        [serverId]="serverId"
                        [useCaching]="true"
                        [isThirdParty]="isThirdParty" [contactId]="contactPublicId"
                    ></report-accordion>
                    <payout-accordion
                        (analytics)="handleAnalytics($event)"
                        (userClick)="handleClick($event)"
                        (onError)="
                            handleComponentError('Payout Accordion', $event)
                        "
                        *ngIf="
                            selectedCategory.categoryName === 'PAYOUT' &&
                            selectedCategory.isSelected
                        "
                        [clientId]="clientId"
                        [accessToken]="accessToken"
                        [env]="env"
                        [claimNumber]="claimNumber"
                        [serverId]="serverId"
                        [useCaching]="true"
                        [isThirdParty]="isThirdParty" [contactId]="contactPublicId"
                    ></payout-accordion>

                    <services-accordion
                        (analytics)="handleAnalytics($event)"
                        (userClick)="handleClick($event)"
                        (onError)="
                            handleComponentError('Services Accordion', $event)
                        "
                        *ngIf="
                            selectedCategory.categoryName === 'SERVICES' &&
                            selectedCategory.isSelected
                        "
                        [clientId]="clientId"
                        [accessToken]="accessToken"
                        [env]="env"
                        [claimNumber]="claimNumber"
                        [serverId]="serverId"
                        [useCaching]="true"
                        [isThirdParty]="isThirdParty" [contactId]="contactPublicId"
                    ></services-accordion>

                    <close-accordion
                        (analytics)="handleAnalytics($event)"
                        (userClick)="handleClick($event)"
                        (onError)="
                            handleComponentError('Close Accordion', $event)
                        "
                        *ngIf="
                            selectedCategory.categoryName === 'CLOSE' &&
                            selectedCategory.isSelected
                        "
                        [clientId]="clientId"
                        [accessToken]="accessToken"
                        [env]="env"
                        [claimNumber]="claimNumber"
                        [serverId]="serverId"
                        [useCaching]="true"
                        [isThirdParty]="isThirdParty" [contactId]="contactPublicId"
                    ></close-accordion>
                    <div class="repair-accordion-wrapper">
                        <repairs-accordion
                            *ngIf="
                                selectedCategory.categoryName === 'REPAIRS' &&
                                selectedCategory.isSelected
                            "
                            (data)="handleServiceOffering($event)"
                            (analytics)="handleAnalytics($event)"
                            (userClick)="handleRepairsClick($event)"
                            (onError)="
                                handleComponentError(
                                    'Repairs Accordion',
                                    $event
                                )
                            "
                            [clientId]="clientId"
                            [accessToken]="accessToken"
                            [env]="env"
                            [claimNumber]="claimNumber"
                            [serverId]="serverId"
                            [useCaching]="true"
                            [isThirdParty]="isThirdParty" [contactId]="contactPublicId"
                        ></repairs-accordion>
                    </div>
                </ng-container>
            </div>
            <div class="tracker-margin-left tracker-margin-top">
                <interactive-faqs
                    *ngIf="contactPublicId"
                    (analytics)="handleAnalytics($event)"
                    (userClick)="handleClick($event)"
                    (onError)="handleComponentError('Interactive FAQs', $event)"
                    [clientId]="clientId"
                    [accessToken]="accessToken"
                    [env]="env"
                    [contactId]="contactPublicId"
                    [claimNumber]="claimNumber"
                    [serverId]="serverId"
                    [selectedCategory]="selectedCategory.categoryName"
                    [useCaching]="true"
                ></interactive-faqs>
            </div>
        </div>
        <div class="tracker-mobile tracker-margin-1rem">
            <contact-us
                (analytics)="handleAnalytics($event)"
                (userClick)="handleClick($event)"
                (onError)="handleComponentError('Contact us', $event)"
                [clientId]="clientId"
                [accessToken]="accessToken"
                [env]="env"
                [claimNumber]="claimNumber"
                [serverId]="serverId"
            ></contact-us>
        </div>
    </div>
    <div class="tracker-fraudlanguage-message">
        <p
            [ngStyle]="{
                'font-family': fraudLanguageFont,
                'font-size': fraudLanguageSize
            }"
        >
            {{ claimFraudLanguage }}
        </p>
    </div>
    <is-bread-crumbs class="breadCrumbs"
        [homeIconLink]="homeIconLink"
        [breadCrumbs]="breadCrumbs"
        [currentPage]="currentPage"
        position="bottom"
        [isClaims]="true">
    </is-bread-crumbs>

    <bolt-modal
        #boltModalConfirm
        id="confirmModal"
        type="confirm"
        [heading]="confirmationHeader"
        primarybutton="Continue"
    >
        <ng-container *ngIf="serviceSelected === 'oys'">
            <p>
                <strong
                    >We've notified the facility and they'll contact you during
                    business hours to schedule an appointment to estimate
                    damages. If you like, you may contact them.</strong
                >
            </p>
            <p>
                If you decide later that you want to select a different
                facility, you may make the changes in the "Repairs" section, or
                contact your claim associate.
            </p>
            <p *ngIf="hasTow">
                You'll also receive a call from a third party towing service to
                schedule a time to move your vehicle. If your vehicle is located
                more than 100 miles away, a claim associate may follow up with
                you.
            </p>
        </ng-container>
        <ng-container *ngIf="serviceSelected === 'driveIn'">
            <p>
                <strong
                    >You've scheduled an appointment to appraise your damages.
                    We'll send you a confirmation email.</strong
                >
            </p>
            <p>
                If you decide later that you want to change the date, time or
                location of your appointment, you may reschedule it in the
                "Repairs" section, or contact your claim associate.
            </p>
        </ng-container>
    </bolt-modal>
</main>
