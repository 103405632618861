import { Injectable } from '@angular/core';
import { EfnolCacheModel, ServicesContainer } from '../models/efnol-cache-model';
import { CryptService } from './crypt.service';
import { EfnolSessionService } from './efnol-session.service';

@Injectable({
    providedIn: 'root'
})
export class EfnolCacheService {
    constructor (
        private cryptService: CryptService,
        private efnolSessionService: EfnolSessionService
    ) { }

    setInitialFnolModel(): void {
        this.setSessionValue({
            basicInfo: {},
            accidentDetails: {},
            services: {}
        });
    }

    getSessionValue(): any {
        return this.cryptService.getSessionItemJSON('efnolCache');
    }

    setSessionValue(sessionObject: EfnolCacheModel): any {
        this.cryptService.setSessonItemJSON('efnolCache', sessionObject);
    }

    getBasicInfoValue(modelName: any): any {
        try {
            return this.getSessionValue().basicInfo[modelName];
        } catch {
            return null;
        }
    }
    setBasicInfoValue(modelName: any, newValue: any): void {
        const efnolCache: EfnolCacheModel = this.getSessionValue();
        efnolCache.basicInfo[modelName] = newValue;
        this.setSessionValue(efnolCache);
    }

    getAccidentDetailsValue(modelName: any): any {
        try {
            if (modelName === 'accidentType') {
                return this.getAccidentTypeValue();
            }
            return this.getSessionValue().accidentDetails[modelName];
        } catch {
            return null;
        }
    }
    getAccidentTypeValue(): string {
        const type = this.getSessionValue().accidentDetails['accidentType'];
        if (type === 'accident') {
                return 'Accident';
        } else {
                return type;
        }
    }
    setAccidentDetailsValue(modelName: any, newValue: any): void {
        const efnolCache: EfnolCacheModel = this.getSessionValue();
        efnolCache.accidentDetails[modelName] = newValue;
        this.setSessionValue(efnolCache);
    }

    getServicesValue(modelName: any): any {
        try {
            return this.getSessionValue().services[modelName];
        } catch {
            return null;
        }
    }
    setServicesValue(modelName: any, newValue: any): void {
        const efnolCache: EfnolCacheModel = this.getSessionValue();
        efnolCache.services[modelName] = newValue;
        this.setSessionValue(efnolCache);
    }


    setPhotoProcessServiceValue(modelName: any, newValue: any): void {
        const efnolCache: ServicesContainer = this.efnolSessionService.getPhotoProcessSessionValue();
        efnolCache[modelName] = newValue;
        this.efnolSessionService.setPhotoProcessSessionValue(efnolCache);
    }

    getPhotoProcessServiceValue(modelName: any): any {
        try {
            return this.efnolSessionService.getPhotoProcessSessionValue()[modelName];
        } catch {
            return null;
        }
    }
}
