import { Injectable } from '@angular/core';
import { ClaimsLogger, CloudLoggerService } from './cloud-logger.service';
@Injectable()
export class LoggerService {
    private loggers: { logger: ClaimsLogger, enabled: () => boolean }[];

    constructor (
        private cloudLoggerService: CloudLoggerService
    ) {
        this.loggers = [
            {
                logger: cloudLoggerService,
                enabled: (): any => true
            }
        ];
    }

    setModuleName(module: string): void {
        this.forEachLogger((logger) => logger.setModuleName(module));
    }

    setMember(userType: string): void {
        this.forEachLogger((logger) => logger.setMember(userType));
    }

    setAccessCode(accessCode: string): void {
        this.forEachLogger((logger) => logger.setAccessCode(accessCode));
    }

    setPolicyNumber(policyNumber: string): void {
        this.forEachLogger((logger) => logger.setPolicyNumber(policyNumber));
    }

    setClaimNumber(claimNumber: string): void {
        this.forEachLogger((logger) => logger.setClaimNumber(claimNumber));
    }

    setLossCause(lossCause: any): void {
        this.forEachLogger((logger) => logger.setLossCause(lossCause));
    }

    setServiceSelection(serviceSelection: any): void {
        this.forEachLogger((logger) => logger.setServiceSelection(serviceSelection));
    }

    setPageName(pageName: string): void {
        this.forEachLogger((logger) => logger.setPageName(`Claim-Modernization: On Load of Page ${pageName}`));
    }

    setComponentName(componentName: any): void {
        this.forEachLogger((logger) => logger.setComponentName(componentName));
    }

    info(message: string, jsonObject?: any, additionalAttributes?: any): void {
        this.forEachLogger((logger) => {
            logger.setInfo(message, jsonObject, additionalAttributes);
        });
    }

    error(message: string, jsonObject?: any, additionalAttributes?: any): void {
        this.forEachLogger((logger) => {
            logger.setError(message, jsonObject, additionalAttributes);
        });
    }

    warn(message: string, jsonObject?: any, additionalAttributes?: any): void {
        this.forEachLogger((logger) => {
            logger.setWarn(message, jsonObject, additionalAttributes);
        });
    }

    debug(message: string, jsonObject?: any, additionalAttributes?: any): void {
        this.forEachLogger((logger) => {
            logger.setDebug(message, jsonObject, additionalAttributes);
        });
    }

    trace(message: string, jsonObject?: any, additionalAttributes?: any): void {
        this.forEachLogger((logger) => {
            logger.setTrace(message, jsonObject, additionalAttributes);
        });
    }

    logCurrentContents(): void {
        this.forEachLogger((logger) => {
            if (logger.loggedStatements.length) {
                logger.logCurrentContents();
            }
        });
    }

    buildLoggerRequest(options: any): any {
        return this.cloudLoggerService.buildLoggerRequest(options);
    }

    buildCloudLoggerRequest(options: any): any {
        return this.cloudLoggerService.buildLoggerRequest(options);
    }

    getAuthToken(): string {
        return this.cloudLoggerService.getAuthToken();
    }

    getUser(): string {
        return this.cloudLoggerService.getUser();
    }

    getSessionId(): string {
        return this.cloudLoggerService.getSessionId();
    }

    getUniqueKey(): string {
        return this.cloudLoggerService.getUniqueKey();
    }

    private forEachLogger(callback: (logger: ClaimsLogger) => void): void {
        this.loggers.forEach(({ logger, enabled }) => {
            if (enabled()) {
                callback(logger);
            }
        });
    }

    getStartLoadTimer(): number {
        return this.cloudLoggerService.getStartLoadTimer();
    }

    getStopLoadTimer(): number {
        return this.cloudLoggerService.getStopLoadTimer();
    }

    getDeviceInfo(): any {
        return this.cloudLoggerService.getDeviceInfo();
    }
}
