import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthOptions, EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { PersonalConstants } from 'app/common/personalConstants';
import { SessionService } from 'app/shared/services/session.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})

export class ClaimsOidcAuthService {
    constructor (
        private oidcSecurityService: OidcSecurityService,
        private router: Router,
        private sessionService: SessionService,
        private oidcEventService: PublicEventsService
    ) {
        let configId;
        if (this.sessionService.getUserType().indexOf('unregistered') > -1) {
            configId = this.sessionService.getSessionItemDecrypted('fromMFA') === 'true' ? PersonalConstants.LOGINTYPE.UNREGISTEREDEXPANDUSER : PersonalConstants.LOGINTYPE.UNREGISTERED;
        } else {
            configId = PersonalConstants.LOGINTYPE.REGISTERED;
        }

        this.oidcEventService
            .registerForEvents()
            .pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
            .subscribe((eventResult) => {
                this.oidcSecurityService.getAuthenticationResult(configId).subscribe((result) => {
                    if (eventResult.value?.isAuthenticated) {
                        this.setAccessToken(result?.access_token);
                        this.setIdToken(this.parseJwt(result?.id_token));
                        this.setAuthStatus(true, configId === PersonalConstants.LOGINTYPE.REGISTERED);
                        this.setExpiration(PersonalConstants.OIDCTOKENTIME.TOKENEXPIRYTIME);
                    }
                });
            });

        this.oidcEventService
            .registerForEvents()
            .pipe(filter((notification) => notification.type === EventTypes.UserDataChanged))
            .subscribe((userinfo) => {
                if (configId === PersonalConstants.LOGINTYPE.REGISTERED && userinfo?.value?.userData) this.processUserInfo(userinfo.value.userData);
            });
    }

    private accessToken$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private idToken$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private userInfo$: BehaviorSubject<string> = new BehaviorSubject<any>('');
    private authStatus$: BehaviorSubject<any> = new BehaviorSubject<any>({
        isAuthorized: false, isRegistered: false
    });

    setAccessToken(accessToken): void {
        this.accessToken$.next(accessToken);
    }

    getAccessToken(): Observable<string> {
        if (this.sessionService.getSessionItemDecrypted(PersonalConstants.LOGIN_ENTRY_POINTS.IAMBYPASS) === 'true') {
            this.setAccessToken(sessionStorage.getItem('AccessToken'));
        }
        return this.accessToken$.asObservable();
    }

    setAuthStatus(isAuthorized: boolean, isRegistered: boolean): void {
        const authStatus = {
            isAuthorized,
            isRegistered
        };
        sessionStorage.setItem('authenticatedFlag', JSON.stringify(isRegistered && isAuthorized));
        this.authStatus$.next(authStatus);
    }

    getAuthStatus(): Observable<any> {
        if (this.sessionService.getSessionItemDecrypted(PersonalConstants.LOGIN_ENTRY_POINTS.IAMBYPASS) === 'true') {
            this.setAuthStatus(true, true);
        }
        return this.authStatus$.asObservable();
    }

    setIdToken(idToken): void {
        this.idToken$.next(idToken);
    }

    getIdToken(): Observable<any> {
        if (this.sessionService.getSessionItemDecrypted(PersonalConstants.LOGIN_ENTRY_POINTS.IAMBYPASS) === 'true') {
            this.setIdToken(sessionStorage.getItem('id_token'));
        }
        return this.idToken$.asObservable();
    }

    checkOidcAuthStatus(configId?: string): Observable<any> {
        return this.oidcSecurityService.checkAuth(null, configId);
    }

    login(configId: string, authOptions: AuthOptions = null,): void {
        this.oidcSecurityService.authorize(configId, authOptions);
    }

    logOffLocal(): void {
        this.sanitizeSession();
        this.oidcSecurityService.logoffLocalMultiple();
    }

    logOffRegisteredUser(): void {
        this.oidcSecurityService.logoff(PersonalConstants.LOGINTYPE.REGISTERED);
    }

    logOffUnRegisteredUser(): void {
        this.sanitizeSession();
        this.oidcSecurityService.logoff(PersonalConstants.LOGINTYPE.UNREGISTERED);
    }

    forceRefresh(configId: string, customParams: any = null,): Observable<any> {
        return this.oidcSecurityService.forceRefreshSession(customParams, configId);
    }

    getCustomAuthConfig(): any {
        return this.sessionService.getSessionItemDecrypted('AUTH_CONFIG');
    }

    saveCustomAuthConfig(authConfig): void {
        this.sessionService.setSessionItem('AUTH_CONFIG', authConfig);
    }

    parseJwt(token): any {
        let parsedJwt;
        if (!!token) {
            const base64URL = token.split('.')[1];
            const base64 = base64URL.replace('-', '+').replace('_', '/');
            parsedJwt = JSON.parse(window.atob(base64));
        }
        return parsedJwt;
    }

    sanitizeSession(): void {
        const itemsToRemoveList = [
            'id_token',
            'AccessToken',
            'ACCESS_TOKEN_EXPIRATION',
            'AUTH_CONFIG',
            'policyNumber',
            'efnolCache',
            'userId',
            'authenticatedFlag',
            'sessionId',
            'nw-policyNumber'
        ];
        itemsToRemoveList.forEach((item) => {
            if (sessionStorage.getItem(item)) sessionStorage.removeItem(item);
        });
    }

    clearSession(): void {
        sessionStorage.clear();
    }

    getLineOfBusiness(jwt): any {
        let lineOfBusiness;
        if (jwt?.agreementNumber) {
            const inputPolicyNumber = jwt.agreementNumber;
            const agreements = jwt.masked_agreements || jwt.agreements;
            if (agreements && agreements.length > 0) {
                for (const agreement of agreements) {
                    if (agreement.agreement_number && agreement.agreement_number.slice(-3) === inputPolicyNumber.slice(-3)) {
                        lineOfBusiness = agreement.line_of_business;
                        break;
                    }
                }
            }
        }
        return lineOfBusiness;
    }

    getProductType(jwt): any {
        let productType;
        if (jwt?.agreementNumber) {
            const inputPolicyNumber = jwt.agreementNumber;
            const agreements = jwt.masked_agreements || jwt.agreements;
            if (agreements && agreements.length > 0) {
                for (const agreement of agreements) {
                    if (agreement.agreement_number && agreement.agreement_number.slice(-3) === inputPolicyNumber.slice(-3)) {
                        productType = agreement.product_type;
                        break;
                    }
                }
            }
        }
        return productType;
    }

    setExpiration(expiresIn: string): void {
        const expiresInMilliseconds = parseInt(expiresIn, 10) * 1000;
        const nowMilliseconds = new Date().getTime();
        const expirationMilliseconds = nowMilliseconds + expiresInMilliseconds;
        window.sessionStorage.setItem('ACCESS_TOKEN_EXPIRATION', expirationMilliseconds.toString(10));
    }


    setUserInfo(userInfo): void {
        this.userInfo$.next(userInfo);
    }

    getUserInfo(): Observable<any> {
        return this.userInfo$.asObservable();
    }

    processUserInfo(userData: any): void {
        const userObj = {
            firstName: userData?.given_name,
            lastName: userData?.family_name,
            userId: userData?.userId,
            email: userData?.email
        };
        window.sessionStorage.setItem('userId', userData?.userId);
        this.setUserInfo(userObj);
    }
    checkActiveSessionOnExternalRoute(loginState: string, registrationType?: string): boolean | Observable<boolean> {
        const userType = registrationType ? registrationType : this.sessionService.getUserType();
        let authStatus = false;
        const status = this.authStatus$.getValue();
        if (this.sessionService.getSessionItemDecrypted(PersonalConstants.LOGIN_ENTRY_POINTS.IAMBYPASS) === 'true' || (this.sessionService.getSessionItemDecrypted('fromMFA') === 'true' && this.sessionService.getSessionItemDecrypted('MFAToken').access_token)) {
            status['isAuthorized'] = true;
        }
        authStatus = status.isAuthorized;
        if (!status.isAuthorized) {
            if (loginState) {
                if (userType.indexOf('unregistered') >= 0) {
                    const authConfig = JSON.parse(sessionStorage.getItem('AUTH_CONFIG'));
                    const authOptions: AuthOptions = { customParams: authConfig };
                    sessionStorage.setItem('loginState', loginState);
                    this.login(PersonalConstants.LOGINTYPE.UNREGISTERED, authOptions);
                } else {
                    sessionStorage.setItem('loginState', loginState);
                    this.login(PersonalConstants.LOGINTYPE.REGISTERED);
                }
            } else {
                this.router.navigate([PersonalConstants.URLS.error500]);
            }
        }
        return authStatus;
    }

    getMFAUserType(): string {
        let userType = '';
        switch (sessionStorage.getItem('user')) {
            case 'mfa-member':
                userType = 'registered';
                break;
            case 'mfa-non-member':
                userType = 'unregistered-non-member';
                break;
        }
        return userType;
    }
}


