import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ClaimsHelperService } from './claims-helper.service';
import { EnvironmentpickerService } from './environmentpicker.service';
import { HttpService } from './http.service';
import { HttpHeaderService } from './httpheader.service';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';

@Injectable()
export class ClaimsDashboardService {
    claimDetails;
    vehicleIncidents;
    vehicleDetails;
    driverDetails;
    userType;

    constructor (
        private logger: LoggerService,
        private claimsHelperService: ClaimsHelperService,
        private sessionService: SessionService,
        private httpService: HttpService,
        private httpHeaderService: HttpHeaderService,
        private environmentPicker: EnvironmentpickerService
    ) {
            this.userType = this.sessionService.getUserType();
    }

    getContentAccessToken(): Promise<any> {
        const claimId = this.getClaimId();
        const request = {
            uri: `claims/${claimId}/access-token`,
            type: '',
            requestPayload: {},
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.claimsContentManagement,
            GUID: '',
            headers: this.httpHeaderService.getNWClaimServiceHeaders(environment.CLAIMS_DEV)
        };

        return new Promise((resolve: (value: Response) => void, reject) => {
            this.httpService.invokeHttpPost(request, false).subscribe(
                (response) => {
                    this.logger.info(`claimsDashboardService: successful getContentAccessToken call:${JSON.stringify(response)}`);
                    resolve(response);
                },
                (error: any) => {
                    this.logger.error(`claimsDashboardService: ERROR in getContentAccessToken call:${JSON.stringify(error)}`);
                    reject(error);
                }
            );
        });
    }

    getContentFiles(): any {
        const claimId = this.getClaimId();
        const request = {
            uri: `claims/${claimId}/files`,
            type: '',
            requestPayload: {},
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.claimsContentManagement,
            GUID: '',
            headers: this.httpHeaderService.getNWClaimServiceHeaders(environment.CLAIMS_DEV)
        };

        return new Promise((resolve: (value: Response) => void, reject) => {
            this.httpService.invokeHttpGet(request).subscribe(
                (response) => {
                    this.logger.info(`claimsDashboardService: successful getContentFiles call:${JSON.stringify(response)}`);
                    resolve(response);
                },
                (error: any) => {
                    this.logger.error(`claimsDashboardService: ERROR in getContentFiles call:${JSON.stringify(error)}`);
                    reject(error);
                }
            );
        });
    }

    getClaimAccessCode(): any {
        if ((this.sessionService.getSessionItemDecrypted('fromMFA') === 'true')) {
            return this.sessionService.getSessionItemDecrypted('CLAIMS_ACCESSCODE');
        } else {
            return this.claimsHelperService.getSessionObjectValue('Claim-Access-Code') ||
            JSON.parse(sessionStorage.getItem('AUTH_CONFIG'))?.auth_id_claimAccessCode;
        }
    }

    getClaimGuid(): any {
        if ((this.sessionService.getSessionItemDecrypted('fromMFA') === 'true')) {
            return this.sessionService.getSessionItemDecrypted('CLAIMS_GUID');
        } else {
            return this.claimsHelperService.getSessionObjectValue('Claim-Guid');
        }
    }

    getClaimId(): any {
        return this.claimsHelperService.getSessionObjectValue('Claim-Number-Stored-In-Session') ||
            this.claimsHelperService.getSessionObjectValue('claimNumber');
    }

    getVehicleIncidents(claim: any): any {
        return null;
    }

    getVehicleDetails(): any {
        let vehicleIncidents;
        const vehicles: any[] = [];

        if (this.claimDetails?.lobs?.personalAuto) {
            vehicleIncidents = this.claimDetails.lobs.personalAuto.vehicleIncidents;
            for (const vh of vehicleIncidents) {
                vehicles.push(vh.vehicle);
            }
        }
        return vehicles;
    }

    getDriverDetails(): any {
        let vehicleIncidents;
        const drivers: any[] = [];

        if (this.claimDetails?.lobs?.personalAuto) {
            vehicleIncidents = this.claimDetails.lobs.personalAuto.vehicleIncidents;

            for (const vh of vehicleIncidents) {
                drivers.push(vh.driver);
            }
        }
        return drivers;
    }

    getPropertyLocation(): any {
        let propertyLocation;

        if (this.userType === 'unregistered-express') {
            for (const exposure of this.claimDetails.exposures) {
                if (this.claimsHelperService.checkExposureIncidentType(exposure, 'property')) {
                    propertyLocation = exposure.lobs.homeowner.fixedPropertyIncident.location;
                    break;
                }
            }
        }

        return propertyLocation;
    }

    getContactDetails(): any {
        this.getClaimDetails().then((claimDetails) => {
            if (this.userType === 'registered') {
                const claimContact = this.claimsHelperService.getInsuredContact(claimDetails.claimContacts);
                this.logger.debug('ClaimsDashboardService: claimContact Insured FOUND');
                const contactStatusArray = claimContact.contactDTO.contactStatuses;
                for (const contactStatusObj of contactStatusArray) {
                    const vehicleId = contactStatusObj.vehiclePublicID;
                    let vehicleIncidentArray;
                    if (claimDetails.lobs.personalAuto) {
                        vehicleIncidentArray = claimDetails.lobs.personalAuto.vehicleIncidents;
                    }
                    if (vehicleId !== undefined) {
                        for (const vehicleObj of vehicleIncidentArray) {
                            if (vehicleId === vehicleObj.vehicle.publicID) {
                                if (vehicleObj.vehicle.RentalName !== undefined) {
                                    contactStatusObj.RentalName = vehicleObj.vehicle.RentalName;
                                    contactStatusObj.RentalPerDayLimit = vehicleObj.vehicle.RentalValuePerDay;
                                    contactStatusObj.RentalExposureLimit = vehicleObj.vehicle.RentalValue;
                                }
                            }
                        }
                    }
                }
                return claimContact;
            } else {
                this.logger.debug('ClaimsDashboardService: claimContact Claimant FOUND');
                return {};
            }
        });
    }

    refreshClaimDetails(): any {
        // TO-DO: method to not used cached getClaimDetails response
        return this.getClaimDetails();
    }

    getClaimDetails(): Promise<any> {
        let claimId = this.getClaimId();

        if (this.claimDetails && (this.claimDetails.claimNumber === claimId)) {
            this.logger.debug('ClaimsDashboardService: USING CACHED CLAIMDETAILS');
            return Promise.resolve(this.claimDetails);
        }
        this.logger.info('ClaimsDashboardService: NOT USING CACHED CLAIMDETAILS');
        this.setClaimDetails(null);
        claimId = this.getClaimId();
        let URI = `claims/${claimId}`;
        let URL = environment.ClaimsAPIs.oldClaimsBaseAPI;
        let configHeaders = this.httpHeaderService.getClaimServiceHeaders(environment.CLAIMS_DEV);
        if (this.userType === 'unregistered-express') {
            URL = environment.ClaimsAPIs.claimsExperienceApi;
            configHeaders = this.httpHeaderService.getNWClaimServiceHeaders(environment.CLAIMS_DEV);
            URI = `notices-of-loss/${claimId}`;
        }
        // stub logic???
        const request = {
            uri: URI,
            type: '',
            requestPayload: {},
            baseUrl: this.environmentPicker.getEnvironmentClaimsCenterApiURL() + URL,
            GUID: '',
            headers: configHeaders
        };
        return new Promise((resolve: (value: Response) => void, reject) => {
            this.httpService.invokeHttpGet(request).subscribe(
                (response) => {
                    this.logger.info(`claimsDashboardService: successful getClaimDetails call: ${JSON.stringify(response)}`);
                    this.setClaimDetails(response);
                    resolve(response);
                },
                (error: any) => {
                    this.logger.error(`claimsDashboardService: ERROR in getClaimDetails call:${JSON.stringify(error)}`);
                    reject(error);
                }
            );
        });
    }


    setClaimDetails(claimDetails: any): void {
        this.claimDetails = claimDetails;
    }
}
