import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PersonalConstants } from '../common/personalConstants';

@Injectable()

export class AuthErrorInterceptor {
    constructor (private router: Router, @Inject(DOCUMENT) private document: any) { }

    canActivate(): boolean {
        const url_fragment = this.document.location.href;
        const error_fragment = url_fragment.split('#')[1];
        const errorArray = error_fragment.split('&');
        const accountErrorCode = errorArray[0].split('=')[1];

        parent.postMessage(accountErrorCode, window.location.origin);

        this.router.navigate([PersonalConstants.URLS.error500]);
        return false;
    }
}
