import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PersonalConstants } from 'app/common/personalConstants';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EnvironmentpickerService } from './environmentpicker.service';
import { HttpService } from './http.service';
import { HttpHeaderService } from './httpheader.service';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class ClaimsTrackerService {
    basicClaimDetails: any;
    safeliteApiName = 'getSafeliteDigitalSignature()';
    constructor (
        private httpHeaderService: HttpHeaderService,
        private httpService: HttpService,
        private logger: LoggerService,
        private environmentPickerService: EnvironmentpickerService,
        private sessionService: SessionService,
    ) { }

    searchPolicySummary(claimId: any): Observable<any> {
        const uri = 'policy/search';
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.CLAIMS_POLICY;
        const requestObj = {
            name: 'searchPolicySummary()',
            body: {
                claimNumber: claimId
            }
        };
        return this.invokeObservablePostService(uri, baseUrl, requestObj);
    }
    getPolicySummary(claimId: any): Observable<any> {
        const uri = `claims/${claimId}/policy`;
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.ClaimsAPIs.claimsPolicy;
        return this.invokeGetService(uri, baseUrl, 'getPolicySummary()');
    }

    getClaimDetails(claimId: any): Observable<any> {
        const uri = `claims/${claimId}`;
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.GET_BASIC_CLAIMS_DETAILS;

        return this.invokeGetService(uri, baseUrl, 'getClaimDetails()');
    }

    getClaimsContacts(claimId: any): Observable<any> {
        const uri = `claims/${claimId}/claim-contacts`;
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.CLAIMS_PAYEMNT_API;

        return this.invokeGetService(uri, baseUrl, 'getClaimsContacts()');
    }

    getClaimVehicleIncidents(claimId: any): Observable<any> {
        const uri = `claims/${claimId}/vehicle-incidents`;
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.CLAIMS_INCIDENTS;
        return this.invokeGetService(uri, baseUrl, 'getVehicleIncidents()');
    }

    getClaimVehicleRiskUnits(claimId: any, policyPublicId: any): Observable<any> {
        const uri = `claims/${claimId}/policy/${policyPublicId}/vehicle-risk-units`;
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.CLAIMS_POLICY;
        return this.invokeGetService(uri, baseUrl, 'getVehicleRiskUnits()');
    }

    getClaimStatusTracker(claimId: any): Observable<any> {
        const uri = `claims/claims-tracker/${claimId}`;
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.CLAIM_TRACKER_API;
        return this.invokeGetService(uri, baseUrl, 'getClaimTracker()');
    }

    getClaimsAdjusterSummaries(claimId: any): Observable<any> {
        const uri = `${claimId}/adjuster-summaries`;
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.ADJUSTER_SUMMARIES;
        return this.invokeGetService(uri, baseUrl, 'getAdjusterSummaries()');
    }

    getClaimVehicleEstimates(claimId: any, vehicleIncidentId: any, omitDocs: boolean): Observable<any> {
        const modifier = omitDocs ? '?omit_docs=true' : '?omit_docs=false';
        const uri = `claims/${claimId}/incident/${vehicleIncidentId}/vehicle-estimates${modifier}`;
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.ESTIMATES_AND_REPAIRS;
        return this.invokeGetService(uri, baseUrl, 'getVehicleEstimates()');
    }

    getClaimServiceOfferings(claimId: any, vehicleIncidentId: any): Observable<any> {
        const uri = `claims/${claimId}/incidents/${vehicleIncidentId}/service-offerings`;
        const baseUrl =
            this.environmentPickerService.getEnvironmentClaimsCenterApiURL() +
            environment.CLAIMS_API.CLAIMS_SERVICE_OFFERINGS;
        return this.invokeGetService(uri, baseUrl, 'getServiceOfferings()');
    }

    getClaimFinancialSummary(claimId: any): Observable<any> {
        const uri = `claims/${claimId}/financial-summary`;
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.CLAIMS_BASE_FINANCIAL_API;
        return this.invokeGetService(uri, baseUrl, 'getFinancialSummary()');
    }

    getClaimPayments(claimId: any): Observable<any> {
        const uri = `claims/${claimId}/payments`;
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.CLAIMS_BASE_FINANCIAL_API;
        return this.invokeGetService(uri, baseUrl, 'getPayments()');
    }

    getClaimsExposures(claimId: any, exposurePublicId?: any): Observable<any> {
        let uri = `claims/${claimId}/exposures`;
        uri = exposurePublicId ? `${uri}/${exposurePublicId}` : uri;
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.CLAIMS_EXPOSURES;
        return this.invokeGetService(uri, baseUrl, 'getExposure()');
    }

    getClaimPolicyCoverages(claimId: any, policyPublicId: any): Observable<any> {
        // need to make a call to this.getPolicySummary() to get the publicId from that for the {policy_public_id} in this call
        const uri = `claims/${claimId}/policy/${policyPublicId}/coverages`;
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.CLAIMS_POLICY;
        return this.invokeGetService(uri, baseUrl, 'getPolicyCoverages()');
    }

    getFraudLanguage(claimObj: any): Observable<any> {
        const claimId = claimObj.claimId ? claimObj.claimId : undefined;
        const state = claimObj.state ? claimObj.state : undefined;
        const referenceId = claimObj.referenceId ? claimObj.referenceId : undefined;
        const referenceType = claimObj.referenceType ? claimObj.referenceType : undefined;
        const uri = 'claims/fraud-language';
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.CLAIM_FRAUD_LANGUAGE;
        const requestObj = {
            name: 'getFraudLanguage()',
            body: {
                claimNumber: claimId,
                state: state,
                referenceId: referenceId,
                referenceType: referenceType
            }
        };
        return this.invokeObservablePostService(uri, baseUrl, requestObj);
    }

    getClaimVehicleCoverages(claimId: any, policyPublicId: any, vehicleRiskUnitId: any): Observable<any> {
        // need to make a call to this.getPolicySummary() to get the publicId from that for the {policy_public_id} in this call
        if (vehicleRiskUnitId) {
            const uri = `claims/${claimId}/policy/${
                 policyPublicId}/coverages?risk_unit_public_id=${vehicleRiskUnitId}`;
            const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.CLAIMS_POLICY;
            return this.invokeGetService(uri, baseUrl, 'getVehicleCoverages()');
        } else {
            return of({});
        }
    }

    getVehicleDetails(vehicle: any): Observable<any> {
        const uri = vehicle?.vIN + PersonalConstants.VIN_SERVICE.PARAMS + vehicle?.year;
        const baseUrl = PersonalConstants.VIN_SERVICE.ENDPOINT;
        return this.invokeGetService(uri, baseUrl, 'getVehicleDetails()');
    }

    getClaimQuestions(claimId: any): Observable<any> {
        const uri = `claims/${claimId}/claim-questions`;
        const baseUrl = this.environmentPickerService.getEnvironmentClaimsCenterApiURL() + environment.CLAIMS_API.GET_BASIC_CLAIMS_DETAILS;
        return this.invokeGetService(uri, baseUrl, 'getClaimQuestions()');
    }

    getSafeLiteDigitalSignature(policyNumber: any): Observable<any> {
        this.logger.info(`getSafeLiteDigitalSignature policyNumber: ${policyNumber}`);
        const uri = `${environment.ClaimsAPIs.safeLiteDigitalSignature}?policyNumber=${policyNumber}`;
        const baseUrl = environment.API_ENVIRONMENT;
        return this.invokeGetService(uri, baseUrl, this.safeliteApiName);
    }

    invokeGetService(uri: any, baseUrl: any, name: any): Observable<any> {
        baseUrl = this.getErrorScenarioBaseUrl(name, baseUrl);
        let headers: HttpHeaders;
        if (name === this.safeliteApiName) {
            this.logger.info(`safelite in the remove header block: ${name}`);
            headers = this.httpHeaderService.getSafeliteMFAServiceHeaders(environment.CLAIMS_DEV);
        } else {
            headers = this.httpHeaderService.getNWClaimServiceHeaders(environment.CLAIMS_DEV);
        }

        const request = {
            uri: uri,
            type: '',
            requestPayload: {},
            baseUrl: baseUrl,
            GUID: '',
            headers: headers
        };

        this.logger.info(`invokeGetService ${name} request: ${request}`);
        const startTimestamp = new Date().getTime();
        const response$ = this.httpService.invokeHttpGet(request);
        return response$.pipe(
            map((response: any) => {
                const endTimestamp = new Date().getTime();
                const loadTime = endTimestamp - startTimestamp;
                const loadResponse = response;
                loadResponse['loadTime'] = loadTime;
                this.logger.info(`loadTime for ${name} : ${loadTime}`);
                return loadResponse;
            }),
            catchError((error) => {
                this.logger.error(`claimsTrackerService: ERROR in ClaimsTrackerService.${
                     name} call:${JSON.stringify(error)}`, error);
                return error;
            }));
    }
    invokeObservablePostService(uri: any, baseUrl: any, requestObj: any): Observable<any> {
        baseUrl = this.getErrorScenarioBaseUrl(requestObj.name, baseUrl);
        let headers = this.httpHeaderService.getNWClaimServiceHeaders(environment.CLAIMS_DEV);
        if (requestObj.name === 'getFraudLanguage()' && this.sessionService.getSessionItemDecrypted('isPublicFileUpload') === true) {
            headers = this.httpHeaderService.getPublicAPIClaimServiceHeaders(environment.CLAIMS_DEV);
        }

        const request = {
            uri: uri,
            type: '',
            requestPayload: requestObj.body,
            baseUrl: baseUrl,
            GUID: '',
            headers: headers
        };
        const startTimestamp = new Date().getTime();
        const response$ = this.httpService.invokeHttpPost(request);
        return response$.pipe(
            map((response: any) => {
                const endTimestamp = new Date().getTime();
                const loadTime = endTimestamp - startTimestamp;
                const loadResponse = response;
                loadResponse['loadTime'] = loadTime;
                this.logger.info(`loadTime for ${requestObj.name} : ${loadTime}`);
                return loadResponse;
            }),
            catchError((error) => {
                this.logger.error(`claimsTrackerService: ERROR in ClaimsTrackerService.${
                     requestObj.name} call:${JSON.stringify(error)}`, error);
                return error;
            }));
    }

    // would like to use this in the form of a cookie or maybe part of the chrome plugin eventually
    getErrorScenarioBaseUrl(serviceName: any, baseUrl: any): any {
        let configuredUrl = baseUrl;
        serviceName = serviceName.replace('()', '');

        const errorList = {
            downServices: {
                'getClaimDetails': false,
                'getVehicleIncidents': false,
                'getClaimTracker': false,
                'getFraudLanguage': false,
                'searchPolicySummary': false,
                'getExposures': false,
                'getVehicleRiskUnits': false,
                'getPolicyCoverages': false,
                'getServiceOfferings': false,
                'getVehicleCoverages': false,
                'getPayments': false,
                'getFinancialSummary': false,
                'getVehicleEstimates': false,
                'getAdjusterSummaries': false,
                'getExposure': false,
                'getSafeliteDigitalSignature': false
            }
        };

        if (errorList.downServices.hasOwnProperty(serviceName) && errorList.downServices[serviceName] === true) {
            this.logger.info(`getErrorScenarioBaseUrl: ${serviceName}() is triggered to error`);
            configuredUrl = configuredUrl.replace('claims-administration/', 'claims-administration/z');
        }

        return configuredUrl;
    }
}
