import { Injectable } from '@angular/core';
import { EfnolCacheService } from './efnol-cache.service';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class ClaimsChatDataService {
    businessUnit = 'CLM';
    pageTitle = '';
    emailAddress = '';
    firstName = '';
    lastName = '';
    productType = 'Auto';
    policyNumber = '';
    callerState = '';

    constructor (
        private sessionService: SessionService,
        private efnolCacheService: EfnolCacheService,
        private loggerService: LoggerService
    ) { }

    outputClaimsInfoValues(): any {
        return {
            BU: this.businessUnit,
            pageTitle: this.pageTitle,
            emailAddress: this.emailAddress,
            G_firstName: this.firstName,
            G_lastName: this.lastName,
            productType: this.productType,
            policyNumber: this.policyNumber,
            G_CallerState: this.callerState
        };
    }

    populateLiveChatBaseInfo(emailAddress: string): void {
        try {
            this.emailAddress = emailAddress || '';
            if (this.sessionService) {
                this.policyNumber = this.sessionService.getPolicyNumber();
            }
            if (this.efnolCacheService) {
                const efnolInfo = this.efnolCacheService.getSessionValue();
                if (efnolInfo?.basicInfo) {
                    this.firstName = efnolInfo.basicInfo?.reporterFirstName || '';
                    this.lastName = efnolInfo.basicInfo?.reporterLastName || '';
                }
            }
        } catch (error) {
            this.loggerService.error('Issue with setting live chat data', error);
        }
    }

    updatePageTitle(pageTitle: string): void {
        this.pageTitle = pageTitle;
    }
}
