import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// eslint-disable-next-line
type ConditionalFunction = (FormGroup: any) => boolean;

interface Conditional {
    when: Array<any> | ConditionalFunction;
    name: string;
    control: AbstractControl;
}

@Injectable()
export class BoltFormHelper {
    checkNextValidator = [];
    errorGroups = [];
    constructor (private _fb: UntypedFormBuilder) { }

    // similar syntax to FormBuilder.group but allows us to specify conditional
    // sections that get added or removed based on answers to other inputs
    // allowing for proper form validation when the user takes one path and
    // another has validation that doesn't happen because it's never reached
    build(form: any, conditions?: Array<Conditional[]>, depth: number = 0): UntypedFormGroup {
        // conditionals is a double array where the first index is nested depth, and second is the conditionals at that depth
        const conditionals: Array<Conditional[]> = conditions || [];
        // if we're at a new depth, add a new first index to array
        if (conditionals.length < depth + 1) {
            conditionals.push([]);
        }

        // tslint:disable-next-line:forin
        for (const controlName in form) {
            const controlConfig: Conditional = form[controlName];

            // if it's an object, this is our conditional form control/group
            if (controlConfig instanceof Object && controlConfig.hasOwnProperty('when') && controlConfig.hasOwnProperty('control')) {
                // recursively call build because we allow nested FormHelper just like groups can be nested
                // if it's an Object but not an array, this is another FormHelper.build config
                if (controlConfig.control instanceof Object && !(controlConfig.control instanceof Array)) {
                    controlConfig.control = this.build(controlConfig.control, conditionals, depth + 1);
                } else {
                    // otherwise it's formbuilder's syntax for a single control, a primitive or an array
                    // cast this to 'any' so typescript ignores that we're calling an internal method
                    // don't want to duplicate their code.
                    controlConfig.control = (this._fb as any)._createControl(controlConfig.control);
                }

                controlConfig.name = controlName;

                // push it to the right depth
                conditionals[depth].push(controlConfig);

                // add the control in, but now we have a reference so we can set it to disabled later
                form[controlName] = controlConfig.control;
            }
        }

        // build a FormGroup using all the non-conditionals
        const fg = this._fb.group(form);

        if (depth === 0) {
            // run all the conditionals once the form has been built
            this.onFormValueChange(fg, conditionals);

            // listen for value changes on the group as a whole instead of individual
            // this makes it easier to register complex conditionals
            fg.valueChanges.subscribe(() => this.onFormValueChange(fg, conditionals));
        }

        return fg;
    }

    private onFormValueChange(group: UntypedFormGroup, conditionals: Array<Conditional[]>): void {
        // console.log('form value change');
        // we check conditionals on breadth-first because if there's a form group that gets
        // "enabled", it enables all of it's children, so we want children to be checked last,
        // because they have their own conditionals that could make them disabled.
        conditionals.forEach((depth: Conditional[]) => {
            depth.forEach((conditional: Conditional) => {
                // enable or disable the control conditionally
                const control = conditional.control;
                // if the parent is disabled we don't need check the conditional because parent disabling
                // automatically disables all children and we are going top down.
                if (control.parent && control.parent.disabled) {
 return;
}
                if (this.checkCondition(group, conditional.when)) {
                    if (!control.enabled) {
                        console.log(`enabling ${conditional.name}`);
                        control.enable({ onlySelf: false, emitEvent: false });
                        control.markAsUntouched();
                        control.updateValueAndValidity();
                        control.reset();
                    }
                } else if (!control.disabled) {
                        console.log(`disabling ${conditional.name}`);
                        control.disable({ onlySelf: false, emitEvent: false });
                        control.markAsUntouched();
                        control.updateValueAndValidity();
                        control.reset();
                    }
            });
        });
    }

    private checkCondition(form: UntypedFormGroup, condition: any): boolean {
        // simple condition ['formControlName', 'value to check for equality']
        if (condition instanceof Array) {
            const controlName = condition[0];
            const value = condition[1];

            // console.log(condition, form.get(controlName).value);

            return form.get(controlName).value === value;
        }

        // more complex is a function function(form): boolean => { return truthiness; }
        if (condition instanceof Function) {
            return condition.call(this, form);
        }
    }

    groupValidate(formGroup: any): any {
        this.checkNextValidator = [];
        Object.keys(formGroup).forEach((keys) => {
            this.checkNextValidator.push(keys);
        });
    }

    doGroupValidation(group): any {
        this.errorGroups = [];
        if (!!group) {
            this.checkNextValidator.forEach((formGroup) => {
                if (group.controls[formGroup] && !group.controls[formGroup].disabled) {
                    const form = group.controls[formGroup].value;
                    const containsValue = Object.keys(form).filter((key) => (form[key] !== null &&
                        form[key] !== false && form[key] !== ''));
                    if (containsValue.length <= 0) {
                        if (this.errorGroups.indexOf(formGroup) <= -1) {
                            this.errorGroups.push(formGroup);
                        }
                    } else {
                        this.errorGroups.splice(this.errorGroups.indexOf(formGroup));
                    }
                }
            });
        }
    }
}
