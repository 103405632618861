import { Injectable } from '@angular/core';
import { ServicesContainer } from '../models/efnol-cache-model';
import { FnolModels } from './../models/claims-fnol-model';
import { CryptService } from './crypt.service';

@Injectable({
    providedIn: 'root'
})
export class EfnolSessionService {
    constructor (private cryptService: CryptService) { }

    setInitialFnolModel(): void {
        this.setSessionValue({
            incident: {},
            reporter: {},
            lossCause: {},
            readyToFile: {},
            vehicleInvolved: {},
            notes: {},
            lookUpVehicle: {},
            updateReporter: {}
        });
    }
    setPhotoProcessOptionsModel(): void {
        this.setPhotoProcessSessionValue({
            photoProcessOption: ''
        });
    }

    // incident model
    getIncidentModel(modelName): any {
        try {
            return this.getSessionValue().incident[modelName];
        } catch {
            return null;
        }
    }



    setIncidentModel(modelName, incidentObj: any): void {
        const fnolSession: FnolModels = this.getSessionValue();
        fnolSession.incident[modelName] = incidentObj;
        this.setSessionValue(fnolSession);
    }

    // updateReporter model
    getUpdateReporterModel(modelName): any {
        try {
            return this.getSessionValue().updateReporter[modelName];
        } catch {
            return null;
        }
    }

    setUpdateReporterModel(modelName, updateReporterObj: any): void {
        const fnolSession: FnolModels = this.getSessionValue();
        fnolSession.updateReporter[modelName] = updateReporterObj;
        this.setSessionValue(fnolSession);
    }

    // Vehicle Involved model
    getVehicleInvolvedModel(modelName): any {
        try {
            return this.getSessionValue().vehicleInvolved[modelName];
        } catch {
            return null;
        }
    }
    setVehicleInvolvedModel(modelName, vehicleInvolvedObj: any): void {
        const fnolSession: FnolModels = this.getSessionValue();
        fnolSession.vehicleInvolved[modelName] = vehicleInvolvedObj;
        this.setSessionValue(fnolSession);
    }

    // Look Up Vehicle Model
    getLookUpVehicleModel(modelName): any {
        try {
            return this.getSessionValue().lookUpVehicle[modelName];
        } catch {
            return null;
        }
    }
    setLookUpVehicleModel(modelName, lookUpVehicleObj: any): void {
        const fnolSession: FnolModels = this.getSessionValue();
        fnolSession.lookUpVehicle[modelName] = lookUpVehicleObj;
        this.setSessionValue(fnolSession);
    }

    // notes modal
    getNotesModel(modelName): any {
        try {
            return this.getSessionValue().notes[modelName];
        } catch {
            return null;
        }
    }

    setNotesModel(modelName, notesObj: any): void {
        const fnolSession: FnolModels = this.getSessionValue();
        fnolSession.notes[modelName] = notesObj;
        this.setSessionValue(fnolSession);
    }

    // Reporter Model
    getReporterModel(modelName: string): any {
        try {
            return this.getSessionValue().reporter[modelName];
        } catch {
            return null;
        }
    }

    setReporterModel(modelName: string, reporterObj: any): void {
        const fnolSession: FnolModels = this.getSessionValue();
        fnolSession.reporter[modelName] = reporterObj;
        this.setSessionValue(fnolSession);
    }

    // lossCause Model
    getLossCauseModel(modelName: string): any {
        return this.getSessionValue().reporter[modelName];
    }

    setLossCauseModel(modelName: string, reporterObj: any): void {
        const fnolSession: FnolModels = this.getSessionValue();
        fnolSession.reporter[modelName] = reporterObj;
        this.setSessionValue(fnolSession);
    }

    // readyToFile model
    getReadyToFileModel(modelName): any {
        try {
            return this.getSessionValue().readyToFile[modelName];
        } catch {
            return null;
        }
    }

    setReadyToFileModel(modelName, readyToFileObj: any): void {
        const fnolSession: FnolModels = this.getSessionValue();
        fnolSession.readyToFile[modelName] = readyToFileObj;
        this.setSessionValue(fnolSession);
    }

    getSessionValue(): any {
        return this.cryptService.getSessionItemJSON('fnolObject');
    }

    setSessionValue(sessionObject: FnolModels): any {
        this.cryptService.setSessonItemJSON('fnolObject', sessionObject);
    }


    setPhotoProcessSessionValue(sessionObject: ServicesContainer): any {
        this.cryptService.setSessonItemJSON('photoProcessOption', sessionObject);
    }

    getPhotoProcessSessionValue(): any {
        return this.cryptService.getSessionItemJSON('photoProcessOption');
    }
}
