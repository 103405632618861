import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { HttpService } from './http.service';
import { HttpHeaderService } from './httpheader.service';
import { IframeQueue } from './iframe-queue.service';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';

export type AuthorizeParams = CustomerSearchParams;
export type EUAAuthorizeParams = ClaimsServicingParams;

export interface AuthorizeConfigBase {
    client_id: string;
    nonce: string;
    redirect_uri: string;
    response_type: string;
    scope: string;
    realm: string;
    state: string;
    message_id?: string; // [SessionExtend TODO] IS THIS ACTUALLY REQUIRED?
}

export interface CustomerSearchConfig {
    auth_method: 'customer-search';
    realm: 'unidentified';
    auth_id_agreementNumber: string;
    auth_id_postalCode: string;
    auth_id_birthDate: string;
    auth_id_lastName: string;
}

export type CustomerSearchParams = Partial<AuthorizeConfigBase> & Partial<CustomerSearchConfig>;

export interface PingConfig {
    auth_method: 'ping';
    realm: 'member';
}

export type PingParams = Partial<AuthorizeConfigBase> & Partial<PingConfig>;

export interface ClaimsServicingConfig {
    auth_method: 'claims-servicing';
    realm: 'unidentified';
    auth_id_claimAccessCode: string;
    auth_id_claimAccessIdentifier: string;
    auth_id_phoneNumber: string;
}

export type ClaimsServicingParams = Partial<AuthorizeConfigBase> & Partial<ClaimsServicingConfig>;

@Injectable()
export class OauthClient {
    constructor (
        private iframeQueue: IframeQueue,
        private httpHeaderService: HttpHeaderService,
        private httpService: HttpService,
        private logger: LoggerService,
        private sessionService: SessionService,

    ) { }

    authorize(params: AuthorizeParams): Observable<any> {
        const endpoint = environment.OAUTH.AUTH_2_ENDPOINT + this.toQueryString(params);

        return this.iframeQueue.next(endpoint).pipe(
            map((authServerResponse) => this.mapResponse(authServerResponse)),
            take(1)
        );
    }

    refresh(): Observable<any> {
        const refreshToken = this.sessionService.getSessionItemDecrypted('RefreshToken');
        const urlEncodedParams = new HttpParams()
            .append('grant_type', 'refresh_token')
            .append('refresh_token', refreshToken);
        const baseUrl = environment.OAUTH.REFRESH_2_ENDPOINT;
        const uri = '/token';
        return this.invokeObservablePostService(baseUrl, uri, urlEncodedParams);
    }

    createSession(params: AuthorizeParams, loginState: string): any {
        window.sessionStorage.setItem('loginState', loginState);
        return environment.OAUTH.AUTH_2_ENDPOINT + this.toQueryString(params);
    }

    private toQueryString(authorizeParams: object): string {
        return `?${Object
            .keys(authorizeParams)
            .map((paramName) => `${paramName}=${authorizeParams[paramName]}`)
            .join('&')}`;
    }

    public parseJwt(token): any {
        let parsedJwt;
        if (token) {
            const base64URL = token.split('.')[1];
            const base64 = base64URL.replace('-', '+').replace('_', '/');
            parsedJwt = JSON.parse(window.atob(base64));
        }
        return parsedJwt;
    }

    public getLineOfBusiness(jwt): any {
        let lineOfBusiness;
        if (jwt?.agreementNumber) {
            const inputPolicyNumber = jwt.agreementNumber;
            const agreements = jwt.masked_agreements || jwt.agreements;
            if (agreements && agreements.length > 0) {
                for (const agreement of agreements) {
                    if (agreement.agreement_number && agreement.agreement_number.slice(-3) === inputPolicyNumber.slice(-3)) {
                        lineOfBusiness = agreement.line_of_business;
                        break;
                    }
                }
            }
        }
        return lineOfBusiness;
    }

    public invokeObservablePostService(baseUrl: any, uri: any, urlEncodedParams: any): Observable<any> {
        const headers = this.httpHeaderService.getEUAServiceHeaders();
        const url = baseUrl;
        const request = {
            requestPayload: urlEncodedParams,
            baseUrl: url,
            uri: uri,
            headers: headers
        };
        const response$ = this.httpService.invokeHttpPost(request);
        return response$.pipe(
            map((response: any) => {
                this.logger.info(`EUARefreshService: Successful response in EUARefreshService.${JSON.stringify(response)}`);
                return response;
            },
                catchError((error) => {
                    this.logger.error(`EUARefreshService: ERROR in EUARefreshService.${
                         error}`);
                    return error;
                })));
            }
    public getProductType(jwt): any {
        let productType;
        if (jwt?.agreementNumber) {
            const inputPolicyNumber = jwt.agreementNumber;
            const agreements = jwt.masked_agreements || jwt.agreements;
            if (agreements && agreements.length > 0) {
                for (const agreement of agreements) {
                    if (agreement.agreement_number && agreement.agreement_number.slice(-3) === inputPolicyNumber.slice(-3)) {
                        productType = agreement.product_type;
                        break;
                    }
                }
            }
        }
        return productType;
    }

    public mapResponse(authServerResponse: string): any {
        const hashParams = authServerResponse.split('#')[1];

        let authParams;
        if (hashParams) {
            authParams = hashParams
                .split('&')
                .reduce((aggregatedParams, paramString) => {
                    const [key, value] = paramString.split('=');
                    aggregatedParams[key] = value;
                    return aggregatedParams;
                }, {});
        } else {
            authParams = {};
        }

        const successful = 'access_token' in authParams;

        const lineOfBusiness = successful ? this.getLineOfBusiness(this.parseJwt(authParams['id_token'])) : '';
        const productType = successful ? this.getProductType(this.parseJwt(authParams['id_token'])) : '';

        return { successful, authParams, lineOfBusiness, productType };
    }
}
