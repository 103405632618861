import { CommonModule } from '@angular/common';

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NwFormsModule } from '../nw-forms/nw-forms.module';
import { NwTestToolComponent } from './nw-test-tool.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [NwTestToolComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NwFormsModule
    ],
    exports: [NwTestToolComponent]
})
export class NwTestToolModule { }
