<!-- Commenting this old code for restoring if required. -->

<!-- <div id="timeout-modal-container" *ngIf="isOpen">
    <div tabindex="-1" role="dialog" class="modal fade in" style="z-index: 1050;display:block;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Do you want to keep working?</h4>
                </div>
                <div class="modal-body">
                    <div class="media">
                        <ng-container [ngSwitch]="status">
                            <ng-container *ngSwitchCase="'notifyingSessionIsExpiringSoon'">
                                <div class="media-left">
                                    <bolt-icon name="exclamation-triangle-filled" color="yellow"></bolt-icon>
                                </div>
                                <div class="media-body">
                                    <span>For your security, your Nationwide online session will expire in approximately three (3) minutes. What would you like to do?</span>
                                </div>
                            </ng-container>

                                <bolt-waiting-indicator class="bolt-space-inner-xl bolt-space-bottom-lg" *ngSwitchCase="'refreshingSession'">Please wait.</bolt-waiting-indicator>
                            <ng-container *ngSwitchCase="'refreshSessionErrorOccurred'">
                                <div class="media-left">
                                    <span class="nw-modal-icon nw-icon-warning nw-fg-yellow-accent"></span>
                                </div>
                                <div class="media-body">
                                    <span>An error occurred. Please try again later.</span>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="modal-footer">
                    <bolt-button id="end-session-button"
                    class='button-spacing'
                    [attr.disabled]="sessionRefreshInProgress ? 'true' : null"
                    (click)="endUserSession(true);">
                    End session
                </bolt-button>

                    <bolt-button id="keep-working-button"
                        type="primary"
                        [attr.disabled]="sessionRefreshInProgress ? 'true' : null"
                        (click)="refreshUserSession()">
                        Keep working
                    </bolt-button>

                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade in" style="z-index: 1040;"></div>
</div> -->
<div id="timeOutModalPopup">
    <is-timeout-popup (refreshSessionTime)='refreshSession()'></is-timeout-popup>
</div>
