import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClaimsOidcAuthService } from 'app/efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { PersonalConstants } from '../../common/personalConstants';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';
@Injectable({
    providedIn: 'root'
})
export class UtilService {
    lastDigitOfPolicy: any;
    navigate: boolean;
    verifyOTPResponse: any;
    deviceInfo: any;
    userType: any;
    timeout = 500;
    currentWait = 0;
    waitLimit = 20000;
    heartbeatCookieName = PersonalConstants.Application_Variables.HEARTBEAT_COOKIE;
    encryptExceptionList: any = [
        'userType',
        'reportedByType',
        'policyNumber'
    ];

    constructor (
        private LOGGER: LoggerService,
        private authService: ClaimsOidcAuthService,
        private sessionService: SessionService,
        private cookieService: CookieService
    ) { }

    handleError(errorMessage: string, error: HttpErrorResponse, ignoreNotFound?: boolean): Observable<any> {
        if (error.status === 404 && ignoreNotFound) {
            return observableOf([]);
        } else {
            this.LOGGER.error(`${errorMessage} ${error.error}`);
            return observableThrowError(error.error || 'Server error');
        }
    }

    checkIframeLoaded(iframeId: string, page: string): boolean {
        const iframe = document.getElementById(iframeId) as HTMLIFrameElement;
        if (this.currentWait <= this.waitLimit) {
            setTimeout(() => {
                if (iframe?.contentWindow) {
                    iframe.onload = (): void => {
                        this.LOGGER.info(`Analytics: The contents of iframe with id '${iframeId}' are fully loaded for '${page}' page.`);
                    };
                    return true;
                } else {
                    this.LOGGER.debug(`gonna run the check again ${this.currentWait}`);
                    this.currentWait += this.timeout;
                    this.checkIframeLoaded(iframeId, page);
                }
            }, this.timeout);
        } else {
            this.LOGGER.info(`Analytics: The iFrame '${iframeId}' didn't load within ${this.currentWait}ms `);
            return false;
        }
    }

    // TODO: Convert this over into a pipe
    convertDateToEasternStandardTime(dateStringToConvert: Date): Date {
        const utcOffset = dateStringToConvert.getTimezoneOffset();
        let timeDiffFromET: number;

        if (utcOffset !== 240) {
            timeDiffFromET = utcOffset - 240;
            if (timeDiffFromET > 0) {
                dateStringToConvert.setHours(dateStringToConvert.getHours() + (timeDiffFromET / 60));
            } else if (timeDiffFromET < 0) {
                timeDiffFromET = Math.abs(timeDiffFromET);
                dateStringToConvert.setHours(dateStringToConvert.getHours() - (timeDiffFromET / 60));
            }
        }
        return dateStringToConvert;
    }

    addUserToSessionStorage(userInfoData): any {
        if (userInfoData?.sub) {
            let userId = userInfoData.sub;
            if (userId.indexOf(':') !== -1) {
                userId = userId.split(':')[1];
            }
            sessionStorage.setItem(PersonalConstants.splunkProps.userId, userId);
        }
    }

    isValidPOBoxAddress(address: string): boolean {
        return address.match(/^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i) != null;
    }

    clearValidatorsForTheControl(formControl: any, noValueClear: boolean = false): any {
        if (!noValueClear) {
            formControl.setValue('');
        }
        formControl.clearValidators();
        formControl.setErrors(null);
        formControl.markAsUntouched();
    }

    checkAllEmpty(value): boolean {
        return (value === null || value === '' || value === undefined);
    }

    formatPolicyNumber(policyNumber: string): string {
        const numberRegex = /^[0-9]/;
        const alphaRegex = /^[a-zA-Z]/;
        let formattedPolicyNumber = policyNumber.toUpperCase();
        if (formattedPolicyNumber && formattedPolicyNumber.length >= 10) {
            if (formattedPolicyNumber.length >= 12) {
                return formattedPolicyNumber;
            }

            if (policyNumber.charAt(0).match(numberRegex) && formattedPolicyNumber.indexOf(' ') === -1) {
                if (!policyNumber.charAt(4).match(alphaRegex)) {
                    formattedPolicyNumber = `${formattedPolicyNumber.substring(0, 4)}  ${formattedPolicyNumber.substring(4)}`;
                } else {
                    formattedPolicyNumber = formattedPolicyNumber.replace(/ /g, '');
                    formattedPolicyNumber = `${formattedPolicyNumber.substring(0, 5)} ${formattedPolicyNumber.substring(5)}`;
                }
            }
        }
        return formattedPolicyNumber;
    }

    getWorkNumber(number): any {
        if (!!number) {
            const splitNumber = this.splitWorkNumberAndExtn(number);
            return Array.isArray(this.splitWorkNumberAndExtn(splitNumber)) ? splitNumber[0] : splitNumber;
        }
        return undefined;
    }

    getWorkExtn(number): any {
        if (!!number) {
            const splitNumber = this.splitWorkNumberAndExtn(number);
            return Array.isArray(this.splitWorkNumberAndExtn(splitNumber)) ? splitNumber[1] : undefined;
        }
        return undefined;
    }

    splitWorkNumberAndExtn(number: any): any {
        let formattedNumber = number;
        if (number.indexOf(' x') > -1) {
            formattedNumber = number.split(' x');
            return formattedNumber;
        }
        return number;
    }

    getFormattedPolicyNumber(policyNumber): any {
        let polNum = policyNumber;
        if (polNum) {
            polNum = polNum.replace(/\s/g, '');
        }

        const isNumRegEx = /^[0-9]/;
        const isCharRegEx = /^[a-zA-Z]/;
        let formattedPolNum = polNum;
        let charCount = 0;
        let beginCharIndex = 0;
        let lastCharIndex = 0;
        let charString = '';
        if (sessionStorage.getItem('policy-type') === 'Property') {
            if (polNum?.charAt(0).match(isNumRegEx)) {
                for (beginCharIndex; beginCharIndex < polNum.length; beginCharIndex++) {
                    if (polNum.charAt(beginCharIndex).match(isCharRegEx)) {
                        lastCharIndex = beginCharIndex;
                        if (polNum.charAt(lastCharIndex + 1).match(isCharRegEx)) {
                            charString += polNum.charAt(lastCharIndex) + polNum.charAt(lastCharIndex + 1);
                            lastCharIndex += 1;
                        } else if (polNum.charAt(lastCharIndex) === 'T') {
                            charString += polNum.charAt(lastCharIndex) + polNum.charAt(lastCharIndex + 1);
                            lastCharIndex += 1;
                        } else {
                            charString += `${polNum.charAt(lastCharIndex)} `;
                        }
                        formattedPolNum = polNum.substring(0, beginCharIndex) + charString + polNum.substring(lastCharIndex + 1);
                        break;
                    }
                }
            } else if (polNum?.charAt(0).match(isCharRegEx) && formattedPolNum.indexOf(' ') <= -1) {
                // for formatting Allied Property policies
                if (!polNum.charAt(3).match(isCharRegEx)) {
                    for (charCount; charCount < 4; charCount++) {
                        if (!polNum.charAt(charCount).match(isCharRegEx)) {
                            lastCharIndex = charCount;
                            for (charCount; charCount < 4; charCount++) {
                                charString += ' ';
                            }
                        } else {
                            charString += polNum.charAt(charCount);
                        }
                    }
                    formattedPolNum = charString + polNum.substring(lastCharIndex);
                }
            }
        } else if (polNum?.charAt(0).match(isNumRegEx) && formattedPolNum.indexOf(' ') <= -1) {
            if (!polNum.charAt(4).match(isCharRegEx)) {
                formattedPolNum = `${formattedPolNum.substring(0, 4)}  ${formattedPolNum.substring(4)}`;
            } else if (!polNum.charAt(5).match(isCharRegEx)) {
                formattedPolNum = formattedPolNum.replace(' ', '');
                formattedPolNum = `${formattedPolNum.substring(0, 5)} ${formattedPolNum.substring(5)}`;
            }
        } else if (polNum?.charAt(0).match(isCharRegEx) && formattedPolNum.indexOf(' ') <= -1) {
            // for formatting Allied Auto policies
            if (!polNum.charAt(3).match(isCharRegEx)) {
                for (charCount; charCount < 4; charCount++) {
                    if (!polNum.charAt(charCount).match(isCharRegEx)) {
                        lastCharIndex = charCount;
                        for (charCount; charCount < 4; charCount++) {
                            charString += ' ';
                        }
                    } else {
                        charString += polNum.charAt(charCount);
                    }
                }
                formattedPolNum = charString + polNum.substring(lastCharIndex);
            }
        }

        return formattedPolNum.toUpperCase();
    }

    isFutureTime(time): boolean {
        const incidentTime = this.convertTime12to24(time).split(':');
        const nowTime = new Date();
        return parseInt(incidentTime[0], 10) > nowTime.getHours();
    }

    convertTime12to24(time12h): any {
        const [time, modifier] = time12h.split(' ');
        const hoursAndMinutes = time.split(':');
        let hours = hoursAndMinutes[0];
        const minutes = hoursAndMinutes[1];

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    }

    formatPhone(phoneNumber): string {
        if (phoneNumber && phoneNumber.length >= 10) {
            const cleanedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
            let formattedPhoneNumber = '';
            if (cleanedPhoneNumber.length === 10) {
                formattedPhoneNumber = cleanedPhoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
                return `${formattedPhoneNumber[1]}-${formattedPhoneNumber[2]}-${formattedPhoneNumber[3]}`;
            } else if (cleanedPhoneNumber.length > 10) {
                return phoneNumber;
            }
        } else {
            return phoneNumber || '';
        }
    }

    rebuildClaimsNotes(fnolObject): void {
        const notes = this.sessionService.getPropertyNotes();
        if (Object.keys(notes).length > 0) {
            if (!!!fnolObject.note) {
                fnolObject.note = {};
            } else {
                fnolObject.note.body = '';
            }
            notes.forEach((value) => {
                const notesPrev = (fnolObject.note.body === undefined) ? '' : fnolObject.note.body;
                if (!this.isEmpty(value.notes)) {
                    fnolObject.note.body = `${notesPrev}\n${value.notes}`;
                }
            });
            if (fnolObject.note.body === undefined || fnolObject.note.body === null) {
                delete fnolObject.note;
            }
        }
        return fnolObject;
    }

    convertObjToArray(object): any {
        return Object.keys(object).map(function (key) {
            return [key, object[key]];
        });
    }

    getObjectValues(object): any {
        return Object.keys(object).map((key) => object[key]);
    }

    isEmpty(value): boolean {
        if (value !== null && typeof value === 'object') {
            return Object.keys(value).length <= 0;
        } else if (Array.isArray(value)) {
            return value.length <= 0;
        } else {
            return value === null || value === undefined || value === '';
        }
    }

    setFNOLnotesItem(notesMessage: string, pagename: string): void {
        const localNotesStack = this.sessionService.getPropertyNotes();
        if (Object.keys(localNotesStack).length > 0) {
            const existingIndex = localNotesStack.findIndex((obj) => obj.page === pagename);
            if (existingIndex !== undefined && existingIndex !== -1) {
                localNotesStack[existingIndex].notes = notesMessage;
            } else {
                const tempObj = {
                    page: pagename,
                    index: localNotesStack.length,
                    notes: notesMessage
                };
                localNotesStack.push(tempObj);
            }
        } else {
            // newItem fresh
            this.sessionService.setPropertyNotes('');
            const tempObj = {
                page: pagename,
                index: localNotesStack.length,
                notes: notesMessage
            };
            localNotesStack.push(tempObj);
        }
        this.sessionService.setPropertyNotes(localNotesStack);
    }

    returnKeysAndValues(group): any {
        const chunck = {};
        if (group) {
            const nodeGroup = Object.keys(group);
            nodeGroup.map((key, index) => chunck[key] = false);
        }
        return chunck;
    }

    capitalize(a: string): string {
        a = a.toLowerCase();
        return a.replace(/(?:^|\s)\S/g, function (text: string): string {
            return text.toUpperCase();
        });
    }

    isStrictNull(valueToCheck): any {
        const valuesToCheckEmpty = [null, undefined, 'null', 'undefined', ''];
        return (valuesToCheckEmpty.indexOf(valueToCheck) > -1);
    }

    isAllowedValues(valueToCheck, withAllowedValues): any {
        return ((withAllowedValues.indexOf(valueToCheck) > -1));
    }

    isDashboardURL(url: string): boolean {
        const dashboardRoutesList = [
            '/auto/tracker',
            '/dashboard/tracker',
            '/dashboard/incident-details',
            '/claimspayment/claims-payment-option',
            '/file-upload'
        ];
        let matchfound = false;
        dashboardRoutesList.forEach((route) => {
            if (url.indexOf(route) >= 0) {
                matchfound = true;
            }
        });
        return matchfound;
    }

    buildStorageCookie(): void {
        const sessionStorageVals = {};
        for (let index = 0; index < sessionStorage.length; index++) {
            const nextKey = sessionStorage.key(index);
            let nextVal = sessionStorage.getItem(nextKey);
            if (this.encryptExceptionList.indexOf(nextKey) > -1) {
                nextVal = this.sessionService.getSessionItemDecrypted(nextKey);
            }
            if (nextKey !== PersonalConstants.LOGINTYPE.REGISTERED &&
                nextKey !== PersonalConstants.LOGINTYPE.UNREGISTERED &&
                nextVal !== null && !nextKey.startsWith('nwcsa') &&
                !nextKey.startsWith('QSI_History')) {
                sessionStorageVals[nextKey] = nextVal;
            }
        }
        if (this.sessionService.getSessionItemDecrypted('MFAToken').access_token) {
            sessionStorageVals['AccessToken'] = this.sessionService.getSessionItemDecrypted('MFAToken').access_token;
        } else {
            this.authService.getAccessToken().subscribe((authToken) => {
                    sessionStorageVals['AccessToken'] = authToken;
                });
        }
        if (this.sessionService.getSessionItemDecrypted('idToken').idToken) {
            sessionStorageVals['idToken'] = this.sessionService.getSessionItemDecrypted('MFAToken').idToken;
        } else {
              this.authService.getIdToken().subscribe((jwt) => {
                  sessionStorageVals['id_token'] = JSON.stringify(jwt);
              });
        }
        const value = JSON.stringify(sessionStorageVals);
        if (value) {
            this.LOGGER.info(`sessionStorageValsString.length:${value.length}`);
        }

        const value1 = value.slice(0, value.length / 3);
        const value2 = value.slice(value.length / 3, (2 * value.length) / 3);
        const value3 = value.slice((2 * value.length) / 3);
        const domain = environment.NATIONWIDE.PRIMARY_DOMAIN;

        document.cookie = `widgetSessionStorage1=${value1};domain=${domain};path=/`;
        document.cookie = `widgetSessionStorage2=${value2};domain=${domain};path=/`;
        document.cookie = `widgetSessionStorage3=${value3};domain=${domain};path=/`;
    }

    isABredirect(policyNumber, type?: string): boolean {
        sessionStorage.setItem('fromABredirect', 'true');
        sessionStorage.setItem('PRODUCT_TYPE', 'Auto');

        if (PersonalConstants.REDIRECT_USERTYPES.indexOf(sessionStorage.getItem('userType')) >= 0) {
            this.userType = sessionStorage.getItem('userType');
            sessionStorage.setItem('registrationType', this.userType);
        } else {
            this.userType = this.sessionService.getUserType();
            sessionStorage.setItem('registrationType', this.sessionService.getUserType());
        }
        const limit = type === 'tracker' ? environment.abConfig['tracker'] : environment.abConfig[this.userType];
        this.lastDigitOfPolicy = parseInt(policyNumber.charAt(policyNumber.length - 1), 10);
        const shouldABRedirect = ((environment.setABRedirect && type !== 'tracker') ||
        (environment.trackerABRedirect && type === 'tracker'));
        if (shouldABRedirect && this.lastDigitOfPolicy > limit) {
            console.log('AB set to true');
            return true;
        } else {
            console.log('AB set to false');
            return false;
        }
    }

    getOldAppURLs(policyNumber): string {
        let navigateToUrl;
        switch (this.sessionService.getUserType()) {
            case 'registered':
                navigateToUrl = `${environment.CLAIMS_OLD_APP.BASE_URL
                    }?pkey=${policyNumber
                    }&destinationPageName=claims-list#/claims-list?policyNumber=${policyNumber}`;
                break;
            case 'unregistered-member':
                navigateToUrl = `${environment.CLAIMS_OLD_APP.BASE_URL
                    }#/unidentified-member-start-claim?policyNumber=${policyNumber}`;
                break;
            case 'unregistered-member-support':
                navigateToUrl = `${environment.CLAIMS_OLD_APP.BASE_URL
                    }#/unidentified-member-support-start-claim?policyNumber=${policyNumber}`;
                break;
            case 'unregistered-non-member':
                navigateToUrl = `${environment.CLAIMS_OLD_APP.BASE_URL
                    }#/non-member-start-claim?policyNumber=${policyNumber}`;
                break;
            case 'unregistered-non-member-support':
                navigateToUrl = `${environment.CLAIMS_OLD_APP.BASE_URL
                    }#/non-member-support-start-claim?policyNumber=${policyNumber}`;
                break;
        }
        return navigateToUrl;
    }

    getQueryParams(url: string): any {
        if (url.indexOf('?') > -1) {
            const urlFragment = url.split('?');
            const search = urlFragment[1];
            return JSON.parse(`{"${decodeURI(search)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"')}"}`);
        }
    }


    checkforIamBypass(locationPath: string): void {
        const queryParams = this.getQueryParams(locationPath);
        if (queryParams?.IAM_BYPASS === 'true' && queryParams.encodedKey) {
            console.log('AUth Service Logged In via MontyBot');
            this.setMontySession(queryParams);
        }
    }

    setHeartbeatCookie(newEntryName: string, newEntryValue: boolean): void {
        const heartbeatCookieValue = this.cookieService.get(this.heartbeatCookieName);
        let heartbeatCookieValueObj = {};
        if (heartbeatCookieValue) {
            heartbeatCookieValueObj = JSON.parse(heartbeatCookieValue);
            heartbeatCookieValueObj[newEntryName] = newEntryValue;
        } else {
            heartbeatCookieValueObj = {};
            heartbeatCookieValueObj[newEntryName] = newEntryValue;
        }
        this.cookieService.set(this.heartbeatCookieName, JSON.stringify(heartbeatCookieValueObj));
    }

    deleteHeartbeatCookie(): void {
        this.cookieService.delete(this.heartbeatCookieName);
    }

    setMontySession(queryParamsFound: any): void {
        const parsedJWT = this.authService.parseJwt(queryParamsFound?.id_token);
        window.sessionStorage.setItem('arrivedViaIAMBypass', 'true');
        window.sessionStorage.setItem('authenticatedFlag', 'true');
        window.sessionStorage.setItem('claimType', 'Auto'); // hardcoded as we have only auto flow in new app
        window.sessionStorage.setItem('showDefaultDialogue', 'true');
        window.sessionStorage.setItem('userId', parsedJWT.userId);
        window.sessionStorage.setItem('loginState', PersonalConstants.LOGIN_ENTRY_POINTS.IDENTIFICATION_PAGE);
        window.sessionStorage.setItem('AccessToken', queryParamsFound?.access_token);
        window.sessionStorage.setItem('id_token', JSON.stringify(parsedJWT));
        this.sessionService.setSessionItem(PersonalConstants.LOGIN_ENTRY_POINTS.IAMBYPASS, queryParamsFound.IAM_BYPASS);
        this.sessionService.setSessionItem('encodedKey', queryParamsFound.encodedKey);
        this.sessionService.setSessionItem('userType', 'registered');
        this.sessionService.setSessionItem(PersonalConstants.FILE_CLAIM_COMPONENT.POLICYNUMBER, this.getFormattedPolicyNumber(queryParamsFound?.pkey));
        this.authService.setAccessToken(queryParamsFound.access_token);
        this.authService.setIdToken(parsedJWT);
        this.authService.setExpiration(queryParamsFound.expires_in);
        this.authService.setAuthStatus(true, true);
        this.authService.processUserInfo(parsedJWT);
    }
}
