import { Injectable } from '@angular/core';
import { AbstractLoggerService, SplunkService } from '@nationwide/client-logging';
import { PersonalConstants } from 'app/common/personalConstants';
import { LoggerSettings } from 'app/models/logger-settings';
import { environment } from 'environments/environment';
import { EfnolCacheService } from './efnol-cache.service';
import { SessionService } from './session.service';
import { TokenDataService } from './token-data.service';

export interface ClaimsLogger extends AbstractLoggerService {
    setAccessCode(accessCode: string): void;
    setModuleName(module: string): void;
    setMember(userType: string): void;
    setPolicyNumber(policyNumber: string): void;
    setClaimNumber(claimNumber: string): void;
    setLossCause(lossCause: any): void;
    setServiceSelection(serviceSelection: any): void;
    setComponentName(componentName: any): void;
    setPageName(pageName: any): void;
    setInfo(message: string, jsonObject?: any, additionalAttributes?: any): void;
    setError(message: string, jsonObject?: any, additionalAttributes?: any): void;
    setWarn(message: string, jsonObject?: any, additionalAttributes?: any): void;
    setDebug(message: string, jsonObject?: any, additionalAttributes?: any): void;
    setTrace(message: string, jsonObject?: any, additionalAttributes?: any): void;
    buildLoggerRequest(options: any): any;
    getAuthToken(): string;
    getUser(): string;
    getSessionId(): string;
    getUniqueKey(): string;
    getStartLoadTimer(): number;
    getStopLoadTimer(): number;

}

@Injectable({
    providedIn: 'root'
})
export class CloudLoggerService extends AbstractLoggerService implements ClaimsLogger {
    clientLoggingEnabled = true;
    pushLength = environment.CLIENT_LOGGING_API.PUSH_LENGTH;
    clientId = PersonalConstants.appProps.appClientId;
    endpoint = `${environment.CLIENT_LOGGING_API.ENDPOINT}`;
    splunkToken = PersonalConstants.splunkProps.cloudToken;
    appName = PersonalConstants.splunkProps.appName;
    sourceType = PersonalConstants.splunkProps.sourceType;
    maxLogLength = PersonalConstants.splunkProps.maxLogLength;
    retryLogCurrentContentsTime = PersonalConstants.splunkProps.retryLogCurrentContentsTime;
    maxRetryAttempts = environment.CLIENT_LOGGING_API.RETRYCOUNT;
    retryDelay = 1;
    loggingJson = environment.LOGGING_JSON;
    loggingLevel = environment.LOGGING_LEVEL;
    envName = environment.envName;
    member = this.sessionService.getSessionItem('registrationType') || this.sessionService.getUserType();
    isMFAuserType = this.sessionService.getSessionItem('user') || '';
    accessCode = this.sessionService.getSessionItemDecrypted('CLAIMS_ACCESSCODE');
    policyNumber = this.sessionService.getSessionItemDecrypted(PersonalConstants.FILE_CLAIM_COMPONENT.POLICYNUMBER);
    claimNumber = this.sessionService.getClaimNumber();
    lossCause = this.efnolCacheService.getAccidentDetailsValue('accidentCause');
    serviceSelection = this.efnolCacheService.getServicesValue('inspectionOption');
    module = 'Claims';
    componentName = '';
    pageName = '';
    logToConsole = true;
    StartTime = 0;

    constructor (
        splunkService: SplunkService,
        private tokenDataService: TokenDataService,
        private sessionService: SessionService,
        private efnolCacheService: EfnolCacheService,
    ) {
        super(splunkService);
    }

    setModuleName(module: string): void {
        this.module = module;
    }

    setAccessCode(accessCode: string): void {
        this.accessCode = accessCode;
    }

    setMember(userType: string): void {
        this.member = userType;
    }

    setPolicyNumber(policyNumber: string): void {
        this.policyNumber = policyNumber;
    }

    setClaimNumber(claimNumber: string): void {
        this.claimNumber = claimNumber;
    }

    setLossCause(lossCause: any): void {
        this.lossCause = lossCause;
    }

    setServiceSelection(serviceSelection: any): void {
        this.serviceSelection = serviceSelection;
    }

    setComponentName(componentName: any): void {
        this.componentName = componentName;
    }

    setPageName(pageName: any): void {
        this.pageName = pageName;
    }

    setInfo(message: string, jsonObject?: any, additionalAttributes?: any): void {
        jsonObject = jsonObject || {};
        additionalAttributes = additionalAttributes || {};
        this.info(message, jsonObject, this.buildLoggerRequest(additionalAttributes));
    }

    setError(message: string, jsonObject?: any, additionalAttributes?: any): void {
        jsonObject = jsonObject || {};
        additionalAttributes = additionalAttributes || {};
        this.error(message, jsonObject, this.buildLoggerRequest(additionalAttributes));
    }

    setWarn(message: string, jsonObject?: any, additionalAttributes?: any): void {
        jsonObject = jsonObject || {};
        additionalAttributes = additionalAttributes || {};
        this.warn(message, jsonObject, this.buildLoggerRequest(additionalAttributes));
    }

    setDebug(message: string, jsonObject?: any, additionalAttributes?: any): void {
        jsonObject = jsonObject || {};
        additionalAttributes = additionalAttributes || {};
        this.debug(message, jsonObject, this.buildLoggerRequest(additionalAttributes));
    }

    setTrace(message: string, jsonObject?: any, additionalAttributes?: any): void {
        jsonObject = jsonObject || {};
        additionalAttributes = additionalAttributes || {};
        this.trace(message, jsonObject, this.buildLoggerRequest(additionalAttributes));
    }

    buildLoggerRequest(options: any): any {
        const isMFAuserType = `-${this.isMFAuserType}`;
        const properties: LoggerSettings = {
            userType: `${this.member}${this.isMFAuserType ? isMFAuserType : ''}`,
            componentName: this.componentName || null,
            module: this.module,
            uniqueKey: this.getUniqueKey(),
            policyNumber: this.policyNumber && Object.keys(this.policyNumber).length > 0 ? this.policyNumber : null,
            claimNumber: this.claimNumber && Object.keys(this.claimNumber).length > 0 ? this.claimNumber : null,
            lossCause: this.lossCause || null,
            pageName: this.pageName || null,
            serviceSelection: this.serviceSelection || null,
            accessCode: this.accessCode && Object.keys(this.accessCode).length > 0 ? this.accessCode : null
        };

        const deviceInfo = this.getDeviceInfo();

        return {
            ...options, ...deviceInfo, ...properties
        };
    }
    getAuthToken(): string {
        const token = this.tokenDataService.getToken();
        if (token === undefined) {
            return '';
        } else {
            return token;
        }
    }

    getUser(): string {
        const howInvolved = {
            relativeNotListed: 'relative',
            nwAttorney: 'attorney',
            nwAgent: 'agent',
            other: 'other',
            unknown: 'unknown'
        };
        const reportedByType = this.sessionService.getSessionItem('howInvolved') ?
            this.sessionService.getSessionItem('howInvolved') : 'unknown';
        if (window.sessionStorage.getItem('registrationType') === 'unregistered-member-support-property') {
            return howInvolved[reportedByType];
        }
        if (window.sessionStorage.getItem('authenticatedFlag') !== 'true') {
            const accountDetails = window.sessionStorage.getItem('accountDetails');
            if (accountDetails) {
                const accountDetailsArray: string[] = accountDetails.split(',');
                const accountNumber = accountDetailsArray[0];
                return `Account ${accountNumber}`;
            }
        } else if (window.sessionStorage.getItem('authenticatedFlag') !== 'false') {
            const userId = window.sessionStorage.getItem('userId');
            if (userId) {
                return userId;
            }
        }
        return '';
    }

    getSessionId(): string {
        try {
            const sessionId = window.sessionStorage.getItem('sessionId');
            return sessionId;
        } catch (e) {
            return '';
        }
    }

    getUniqueKey(): string {
        try {
            const uniqueKey = window.sessionStorage.getItem('uniqueKey');
            return uniqueKey;
        } catch (e) {
            return '';
        }
    }


    getStartLoadTimer(): number {
        this.StartTime = new Date().getTime();
        return this.StartTime;
    }

    getStopLoadTimer(): number {
        if (this.StartTime) {
            const start = this.StartTime;
            this.StartTime = undefined;
            return (new Date().getTime()) - start;
        }
    }

    getDeviceInfo(): any {
        return this.sessionService.getDeviceInfo();
    }
}
