import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InterpolationComponent } from './interpolation/interpolation.component';
import { NwPhoneNumberFormatComponent } from './nw-phone-number-format/nw-phone-number-format.component';

@NgModule({
    declarations: [
        NwPhoneNumberFormatComponent, InterpolationComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        NwPhoneNumberFormatComponent, InterpolationComponent
    ]
})
export class NwTextFormatModule { }
