import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class IframeQueue {
    private _in: BehaviorSubject<string>;
    private _out: BehaviorSubject<string>;

    constructor () {
        this._in = new BehaviorSubject('');
        this._out = new BehaviorSubject('');
    }

    next(url: string): Observable<string> {
        this._in.next(url);
        return this._out.pipe(filter((value) => !!value));
    }

    get in(): Observable<string> {
        return this._in as Observable<string>;
    }

    get out(): Observer<string> {
        return this._out as Observer<string>;
    }
}
